@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,600;0,800;0,900;1,500;1,600;1,700;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;0,900;1,700;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Exo:wght@900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  font-family: 'Montserrat', sans-serif;
}

.onPage {
  color: rgb(255, 255, 255);
  text-decoration: underline
}

::-webkit-scrollbar {
  width: 13px;
  display: none;
}

/* Track */
::-webkit-scrollbar-track {
  background: #3b3b3b;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #fb2ef9;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #b600b3;
}

@-webkit-keyframes fade-out {
  0% {
    height: 100%;
  }

  100% {
    height: 0%;
  }
}

@keyframes fade-out {
  0% {
    height: 100%;
  }

  100% {
    height: 0%;
  }
}

.firefly {
  position: sticky;
  position: -webkit-sticky;
  left: 50%;
  top: 50%;
  width: 0.4vw;
  height: 0.4vw;
  margin: -0.2vw 0 0 9.8vw;
  -webkit-animation: ease 200s alternate infinite;
          animation: ease 200s alternate infinite;
  pointer-events: none;
}

.firefly::before,
.firefly::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  -webkit-transform-origin: -10vw;
          transform-origin: -10vw;
}

.firefly::before {
  background: black;
  opacity: 0.4;
  -webkit-animation: drift ease alternate infinite;
          animation: drift ease alternate infinite;
}

.firefly::after {
  background: white;
  opacity: 0;
  box-shadow: 0 0 0vw 0vw #31ff40;
  -webkit-animation: drift ease alternate infinite, flash ease infinite;
          animation: drift ease alternate infinite, flash ease infinite;
}

.firefly:nth-child(1) {
  -webkit-animation-name: move1;
          animation-name: move1;
}

.firefly:nth-child(1)::before {
  -webkit-animation-duration: 10s;
          animation-duration: 10s;
}

.firefly:nth-child(1)::after {
  -webkit-animation-duration: 10s, 5869ms;
          animation-duration: 10s, 5869ms;
  -webkit-animation-delay: 0ms, 5103ms;
          animation-delay: 0ms, 5103ms;
}

@-webkit-keyframes move1 {
  0% {
    -webkit-transform: translateX(-41vw) translateY(23vh) scale(0.42);
            transform: translateX(-41vw) translateY(23vh) scale(0.42);
  }

  5% {
    -webkit-transform: translateX(9vw) translateY(-45vh) scale(0.34);
            transform: translateX(9vw) translateY(-45vh) scale(0.34);
  }

  10% {
    -webkit-transform: translateX(-17vw) translateY(-42vh) scale(1);
            transform: translateX(-17vw) translateY(-42vh) scale(1);
  }

  15% {
    -webkit-transform: translateX(8vw) translateY(-23vh) scale(0.51);
            transform: translateX(8vw) translateY(-23vh) scale(0.51);
  }

  20% {
    -webkit-transform: translateX(-29vw) translateY(-20vh) scale(0.56);
            transform: translateX(-29vw) translateY(-20vh) scale(0.56);
  }

  25% {
    -webkit-transform: translateX(8vw) translateY(16vh) scale(0.79);
            transform: translateX(8vw) translateY(16vh) scale(0.79);
  }

  30% {
    -webkit-transform: translateX(11vw) translateY(46vh) scale(0.54);
            transform: translateX(11vw) translateY(46vh) scale(0.54);
  }

  35% {
    -webkit-transform: translateX(9vw) translateY(-45vh) scale(0.58);
            transform: translateX(9vw) translateY(-45vh) scale(0.58);
  }

  40% {
    -webkit-transform: translateX(10vw) translateY(-37vh) scale(0.69);
            transform: translateX(10vw) translateY(-37vh) scale(0.69);
  }

  45% {
    -webkit-transform: translateX(5vw) translateY(26vh) scale(0.52);
            transform: translateX(5vw) translateY(26vh) scale(0.52);
  }

  50% {
    -webkit-transform: translateX(-36vw) translateY(-39vh) scale(0.43);
            transform: translateX(-36vw) translateY(-39vh) scale(0.43);
  }

  55% {
    -webkit-transform: translateX(-45vw) translateY(35vh) scale(0.96);
            transform: translateX(-45vw) translateY(35vh) scale(0.96);
  }

  60% {
    -webkit-transform: translateX(17vw) translateY(38vh) scale(0.66);
            transform: translateX(17vw) translateY(38vh) scale(0.66);
  }

  65% {
    -webkit-transform: translateX(-39vw) translateY(-34vh) scale(0.75);
            transform: translateX(-39vw) translateY(-34vh) scale(0.75);
  }

  70% {
    -webkit-transform: translateX(-45vw) translateY(18vh) scale(0.72);
            transform: translateX(-45vw) translateY(18vh) scale(0.72);
  }

  75% {
    -webkit-transform: translateX(48vw) translateY(21vh) scale(0.42);
            transform: translateX(48vw) translateY(21vh) scale(0.42);
  }

  80% {
    -webkit-transform: translateX(28vw) translateY(-2vh) scale(0.82);
            transform: translateX(28vw) translateY(-2vh) scale(0.82);
  }

  85% {
    -webkit-transform: translateX(-29vw) translateY(30vh) scale(0.48);
            transform: translateX(-29vw) translateY(30vh) scale(0.48);
  }

  90% {
    -webkit-transform: translateX(12vw) translateY(16vh) scale(0.42);
            transform: translateX(12vw) translateY(16vh) scale(0.42);
  }

  95% {
    -webkit-transform: translateX(-8vw) translateY(-24vh) scale(0.52);
            transform: translateX(-8vw) translateY(-24vh) scale(0.52);
  }

  100% {
    -webkit-transform: translateX(-49vw) translateY(6vh) scale(0.34);
            transform: translateX(-49vw) translateY(6vh) scale(0.34);
  }
}

@keyframes move1 {
  0% {
    -webkit-transform: translateX(-41vw) translateY(23vh) scale(0.42);
            transform: translateX(-41vw) translateY(23vh) scale(0.42);
  }

  5% {
    -webkit-transform: translateX(9vw) translateY(-45vh) scale(0.34);
            transform: translateX(9vw) translateY(-45vh) scale(0.34);
  }

  10% {
    -webkit-transform: translateX(-17vw) translateY(-42vh) scale(1);
            transform: translateX(-17vw) translateY(-42vh) scale(1);
  }

  15% {
    -webkit-transform: translateX(8vw) translateY(-23vh) scale(0.51);
            transform: translateX(8vw) translateY(-23vh) scale(0.51);
  }

  20% {
    -webkit-transform: translateX(-29vw) translateY(-20vh) scale(0.56);
            transform: translateX(-29vw) translateY(-20vh) scale(0.56);
  }

  25% {
    -webkit-transform: translateX(8vw) translateY(16vh) scale(0.79);
            transform: translateX(8vw) translateY(16vh) scale(0.79);
  }

  30% {
    -webkit-transform: translateX(11vw) translateY(46vh) scale(0.54);
            transform: translateX(11vw) translateY(46vh) scale(0.54);
  }

  35% {
    -webkit-transform: translateX(9vw) translateY(-45vh) scale(0.58);
            transform: translateX(9vw) translateY(-45vh) scale(0.58);
  }

  40% {
    -webkit-transform: translateX(10vw) translateY(-37vh) scale(0.69);
            transform: translateX(10vw) translateY(-37vh) scale(0.69);
  }

  45% {
    -webkit-transform: translateX(5vw) translateY(26vh) scale(0.52);
            transform: translateX(5vw) translateY(26vh) scale(0.52);
  }

  50% {
    -webkit-transform: translateX(-36vw) translateY(-39vh) scale(0.43);
            transform: translateX(-36vw) translateY(-39vh) scale(0.43);
  }

  55% {
    -webkit-transform: translateX(-45vw) translateY(35vh) scale(0.96);
            transform: translateX(-45vw) translateY(35vh) scale(0.96);
  }

  60% {
    -webkit-transform: translateX(17vw) translateY(38vh) scale(0.66);
            transform: translateX(17vw) translateY(38vh) scale(0.66);
  }

  65% {
    -webkit-transform: translateX(-39vw) translateY(-34vh) scale(0.75);
            transform: translateX(-39vw) translateY(-34vh) scale(0.75);
  }

  70% {
    -webkit-transform: translateX(-45vw) translateY(18vh) scale(0.72);
            transform: translateX(-45vw) translateY(18vh) scale(0.72);
  }

  75% {
    -webkit-transform: translateX(48vw) translateY(21vh) scale(0.42);
            transform: translateX(48vw) translateY(21vh) scale(0.42);
  }

  80% {
    -webkit-transform: translateX(28vw) translateY(-2vh) scale(0.82);
            transform: translateX(28vw) translateY(-2vh) scale(0.82);
  }

  85% {
    -webkit-transform: translateX(-29vw) translateY(30vh) scale(0.48);
            transform: translateX(-29vw) translateY(30vh) scale(0.48);
  }

  90% {
    -webkit-transform: translateX(12vw) translateY(16vh) scale(0.42);
            transform: translateX(12vw) translateY(16vh) scale(0.42);
  }

  95% {
    -webkit-transform: translateX(-8vw) translateY(-24vh) scale(0.52);
            transform: translateX(-8vw) translateY(-24vh) scale(0.52);
  }

  100% {
    -webkit-transform: translateX(-49vw) translateY(6vh) scale(0.34);
            transform: translateX(-49vw) translateY(6vh) scale(0.34);
  }
}

.firefly:nth-child(2) {
  -webkit-animation-name: move2;
          animation-name: move2;
}

.firefly:nth-child(2)::before {
  -webkit-animation-duration: 13s;
          animation-duration: 13s;
}

.firefly:nth-child(2)::after {
  -webkit-animation-duration: 13s, 8622ms;
          animation-duration: 13s, 8622ms;
  -webkit-animation-delay: 0ms, 6050ms;
          animation-delay: 0ms, 6050ms;
}

@-webkit-keyframes move2 {
  0% {
    -webkit-transform: translateX(-13vw) translateY(17vh) scale(0.94);
            transform: translateX(-13vw) translateY(17vh) scale(0.94);
  }

  4.347826087% {
    -webkit-transform: translateX(12vw) translateY(24vh) scale(0.48);
            transform: translateX(12vw) translateY(24vh) scale(0.48);
  }

  8.6956521739% {
    -webkit-transform: translateX(16vw) translateY(-40vh) scale(0.39);
            transform: translateX(16vw) translateY(-40vh) scale(0.39);
  }

  13.0434782609% {
    -webkit-transform: translateX(22vw) translateY(22vh) scale(0.68);
            transform: translateX(22vw) translateY(22vh) scale(0.68);
  }

  17.3913043478% {
    -webkit-transform: translateX(19vw) translateY(-14vh) scale(0.42);
            transform: translateX(19vw) translateY(-14vh) scale(0.42);
  }

  21.7391304348% {
    -webkit-transform: translateX(2vw) translateY(-18vh) scale(0.3);
            transform: translateX(2vw) translateY(-18vh) scale(0.3);
  }

  26.0869565217% {
    -webkit-transform: translateX(2vw) translateY(38vh) scale(0.29);
            transform: translateX(2vw) translateY(38vh) scale(0.29);
  }

  30.4347826087% {
    -webkit-transform: translateX(28vw) translateY(6vh) scale(0.87);
            transform: translateX(28vw) translateY(6vh) scale(0.87);
  }

  34.7826086957% {
    -webkit-transform: translateX(-48vw) translateY(21vh) scale(0.88);
            transform: translateX(-48vw) translateY(21vh) scale(0.88);
  }

  39.1304347826% {
    -webkit-transform: translateX(32vw) translateY(15vh) scale(0.76);
            transform: translateX(32vw) translateY(15vh) scale(0.76);
  }

  43.4782608696% {
    -webkit-transform: translateX(32vw) translateY(1vh) scale(0.31);
            transform: translateX(32vw) translateY(1vh) scale(0.31);
  }

  47.8260869565% {
    -webkit-transform: translateX(35vw) translateY(0vh) scale(0.29);
            transform: translateX(35vw) translateY(0vh) scale(0.29);
  }

  52.1739130435% {
    -webkit-transform: translateX(9vw) translateY(14vh) scale(0.27);
            transform: translateX(9vw) translateY(14vh) scale(0.27);
  }

  56.5217391304% {
    -webkit-transform: translateX(12vw) translateY(-20vh) scale(0.64);
            transform: translateX(12vw) translateY(-20vh) scale(0.64);
  }

  60.8695652174% {
    -webkit-transform: translateX(-21vw) translateY(27vh) scale(0.72);
            transform: translateX(-21vw) translateY(27vh) scale(0.72);
  }

  65.2173913043% {
    -webkit-transform: translateX(-47vw) translateY(-33vh) scale(0.31);
            transform: translateX(-47vw) translateY(-33vh) scale(0.31);
  }

  69.5652173913% {
    -webkit-transform: translateX(-25vw) translateY(7vh) scale(0.85);
            transform: translateX(-25vw) translateY(7vh) scale(0.85);
  }

  73.9130434783% {
    -webkit-transform: translateX(42vw) translateY(38vh) scale(0.88);
            transform: translateX(42vw) translateY(38vh) scale(0.88);
  }

  78.2608695652% {
    -webkit-transform: translateX(11vw) translateY(10vh) scale(0.91);
            transform: translateX(11vw) translateY(10vh) scale(0.91);
  }

  82.6086956522% {
    -webkit-transform: translateX(2vw) translateY(6vh) scale(0.59);
            transform: translateX(2vw) translateY(6vh) scale(0.59);
  }

  86.9565217391% {
    -webkit-transform: translateX(37vw) translateY(4vh) scale(0.59);
            transform: translateX(37vw) translateY(4vh) scale(0.59);
  }

  91.3043478261% {
    -webkit-transform: translateX(-34vw) translateY(-32vh) scale(0.95);
            transform: translateX(-34vw) translateY(-32vh) scale(0.95);
  }

  95.652173913% {
    -webkit-transform: translateX(-47vw) translateY(32vh) scale(0.81);
            transform: translateX(-47vw) translateY(32vh) scale(0.81);
  }

  100% {
    -webkit-transform: translateX(32vw) translateY(22vh) scale(0.53);
            transform: translateX(32vw) translateY(22vh) scale(0.53);
  }
}

@keyframes move2 {
  0% {
    -webkit-transform: translateX(-13vw) translateY(17vh) scale(0.94);
            transform: translateX(-13vw) translateY(17vh) scale(0.94);
  }

  4.347826087% {
    -webkit-transform: translateX(12vw) translateY(24vh) scale(0.48);
            transform: translateX(12vw) translateY(24vh) scale(0.48);
  }

  8.6956521739% {
    -webkit-transform: translateX(16vw) translateY(-40vh) scale(0.39);
            transform: translateX(16vw) translateY(-40vh) scale(0.39);
  }

  13.0434782609% {
    -webkit-transform: translateX(22vw) translateY(22vh) scale(0.68);
            transform: translateX(22vw) translateY(22vh) scale(0.68);
  }

  17.3913043478% {
    -webkit-transform: translateX(19vw) translateY(-14vh) scale(0.42);
            transform: translateX(19vw) translateY(-14vh) scale(0.42);
  }

  21.7391304348% {
    -webkit-transform: translateX(2vw) translateY(-18vh) scale(0.3);
            transform: translateX(2vw) translateY(-18vh) scale(0.3);
  }

  26.0869565217% {
    -webkit-transform: translateX(2vw) translateY(38vh) scale(0.29);
            transform: translateX(2vw) translateY(38vh) scale(0.29);
  }

  30.4347826087% {
    -webkit-transform: translateX(28vw) translateY(6vh) scale(0.87);
            transform: translateX(28vw) translateY(6vh) scale(0.87);
  }

  34.7826086957% {
    -webkit-transform: translateX(-48vw) translateY(21vh) scale(0.88);
            transform: translateX(-48vw) translateY(21vh) scale(0.88);
  }

  39.1304347826% {
    -webkit-transform: translateX(32vw) translateY(15vh) scale(0.76);
            transform: translateX(32vw) translateY(15vh) scale(0.76);
  }

  43.4782608696% {
    -webkit-transform: translateX(32vw) translateY(1vh) scale(0.31);
            transform: translateX(32vw) translateY(1vh) scale(0.31);
  }

  47.8260869565% {
    -webkit-transform: translateX(35vw) translateY(0vh) scale(0.29);
            transform: translateX(35vw) translateY(0vh) scale(0.29);
  }

  52.1739130435% {
    -webkit-transform: translateX(9vw) translateY(14vh) scale(0.27);
            transform: translateX(9vw) translateY(14vh) scale(0.27);
  }

  56.5217391304% {
    -webkit-transform: translateX(12vw) translateY(-20vh) scale(0.64);
            transform: translateX(12vw) translateY(-20vh) scale(0.64);
  }

  60.8695652174% {
    -webkit-transform: translateX(-21vw) translateY(27vh) scale(0.72);
            transform: translateX(-21vw) translateY(27vh) scale(0.72);
  }

  65.2173913043% {
    -webkit-transform: translateX(-47vw) translateY(-33vh) scale(0.31);
            transform: translateX(-47vw) translateY(-33vh) scale(0.31);
  }

  69.5652173913% {
    -webkit-transform: translateX(-25vw) translateY(7vh) scale(0.85);
            transform: translateX(-25vw) translateY(7vh) scale(0.85);
  }

  73.9130434783% {
    -webkit-transform: translateX(42vw) translateY(38vh) scale(0.88);
            transform: translateX(42vw) translateY(38vh) scale(0.88);
  }

  78.2608695652% {
    -webkit-transform: translateX(11vw) translateY(10vh) scale(0.91);
            transform: translateX(11vw) translateY(10vh) scale(0.91);
  }

  82.6086956522% {
    -webkit-transform: translateX(2vw) translateY(6vh) scale(0.59);
            transform: translateX(2vw) translateY(6vh) scale(0.59);
  }

  86.9565217391% {
    -webkit-transform: translateX(37vw) translateY(4vh) scale(0.59);
            transform: translateX(37vw) translateY(4vh) scale(0.59);
  }

  91.3043478261% {
    -webkit-transform: translateX(-34vw) translateY(-32vh) scale(0.95);
            transform: translateX(-34vw) translateY(-32vh) scale(0.95);
  }

  95.652173913% {
    -webkit-transform: translateX(-47vw) translateY(32vh) scale(0.81);
            transform: translateX(-47vw) translateY(32vh) scale(0.81);
  }

  100% {
    -webkit-transform: translateX(32vw) translateY(22vh) scale(0.53);
            transform: translateX(32vw) translateY(22vh) scale(0.53);
  }
}

.firefly:nth-child(3) {
  -webkit-animation-name: move3;
          animation-name: move3;
}

.firefly:nth-child(3)::before {
  -webkit-animation-duration: 14s;
          animation-duration: 14s;
}

.firefly:nth-child(3)::after {
  -webkit-animation-duration: 14s, 7972ms;
          animation-duration: 14s, 7972ms;
  -webkit-animation-delay: 0ms, 2219ms;
          animation-delay: 0ms, 2219ms;
}

@-webkit-keyframes move3 {
  0% {
    -webkit-transform: translateX(-9vw) translateY(-12vh) scale(0.35);
            transform: translateX(-9vw) translateY(-12vh) scale(0.35);
  }

  4% {
    -webkit-transform: translateX(9vw) translateY(45vh) scale(0.75);
            transform: translateX(9vw) translateY(45vh) scale(0.75);
  }

  8% {
    -webkit-transform: translateX(37vw) translateY(-35vh) scale(0.42);
            transform: translateX(37vw) translateY(-35vh) scale(0.42);
  }

  12% {
    -webkit-transform: translateX(-49vw) translateY(5vh) scale(0.46);
            transform: translateX(-49vw) translateY(5vh) scale(0.46);
  }

  16% {
    -webkit-transform: translateX(42vw) translateY(26vh) scale(0.34);
            transform: translateX(42vw) translateY(26vh) scale(0.34);
  }

  20% {
    -webkit-transform: translateX(-12vw) translateY(19vh) scale(0.84);
            transform: translateX(-12vw) translateY(19vh) scale(0.84);
  }

  24% {
    -webkit-transform: translateX(23vw) translateY(6vh) scale(0.45);
            transform: translateX(23vw) translateY(6vh) scale(0.45);
  }

  28% {
    -webkit-transform: translateX(18vw) translateY(-16vh) scale(0.47);
            transform: translateX(18vw) translateY(-16vh) scale(0.47);
  }

  32% {
    -webkit-transform: translateX(22vw) translateY(-40vh) scale(0.45);
            transform: translateX(22vw) translateY(-40vh) scale(0.45);
  }

  36% {
    -webkit-transform: translateX(-37vw) translateY(33vh) scale(0.75);
            transform: translateX(-37vw) translateY(33vh) scale(0.75);
  }

  40% {
    -webkit-transform: translateX(44vw) translateY(33vh) scale(0.61);
            transform: translateX(44vw) translateY(33vh) scale(0.61);
  }

  44% {
    -webkit-transform: translateX(-44vw) translateY(-18vh) scale(0.57);
            transform: translateX(-44vw) translateY(-18vh) scale(0.57);
  }

  48% {
    -webkit-transform: translateX(-9vw) translateY(39vh) scale(0.45);
            transform: translateX(-9vw) translateY(39vh) scale(0.45);
  }

  52% {
    -webkit-transform: translateX(10vw) translateY(41vh) scale(0.51);
            transform: translateX(10vw) translateY(41vh) scale(0.51);
  }

  56% {
    -webkit-transform: translateX(23vw) translateY(-41vh) scale(0.31);
            transform: translateX(23vw) translateY(-41vh) scale(0.31);
  }

  60% {
    -webkit-transform: translateX(48vw) translateY(-5vh) scale(0.33);
            transform: translateX(48vw) translateY(-5vh) scale(0.33);
  }

  64% {
    -webkit-transform: translateX(-49vw) translateY(-43vh) scale(0.3);
            transform: translateX(-49vw) translateY(-43vh) scale(0.3);
  }

  68% {
    -webkit-transform: translateX(-39vw) translateY(5vh) scale(0.44);
            transform: translateX(-39vw) translateY(5vh) scale(0.44);
  }

  72% {
    -webkit-transform: translateX(-18vw) translateY(-28vh) scale(0.94);
            transform: translateX(-18vw) translateY(-28vh) scale(0.94);
  }

  76% {
    -webkit-transform: translateX(22vw) translateY(-18vh) scale(0.67);
            transform: translateX(22vw) translateY(-18vh) scale(0.67);
  }

  80% {
    -webkit-transform: translateX(10vw) translateY(-32vh) scale(0.56);
            transform: translateX(10vw) translateY(-32vh) scale(0.56);
  }

  84% {
    -webkit-transform: translateX(-7vw) translateY(-48vh) scale(0.47);
            transform: translateX(-7vw) translateY(-48vh) scale(0.47);
  }

  88% {
    -webkit-transform: translateX(30vw) translateY(37vh) scale(0.64);
            transform: translateX(30vw) translateY(37vh) scale(0.64);
  }

  92% {
    -webkit-transform: translateX(-46vw) translateY(-37vh) scale(0.53);
            transform: translateX(-46vw) translateY(-37vh) scale(0.53);
  }

  96% {
    -webkit-transform: translateX(9vw) translateY(40vh) scale(0.87);
            transform: translateX(9vw) translateY(40vh) scale(0.87);
  }

  100% {
    -webkit-transform: translateX(-48vw) translateY(-17vh) scale(0.46);
            transform: translateX(-48vw) translateY(-17vh) scale(0.46);
  }
}

@keyframes move3 {
  0% {
    -webkit-transform: translateX(-9vw) translateY(-12vh) scale(0.35);
            transform: translateX(-9vw) translateY(-12vh) scale(0.35);
  }

  4% {
    -webkit-transform: translateX(9vw) translateY(45vh) scale(0.75);
            transform: translateX(9vw) translateY(45vh) scale(0.75);
  }

  8% {
    -webkit-transform: translateX(37vw) translateY(-35vh) scale(0.42);
            transform: translateX(37vw) translateY(-35vh) scale(0.42);
  }

  12% {
    -webkit-transform: translateX(-49vw) translateY(5vh) scale(0.46);
            transform: translateX(-49vw) translateY(5vh) scale(0.46);
  }

  16% {
    -webkit-transform: translateX(42vw) translateY(26vh) scale(0.34);
            transform: translateX(42vw) translateY(26vh) scale(0.34);
  }

  20% {
    -webkit-transform: translateX(-12vw) translateY(19vh) scale(0.84);
            transform: translateX(-12vw) translateY(19vh) scale(0.84);
  }

  24% {
    -webkit-transform: translateX(23vw) translateY(6vh) scale(0.45);
            transform: translateX(23vw) translateY(6vh) scale(0.45);
  }

  28% {
    -webkit-transform: translateX(18vw) translateY(-16vh) scale(0.47);
            transform: translateX(18vw) translateY(-16vh) scale(0.47);
  }

  32% {
    -webkit-transform: translateX(22vw) translateY(-40vh) scale(0.45);
            transform: translateX(22vw) translateY(-40vh) scale(0.45);
  }

  36% {
    -webkit-transform: translateX(-37vw) translateY(33vh) scale(0.75);
            transform: translateX(-37vw) translateY(33vh) scale(0.75);
  }

  40% {
    -webkit-transform: translateX(44vw) translateY(33vh) scale(0.61);
            transform: translateX(44vw) translateY(33vh) scale(0.61);
  }

  44% {
    -webkit-transform: translateX(-44vw) translateY(-18vh) scale(0.57);
            transform: translateX(-44vw) translateY(-18vh) scale(0.57);
  }

  48% {
    -webkit-transform: translateX(-9vw) translateY(39vh) scale(0.45);
            transform: translateX(-9vw) translateY(39vh) scale(0.45);
  }

  52% {
    -webkit-transform: translateX(10vw) translateY(41vh) scale(0.51);
            transform: translateX(10vw) translateY(41vh) scale(0.51);
  }

  56% {
    -webkit-transform: translateX(23vw) translateY(-41vh) scale(0.31);
            transform: translateX(23vw) translateY(-41vh) scale(0.31);
  }

  60% {
    -webkit-transform: translateX(48vw) translateY(-5vh) scale(0.33);
            transform: translateX(48vw) translateY(-5vh) scale(0.33);
  }

  64% {
    -webkit-transform: translateX(-49vw) translateY(-43vh) scale(0.3);
            transform: translateX(-49vw) translateY(-43vh) scale(0.3);
  }

  68% {
    -webkit-transform: translateX(-39vw) translateY(5vh) scale(0.44);
            transform: translateX(-39vw) translateY(5vh) scale(0.44);
  }

  72% {
    -webkit-transform: translateX(-18vw) translateY(-28vh) scale(0.94);
            transform: translateX(-18vw) translateY(-28vh) scale(0.94);
  }

  76% {
    -webkit-transform: translateX(22vw) translateY(-18vh) scale(0.67);
            transform: translateX(22vw) translateY(-18vh) scale(0.67);
  }

  80% {
    -webkit-transform: translateX(10vw) translateY(-32vh) scale(0.56);
            transform: translateX(10vw) translateY(-32vh) scale(0.56);
  }

  84% {
    -webkit-transform: translateX(-7vw) translateY(-48vh) scale(0.47);
            transform: translateX(-7vw) translateY(-48vh) scale(0.47);
  }

  88% {
    -webkit-transform: translateX(30vw) translateY(37vh) scale(0.64);
            transform: translateX(30vw) translateY(37vh) scale(0.64);
  }

  92% {
    -webkit-transform: translateX(-46vw) translateY(-37vh) scale(0.53);
            transform: translateX(-46vw) translateY(-37vh) scale(0.53);
  }

  96% {
    -webkit-transform: translateX(9vw) translateY(40vh) scale(0.87);
            transform: translateX(9vw) translateY(40vh) scale(0.87);
  }

  100% {
    -webkit-transform: translateX(-48vw) translateY(-17vh) scale(0.46);
            transform: translateX(-48vw) translateY(-17vh) scale(0.46);
  }
}

.firefly:nth-child(4) {
  -webkit-animation-name: move4;
          animation-name: move4;
}

.firefly:nth-child(4)::before {
  -webkit-animation-duration: 10s;
          animation-duration: 10s;
}

.firefly:nth-child(4)::after {
  -webkit-animation-duration: 10s, 8866ms;
          animation-duration: 10s, 8866ms;
  -webkit-animation-delay: 0ms, 4122ms;
          animation-delay: 0ms, 4122ms;
}

@-webkit-keyframes move4 {
  0% {
    -webkit-transform: translateX(-1vw) translateY(17vh) scale(0.76);
            transform: translateX(-1vw) translateY(17vh) scale(0.76);
  }

  5.5555555556% {
    -webkit-transform: translateX(-20vw) translateY(-6vh) scale(0.96);
            transform: translateX(-20vw) translateY(-6vh) scale(0.96);
  }

  11.1111111111% {
    -webkit-transform: translateX(7vw) translateY(27vh) scale(0.44);
            transform: translateX(7vw) translateY(27vh) scale(0.44);
  }

  16.6666666667% {
    -webkit-transform: translateX(1vw) translateY(32vh) scale(0.75);
            transform: translateX(1vw) translateY(32vh) scale(0.75);
  }

  22.2222222222% {
    -webkit-transform: translateX(48vw) translateY(-14vh) scale(0.83);
            transform: translateX(48vw) translateY(-14vh) scale(0.83);
  }

  27.7777777778% {
    -webkit-transform: translateX(-34vw) translateY(-30vh) scale(0.6);
            transform: translateX(-34vw) translateY(-30vh) scale(0.6);
  }

  33.3333333333% {
    -webkit-transform: translateX(40vw) translateY(-27vh) scale(1);
            transform: translateX(40vw) translateY(-27vh) scale(1);
  }

  38.8888888889% {
    -webkit-transform: translateX(-14vw) translateY(-28vh) scale(0.29);
            transform: translateX(-14vw) translateY(-28vh) scale(0.29);
  }

  44.4444444444% {
    -webkit-transform: translateX(37vw) translateY(-14vh) scale(0.56);
            transform: translateX(37vw) translateY(-14vh) scale(0.56);
  }

  50% {
    -webkit-transform: translateX(29vw) translateY(8vh) scale(0.37);
            transform: translateX(29vw) translateY(8vh) scale(0.37);
  }

  55.5555555556% {
    -webkit-transform: translateX(-14vw) translateY(21vh) scale(0.39);
            transform: translateX(-14vw) translateY(21vh) scale(0.39);
  }

  61.1111111111% {
    -webkit-transform: translateX(-1vw) translateY(38vh) scale(0.64);
            transform: translateX(-1vw) translateY(38vh) scale(0.64);
  }

  66.6666666667% {
    -webkit-transform: translateX(40vw) translateY(-17vh) scale(0.78);
            transform: translateX(40vw) translateY(-17vh) scale(0.78);
  }

  72.2222222222% {
    -webkit-transform: translateX(-6vw) translateY(-45vh) scale(0.54);
            transform: translateX(-6vw) translateY(-45vh) scale(0.54);
  }

  77.7777777778% {
    -webkit-transform: translateX(-47vw) translateY(-46vh) scale(0.38);
            transform: translateX(-47vw) translateY(-46vh) scale(0.38);
  }

  83.3333333333% {
    -webkit-transform: translateX(38vw) translateY(-13vh) scale(0.86);
            transform: translateX(38vw) translateY(-13vh) scale(0.86);
  }

  88.8888888889% {
    -webkit-transform: translateX(-8vw) translateY(35vh) scale(0.39);
            transform: translateX(-8vw) translateY(35vh) scale(0.39);
  }

  94.4444444444% {
    -webkit-transform: translateX(-25vw) translateY(-1vh) scale(0.44);
            transform: translateX(-25vw) translateY(-1vh) scale(0.44);
  }

  100% {
    -webkit-transform: translateX(0vw) translateY(-34vh) scale(0.57);
            transform: translateX(0vw) translateY(-34vh) scale(0.57);
  }
}

@keyframes move4 {
  0% {
    -webkit-transform: translateX(-1vw) translateY(17vh) scale(0.76);
            transform: translateX(-1vw) translateY(17vh) scale(0.76);
  }

  5.5555555556% {
    -webkit-transform: translateX(-20vw) translateY(-6vh) scale(0.96);
            transform: translateX(-20vw) translateY(-6vh) scale(0.96);
  }

  11.1111111111% {
    -webkit-transform: translateX(7vw) translateY(27vh) scale(0.44);
            transform: translateX(7vw) translateY(27vh) scale(0.44);
  }

  16.6666666667% {
    -webkit-transform: translateX(1vw) translateY(32vh) scale(0.75);
            transform: translateX(1vw) translateY(32vh) scale(0.75);
  }

  22.2222222222% {
    -webkit-transform: translateX(48vw) translateY(-14vh) scale(0.83);
            transform: translateX(48vw) translateY(-14vh) scale(0.83);
  }

  27.7777777778% {
    -webkit-transform: translateX(-34vw) translateY(-30vh) scale(0.6);
            transform: translateX(-34vw) translateY(-30vh) scale(0.6);
  }

  33.3333333333% {
    -webkit-transform: translateX(40vw) translateY(-27vh) scale(1);
            transform: translateX(40vw) translateY(-27vh) scale(1);
  }

  38.8888888889% {
    -webkit-transform: translateX(-14vw) translateY(-28vh) scale(0.29);
            transform: translateX(-14vw) translateY(-28vh) scale(0.29);
  }

  44.4444444444% {
    -webkit-transform: translateX(37vw) translateY(-14vh) scale(0.56);
            transform: translateX(37vw) translateY(-14vh) scale(0.56);
  }

  50% {
    -webkit-transform: translateX(29vw) translateY(8vh) scale(0.37);
            transform: translateX(29vw) translateY(8vh) scale(0.37);
  }

  55.5555555556% {
    -webkit-transform: translateX(-14vw) translateY(21vh) scale(0.39);
            transform: translateX(-14vw) translateY(21vh) scale(0.39);
  }

  61.1111111111% {
    -webkit-transform: translateX(-1vw) translateY(38vh) scale(0.64);
            transform: translateX(-1vw) translateY(38vh) scale(0.64);
  }

  66.6666666667% {
    -webkit-transform: translateX(40vw) translateY(-17vh) scale(0.78);
            transform: translateX(40vw) translateY(-17vh) scale(0.78);
  }

  72.2222222222% {
    -webkit-transform: translateX(-6vw) translateY(-45vh) scale(0.54);
            transform: translateX(-6vw) translateY(-45vh) scale(0.54);
  }

  77.7777777778% {
    -webkit-transform: translateX(-47vw) translateY(-46vh) scale(0.38);
            transform: translateX(-47vw) translateY(-46vh) scale(0.38);
  }

  83.3333333333% {
    -webkit-transform: translateX(38vw) translateY(-13vh) scale(0.86);
            transform: translateX(38vw) translateY(-13vh) scale(0.86);
  }

  88.8888888889% {
    -webkit-transform: translateX(-8vw) translateY(35vh) scale(0.39);
            transform: translateX(-8vw) translateY(35vh) scale(0.39);
  }

  94.4444444444% {
    -webkit-transform: translateX(-25vw) translateY(-1vh) scale(0.44);
            transform: translateX(-25vw) translateY(-1vh) scale(0.44);
  }

  100% {
    -webkit-transform: translateX(0vw) translateY(-34vh) scale(0.57);
            transform: translateX(0vw) translateY(-34vh) scale(0.57);
  }
}

.firefly:nth-child(5) {
  -webkit-animation-name: move5;
          animation-name: move5;
}

.firefly:nth-child(5)::before {
  -webkit-animation-duration: 18s;
          animation-duration: 18s;
}

.firefly:nth-child(5)::after {
  -webkit-animation-duration: 18s, 6640ms;
          animation-duration: 18s, 6640ms;
  -webkit-animation-delay: 0ms, 1937ms;
          animation-delay: 0ms, 1937ms;
}

@-webkit-keyframes move5 {
  0% {
    -webkit-transform: translateX(33vw) translateY(-14vh) scale(0.83);
            transform: translateX(33vw) translateY(-14vh) scale(0.83);
  }

  4.5454545455% {
    -webkit-transform: translateX(-14vw) translateY(6vh) scale(0.26);
            transform: translateX(-14vw) translateY(6vh) scale(0.26);
  }

  9.0909090909% {
    -webkit-transform: translateX(-15vw) translateY(-12vh) scale(0.56);
            transform: translateX(-15vw) translateY(-12vh) scale(0.56);
  }

  13.6363636364% {
    -webkit-transform: translateX(39vw) translateY(20vh) scale(0.69);
            transform: translateX(39vw) translateY(20vh) scale(0.69);
  }

  18.1818181818% {
    -webkit-transform: translateX(-21vw) translateY(-1vh) scale(0.27);
            transform: translateX(-21vw) translateY(-1vh) scale(0.27);
  }

  22.7272727273% {
    -webkit-transform: translateX(-38vw) translateY(47vh) scale(0.43);
            transform: translateX(-38vw) translateY(47vh) scale(0.43);
  }

  27.2727272727% {
    -webkit-transform: translateX(10vw) translateY(-4vh) scale(0.53);
            transform: translateX(10vw) translateY(-4vh) scale(0.53);
  }

  31.8181818182% {
    -webkit-transform: translateX(-13vw) translateY(21vh) scale(0.88);
            transform: translateX(-13vw) translateY(21vh) scale(0.88);
  }

  36.3636363636% {
    -webkit-transform: translateX(-3vw) translateY(46vh) scale(0.76);
            transform: translateX(-3vw) translateY(46vh) scale(0.76);
  }

  40.9090909091% {
    -webkit-transform: translateX(-2vw) translateY(-11vh) scale(0.32);
            transform: translateX(-2vw) translateY(-11vh) scale(0.32);
  }

  45.4545454545% {
    -webkit-transform: translateX(14vw) translateY(4vh) scale(0.32);
            transform: translateX(14vw) translateY(4vh) scale(0.32);
  }

  50% {
    -webkit-transform: translateX(8vw) translateY(8vh) scale(0.76);
            transform: translateX(8vw) translateY(8vh) scale(0.76);
  }

  54.5454545455% {
    -webkit-transform: translateX(18vw) translateY(-35vh) scale(0.6);
            transform: translateX(18vw) translateY(-35vh) scale(0.6);
  }

  59.0909090909% {
    -webkit-transform: translateX(15vw) translateY(-43vh) scale(0.28);
            transform: translateX(15vw) translateY(-43vh) scale(0.28);
  }

  63.6363636364% {
    -webkit-transform: translateX(-12vw) translateY(12vh) scale(0.68);
            transform: translateX(-12vw) translateY(12vh) scale(0.68);
  }

  68.1818181818% {
    -webkit-transform: translateX(5vw) translateY(25vh) scale(0.96);
            transform: translateX(5vw) translateY(25vh) scale(0.96);
  }

  72.7272727273% {
    -webkit-transform: translateX(-21vw) translateY(-35vh) scale(0.4);
            transform: translateX(-21vw) translateY(-35vh) scale(0.4);
  }

  77.2727272727% {
    -webkit-transform: translateX(-44vw) translateY(7vh) scale(0.98);
            transform: translateX(-44vw) translateY(7vh) scale(0.98);
  }

  81.8181818182% {
    -webkit-transform: translateX(40vw) translateY(-29vh) scale(0.91);
            transform: translateX(40vw) translateY(-29vh) scale(0.91);
  }

  86.3636363636% {
    -webkit-transform: translateX(30vw) translateY(-36vh) scale(0.49);
            transform: translateX(30vw) translateY(-36vh) scale(0.49);
  }

  90.9090909091% {
    -webkit-transform: translateX(48vw) translateY(22vh) scale(0.67);
            transform: translateX(48vw) translateY(22vh) scale(0.67);
  }

  95.4545454545% {
    -webkit-transform: translateX(18vw) translateY(-10vh) scale(0.77);
            transform: translateX(18vw) translateY(-10vh) scale(0.77);
  }

  100% {
    -webkit-transform: translateX(-40vw) translateY(-6vh) scale(0.91);
            transform: translateX(-40vw) translateY(-6vh) scale(0.91);
  }
}

@keyframes move5 {
  0% {
    -webkit-transform: translateX(33vw) translateY(-14vh) scale(0.83);
            transform: translateX(33vw) translateY(-14vh) scale(0.83);
  }

  4.5454545455% {
    -webkit-transform: translateX(-14vw) translateY(6vh) scale(0.26);
            transform: translateX(-14vw) translateY(6vh) scale(0.26);
  }

  9.0909090909% {
    -webkit-transform: translateX(-15vw) translateY(-12vh) scale(0.56);
            transform: translateX(-15vw) translateY(-12vh) scale(0.56);
  }

  13.6363636364% {
    -webkit-transform: translateX(39vw) translateY(20vh) scale(0.69);
            transform: translateX(39vw) translateY(20vh) scale(0.69);
  }

  18.1818181818% {
    -webkit-transform: translateX(-21vw) translateY(-1vh) scale(0.27);
            transform: translateX(-21vw) translateY(-1vh) scale(0.27);
  }

  22.7272727273% {
    -webkit-transform: translateX(-38vw) translateY(47vh) scale(0.43);
            transform: translateX(-38vw) translateY(47vh) scale(0.43);
  }

  27.2727272727% {
    -webkit-transform: translateX(10vw) translateY(-4vh) scale(0.53);
            transform: translateX(10vw) translateY(-4vh) scale(0.53);
  }

  31.8181818182% {
    -webkit-transform: translateX(-13vw) translateY(21vh) scale(0.88);
            transform: translateX(-13vw) translateY(21vh) scale(0.88);
  }

  36.3636363636% {
    -webkit-transform: translateX(-3vw) translateY(46vh) scale(0.76);
            transform: translateX(-3vw) translateY(46vh) scale(0.76);
  }

  40.9090909091% {
    -webkit-transform: translateX(-2vw) translateY(-11vh) scale(0.32);
            transform: translateX(-2vw) translateY(-11vh) scale(0.32);
  }

  45.4545454545% {
    -webkit-transform: translateX(14vw) translateY(4vh) scale(0.32);
            transform: translateX(14vw) translateY(4vh) scale(0.32);
  }

  50% {
    -webkit-transform: translateX(8vw) translateY(8vh) scale(0.76);
            transform: translateX(8vw) translateY(8vh) scale(0.76);
  }

  54.5454545455% {
    -webkit-transform: translateX(18vw) translateY(-35vh) scale(0.6);
            transform: translateX(18vw) translateY(-35vh) scale(0.6);
  }

  59.0909090909% {
    -webkit-transform: translateX(15vw) translateY(-43vh) scale(0.28);
            transform: translateX(15vw) translateY(-43vh) scale(0.28);
  }

  63.6363636364% {
    -webkit-transform: translateX(-12vw) translateY(12vh) scale(0.68);
            transform: translateX(-12vw) translateY(12vh) scale(0.68);
  }

  68.1818181818% {
    -webkit-transform: translateX(5vw) translateY(25vh) scale(0.96);
            transform: translateX(5vw) translateY(25vh) scale(0.96);
  }

  72.7272727273% {
    -webkit-transform: translateX(-21vw) translateY(-35vh) scale(0.4);
            transform: translateX(-21vw) translateY(-35vh) scale(0.4);
  }

  77.2727272727% {
    -webkit-transform: translateX(-44vw) translateY(7vh) scale(0.98);
            transform: translateX(-44vw) translateY(7vh) scale(0.98);
  }

  81.8181818182% {
    -webkit-transform: translateX(40vw) translateY(-29vh) scale(0.91);
            transform: translateX(40vw) translateY(-29vh) scale(0.91);
  }

  86.3636363636% {
    -webkit-transform: translateX(30vw) translateY(-36vh) scale(0.49);
            transform: translateX(30vw) translateY(-36vh) scale(0.49);
  }

  90.9090909091% {
    -webkit-transform: translateX(48vw) translateY(22vh) scale(0.67);
            transform: translateX(48vw) translateY(22vh) scale(0.67);
  }

  95.4545454545% {
    -webkit-transform: translateX(18vw) translateY(-10vh) scale(0.77);
            transform: translateX(18vw) translateY(-10vh) scale(0.77);
  }

  100% {
    -webkit-transform: translateX(-40vw) translateY(-6vh) scale(0.91);
            transform: translateX(-40vw) translateY(-6vh) scale(0.91);
  }
}

.firefly:nth-child(6) {
  -webkit-animation-name: move6;
          animation-name: move6;
}

.firefly:nth-child(6)::before {
  -webkit-animation-duration: 15s;
          animation-duration: 15s;
}

.firefly:nth-child(6)::after {
  -webkit-animation-duration: 15s, 8079ms;
          animation-duration: 15s, 8079ms;
  -webkit-animation-delay: 0ms, 6703ms;
          animation-delay: 0ms, 6703ms;
}

@-webkit-keyframes move6 {
  0% {
    -webkit-transform: translateX(7vw) translateY(-40vh) scale(0.74);
            transform: translateX(7vw) translateY(-40vh) scale(0.74);
  }

  4.7619047619% {
    -webkit-transform: translateX(-23vw) translateY(32vh) scale(0.7);
            transform: translateX(-23vw) translateY(32vh) scale(0.7);
  }

  9.5238095238% {
    -webkit-transform: translateX(-9vw) translateY(0vh) scale(0.34);
            transform: translateX(-9vw) translateY(0vh) scale(0.34);
  }

  14.2857142857% {
    -webkit-transform: translateX(-36vw) translateY(18vh) scale(0.65);
            transform: translateX(-36vw) translateY(18vh) scale(0.65);
  }

  19.0476190476% {
    -webkit-transform: translateX(37vw) translateY(-8vh) scale(0.84);
            transform: translateX(37vw) translateY(-8vh) scale(0.84);
  }

  23.8095238095% {
    -webkit-transform: translateX(7vw) translateY(27vh) scale(0.44);
            transform: translateX(7vw) translateY(27vh) scale(0.44);
  }

  28.5714285714% {
    -webkit-transform: translateX(-3vw) translateY(8vh) scale(0.93);
            transform: translateX(-3vw) translateY(8vh) scale(0.93);
  }

  33.3333333333% {
    -webkit-transform: translateX(-18vw) translateY(5vh) scale(0.87);
            transform: translateX(-18vw) translateY(5vh) scale(0.87);
  }

  38.0952380952% {
    -webkit-transform: translateX(25vw) translateY(19vh) scale(0.45);
            transform: translateX(25vw) translateY(19vh) scale(0.45);
  }

  42.8571428571% {
    -webkit-transform: translateX(46vw) translateY(14vh) scale(0.95);
            transform: translateX(46vw) translateY(14vh) scale(0.95);
  }

  47.619047619% {
    -webkit-transform: translateX(13vw) translateY(-10vh) scale(0.5);
            transform: translateX(13vw) translateY(-10vh) scale(0.5);
  }

  52.380952381% {
    -webkit-transform: translateX(15vw) translateY(11vh) scale(0.4);
            transform: translateX(15vw) translateY(11vh) scale(0.4);
  }

  57.1428571429% {
    -webkit-transform: translateX(15vw) translateY(-35vh) scale(0.96);
            transform: translateX(15vw) translateY(-35vh) scale(0.96);
  }

  61.9047619048% {
    -webkit-transform: translateX(-13vw) translateY(41vh) scale(0.35);
            transform: translateX(-13vw) translateY(41vh) scale(0.35);
  }

  66.6666666667% {
    -webkit-transform: translateX(-23vw) translateY(26vh) scale(0.9);
            transform: translateX(-23vw) translateY(26vh) scale(0.9);
  }

  71.4285714286% {
    -webkit-transform: translateX(-7vw) translateY(4vh) scale(0.81);
            transform: translateX(-7vw) translateY(4vh) scale(0.81);
  }

  76.1904761905% {
    -webkit-transform: translateX(-49vw) translateY(-18vh) scale(0.84);
            transform: translateX(-49vw) translateY(-18vh) scale(0.84);
  }

  80.9523809524% {
    -webkit-transform: translateX(26vw) translateY(33vh) scale(0.43);
            transform: translateX(26vw) translateY(33vh) scale(0.43);
  }

  85.7142857143% {
    -webkit-transform: translateX(-6vw) translateY(23vh) scale(0.48);
            transform: translateX(-6vw) translateY(23vh) scale(0.48);
  }

  90.4761904762% {
    -webkit-transform: translateX(46vw) translateY(-5vh) scale(0.8);
            transform: translateX(46vw) translateY(-5vh) scale(0.8);
  }

  95.2380952381% {
    -webkit-transform: translateX(42vw) translateY(33vh) scale(0.6);
            transform: translateX(42vw) translateY(33vh) scale(0.6);
  }

  100% {
    -webkit-transform: translateX(-28vw) translateY(26vh) scale(0.64);
            transform: translateX(-28vw) translateY(26vh) scale(0.64);
  }
}

@keyframes move6 {
  0% {
    -webkit-transform: translateX(7vw) translateY(-40vh) scale(0.74);
            transform: translateX(7vw) translateY(-40vh) scale(0.74);
  }

  4.7619047619% {
    -webkit-transform: translateX(-23vw) translateY(32vh) scale(0.7);
            transform: translateX(-23vw) translateY(32vh) scale(0.7);
  }

  9.5238095238% {
    -webkit-transform: translateX(-9vw) translateY(0vh) scale(0.34);
            transform: translateX(-9vw) translateY(0vh) scale(0.34);
  }

  14.2857142857% {
    -webkit-transform: translateX(-36vw) translateY(18vh) scale(0.65);
            transform: translateX(-36vw) translateY(18vh) scale(0.65);
  }

  19.0476190476% {
    -webkit-transform: translateX(37vw) translateY(-8vh) scale(0.84);
            transform: translateX(37vw) translateY(-8vh) scale(0.84);
  }

  23.8095238095% {
    -webkit-transform: translateX(7vw) translateY(27vh) scale(0.44);
            transform: translateX(7vw) translateY(27vh) scale(0.44);
  }

  28.5714285714% {
    -webkit-transform: translateX(-3vw) translateY(8vh) scale(0.93);
            transform: translateX(-3vw) translateY(8vh) scale(0.93);
  }

  33.3333333333% {
    -webkit-transform: translateX(-18vw) translateY(5vh) scale(0.87);
            transform: translateX(-18vw) translateY(5vh) scale(0.87);
  }

  38.0952380952% {
    -webkit-transform: translateX(25vw) translateY(19vh) scale(0.45);
            transform: translateX(25vw) translateY(19vh) scale(0.45);
  }

  42.8571428571% {
    -webkit-transform: translateX(46vw) translateY(14vh) scale(0.95);
            transform: translateX(46vw) translateY(14vh) scale(0.95);
  }

  47.619047619% {
    -webkit-transform: translateX(13vw) translateY(-10vh) scale(0.5);
            transform: translateX(13vw) translateY(-10vh) scale(0.5);
  }

  52.380952381% {
    -webkit-transform: translateX(15vw) translateY(11vh) scale(0.4);
            transform: translateX(15vw) translateY(11vh) scale(0.4);
  }

  57.1428571429% {
    -webkit-transform: translateX(15vw) translateY(-35vh) scale(0.96);
            transform: translateX(15vw) translateY(-35vh) scale(0.96);
  }

  61.9047619048% {
    -webkit-transform: translateX(-13vw) translateY(41vh) scale(0.35);
            transform: translateX(-13vw) translateY(41vh) scale(0.35);
  }

  66.6666666667% {
    -webkit-transform: translateX(-23vw) translateY(26vh) scale(0.9);
            transform: translateX(-23vw) translateY(26vh) scale(0.9);
  }

  71.4285714286% {
    -webkit-transform: translateX(-7vw) translateY(4vh) scale(0.81);
            transform: translateX(-7vw) translateY(4vh) scale(0.81);
  }

  76.1904761905% {
    -webkit-transform: translateX(-49vw) translateY(-18vh) scale(0.84);
            transform: translateX(-49vw) translateY(-18vh) scale(0.84);
  }

  80.9523809524% {
    -webkit-transform: translateX(26vw) translateY(33vh) scale(0.43);
            transform: translateX(26vw) translateY(33vh) scale(0.43);
  }

  85.7142857143% {
    -webkit-transform: translateX(-6vw) translateY(23vh) scale(0.48);
            transform: translateX(-6vw) translateY(23vh) scale(0.48);
  }

  90.4761904762% {
    -webkit-transform: translateX(46vw) translateY(-5vh) scale(0.8);
            transform: translateX(46vw) translateY(-5vh) scale(0.8);
  }

  95.2380952381% {
    -webkit-transform: translateX(42vw) translateY(33vh) scale(0.6);
            transform: translateX(42vw) translateY(33vh) scale(0.6);
  }

  100% {
    -webkit-transform: translateX(-28vw) translateY(26vh) scale(0.64);
            transform: translateX(-28vw) translateY(26vh) scale(0.64);
  }
}

.firefly:nth-child(7) {
  -webkit-animation-name: move7;
          animation-name: move7;
}

.firefly:nth-child(7)::before {
  -webkit-animation-duration: 17s;
          animation-duration: 17s;
}

.firefly:nth-child(7)::after {
  -webkit-animation-duration: 17s, 9827ms;
          animation-duration: 17s, 9827ms;
  -webkit-animation-delay: 0ms, 3278ms;
          animation-delay: 0ms, 3278ms;
}

@-webkit-keyframes move7 {
  0% {
    -webkit-transform: translateX(-1vw) translateY(41vh) scale(0.51);
            transform: translateX(-1vw) translateY(41vh) scale(0.51);
  }

  5.5555555556% {
    -webkit-transform: translateX(38vw) translateY(32vh) scale(0.61);
            transform: translateX(38vw) translateY(32vh) scale(0.61);
  }

  11.1111111111% {
    -webkit-transform: translateX(36vw) translateY(44vh) scale(0.89);
            transform: translateX(36vw) translateY(44vh) scale(0.89);
  }

  16.6666666667% {
    -webkit-transform: translateX(-22vw) translateY(-36vh) scale(0.68);
            transform: translateX(-22vw) translateY(-36vh) scale(0.68);
  }

  22.2222222222% {
    -webkit-transform: translateX(4vw) translateY(-2vh) scale(0.87);
            transform: translateX(4vw) translateY(-2vh) scale(0.87);
  }

  27.7777777778% {
    -webkit-transform: translateX(11vw) translateY(-5vh) scale(0.48);
            transform: translateX(11vw) translateY(-5vh) scale(0.48);
  }

  33.3333333333% {
    -webkit-transform: translateX(-30vw) translateY(28vh) scale(0.43);
            transform: translateX(-30vw) translateY(28vh) scale(0.43);
  }

  38.8888888889% {
    -webkit-transform: translateX(-38vw) translateY(-4vh) scale(0.84);
            transform: translateX(-38vw) translateY(-4vh) scale(0.84);
  }

  44.4444444444% {
    -webkit-transform: translateX(-29vw) translateY(33vh) scale(0.43);
            transform: translateX(-29vw) translateY(33vh) scale(0.43);
  }

  50% {
    -webkit-transform: translateX(1vw) translateY(-4vh) scale(0.96);
            transform: translateX(1vw) translateY(-4vh) scale(0.96);
  }

  55.5555555556% {
    -webkit-transform: translateX(38vw) translateY(-23vh) scale(0.5);
            transform: translateX(38vw) translateY(-23vh) scale(0.5);
  }

  61.1111111111% {
    -webkit-transform: translateX(-47vw) translateY(-1vh) scale(0.91);
            transform: translateX(-47vw) translateY(-1vh) scale(0.91);
  }

  66.6666666667% {
    -webkit-transform: translateX(40vw) translateY(-8vh) scale(0.52);
            transform: translateX(40vw) translateY(-8vh) scale(0.52);
  }

  72.2222222222% {
    -webkit-transform: translateX(26vw) translateY(14vh) scale(0.83);
            transform: translateX(26vw) translateY(14vh) scale(0.83);
  }

  77.7777777778% {
    -webkit-transform: translateX(17vw) translateY(-48vh) scale(0.97);
            transform: translateX(17vw) translateY(-48vh) scale(0.97);
  }

  83.3333333333% {
    -webkit-transform: translateX(-22vw) translateY(45vh) scale(0.85);
            transform: translateX(-22vw) translateY(45vh) scale(0.85);
  }

  88.8888888889% {
    -webkit-transform: translateX(-21vw) translateY(-9vh) scale(0.45);
            transform: translateX(-21vw) translateY(-9vh) scale(0.45);
  }

  94.4444444444% {
    -webkit-transform: translateX(39vw) translateY(38vh) scale(0.59);
            transform: translateX(39vw) translateY(38vh) scale(0.59);
  }

  100% {
    -webkit-transform: translateX(-37vw) translateY(-2vh) scale(0.55);
            transform: translateX(-37vw) translateY(-2vh) scale(0.55);
  }
}

@keyframes move7 {
  0% {
    -webkit-transform: translateX(-1vw) translateY(41vh) scale(0.51);
            transform: translateX(-1vw) translateY(41vh) scale(0.51);
  }

  5.5555555556% {
    -webkit-transform: translateX(38vw) translateY(32vh) scale(0.61);
            transform: translateX(38vw) translateY(32vh) scale(0.61);
  }

  11.1111111111% {
    -webkit-transform: translateX(36vw) translateY(44vh) scale(0.89);
            transform: translateX(36vw) translateY(44vh) scale(0.89);
  }

  16.6666666667% {
    -webkit-transform: translateX(-22vw) translateY(-36vh) scale(0.68);
            transform: translateX(-22vw) translateY(-36vh) scale(0.68);
  }

  22.2222222222% {
    -webkit-transform: translateX(4vw) translateY(-2vh) scale(0.87);
            transform: translateX(4vw) translateY(-2vh) scale(0.87);
  }

  27.7777777778% {
    -webkit-transform: translateX(11vw) translateY(-5vh) scale(0.48);
            transform: translateX(11vw) translateY(-5vh) scale(0.48);
  }

  33.3333333333% {
    -webkit-transform: translateX(-30vw) translateY(28vh) scale(0.43);
            transform: translateX(-30vw) translateY(28vh) scale(0.43);
  }

  38.8888888889% {
    -webkit-transform: translateX(-38vw) translateY(-4vh) scale(0.84);
            transform: translateX(-38vw) translateY(-4vh) scale(0.84);
  }

  44.4444444444% {
    -webkit-transform: translateX(-29vw) translateY(33vh) scale(0.43);
            transform: translateX(-29vw) translateY(33vh) scale(0.43);
  }

  50% {
    -webkit-transform: translateX(1vw) translateY(-4vh) scale(0.96);
            transform: translateX(1vw) translateY(-4vh) scale(0.96);
  }

  55.5555555556% {
    -webkit-transform: translateX(38vw) translateY(-23vh) scale(0.5);
            transform: translateX(38vw) translateY(-23vh) scale(0.5);
  }

  61.1111111111% {
    -webkit-transform: translateX(-47vw) translateY(-1vh) scale(0.91);
            transform: translateX(-47vw) translateY(-1vh) scale(0.91);
  }

  66.6666666667% {
    -webkit-transform: translateX(40vw) translateY(-8vh) scale(0.52);
            transform: translateX(40vw) translateY(-8vh) scale(0.52);
  }

  72.2222222222% {
    -webkit-transform: translateX(26vw) translateY(14vh) scale(0.83);
            transform: translateX(26vw) translateY(14vh) scale(0.83);
  }

  77.7777777778% {
    -webkit-transform: translateX(17vw) translateY(-48vh) scale(0.97);
            transform: translateX(17vw) translateY(-48vh) scale(0.97);
  }

  83.3333333333% {
    -webkit-transform: translateX(-22vw) translateY(45vh) scale(0.85);
            transform: translateX(-22vw) translateY(45vh) scale(0.85);
  }

  88.8888888889% {
    -webkit-transform: translateX(-21vw) translateY(-9vh) scale(0.45);
            transform: translateX(-21vw) translateY(-9vh) scale(0.45);
  }

  94.4444444444% {
    -webkit-transform: translateX(39vw) translateY(38vh) scale(0.59);
            transform: translateX(39vw) translateY(38vh) scale(0.59);
  }

  100% {
    -webkit-transform: translateX(-37vw) translateY(-2vh) scale(0.55);
            transform: translateX(-37vw) translateY(-2vh) scale(0.55);
  }
}

.firefly:nth-child(8) {
  -webkit-animation-name: move8;
          animation-name: move8;
}

.firefly:nth-child(8)::before {
  -webkit-animation-duration: 9s;
          animation-duration: 9s;
}

.firefly:nth-child(8)::after {
  -webkit-animation-duration: 9s, 8380ms;
          animation-duration: 9s, 8380ms;
  -webkit-animation-delay: 0ms, 4603ms;
          animation-delay: 0ms, 4603ms;
}

@-webkit-keyframes move8 {
  0% {
    -webkit-transform: translateX(-17vw) translateY(30vh) scale(0.58);
            transform: translateX(-17vw) translateY(30vh) scale(0.58);
  }

  5.8823529412% {
    -webkit-transform: translateX(-13vw) translateY(-42vh) scale(0.82);
            transform: translateX(-13vw) translateY(-42vh) scale(0.82);
  }

  11.7647058824% {
    -webkit-transform: translateX(-10vw) translateY(-38vh) scale(0.86);
            transform: translateX(-10vw) translateY(-38vh) scale(0.86);
  }

  17.6470588235% {
    -webkit-transform: translateX(49vw) translateY(47vh) scale(0.97);
            transform: translateX(49vw) translateY(47vh) scale(0.97);
  }

  23.5294117647% {
    -webkit-transform: translateX(-17vw) translateY(-44vh) scale(1);
            transform: translateX(-17vw) translateY(-44vh) scale(1);
  }

  29.4117647059% {
    -webkit-transform: translateX(-6vw) translateY(-20vh) scale(0.5);
            transform: translateX(-6vw) translateY(-20vh) scale(0.5);
  }

  35.2941176471% {
    -webkit-transform: translateX(-39vw) translateY(-34vh) scale(0.33);
            transform: translateX(-39vw) translateY(-34vh) scale(0.33);
  }

  41.1764705882% {
    -webkit-transform: translateX(38vw) translateY(49vh) scale(0.45);
            transform: translateX(38vw) translateY(49vh) scale(0.45);
  }

  47.0588235294% {
    -webkit-transform: translateX(43vw) translateY(39vh) scale(0.43);
            transform: translateX(43vw) translateY(39vh) scale(0.43);
  }

  52.9411764706% {
    -webkit-transform: translateX(46vw) translateY(-4vh) scale(0.27);
            transform: translateX(46vw) translateY(-4vh) scale(0.27);
  }

  58.8235294118% {
    -webkit-transform: translateX(-16vw) translateY(-20vh) scale(0.71);
            transform: translateX(-16vw) translateY(-20vh) scale(0.71);
  }

  64.7058823529% {
    -webkit-transform: translateX(-43vw) translateY(-40vh) scale(0.84);
            transform: translateX(-43vw) translateY(-40vh) scale(0.84);
  }

  70.5882352941% {
    -webkit-transform: translateX(-10vw) translateY(13vh) scale(0.81);
            transform: translateX(-10vw) translateY(13vh) scale(0.81);
  }

  76.4705882353% {
    -webkit-transform: translateX(38vw) translateY(1vh) scale(0.36);
            transform: translateX(38vw) translateY(1vh) scale(0.36);
  }

  82.3529411765% {
    -webkit-transform: translateX(32vw) translateY(-40vh) scale(0.71);
            transform: translateX(32vw) translateY(-40vh) scale(0.71);
  }

  88.2352941176% {
    -webkit-transform: translateX(0vw) translateY(-49vh) scale(0.32);
            transform: translateX(0vw) translateY(-49vh) scale(0.32);
  }

  94.1176470588% {
    -webkit-transform: translateX(12vw) translateY(-49vh) scale(0.29);
            transform: translateX(12vw) translateY(-49vh) scale(0.29);
  }

  100% {
    -webkit-transform: translateX(7vw) translateY(-3vh) scale(0.94);
            transform: translateX(7vw) translateY(-3vh) scale(0.94);
  }
}

@keyframes move8 {
  0% {
    -webkit-transform: translateX(-17vw) translateY(30vh) scale(0.58);
            transform: translateX(-17vw) translateY(30vh) scale(0.58);
  }

  5.8823529412% {
    -webkit-transform: translateX(-13vw) translateY(-42vh) scale(0.82);
            transform: translateX(-13vw) translateY(-42vh) scale(0.82);
  }

  11.7647058824% {
    -webkit-transform: translateX(-10vw) translateY(-38vh) scale(0.86);
            transform: translateX(-10vw) translateY(-38vh) scale(0.86);
  }

  17.6470588235% {
    -webkit-transform: translateX(49vw) translateY(47vh) scale(0.97);
            transform: translateX(49vw) translateY(47vh) scale(0.97);
  }

  23.5294117647% {
    -webkit-transform: translateX(-17vw) translateY(-44vh) scale(1);
            transform: translateX(-17vw) translateY(-44vh) scale(1);
  }

  29.4117647059% {
    -webkit-transform: translateX(-6vw) translateY(-20vh) scale(0.5);
            transform: translateX(-6vw) translateY(-20vh) scale(0.5);
  }

  35.2941176471% {
    -webkit-transform: translateX(-39vw) translateY(-34vh) scale(0.33);
            transform: translateX(-39vw) translateY(-34vh) scale(0.33);
  }

  41.1764705882% {
    -webkit-transform: translateX(38vw) translateY(49vh) scale(0.45);
            transform: translateX(38vw) translateY(49vh) scale(0.45);
  }

  47.0588235294% {
    -webkit-transform: translateX(43vw) translateY(39vh) scale(0.43);
            transform: translateX(43vw) translateY(39vh) scale(0.43);
  }

  52.9411764706% {
    -webkit-transform: translateX(46vw) translateY(-4vh) scale(0.27);
            transform: translateX(46vw) translateY(-4vh) scale(0.27);
  }

  58.8235294118% {
    -webkit-transform: translateX(-16vw) translateY(-20vh) scale(0.71);
            transform: translateX(-16vw) translateY(-20vh) scale(0.71);
  }

  64.7058823529% {
    -webkit-transform: translateX(-43vw) translateY(-40vh) scale(0.84);
            transform: translateX(-43vw) translateY(-40vh) scale(0.84);
  }

  70.5882352941% {
    -webkit-transform: translateX(-10vw) translateY(13vh) scale(0.81);
            transform: translateX(-10vw) translateY(13vh) scale(0.81);
  }

  76.4705882353% {
    -webkit-transform: translateX(38vw) translateY(1vh) scale(0.36);
            transform: translateX(38vw) translateY(1vh) scale(0.36);
  }

  82.3529411765% {
    -webkit-transform: translateX(32vw) translateY(-40vh) scale(0.71);
            transform: translateX(32vw) translateY(-40vh) scale(0.71);
  }

  88.2352941176% {
    -webkit-transform: translateX(0vw) translateY(-49vh) scale(0.32);
            transform: translateX(0vw) translateY(-49vh) scale(0.32);
  }

  94.1176470588% {
    -webkit-transform: translateX(12vw) translateY(-49vh) scale(0.29);
            transform: translateX(12vw) translateY(-49vh) scale(0.29);
  }

  100% {
    -webkit-transform: translateX(7vw) translateY(-3vh) scale(0.94);
            transform: translateX(7vw) translateY(-3vh) scale(0.94);
  }
}

.firefly:nth-child(9) {
  -webkit-animation-name: move9;
          animation-name: move9;
}

.firefly:nth-child(9)::before {
  -webkit-animation-duration: 18s;
          animation-duration: 18s;
}

.firefly:nth-child(9)::after {
  -webkit-animation-duration: 18s, 10823ms;
          animation-duration: 18s, 10823ms;
  -webkit-animation-delay: 0ms, 705ms;
          animation-delay: 0ms, 705ms;
}

@-webkit-keyframes move9 {
  0% {
    -webkit-transform: translateX(3vw) translateY(4vh) scale(0.41);
            transform: translateX(3vw) translateY(4vh) scale(0.41);
  }

  5.2631578947% {
    -webkit-transform: translateX(-43vw) translateY(40vh) scale(0.79);
            transform: translateX(-43vw) translateY(40vh) scale(0.79);
  }

  10.5263157895% {
    -webkit-transform: translateX(-39vw) translateY(22vh) scale(0.38);
            transform: translateX(-39vw) translateY(22vh) scale(0.38);
  }

  15.7894736842% {
    -webkit-transform: translateX(-32vw) translateY(1vh) scale(0.41);
            transform: translateX(-32vw) translateY(1vh) scale(0.41);
  }

  21.0526315789% {
    -webkit-transform: translateX(-33vw) translateY(31vh) scale(0.81);
            transform: translateX(-33vw) translateY(31vh) scale(0.81);
  }

  26.3157894737% {
    -webkit-transform: translateX(2vw) translateY(-33vh) scale(0.43);
            transform: translateX(2vw) translateY(-33vh) scale(0.43);
  }

  31.5789473684% {
    -webkit-transform: translateX(-2vw) translateY(41vh) scale(0.78);
            transform: translateX(-2vw) translateY(41vh) scale(0.78);
  }

  36.8421052632% {
    -webkit-transform: translateX(-1vw) translateY(25vh) scale(0.69);
            transform: translateX(-1vw) translateY(25vh) scale(0.69);
  }

  42.1052631579% {
    -webkit-transform: translateX(-39vw) translateY(-28vh) scale(0.94);
            transform: translateX(-39vw) translateY(-28vh) scale(0.94);
  }

  47.3684210526% {
    -webkit-transform: translateX(29vw) translateY(13vh) scale(0.42);
            transform: translateX(29vw) translateY(13vh) scale(0.42);
  }

  52.6315789474% {
    -webkit-transform: translateX(42vw) translateY(17vh) scale(0.47);
            transform: translateX(42vw) translateY(17vh) scale(0.47);
  }

  57.8947368421% {
    -webkit-transform: translateX(14vw) translateY(-9vh) scale(0.59);
            transform: translateX(14vw) translateY(-9vh) scale(0.59);
  }

  63.1578947368% {
    -webkit-transform: translateX(12vw) translateY(-8vh) scale(0.29);
            transform: translateX(12vw) translateY(-8vh) scale(0.29);
  }

  68.4210526316% {
    -webkit-transform: translateX(-31vw) translateY(-25vh) scale(0.81);
            transform: translateX(-31vw) translateY(-25vh) scale(0.81);
  }

  73.6842105263% {
    -webkit-transform: translateX(-45vw) translateY(13vh) scale(0.33);
            transform: translateX(-45vw) translateY(13vh) scale(0.33);
  }

  78.9473684211% {
    -webkit-transform: translateX(-6vw) translateY(-48vh) scale(0.86);
            transform: translateX(-6vw) translateY(-48vh) scale(0.86);
  }

  84.2105263158% {
    -webkit-transform: translateX(-18vw) translateY(-12vh) scale(0.99);
            transform: translateX(-18vw) translateY(-12vh) scale(0.99);
  }

  89.4736842105% {
    -webkit-transform: translateX(22vw) translateY(-39vh) scale(0.51);
            transform: translateX(22vw) translateY(-39vh) scale(0.51);
  }

  94.7368421053% {
    -webkit-transform: translateX(43vw) translateY(-15vh) scale(0.6);
            transform: translateX(43vw) translateY(-15vh) scale(0.6);
  }

  100% {
    -webkit-transform: translateX(41vw) translateY(-14vh) scale(0.56);
            transform: translateX(41vw) translateY(-14vh) scale(0.56);
  }
}

@keyframes move9 {
  0% {
    -webkit-transform: translateX(3vw) translateY(4vh) scale(0.41);
            transform: translateX(3vw) translateY(4vh) scale(0.41);
  }

  5.2631578947% {
    -webkit-transform: translateX(-43vw) translateY(40vh) scale(0.79);
            transform: translateX(-43vw) translateY(40vh) scale(0.79);
  }

  10.5263157895% {
    -webkit-transform: translateX(-39vw) translateY(22vh) scale(0.38);
            transform: translateX(-39vw) translateY(22vh) scale(0.38);
  }

  15.7894736842% {
    -webkit-transform: translateX(-32vw) translateY(1vh) scale(0.41);
            transform: translateX(-32vw) translateY(1vh) scale(0.41);
  }

  21.0526315789% {
    -webkit-transform: translateX(-33vw) translateY(31vh) scale(0.81);
            transform: translateX(-33vw) translateY(31vh) scale(0.81);
  }

  26.3157894737% {
    -webkit-transform: translateX(2vw) translateY(-33vh) scale(0.43);
            transform: translateX(2vw) translateY(-33vh) scale(0.43);
  }

  31.5789473684% {
    -webkit-transform: translateX(-2vw) translateY(41vh) scale(0.78);
            transform: translateX(-2vw) translateY(41vh) scale(0.78);
  }

  36.8421052632% {
    -webkit-transform: translateX(-1vw) translateY(25vh) scale(0.69);
            transform: translateX(-1vw) translateY(25vh) scale(0.69);
  }

  42.1052631579% {
    -webkit-transform: translateX(-39vw) translateY(-28vh) scale(0.94);
            transform: translateX(-39vw) translateY(-28vh) scale(0.94);
  }

  47.3684210526% {
    -webkit-transform: translateX(29vw) translateY(13vh) scale(0.42);
            transform: translateX(29vw) translateY(13vh) scale(0.42);
  }

  52.6315789474% {
    -webkit-transform: translateX(42vw) translateY(17vh) scale(0.47);
            transform: translateX(42vw) translateY(17vh) scale(0.47);
  }

  57.8947368421% {
    -webkit-transform: translateX(14vw) translateY(-9vh) scale(0.59);
            transform: translateX(14vw) translateY(-9vh) scale(0.59);
  }

  63.1578947368% {
    -webkit-transform: translateX(12vw) translateY(-8vh) scale(0.29);
            transform: translateX(12vw) translateY(-8vh) scale(0.29);
  }

  68.4210526316% {
    -webkit-transform: translateX(-31vw) translateY(-25vh) scale(0.81);
            transform: translateX(-31vw) translateY(-25vh) scale(0.81);
  }

  73.6842105263% {
    -webkit-transform: translateX(-45vw) translateY(13vh) scale(0.33);
            transform: translateX(-45vw) translateY(13vh) scale(0.33);
  }

  78.9473684211% {
    -webkit-transform: translateX(-6vw) translateY(-48vh) scale(0.86);
            transform: translateX(-6vw) translateY(-48vh) scale(0.86);
  }

  84.2105263158% {
    -webkit-transform: translateX(-18vw) translateY(-12vh) scale(0.99);
            transform: translateX(-18vw) translateY(-12vh) scale(0.99);
  }

  89.4736842105% {
    -webkit-transform: translateX(22vw) translateY(-39vh) scale(0.51);
            transform: translateX(22vw) translateY(-39vh) scale(0.51);
  }

  94.7368421053% {
    -webkit-transform: translateX(43vw) translateY(-15vh) scale(0.6);
            transform: translateX(43vw) translateY(-15vh) scale(0.6);
  }

  100% {
    -webkit-transform: translateX(41vw) translateY(-14vh) scale(0.56);
            transform: translateX(41vw) translateY(-14vh) scale(0.56);
  }
}

.firefly:nth-child(10) {
  -webkit-animation-name: move10;
          animation-name: move10;
}

.firefly:nth-child(10)::before {
  -webkit-animation-duration: 15s;
          animation-duration: 15s;
}

.firefly:nth-child(10)::after {
  -webkit-animation-duration: 15s, 9714ms;
          animation-duration: 15s, 9714ms;
  -webkit-animation-delay: 0ms, 7928ms;
          animation-delay: 0ms, 7928ms;
}

@-webkit-keyframes move10 {
  0% {
    -webkit-transform: translateX(-5vw) translateY(25vh) scale(0.3);
            transform: translateX(-5vw) translateY(25vh) scale(0.3);
  }

  3.8461538462% {
    -webkit-transform: translateX(11vw) translateY(42vh) scale(0.81);
            transform: translateX(11vw) translateY(42vh) scale(0.81);
  }

  7.6923076923% {
    -webkit-transform: translateX(-45vw) translateY(25vh) scale(0.44);
            transform: translateX(-45vw) translateY(25vh) scale(0.44);
  }

  11.5384615385% {
    -webkit-transform: translateX(23vw) translateY(-4vh) scale(0.43);
            transform: translateX(23vw) translateY(-4vh) scale(0.43);
  }

  15.3846153846% {
    -webkit-transform: translateX(10vw) translateY(12vh) scale(0.84);
            transform: translateX(10vw) translateY(12vh) scale(0.84);
  }

  19.2307692308% {
    -webkit-transform: translateX(-33vw) translateY(38vh) scale(0.95);
            transform: translateX(-33vw) translateY(38vh) scale(0.95);
  }

  23.0769230769% {
    -webkit-transform: translateX(15vw) translateY(23vh) scale(0.56);
            transform: translateX(15vw) translateY(23vh) scale(0.56);
  }

  26.9230769231% {
    -webkit-transform: translateX(-12vw) translateY(-17vh) scale(0.42);
            transform: translateX(-12vw) translateY(-17vh) scale(0.42);
  }

  30.7692307692% {
    -webkit-transform: translateX(1vw) translateY(-34vh) scale(0.61);
            transform: translateX(1vw) translateY(-34vh) scale(0.61);
  }

  34.6153846154% {
    -webkit-transform: translateX(22vw) translateY(-25vh) scale(0.32);
            transform: translateX(22vw) translateY(-25vh) scale(0.32);
  }

  38.4615384615% {
    -webkit-transform: translateX(-17vw) translateY(26vh) scale(0.65);
            transform: translateX(-17vw) translateY(26vh) scale(0.65);
  }

  42.3076923077% {
    -webkit-transform: translateX(3vw) translateY(29vh) scale(0.91);
            transform: translateX(3vw) translateY(29vh) scale(0.91);
  }

  46.1538461538% {
    -webkit-transform: translateX(-12vw) translateY(-36vh) scale(0.64);
            transform: translateX(-12vw) translateY(-36vh) scale(0.64);
  }

  50% {
    -webkit-transform: translateX(-44vw) translateY(41vh) scale(0.44);
            transform: translateX(-44vw) translateY(41vh) scale(0.44);
  }

  53.8461538462% {
    -webkit-transform: translateX(9vw) translateY(-29vh) scale(0.6);
            transform: translateX(9vw) translateY(-29vh) scale(0.6);
  }

  57.6923076923% {
    -webkit-transform: translateX(22vw) translateY(30vh) scale(0.98);
            transform: translateX(22vw) translateY(30vh) scale(0.98);
  }

  61.5384615385% {
    -webkit-transform: translateX(-20vw) translateY(33vh) scale(0.7);
            transform: translateX(-20vw) translateY(33vh) scale(0.7);
  }

  65.3846153846% {
    -webkit-transform: translateX(40vw) translateY(24vh) scale(0.38);
            transform: translateX(40vw) translateY(24vh) scale(0.38);
  }

  69.2307692308% {
    -webkit-transform: translateX(-19vw) translateY(4vh) scale(0.48);
            transform: translateX(-19vw) translateY(4vh) scale(0.48);
  }

  73.0769230769% {
    -webkit-transform: translateX(-18vw) translateY(-32vh) scale(1);
            transform: translateX(-18vw) translateY(-32vh) scale(1);
  }

  76.9230769231% {
    -webkit-transform: translateX(17vw) translateY(1vh) scale(0.68);
            transform: translateX(17vw) translateY(1vh) scale(0.68);
  }

  80.7692307692% {
    -webkit-transform: translateX(-40vw) translateY(-16vh) scale(0.92);
            transform: translateX(-40vw) translateY(-16vh) scale(0.92);
  }

  84.6153846154% {
    -webkit-transform: translateX(17vw) translateY(32vh) scale(0.9);
            transform: translateX(17vw) translateY(32vh) scale(0.9);
  }

  88.4615384615% {
    -webkit-transform: translateX(14vw) translateY(45vh) scale(0.8);
            transform: translateX(14vw) translateY(45vh) scale(0.8);
  }

  92.3076923077% {
    -webkit-transform: translateX(14vw) translateY(50vh) scale(0.74);
            transform: translateX(14vw) translateY(50vh) scale(0.74);
  }

  96.1538461538% {
    -webkit-transform: translateX(26vw) translateY(47vh) scale(0.83);
            transform: translateX(26vw) translateY(47vh) scale(0.83);
  }

  100% {
    -webkit-transform: translateX(49vw) translateY(-40vh) scale(0.95);
            transform: translateX(49vw) translateY(-40vh) scale(0.95);
  }
}

@keyframes move10 {
  0% {
    -webkit-transform: translateX(-5vw) translateY(25vh) scale(0.3);
            transform: translateX(-5vw) translateY(25vh) scale(0.3);
  }

  3.8461538462% {
    -webkit-transform: translateX(11vw) translateY(42vh) scale(0.81);
            transform: translateX(11vw) translateY(42vh) scale(0.81);
  }

  7.6923076923% {
    -webkit-transform: translateX(-45vw) translateY(25vh) scale(0.44);
            transform: translateX(-45vw) translateY(25vh) scale(0.44);
  }

  11.5384615385% {
    -webkit-transform: translateX(23vw) translateY(-4vh) scale(0.43);
            transform: translateX(23vw) translateY(-4vh) scale(0.43);
  }

  15.3846153846% {
    -webkit-transform: translateX(10vw) translateY(12vh) scale(0.84);
            transform: translateX(10vw) translateY(12vh) scale(0.84);
  }

  19.2307692308% {
    -webkit-transform: translateX(-33vw) translateY(38vh) scale(0.95);
            transform: translateX(-33vw) translateY(38vh) scale(0.95);
  }

  23.0769230769% {
    -webkit-transform: translateX(15vw) translateY(23vh) scale(0.56);
            transform: translateX(15vw) translateY(23vh) scale(0.56);
  }

  26.9230769231% {
    -webkit-transform: translateX(-12vw) translateY(-17vh) scale(0.42);
            transform: translateX(-12vw) translateY(-17vh) scale(0.42);
  }

  30.7692307692% {
    -webkit-transform: translateX(1vw) translateY(-34vh) scale(0.61);
            transform: translateX(1vw) translateY(-34vh) scale(0.61);
  }

  34.6153846154% {
    -webkit-transform: translateX(22vw) translateY(-25vh) scale(0.32);
            transform: translateX(22vw) translateY(-25vh) scale(0.32);
  }

  38.4615384615% {
    -webkit-transform: translateX(-17vw) translateY(26vh) scale(0.65);
            transform: translateX(-17vw) translateY(26vh) scale(0.65);
  }

  42.3076923077% {
    -webkit-transform: translateX(3vw) translateY(29vh) scale(0.91);
            transform: translateX(3vw) translateY(29vh) scale(0.91);
  }

  46.1538461538% {
    -webkit-transform: translateX(-12vw) translateY(-36vh) scale(0.64);
            transform: translateX(-12vw) translateY(-36vh) scale(0.64);
  }

  50% {
    -webkit-transform: translateX(-44vw) translateY(41vh) scale(0.44);
            transform: translateX(-44vw) translateY(41vh) scale(0.44);
  }

  53.8461538462% {
    -webkit-transform: translateX(9vw) translateY(-29vh) scale(0.6);
            transform: translateX(9vw) translateY(-29vh) scale(0.6);
  }

  57.6923076923% {
    -webkit-transform: translateX(22vw) translateY(30vh) scale(0.98);
            transform: translateX(22vw) translateY(30vh) scale(0.98);
  }

  61.5384615385% {
    -webkit-transform: translateX(-20vw) translateY(33vh) scale(0.7);
            transform: translateX(-20vw) translateY(33vh) scale(0.7);
  }

  65.3846153846% {
    -webkit-transform: translateX(40vw) translateY(24vh) scale(0.38);
            transform: translateX(40vw) translateY(24vh) scale(0.38);
  }

  69.2307692308% {
    -webkit-transform: translateX(-19vw) translateY(4vh) scale(0.48);
            transform: translateX(-19vw) translateY(4vh) scale(0.48);
  }

  73.0769230769% {
    -webkit-transform: translateX(-18vw) translateY(-32vh) scale(1);
            transform: translateX(-18vw) translateY(-32vh) scale(1);
  }

  76.9230769231% {
    -webkit-transform: translateX(17vw) translateY(1vh) scale(0.68);
            transform: translateX(17vw) translateY(1vh) scale(0.68);
  }

  80.7692307692% {
    -webkit-transform: translateX(-40vw) translateY(-16vh) scale(0.92);
            transform: translateX(-40vw) translateY(-16vh) scale(0.92);
  }

  84.6153846154% {
    -webkit-transform: translateX(17vw) translateY(32vh) scale(0.9);
            transform: translateX(17vw) translateY(32vh) scale(0.9);
  }

  88.4615384615% {
    -webkit-transform: translateX(14vw) translateY(45vh) scale(0.8);
            transform: translateX(14vw) translateY(45vh) scale(0.8);
  }

  92.3076923077% {
    -webkit-transform: translateX(14vw) translateY(50vh) scale(0.74);
            transform: translateX(14vw) translateY(50vh) scale(0.74);
  }

  96.1538461538% {
    -webkit-transform: translateX(26vw) translateY(47vh) scale(0.83);
            transform: translateX(26vw) translateY(47vh) scale(0.83);
  }

  100% {
    -webkit-transform: translateX(49vw) translateY(-40vh) scale(0.95);
            transform: translateX(49vw) translateY(-40vh) scale(0.95);
  }
}

.firefly:nth-child(11) {
  -webkit-animation-name: move11;
          animation-name: move11;
}

.firefly:nth-child(11)::before {
  -webkit-animation-duration: 13s;
          animation-duration: 13s;
}

.firefly:nth-child(11)::after {
  -webkit-animation-duration: 13s, 7476ms;
          animation-duration: 13s, 7476ms;
  -webkit-animation-delay: 0ms, 5436ms;
          animation-delay: 0ms, 5436ms;
}

@-webkit-keyframes move11 {
  0% {
    -webkit-transform: translateX(-7vw) translateY(16vh) scale(0.42);
            transform: translateX(-7vw) translateY(16vh) scale(0.42);
  }

  4.1666666667% {
    -webkit-transform: translateX(-44vw) translateY(14vh) scale(0.89);
            transform: translateX(-44vw) translateY(14vh) scale(0.89);
  }

  8.3333333333% {
    -webkit-transform: translateX(17vw) translateY(-25vh) scale(0.72);
            transform: translateX(17vw) translateY(-25vh) scale(0.72);
  }

  12.5% {
    -webkit-transform: translateX(-40vw) translateY(-22vh) scale(0.47);
            transform: translateX(-40vw) translateY(-22vh) scale(0.47);
  }

  16.6666666667% {
    -webkit-transform: translateX(49vw) translateY(12vh) scale(0.93);
            transform: translateX(49vw) translateY(12vh) scale(0.93);
  }

  20.8333333333% {
    -webkit-transform: translateX(-45vw) translateY(21vh) scale(0.6);
            transform: translateX(-45vw) translateY(21vh) scale(0.6);
  }

  25% {
    -webkit-transform: translateX(36vw) translateY(-41vh) scale(0.48);
            transform: translateX(36vw) translateY(-41vh) scale(0.48);
  }

  29.1666666667% {
    -webkit-transform: translateX(-9vw) translateY(0vh) scale(0.98);
            transform: translateX(-9vw) translateY(0vh) scale(0.98);
  }

  33.3333333333% {
    -webkit-transform: translateX(-32vw) translateY(37vh) scale(0.29);
            transform: translateX(-32vw) translateY(37vh) scale(0.29);
  }

  37.5% {
    -webkit-transform: translateX(31vw) translateY(-46vh) scale(0.34);
            transform: translateX(31vw) translateY(-46vh) scale(0.34);
  }

  41.6666666667% {
    -webkit-transform: translateX(21vw) translateY(-45vh) scale(0.51);
            transform: translateX(21vw) translateY(-45vh) scale(0.51);
  }

  45.8333333333% {
    -webkit-transform: translateX(-12vw) translateY(-46vh) scale(0.87);
            transform: translateX(-12vw) translateY(-46vh) scale(0.87);
  }

  50% {
    -webkit-transform: translateX(25vw) translateY(28vh) scale(0.48);
            transform: translateX(25vw) translateY(28vh) scale(0.48);
  }

  54.1666666667% {
    -webkit-transform: translateX(-16vw) translateY(-25vh) scale(0.76);
            transform: translateX(-16vw) translateY(-25vh) scale(0.76);
  }

  58.3333333333% {
    -webkit-transform: translateX(-4vw) translateY(48vh) scale(0.63);
            transform: translateX(-4vw) translateY(48vh) scale(0.63);
  }

  62.5% {
    -webkit-transform: translateX(48vw) translateY(-32vh) scale(0.31);
            transform: translateX(48vw) translateY(-32vh) scale(0.31);
  }

  66.6666666667% {
    -webkit-transform: translateX(-39vw) translateY(-39vh) scale(0.56);
            transform: translateX(-39vw) translateY(-39vh) scale(0.56);
  }

  70.8333333333% {
    -webkit-transform: translateX(-1vw) translateY(37vh) scale(0.5);
            transform: translateX(-1vw) translateY(37vh) scale(0.5);
  }

  75% {
    -webkit-transform: translateX(-9vw) translateY(-5vh) scale(0.56);
            transform: translateX(-9vw) translateY(-5vh) scale(0.56);
  }

  79.1666666667% {
    -webkit-transform: translateX(-11vw) translateY(13vh) scale(0.96);
            transform: translateX(-11vw) translateY(13vh) scale(0.96);
  }

  83.3333333333% {
    -webkit-transform: translateX(-1vw) translateY(-41vh) scale(0.55);
            transform: translateX(-1vw) translateY(-41vh) scale(0.55);
  }

  87.5% {
    -webkit-transform: translateX(17vw) translateY(18vh) scale(0.58);
            transform: translateX(17vw) translateY(18vh) scale(0.58);
  }

  91.6666666667% {
    -webkit-transform: translateX(-12vw) translateY(10vh) scale(0.99);
            transform: translateX(-12vw) translateY(10vh) scale(0.99);
  }

  95.8333333333% {
    -webkit-transform: translateX(33vw) translateY(50vh) scale(0.85);
            transform: translateX(33vw) translateY(50vh) scale(0.85);
  }

  100% {
    -webkit-transform: translateX(43vw) translateY(38vh) scale(0.52);
            transform: translateX(43vw) translateY(38vh) scale(0.52);
  }
}

@keyframes move11 {
  0% {
    -webkit-transform: translateX(-7vw) translateY(16vh) scale(0.42);
            transform: translateX(-7vw) translateY(16vh) scale(0.42);
  }

  4.1666666667% {
    -webkit-transform: translateX(-44vw) translateY(14vh) scale(0.89);
            transform: translateX(-44vw) translateY(14vh) scale(0.89);
  }

  8.3333333333% {
    -webkit-transform: translateX(17vw) translateY(-25vh) scale(0.72);
            transform: translateX(17vw) translateY(-25vh) scale(0.72);
  }

  12.5% {
    -webkit-transform: translateX(-40vw) translateY(-22vh) scale(0.47);
            transform: translateX(-40vw) translateY(-22vh) scale(0.47);
  }

  16.6666666667% {
    -webkit-transform: translateX(49vw) translateY(12vh) scale(0.93);
            transform: translateX(49vw) translateY(12vh) scale(0.93);
  }

  20.8333333333% {
    -webkit-transform: translateX(-45vw) translateY(21vh) scale(0.6);
            transform: translateX(-45vw) translateY(21vh) scale(0.6);
  }

  25% {
    -webkit-transform: translateX(36vw) translateY(-41vh) scale(0.48);
            transform: translateX(36vw) translateY(-41vh) scale(0.48);
  }

  29.1666666667% {
    -webkit-transform: translateX(-9vw) translateY(0vh) scale(0.98);
            transform: translateX(-9vw) translateY(0vh) scale(0.98);
  }

  33.3333333333% {
    -webkit-transform: translateX(-32vw) translateY(37vh) scale(0.29);
            transform: translateX(-32vw) translateY(37vh) scale(0.29);
  }

  37.5% {
    -webkit-transform: translateX(31vw) translateY(-46vh) scale(0.34);
            transform: translateX(31vw) translateY(-46vh) scale(0.34);
  }

  41.6666666667% {
    -webkit-transform: translateX(21vw) translateY(-45vh) scale(0.51);
            transform: translateX(21vw) translateY(-45vh) scale(0.51);
  }

  45.8333333333% {
    -webkit-transform: translateX(-12vw) translateY(-46vh) scale(0.87);
            transform: translateX(-12vw) translateY(-46vh) scale(0.87);
  }

  50% {
    -webkit-transform: translateX(25vw) translateY(28vh) scale(0.48);
            transform: translateX(25vw) translateY(28vh) scale(0.48);
  }

  54.1666666667% {
    -webkit-transform: translateX(-16vw) translateY(-25vh) scale(0.76);
            transform: translateX(-16vw) translateY(-25vh) scale(0.76);
  }

  58.3333333333% {
    -webkit-transform: translateX(-4vw) translateY(48vh) scale(0.63);
            transform: translateX(-4vw) translateY(48vh) scale(0.63);
  }

  62.5% {
    -webkit-transform: translateX(48vw) translateY(-32vh) scale(0.31);
            transform: translateX(48vw) translateY(-32vh) scale(0.31);
  }

  66.6666666667% {
    -webkit-transform: translateX(-39vw) translateY(-39vh) scale(0.56);
            transform: translateX(-39vw) translateY(-39vh) scale(0.56);
  }

  70.8333333333% {
    -webkit-transform: translateX(-1vw) translateY(37vh) scale(0.5);
            transform: translateX(-1vw) translateY(37vh) scale(0.5);
  }

  75% {
    -webkit-transform: translateX(-9vw) translateY(-5vh) scale(0.56);
            transform: translateX(-9vw) translateY(-5vh) scale(0.56);
  }

  79.1666666667% {
    -webkit-transform: translateX(-11vw) translateY(13vh) scale(0.96);
            transform: translateX(-11vw) translateY(13vh) scale(0.96);
  }

  83.3333333333% {
    -webkit-transform: translateX(-1vw) translateY(-41vh) scale(0.55);
            transform: translateX(-1vw) translateY(-41vh) scale(0.55);
  }

  87.5% {
    -webkit-transform: translateX(17vw) translateY(18vh) scale(0.58);
            transform: translateX(17vw) translateY(18vh) scale(0.58);
  }

  91.6666666667% {
    -webkit-transform: translateX(-12vw) translateY(10vh) scale(0.99);
            transform: translateX(-12vw) translateY(10vh) scale(0.99);
  }

  95.8333333333% {
    -webkit-transform: translateX(33vw) translateY(50vh) scale(0.85);
            transform: translateX(33vw) translateY(50vh) scale(0.85);
  }

  100% {
    -webkit-transform: translateX(43vw) translateY(38vh) scale(0.52);
            transform: translateX(43vw) translateY(38vh) scale(0.52);
  }
}

.firefly:nth-child(12) {
  -webkit-animation-name: move12;
          animation-name: move12;
}

.firefly:nth-child(12)::before {
  -webkit-animation-duration: 13s;
          animation-duration: 13s;
}

.firefly:nth-child(12)::after {
  -webkit-animation-duration: 13s, 5428ms;
          animation-duration: 13s, 5428ms;
  -webkit-animation-delay: 0ms, 4447ms;
          animation-delay: 0ms, 4447ms;
}

@-webkit-keyframes move12 {
  0% {
    -webkit-transform: translateX(19vw) translateY(-25vh) scale(0.71);
            transform: translateX(19vw) translateY(-25vh) scale(0.71);
  }

  3.7037037037% {
    -webkit-transform: translateX(5vw) translateY(-44vh) scale(0.68);
            transform: translateX(5vw) translateY(-44vh) scale(0.68);
  }

  7.4074074074% {
    -webkit-transform: translateX(-11vw) translateY(21vh) scale(0.96);
            transform: translateX(-11vw) translateY(21vh) scale(0.96);
  }

  11.1111111111% {
    -webkit-transform: translateX(-16vw) translateY(-35vh) scale(0.62);
            transform: translateX(-16vw) translateY(-35vh) scale(0.62);
  }

  14.8148148148% {
    -webkit-transform: translateX(19vw) translateY(36vh) scale(0.51);
            transform: translateX(19vw) translateY(36vh) scale(0.51);
  }

  18.5185185185% {
    -webkit-transform: translateX(-31vw) translateY(39vh) scale(0.91);
            transform: translateX(-31vw) translateY(39vh) scale(0.91);
  }

  22.2222222222% {
    -webkit-transform: translateX(-13vw) translateY(-33vh) scale(0.57);
            transform: translateX(-13vw) translateY(-33vh) scale(0.57);
  }

  25.9259259259% {
    -webkit-transform: translateX(-11vw) translateY(-43vh) scale(0.91);
            transform: translateX(-11vw) translateY(-43vh) scale(0.91);
  }

  29.6296296296% {
    -webkit-transform: translateX(6vw) translateY(-23vh) scale(0.35);
            transform: translateX(6vw) translateY(-23vh) scale(0.35);
  }

  33.3333333333% {
    -webkit-transform: translateX(50vw) translateY(-5vh) scale(1);
            transform: translateX(50vw) translateY(-5vh) scale(1);
  }

  37.037037037% {
    -webkit-transform: translateX(-42vw) translateY(0vh) scale(0.98);
            transform: translateX(-42vw) translateY(0vh) scale(0.98);
  }

  40.7407407407% {
    -webkit-transform: translateX(35vw) translateY(40vh) scale(0.97);
            transform: translateX(35vw) translateY(40vh) scale(0.97);
  }

  44.4444444444% {
    -webkit-transform: translateX(-6vw) translateY(-30vh) scale(0.28);
            transform: translateX(-6vw) translateY(-30vh) scale(0.28);
  }

  48.1481481481% {
    -webkit-transform: translateX(7vw) translateY(0vh) scale(0.85);
            transform: translateX(7vw) translateY(0vh) scale(0.85);
  }

  51.8518518519% {
    -webkit-transform: translateX(-29vw) translateY(-42vh) scale(0.82);
            transform: translateX(-29vw) translateY(-42vh) scale(0.82);
  }

  55.5555555556% {
    -webkit-transform: translateX(-13vw) translateY(-39vh) scale(0.93);
            transform: translateX(-13vw) translateY(-39vh) scale(0.93);
  }

  59.2592592593% {
    -webkit-transform: translateX(-16vw) translateY(43vh) scale(0.99);
            transform: translateX(-16vw) translateY(43vh) scale(0.99);
  }

  62.962962963% {
    -webkit-transform: translateX(19vw) translateY(38vh) scale(0.61);
            transform: translateX(19vw) translateY(38vh) scale(0.61);
  }

  66.6666666667% {
    -webkit-transform: translateX(13vw) translateY(26vh) scale(0.75);
            transform: translateX(13vw) translateY(26vh) scale(0.75);
  }

  70.3703703704% {
    -webkit-transform: translateX(-3vw) translateY(-23vh) scale(0.7);
            transform: translateX(-3vw) translateY(-23vh) scale(0.7);
  }

  74.0740740741% {
    -webkit-transform: translateX(-19vw) translateY(3vh) scale(0.93);
            transform: translateX(-19vw) translateY(3vh) scale(0.93);
  }

  77.7777777778% {
    -webkit-transform: translateX(8vw) translateY(-18vh) scale(0.77);
            transform: translateX(8vw) translateY(-18vh) scale(0.77);
  }

  81.4814814815% {
    -webkit-transform: translateX(-23vw) translateY(-12vh) scale(0.88);
            transform: translateX(-23vw) translateY(-12vh) scale(0.88);
  }

  85.1851851852% {
    -webkit-transform: translateX(29vw) translateY(-27vh) scale(0.75);
            transform: translateX(29vw) translateY(-27vh) scale(0.75);
  }

  88.8888888889% {
    -webkit-transform: translateX(-28vw) translateY(-32vh) scale(0.46);
            transform: translateX(-28vw) translateY(-32vh) scale(0.46);
  }

  92.5925925926% {
    -webkit-transform: translateX(-40vw) translateY(11vh) scale(0.54);
            transform: translateX(-40vw) translateY(11vh) scale(0.54);
  }

  96.2962962963% {
    -webkit-transform: translateX(19vw) translateY(3vh) scale(0.86);
            transform: translateX(19vw) translateY(3vh) scale(0.86);
  }

  100% {
    -webkit-transform: translateX(35vw) translateY(-45vh) scale(0.73);
            transform: translateX(35vw) translateY(-45vh) scale(0.73);
  }
}

@keyframes move12 {
  0% {
    -webkit-transform: translateX(19vw) translateY(-25vh) scale(0.71);
            transform: translateX(19vw) translateY(-25vh) scale(0.71);
  }

  3.7037037037% {
    -webkit-transform: translateX(5vw) translateY(-44vh) scale(0.68);
            transform: translateX(5vw) translateY(-44vh) scale(0.68);
  }

  7.4074074074% {
    -webkit-transform: translateX(-11vw) translateY(21vh) scale(0.96);
            transform: translateX(-11vw) translateY(21vh) scale(0.96);
  }

  11.1111111111% {
    -webkit-transform: translateX(-16vw) translateY(-35vh) scale(0.62);
            transform: translateX(-16vw) translateY(-35vh) scale(0.62);
  }

  14.8148148148% {
    -webkit-transform: translateX(19vw) translateY(36vh) scale(0.51);
            transform: translateX(19vw) translateY(36vh) scale(0.51);
  }

  18.5185185185% {
    -webkit-transform: translateX(-31vw) translateY(39vh) scale(0.91);
            transform: translateX(-31vw) translateY(39vh) scale(0.91);
  }

  22.2222222222% {
    -webkit-transform: translateX(-13vw) translateY(-33vh) scale(0.57);
            transform: translateX(-13vw) translateY(-33vh) scale(0.57);
  }

  25.9259259259% {
    -webkit-transform: translateX(-11vw) translateY(-43vh) scale(0.91);
            transform: translateX(-11vw) translateY(-43vh) scale(0.91);
  }

  29.6296296296% {
    -webkit-transform: translateX(6vw) translateY(-23vh) scale(0.35);
            transform: translateX(6vw) translateY(-23vh) scale(0.35);
  }

  33.3333333333% {
    -webkit-transform: translateX(50vw) translateY(-5vh) scale(1);
            transform: translateX(50vw) translateY(-5vh) scale(1);
  }

  37.037037037% {
    -webkit-transform: translateX(-42vw) translateY(0vh) scale(0.98);
            transform: translateX(-42vw) translateY(0vh) scale(0.98);
  }

  40.7407407407% {
    -webkit-transform: translateX(35vw) translateY(40vh) scale(0.97);
            transform: translateX(35vw) translateY(40vh) scale(0.97);
  }

  44.4444444444% {
    -webkit-transform: translateX(-6vw) translateY(-30vh) scale(0.28);
            transform: translateX(-6vw) translateY(-30vh) scale(0.28);
  }

  48.1481481481% {
    -webkit-transform: translateX(7vw) translateY(0vh) scale(0.85);
            transform: translateX(7vw) translateY(0vh) scale(0.85);
  }

  51.8518518519% {
    -webkit-transform: translateX(-29vw) translateY(-42vh) scale(0.82);
            transform: translateX(-29vw) translateY(-42vh) scale(0.82);
  }

  55.5555555556% {
    -webkit-transform: translateX(-13vw) translateY(-39vh) scale(0.93);
            transform: translateX(-13vw) translateY(-39vh) scale(0.93);
  }

  59.2592592593% {
    -webkit-transform: translateX(-16vw) translateY(43vh) scale(0.99);
            transform: translateX(-16vw) translateY(43vh) scale(0.99);
  }

  62.962962963% {
    -webkit-transform: translateX(19vw) translateY(38vh) scale(0.61);
            transform: translateX(19vw) translateY(38vh) scale(0.61);
  }

  66.6666666667% {
    -webkit-transform: translateX(13vw) translateY(26vh) scale(0.75);
            transform: translateX(13vw) translateY(26vh) scale(0.75);
  }

  70.3703703704% {
    -webkit-transform: translateX(-3vw) translateY(-23vh) scale(0.7);
            transform: translateX(-3vw) translateY(-23vh) scale(0.7);
  }

  74.0740740741% {
    -webkit-transform: translateX(-19vw) translateY(3vh) scale(0.93);
            transform: translateX(-19vw) translateY(3vh) scale(0.93);
  }

  77.7777777778% {
    -webkit-transform: translateX(8vw) translateY(-18vh) scale(0.77);
            transform: translateX(8vw) translateY(-18vh) scale(0.77);
  }

  81.4814814815% {
    -webkit-transform: translateX(-23vw) translateY(-12vh) scale(0.88);
            transform: translateX(-23vw) translateY(-12vh) scale(0.88);
  }

  85.1851851852% {
    -webkit-transform: translateX(29vw) translateY(-27vh) scale(0.75);
            transform: translateX(29vw) translateY(-27vh) scale(0.75);
  }

  88.8888888889% {
    -webkit-transform: translateX(-28vw) translateY(-32vh) scale(0.46);
            transform: translateX(-28vw) translateY(-32vh) scale(0.46);
  }

  92.5925925926% {
    -webkit-transform: translateX(-40vw) translateY(11vh) scale(0.54);
            transform: translateX(-40vw) translateY(11vh) scale(0.54);
  }

  96.2962962963% {
    -webkit-transform: translateX(19vw) translateY(3vh) scale(0.86);
            transform: translateX(19vw) translateY(3vh) scale(0.86);
  }

  100% {
    -webkit-transform: translateX(35vw) translateY(-45vh) scale(0.73);
            transform: translateX(35vw) translateY(-45vh) scale(0.73);
  }
}

.firefly:nth-child(13) {
  -webkit-animation-name: move13;
          animation-name: move13;
}

.firefly:nth-child(13)::before {
  -webkit-animation-duration: 12s;
          animation-duration: 12s;
}

.firefly:nth-child(13)::after {
  -webkit-animation-duration: 12s, 10857ms;
          animation-duration: 12s, 10857ms;
  -webkit-animation-delay: 0ms, 8399ms;
          animation-delay: 0ms, 8399ms;
}

@-webkit-keyframes move13 {
  0% {
    -webkit-transform: translateX(-20vw) translateY(28vh) scale(0.31);
            transform: translateX(-20vw) translateY(28vh) scale(0.31);
  }

  5.2631578947% {
    -webkit-transform: translateX(10vw) translateY(-35vh) scale(0.91);
            transform: translateX(10vw) translateY(-35vh) scale(0.91);
  }

  10.5263157895% {
    -webkit-transform: translateX(-12vw) translateY(7vh) scale(0.65);
            transform: translateX(-12vw) translateY(7vh) scale(0.65);
  }

  15.7894736842% {
    -webkit-transform: translateX(-35vw) translateY(-3vh) scale(0.56);
            transform: translateX(-35vw) translateY(-3vh) scale(0.56);
  }

  21.0526315789% {
    -webkit-transform: translateX(-24vw) translateY(-16vh) scale(0.35);
            transform: translateX(-24vw) translateY(-16vh) scale(0.35);
  }

  26.3157894737% {
    -webkit-transform: translateX(0vw) translateY(20vh) scale(0.57);
            transform: translateX(0vw) translateY(20vh) scale(0.57);
  }

  31.5789473684% {
    -webkit-transform: translateX(-45vw) translateY(-3vh) scale(0.77);
            transform: translateX(-45vw) translateY(-3vh) scale(0.77);
  }

  36.8421052632% {
    -webkit-transform: translateX(37vw) translateY(36vh) scale(0.7);
            transform: translateX(37vw) translateY(36vh) scale(0.7);
  }

  42.1052631579% {
    -webkit-transform: translateX(-9vw) translateY(8vh) scale(0.92);
            transform: translateX(-9vw) translateY(8vh) scale(0.92);
  }

  47.3684210526% {
    -webkit-transform: translateX(-14vw) translateY(-41vh) scale(0.29);
            transform: translateX(-14vw) translateY(-41vh) scale(0.29);
  }

  52.6315789474% {
    -webkit-transform: translateX(-12vw) translateY(-35vh) scale(0.71);
            transform: translateX(-12vw) translateY(-35vh) scale(0.71);
  }

  57.8947368421% {
    -webkit-transform: translateX(-3vw) translateY(-21vh) scale(0.48);
            transform: translateX(-3vw) translateY(-21vh) scale(0.48);
  }

  63.1578947368% {
    -webkit-transform: translateX(12vw) translateY(6vh) scale(0.8);
            transform: translateX(12vw) translateY(6vh) scale(0.8);
  }

  68.4210526316% {
    -webkit-transform: translateX(16vw) translateY(-5vh) scale(0.75);
            transform: translateX(16vw) translateY(-5vh) scale(0.75);
  }

  73.6842105263% {
    -webkit-transform: translateX(-39vw) translateY(-43vh) scale(0.68);
            transform: translateX(-39vw) translateY(-43vh) scale(0.68);
  }

  78.9473684211% {
    -webkit-transform: translateX(35vw) translateY(-46vh) scale(0.3);
            transform: translateX(35vw) translateY(-46vh) scale(0.3);
  }

  84.2105263158% {
    -webkit-transform: translateX(35vw) translateY(-45vh) scale(0.83);
            transform: translateX(35vw) translateY(-45vh) scale(0.83);
  }

  89.4736842105% {
    -webkit-transform: translateX(-8vw) translateY(13vh) scale(0.39);
            transform: translateX(-8vw) translateY(13vh) scale(0.39);
  }

  94.7368421053% {
    -webkit-transform: translateX(-12vw) translateY(32vh) scale(0.45);
            transform: translateX(-12vw) translateY(32vh) scale(0.45);
  }

  100% {
    -webkit-transform: translateX(-14vw) translateY(42vh) scale(0.67);
            transform: translateX(-14vw) translateY(42vh) scale(0.67);
  }
}

@keyframes move13 {
  0% {
    -webkit-transform: translateX(-20vw) translateY(28vh) scale(0.31);
            transform: translateX(-20vw) translateY(28vh) scale(0.31);
  }

  5.2631578947% {
    -webkit-transform: translateX(10vw) translateY(-35vh) scale(0.91);
            transform: translateX(10vw) translateY(-35vh) scale(0.91);
  }

  10.5263157895% {
    -webkit-transform: translateX(-12vw) translateY(7vh) scale(0.65);
            transform: translateX(-12vw) translateY(7vh) scale(0.65);
  }

  15.7894736842% {
    -webkit-transform: translateX(-35vw) translateY(-3vh) scale(0.56);
            transform: translateX(-35vw) translateY(-3vh) scale(0.56);
  }

  21.0526315789% {
    -webkit-transform: translateX(-24vw) translateY(-16vh) scale(0.35);
            transform: translateX(-24vw) translateY(-16vh) scale(0.35);
  }

  26.3157894737% {
    -webkit-transform: translateX(0vw) translateY(20vh) scale(0.57);
            transform: translateX(0vw) translateY(20vh) scale(0.57);
  }

  31.5789473684% {
    -webkit-transform: translateX(-45vw) translateY(-3vh) scale(0.77);
            transform: translateX(-45vw) translateY(-3vh) scale(0.77);
  }

  36.8421052632% {
    -webkit-transform: translateX(37vw) translateY(36vh) scale(0.7);
            transform: translateX(37vw) translateY(36vh) scale(0.7);
  }

  42.1052631579% {
    -webkit-transform: translateX(-9vw) translateY(8vh) scale(0.92);
            transform: translateX(-9vw) translateY(8vh) scale(0.92);
  }

  47.3684210526% {
    -webkit-transform: translateX(-14vw) translateY(-41vh) scale(0.29);
            transform: translateX(-14vw) translateY(-41vh) scale(0.29);
  }

  52.6315789474% {
    -webkit-transform: translateX(-12vw) translateY(-35vh) scale(0.71);
            transform: translateX(-12vw) translateY(-35vh) scale(0.71);
  }

  57.8947368421% {
    -webkit-transform: translateX(-3vw) translateY(-21vh) scale(0.48);
            transform: translateX(-3vw) translateY(-21vh) scale(0.48);
  }

  63.1578947368% {
    -webkit-transform: translateX(12vw) translateY(6vh) scale(0.8);
            transform: translateX(12vw) translateY(6vh) scale(0.8);
  }

  68.4210526316% {
    -webkit-transform: translateX(16vw) translateY(-5vh) scale(0.75);
            transform: translateX(16vw) translateY(-5vh) scale(0.75);
  }

  73.6842105263% {
    -webkit-transform: translateX(-39vw) translateY(-43vh) scale(0.68);
            transform: translateX(-39vw) translateY(-43vh) scale(0.68);
  }

  78.9473684211% {
    -webkit-transform: translateX(35vw) translateY(-46vh) scale(0.3);
            transform: translateX(35vw) translateY(-46vh) scale(0.3);
  }

  84.2105263158% {
    -webkit-transform: translateX(35vw) translateY(-45vh) scale(0.83);
            transform: translateX(35vw) translateY(-45vh) scale(0.83);
  }

  89.4736842105% {
    -webkit-transform: translateX(-8vw) translateY(13vh) scale(0.39);
            transform: translateX(-8vw) translateY(13vh) scale(0.39);
  }

  94.7368421053% {
    -webkit-transform: translateX(-12vw) translateY(32vh) scale(0.45);
            transform: translateX(-12vw) translateY(32vh) scale(0.45);
  }

  100% {
    -webkit-transform: translateX(-14vw) translateY(42vh) scale(0.67);
            transform: translateX(-14vw) translateY(42vh) scale(0.67);
  }
}

.firefly:nth-child(14) {
  -webkit-animation-name: move14;
          animation-name: move14;
}

.firefly:nth-child(14)::before {
  -webkit-animation-duration: 11s;
          animation-duration: 11s;
}

.firefly:nth-child(14)::after {
  -webkit-animation-duration: 11s, 7829ms;
          animation-duration: 11s, 7829ms;
  -webkit-animation-delay: 0ms, 5814ms;
          animation-delay: 0ms, 5814ms;
}

@-webkit-keyframes move14 {
  0% {
    -webkit-transform: translateX(10vw) translateY(-18vh) scale(0.99);
            transform: translateX(10vw) translateY(-18vh) scale(0.99);
  }

  5.8823529412% {
    -webkit-transform: translateX(7vw) translateY(18vh) scale(0.53);
            transform: translateX(7vw) translateY(18vh) scale(0.53);
  }

  11.7647058824% {
    -webkit-transform: translateX(-38vw) translateY(3vh) scale(0.96);
            transform: translateX(-38vw) translateY(3vh) scale(0.96);
  }

  17.6470588235% {
    -webkit-transform: translateX(22vw) translateY(-38vh) scale(0.49);
            transform: translateX(22vw) translateY(-38vh) scale(0.49);
  }

  23.5294117647% {
    -webkit-transform: translateX(8vw) translateY(-38vh) scale(0.41);
            transform: translateX(8vw) translateY(-38vh) scale(0.41);
  }

  29.4117647059% {
    -webkit-transform: translateX(34vw) translateY(26vh) scale(0.7);
            transform: translateX(34vw) translateY(26vh) scale(0.7);
  }

  35.2941176471% {
    -webkit-transform: translateX(-7vw) translateY(-3vh) scale(0.52);
            transform: translateX(-7vw) translateY(-3vh) scale(0.52);
  }

  41.1764705882% {
    -webkit-transform: translateX(-43vw) translateY(45vh) scale(0.43);
            transform: translateX(-43vw) translateY(45vh) scale(0.43);
  }

  47.0588235294% {
    -webkit-transform: translateX(47vw) translateY(9vh) scale(0.89);
            transform: translateX(47vw) translateY(9vh) scale(0.89);
  }

  52.9411764706% {
    -webkit-transform: translateX(45vw) translateY(32vh) scale(0.77);
            transform: translateX(45vw) translateY(32vh) scale(0.77);
  }

  58.8235294118% {
    -webkit-transform: translateX(8vw) translateY(16vh) scale(0.64);
            transform: translateX(8vw) translateY(16vh) scale(0.64);
  }

  64.7058823529% {
    -webkit-transform: translateX(-45vw) translateY(-38vh) scale(0.92);
            transform: translateX(-45vw) translateY(-38vh) scale(0.92);
  }

  70.5882352941% {
    -webkit-transform: translateX(-6vw) translateY(-40vh) scale(0.64);
            transform: translateX(-6vw) translateY(-40vh) scale(0.64);
  }

  76.4705882353% {
    -webkit-transform: translateX(29vw) translateY(-27vh) scale(0.83);
            transform: translateX(29vw) translateY(-27vh) scale(0.83);
  }

  82.3529411765% {
    -webkit-transform: translateX(4vw) translateY(10vh) scale(0.86);
            transform: translateX(4vw) translateY(10vh) scale(0.86);
  }

  88.2352941176% {
    -webkit-transform: translateX(-8vw) translateY(43vh) scale(0.91);
            transform: translateX(-8vw) translateY(43vh) scale(0.91);
  }

  94.1176470588% {
    -webkit-transform: translateX(33vw) translateY(10vh) scale(0.57);
            transform: translateX(33vw) translateY(10vh) scale(0.57);
  }

  100% {
    -webkit-transform: translateX(-17vw) translateY(14vh) scale(0.83);
            transform: translateX(-17vw) translateY(14vh) scale(0.83);
  }
}

@keyframes move14 {
  0% {
    -webkit-transform: translateX(10vw) translateY(-18vh) scale(0.99);
            transform: translateX(10vw) translateY(-18vh) scale(0.99);
  }

  5.8823529412% {
    -webkit-transform: translateX(7vw) translateY(18vh) scale(0.53);
            transform: translateX(7vw) translateY(18vh) scale(0.53);
  }

  11.7647058824% {
    -webkit-transform: translateX(-38vw) translateY(3vh) scale(0.96);
            transform: translateX(-38vw) translateY(3vh) scale(0.96);
  }

  17.6470588235% {
    -webkit-transform: translateX(22vw) translateY(-38vh) scale(0.49);
            transform: translateX(22vw) translateY(-38vh) scale(0.49);
  }

  23.5294117647% {
    -webkit-transform: translateX(8vw) translateY(-38vh) scale(0.41);
            transform: translateX(8vw) translateY(-38vh) scale(0.41);
  }

  29.4117647059% {
    -webkit-transform: translateX(34vw) translateY(26vh) scale(0.7);
            transform: translateX(34vw) translateY(26vh) scale(0.7);
  }

  35.2941176471% {
    -webkit-transform: translateX(-7vw) translateY(-3vh) scale(0.52);
            transform: translateX(-7vw) translateY(-3vh) scale(0.52);
  }

  41.1764705882% {
    -webkit-transform: translateX(-43vw) translateY(45vh) scale(0.43);
            transform: translateX(-43vw) translateY(45vh) scale(0.43);
  }

  47.0588235294% {
    -webkit-transform: translateX(47vw) translateY(9vh) scale(0.89);
            transform: translateX(47vw) translateY(9vh) scale(0.89);
  }

  52.9411764706% {
    -webkit-transform: translateX(45vw) translateY(32vh) scale(0.77);
            transform: translateX(45vw) translateY(32vh) scale(0.77);
  }

  58.8235294118% {
    -webkit-transform: translateX(8vw) translateY(16vh) scale(0.64);
            transform: translateX(8vw) translateY(16vh) scale(0.64);
  }

  64.7058823529% {
    -webkit-transform: translateX(-45vw) translateY(-38vh) scale(0.92);
            transform: translateX(-45vw) translateY(-38vh) scale(0.92);
  }

  70.5882352941% {
    -webkit-transform: translateX(-6vw) translateY(-40vh) scale(0.64);
            transform: translateX(-6vw) translateY(-40vh) scale(0.64);
  }

  76.4705882353% {
    -webkit-transform: translateX(29vw) translateY(-27vh) scale(0.83);
            transform: translateX(29vw) translateY(-27vh) scale(0.83);
  }

  82.3529411765% {
    -webkit-transform: translateX(4vw) translateY(10vh) scale(0.86);
            transform: translateX(4vw) translateY(10vh) scale(0.86);
  }

  88.2352941176% {
    -webkit-transform: translateX(-8vw) translateY(43vh) scale(0.91);
            transform: translateX(-8vw) translateY(43vh) scale(0.91);
  }

  94.1176470588% {
    -webkit-transform: translateX(33vw) translateY(10vh) scale(0.57);
            transform: translateX(33vw) translateY(10vh) scale(0.57);
  }

  100% {
    -webkit-transform: translateX(-17vw) translateY(14vh) scale(0.83);
            transform: translateX(-17vw) translateY(14vh) scale(0.83);
  }
}

.firefly:nth-child(15) {
  -webkit-animation-name: move15;
          animation-name: move15;
}

.firefly:nth-child(15)::before {
  -webkit-animation-duration: 14s;
          animation-duration: 14s;
}

.firefly:nth-child(15)::after {
  -webkit-animation-duration: 14s, 8455ms;
          animation-duration: 14s, 8455ms;
  -webkit-animation-delay: 0ms, 1227ms;
          animation-delay: 0ms, 1227ms;
}

@-webkit-keyframes move15 {
  0% {
    -webkit-transform: translateX(12vw) translateY(-1vh) scale(0.28);
            transform: translateX(12vw) translateY(-1vh) scale(0.28);
  }

  4% {
    -webkit-transform: translateX(6vw) translateY(-14vh) scale(0.61);
            transform: translateX(6vw) translateY(-14vh) scale(0.61);
  }

  8% {
    -webkit-transform: translateX(9vw) translateY(-13vh) scale(0.51);
            transform: translateX(9vw) translateY(-13vh) scale(0.51);
  }

  12% {
    -webkit-transform: translateX(26vw) translateY(14vh) scale(0.81);
            transform: translateX(26vw) translateY(14vh) scale(0.81);
  }

  16% {
    -webkit-transform: translateX(-32vw) translateY(7vh) scale(0.99);
            transform: translateX(-32vw) translateY(7vh) scale(0.99);
  }

  20% {
    -webkit-transform: translateX(19vw) translateY(10vh) scale(0.27);
            transform: translateX(19vw) translateY(10vh) scale(0.27);
  }

  24% {
    -webkit-transform: translateX(-13vw) translateY(-10vh) scale(0.83);
            transform: translateX(-13vw) translateY(-10vh) scale(0.83);
  }

  28% {
    -webkit-transform: translateX(-35vw) translateY(-2vh) scale(0.39);
            transform: translateX(-35vw) translateY(-2vh) scale(0.39);
  }

  32% {
    -webkit-transform: translateX(-28vw) translateY(-13vh) scale(0.82);
            transform: translateX(-28vw) translateY(-13vh) scale(0.82);
  }

  36% {
    -webkit-transform: translateX(-45vw) translateY(28vh) scale(0.48);
            transform: translateX(-45vw) translateY(28vh) scale(0.48);
  }

  40% {
    -webkit-transform: translateX(-5vw) translateY(42vh) scale(0.74);
            transform: translateX(-5vw) translateY(42vh) scale(0.74);
  }

  44% {
    -webkit-transform: translateX(-30vw) translateY(22vh) scale(0.44);
            transform: translateX(-30vw) translateY(22vh) scale(0.44);
  }

  48% {
    -webkit-transform: translateX(15vw) translateY(-21vh) scale(0.55);
            transform: translateX(15vw) translateY(-21vh) scale(0.55);
  }

  52% {
    -webkit-transform: translateX(-6vw) translateY(1vh) scale(0.94);
            transform: translateX(-6vw) translateY(1vh) scale(0.94);
  }

  56% {
    -webkit-transform: translateX(-26vw) translateY(13vh) scale(0.26);
            transform: translateX(-26vw) translateY(13vh) scale(0.26);
  }

  60% {
    -webkit-transform: translateX(44vw) translateY(-49vh) scale(0.67);
            transform: translateX(44vw) translateY(-49vh) scale(0.67);
  }

  64% {
    -webkit-transform: translateX(23vw) translateY(-28vh) scale(0.35);
            transform: translateX(23vw) translateY(-28vh) scale(0.35);
  }

  68% {
    -webkit-transform: translateX(35vw) translateY(37vh) scale(0.56);
            transform: translateX(35vw) translateY(37vh) scale(0.56);
  }

  72% {
    -webkit-transform: translateX(-4vw) translateY(43vh) scale(0.76);
            transform: translateX(-4vw) translateY(43vh) scale(0.76);
  }

  76% {
    -webkit-transform: translateX(-43vw) translateY(43vh) scale(0.56);
            transform: translateX(-43vw) translateY(43vh) scale(0.56);
  }

  80% {
    -webkit-transform: translateX(-12vw) translateY(1vh) scale(0.99);
            transform: translateX(-12vw) translateY(1vh) scale(0.99);
  }

  84% {
    -webkit-transform: translateX(-26vw) translateY(3vh) scale(0.3);
            transform: translateX(-26vw) translateY(3vh) scale(0.3);
  }

  88% {
    -webkit-transform: translateX(46vw) translateY(-9vh) scale(0.55);
            transform: translateX(46vw) translateY(-9vh) scale(0.55);
  }

  92% {
    -webkit-transform: translateX(33vw) translateY(-15vh) scale(0.66);
            transform: translateX(33vw) translateY(-15vh) scale(0.66);
  }

  96% {
    -webkit-transform: translateX(19vw) translateY(-3vh) scale(0.59);
            transform: translateX(19vw) translateY(-3vh) scale(0.59);
  }

  100% {
    -webkit-transform: translateX(-32vw) translateY(-18vh) scale(0.31);
            transform: translateX(-32vw) translateY(-18vh) scale(0.31);
  }
}

@keyframes move15 {
  0% {
    -webkit-transform: translateX(12vw) translateY(-1vh) scale(0.28);
            transform: translateX(12vw) translateY(-1vh) scale(0.28);
  }

  4% {
    -webkit-transform: translateX(6vw) translateY(-14vh) scale(0.61);
            transform: translateX(6vw) translateY(-14vh) scale(0.61);
  }

  8% {
    -webkit-transform: translateX(9vw) translateY(-13vh) scale(0.51);
            transform: translateX(9vw) translateY(-13vh) scale(0.51);
  }

  12% {
    -webkit-transform: translateX(26vw) translateY(14vh) scale(0.81);
            transform: translateX(26vw) translateY(14vh) scale(0.81);
  }

  16% {
    -webkit-transform: translateX(-32vw) translateY(7vh) scale(0.99);
            transform: translateX(-32vw) translateY(7vh) scale(0.99);
  }

  20% {
    -webkit-transform: translateX(19vw) translateY(10vh) scale(0.27);
            transform: translateX(19vw) translateY(10vh) scale(0.27);
  }

  24% {
    -webkit-transform: translateX(-13vw) translateY(-10vh) scale(0.83);
            transform: translateX(-13vw) translateY(-10vh) scale(0.83);
  }

  28% {
    -webkit-transform: translateX(-35vw) translateY(-2vh) scale(0.39);
            transform: translateX(-35vw) translateY(-2vh) scale(0.39);
  }

  32% {
    -webkit-transform: translateX(-28vw) translateY(-13vh) scale(0.82);
            transform: translateX(-28vw) translateY(-13vh) scale(0.82);
  }

  36% {
    -webkit-transform: translateX(-45vw) translateY(28vh) scale(0.48);
            transform: translateX(-45vw) translateY(28vh) scale(0.48);
  }

  40% {
    -webkit-transform: translateX(-5vw) translateY(42vh) scale(0.74);
            transform: translateX(-5vw) translateY(42vh) scale(0.74);
  }

  44% {
    -webkit-transform: translateX(-30vw) translateY(22vh) scale(0.44);
            transform: translateX(-30vw) translateY(22vh) scale(0.44);
  }

  48% {
    -webkit-transform: translateX(15vw) translateY(-21vh) scale(0.55);
            transform: translateX(15vw) translateY(-21vh) scale(0.55);
  }

  52% {
    -webkit-transform: translateX(-6vw) translateY(1vh) scale(0.94);
            transform: translateX(-6vw) translateY(1vh) scale(0.94);
  }

  56% {
    -webkit-transform: translateX(-26vw) translateY(13vh) scale(0.26);
            transform: translateX(-26vw) translateY(13vh) scale(0.26);
  }

  60% {
    -webkit-transform: translateX(44vw) translateY(-49vh) scale(0.67);
            transform: translateX(44vw) translateY(-49vh) scale(0.67);
  }

  64% {
    -webkit-transform: translateX(23vw) translateY(-28vh) scale(0.35);
            transform: translateX(23vw) translateY(-28vh) scale(0.35);
  }

  68% {
    -webkit-transform: translateX(35vw) translateY(37vh) scale(0.56);
            transform: translateX(35vw) translateY(37vh) scale(0.56);
  }

  72% {
    -webkit-transform: translateX(-4vw) translateY(43vh) scale(0.76);
            transform: translateX(-4vw) translateY(43vh) scale(0.76);
  }

  76% {
    -webkit-transform: translateX(-43vw) translateY(43vh) scale(0.56);
            transform: translateX(-43vw) translateY(43vh) scale(0.56);
  }

  80% {
    -webkit-transform: translateX(-12vw) translateY(1vh) scale(0.99);
            transform: translateX(-12vw) translateY(1vh) scale(0.99);
  }

  84% {
    -webkit-transform: translateX(-26vw) translateY(3vh) scale(0.3);
            transform: translateX(-26vw) translateY(3vh) scale(0.3);
  }

  88% {
    -webkit-transform: translateX(46vw) translateY(-9vh) scale(0.55);
            transform: translateX(46vw) translateY(-9vh) scale(0.55);
  }

  92% {
    -webkit-transform: translateX(33vw) translateY(-15vh) scale(0.66);
            transform: translateX(33vw) translateY(-15vh) scale(0.66);
  }

  96% {
    -webkit-transform: translateX(19vw) translateY(-3vh) scale(0.59);
            transform: translateX(19vw) translateY(-3vh) scale(0.59);
  }

  100% {
    -webkit-transform: translateX(-32vw) translateY(-18vh) scale(0.31);
            transform: translateX(-32vw) translateY(-18vh) scale(0.31);
  }
}

@-webkit-keyframes drift {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes drift {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@-webkit-keyframes flash {

  0%,
  30%,
  100% {
    opacity: 0;
    box-shadow: 0 0 0vw 0vw #31ff40;
  }

  5% {
    opacity: 1;
    box-shadow: 0 0 2vw 0.4vw #31ff40;
  }
}

@keyframes flash {

  0%,
  30%,
  100% {
    opacity: 0;
    box-shadow: 0 0 0vw 0vw #31ff40;
  }

  5% {
    opacity: 1;
    box-shadow: 0 0 2vw 0.4vw #31ff40;
  }
}

@media screen and (max-width: 499px) {

  .page-loader {
    width: 100%;
    height: 100vh;
    background: #242424;
    z-index: 5000000000000000;
    position: fixed;
  }

  .loading-area {
    display: grid;
    place-items: center;
    height: 100vh;
  }

  .containerLoad {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .h1Load {
    font-size: 1.5rem;
    color: #00ff2a;
  }

  .ring {
    width: 200px;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  .ring::before {
    content: '';
    width: 200px;
    height: 200px;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
    -webkit-animation: ring 2s linear infinite;
            animation: ring 2s linear infinite;
  }

  @-webkit-keyframes ring {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
      box-shadow: 0px 5px 5px #008afa;
    }

    25% {
      -webkit-transform: rotate(90deg);
              transform: rotate(90deg);
      box-shadow: 0px 5px 5px #02b7ff;
    }

    50% {
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg);
      box-shadow: 0px 5px 5px #0151ff;
    }

    75% {
      -webkit-transform: rotate(270deg);
              transform: rotate(270deg);
      box-shadow: 0px 5px 5px #367de7;
    }

    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
      box-shadow: 0px 5px 5px #008afa;
    }
  }

  @keyframes ring {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
      box-shadow: 0px 5px 5px #008afa;
    }

    25% {
      -webkit-transform: rotate(90deg);
              transform: rotate(90deg);
      box-shadow: 0px 5px 5px #02b7ff;
    }

    50% {
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg);
      box-shadow: 0px 5px 5px #0151ff;
    }

    75% {
      -webkit-transform: rotate(270deg);
              transform: rotate(270deg);
      box-shadow: 0px 5px 5px #367de7;
    }

    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
      box-shadow: 0px 5px 5px #008afa;
    }
  }

  .miniBarMain {
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100vw;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #020529b7;
    display: flex;
    justify-content: space-between;
  }

  .miniBar {
    display: flex;
    justify-content: space-between;
  }

  .navbarMainImgSet {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    align-items: center;
    display: flex;
  }

  .navbar {
    width: 40px;
    justify-content: flex-end;
    cursor: pointer;
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
    display: block;
    align-items: center;

  }

  #fontSize2 {
    font-size: 16px;
    color: white;
    text-align: left;
  }

  #fontSize2:hover {
    color: #818181;
  }

  .littleNav div {
    text-align: left;
    padding: 30px;
    cursor: pointer;
    border-top: #2ba531 1px solid;
  }

  .littleNav {
    background-color: #1a1a1ad8;
    width: 100%;
    height: -webkit-max-content;
    height: max-content;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1000000000;
    flex-flow: column wrap;
    display: flex;
    border-bottom: 5px #196e1e solid;
  }

  .hidden {
    display: none;
  }

  .close {
    width: 35px;
    height: 35px;
    justify-content: flex-end;
    margin-left: auto;
    cursor: pointer;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
  }

  .navLink {
    color: #ff000091;
  }

  .audioPic {
    /* display: none;*/
    width: 100px;
    height: 100px;
    position: sticky;
    position: -webkit-sticky;
    left: 0;
    bottom: 10%;
    z-index: 1000;
  }


  .rmbg {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-bottom: 2%;
    background-color: rgba(0, 0, 0, 0);

  }

  .rmbg2 {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-top: 10%;
    padding-bottom: 2%;
    background: url(/static/media/rmbg.6ed0fbcc.jpg) no-repeat center center fixed;
    background-size: cover;
    width: 97%;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .placeAndWL {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    z-index: 100000000;

  }

  input {
    background-color: rgba(0, 30, 70, 0.568);
    border: white 1px solid;
    font-size: 20px;
    padding: 15px;
    color: #00d6fc;
    z-index: 100000000;
  }

  input:focus {
    background-color: #b3e9ff00;
    color: #00d6fc;
  }

  #wlBtn {
    background-color: rgba(0, 30, 70, 0.568);
    border: 1px solid rgb(16, 192, 223);
    color: #ffffff;
    width: 200px;
    z-index: 100000000;
  }

  #wlBtn:hover {
    font-size: 18px;
  }

  .wlLine {
    background-color: #000000;
    padding: 50px;
  }


  .cloudsMob {
    display: none;
  }

  .connectMob {
    display: none;
  }

  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-top: 100px;
    padding-bottom: 100px;
    background: rgb(52, 52, 52);
    background: linear-gradient(180deg, rgba(52, 52, 52, 1) 12%, rgba(0, 0, 0, 1) 100%);
  }


  .logosMob {
    display: none;
  }

  .allWrap {
    background: rgb(49,89,63);
background: radial-gradient(circle, rgba(49,89,63,1) 0%, rgba(52,74,61,1) 50%, rgba(35,51,41,1) 100%);
    height: 100vh;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    overflow: hidden;
  }

  .cont {
    background: radial-gradient(circle, rgba(0, 0, 0, 0.06684392507002801) 0%, rgba(0, 0, 0, 0.11166185224089631) 50%, rgba(0, 0, 0, 0.3693649334733894) 100%), url(/static/media/BG.b24e41c2.jpg) no-repeat center center fixed;
    background-size: cover;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }



  .in2 p {
    font-size: 28px;
    text-align: center;
    font-family: "Iomanoid";
    letter-spacing: 4px;
    text-shadow: 0 0 10px #ffdc64;
    color: #ffdc64;

  }

  .in2 {
    z-index: 100000000;

  }

  @-webkit-keyframes heartbeat {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }

    50% {
      -webkit-transform: scale(0.9);
              transform: scale(0.9);
    }

    60% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1);
    }

    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }

  @keyframes heartbeat {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }

    50% {
      -webkit-transform: scale(0.9);
              transform: scale(0.9);
    }

    60% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1);
    }

    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }

  .logo2Intro {
    z-index: 200000000000000000000000000000000;
    width: 40%;
    padding-top: 27.9vh;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    -webkit-animation: heartbeat 2s ease-in-out infinite;
            animation: heartbeat 2s ease-in-out infinite;
  }

  .in2 img {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 10vh;
    transition: -webkit-transform 0.8s;
    transition: transform 0.8s;
    transition: transform 0.8s, -webkit-transform 0.8s;
    z-index: 100000000;
  }

  .in2 img:hover {
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
  }


  .introduction {
    font-family: "Iomanoid";
  }

  .twitter img {
    width: 50px;
    height: 50px;
    margin-left: 30px;
    cursor: pointer;
    opacity: 0.6;
    transition: 0.2s ease;
    margin-top: -3%;
    z-index: 200000000000;

  }

  .twitter {
    z-index: 200000000000;
  }

  .twitter img:hover {
    opacity: 100%;
    z-index: 200000000000;

  }

  .boxWrap2Story {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-top: 5%;
    padding-bottom: 2%;
    /*background: linear-gradient(180deg, rgba(9, 28, 45, 0.10325849089635852) 0%, rgba(13, 26, 38, 0.36656381302521013) 35%, rgba(9, 28, 45, 1) 100%), url('./assets/bg1.jpg') no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;*/
    /*background: rgb(12, 22, 42);
    background: linear-gradient(180deg, rgba(12, 22, 42, 1) 11%, rgba(3, 43, 25, 1) 90%);*/
    background-color: rgba(0, 0, 0, 0);
  }

  .boxWrap2StoryR {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    /*background: linear-gradient(180deg, rgba(9, 28, 45, 0.10325849089635852) 0%, rgba(13, 26, 38, 0.36656381302521013) 35%, rgba(9, 28, 45, 1) 100%), url('./assets/bg1.jpg') no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;*/
    /*background: rgb(12, 22, 42);
    background: linear-gradient(180deg, rgba(12, 22, 42, 1) 11%, rgba(3, 43, 25, 1) 90%);*/
    background-color: rgba(0, 0, 0, 0);
    ;
  }

  .boxWrap2StoryT {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-bottom: 2%;
    /*background: linear-gradient(180deg, rgba(9, 28, 45, 0.10325849089635852) 0%, rgba(13, 26, 38, 0.36656381302521013) 35%, rgba(9, 28, 45, 1) 100%), url('./assets/bg1.jpg') no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;*/
    /*background: rgb(12, 22, 42);
    background: linear-gradient(180deg, rgba(12, 22, 42, 1) 11%, rgba(3, 43, 25, 1) 90%);*/
    background-color: rgba(0, 0, 0, 0);
    ;
  }


  .boxWrap2Story2 {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0);

    padding-bottom: 2%;
  }

  .boxWrap2Mint {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-size: cover;
    padding-bottom: 2%;
    z-index: 100000000000;
  }

  .boxWrap2SubR {
    justify-content: space-between;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .boxWrap2Sub1 {}

  .boxWrap2Sub {
    justify-content: space-between;
    width: 98%;
    margin-left: auto;
    margin-right: auto;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.164) 0%, rgba(0, 0, 0, 0.301) 50%, rgba(0, 0, 0, 0.534) 100%), url('https://cdn.discordapp.com/attachments/1028976349920112681/1139504255498195055/NFTHouse9_coins_in_space_cartoon_theme_326f3ecc-8f98-4844-81b1-49ce9a21ba21.png') no-repeat center center fixed;
    background-size: cover;
    padding-top: 10%;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }



  .boxWrap2SubT {
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    background-color: #22e2d9;
  }

  .storyH {
    font-size: 200px;
    color: black;
    font-weight: bolder;
    text-align: center;
    margin-bottom: 10px;
    font-style: normal;
    font-family: 'Exo', sans-serif;
  }

  .outer-div {
    width: 500px;
    height: 100px;
    background-color: #fff;
    transition: background-color 1s ease;
  }

  .storyHK {
    font-size: 80px;
    color: white;
    font-weight: 500;
    text-align: center;
    font-family: "Iomanoid";
    margin-bottom: 2%;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px rgba(255, 255, 0, 0.616), 0 -10px 20px #ff80008c, 0 -18px 40px rgba(255, 0, 0, 0.719);
  }

  .storyH2 {
    font-size: 50px;
    color: rgb(255, 255, 255);
    font-weight: bolder;
    text-align: center;
    margin-bottom: 2%;
    font-family: 'Exo', sans-serif;
  }

  .storyH3 {
    font-size: 100px;
    color: rgb(255, 255, 255);
    font-weight: bolder;
    text-align: center;
    margin-bottom: 2%;
    font-family: 'Exo', sans-serif;
    background: rgb(88, 101, 242);
    background: linear-gradient(180deg, rgb(36, 51, 219) 12%, rgb(67, 3, 185) 100%);
    width: 700px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
    cursor: pointer;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    margin-top: -15%;
  }

  .storyH3:hover {
    background: rgb(38, 47, 222);
    background: linear-gradient(180deg, rgba(38, 47, 222, 1) 12%, rgba(176, 68, 242, 1) 100%);
  }

  /* Animation */
  @-webkit-keyframes fill-div {
    from {
      width: 0;
    }

    to {
      width: 100%;
    }
  }
  @keyframes fill-div {
    from {
      width: 0;
    }

    to {
      width: 100%;
    }
  }

  .bg {
    background-color: #17237e;
    padding-left: 50px;
    padding-right: 50px;
  }

  .storyH2-2 {
    font-size: 50px;
    color: white;
    font-weight: 500;
    text-align: right;
    font-family: "Iomanoid";
    margin-bottom: 10px;
    text-shadow: 0 0 10px #ce9381;
  }

  .about {
    color: white;
    font-size: 18px;
    text-align: left;
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
  }

  .aboutKingdom {
    color: white;
    font-size: 18px;
    text-align: left;
    display: flex;
    justify-content: space-evenly;
  }

  .kingdomsSection {
    color: white;
    font-size: 18px;
    text-align: left;
    display: flex;
    justify-content: space-evenly;
    flex-flow: column nowrap;
  }

  .aboutP {
    width: 90%;
    margin-bottom: 50px;
    margin-top: 30px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
  }

  .aboutPNew {
    width: 90%;
    margin-bottom: 50px;
    margin-top: 30px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
  }

  .aboutPS {
    width: 95%;
    margin-bottom: 50px;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .aboutP3 {
    width: 60%;
    margin-top: auto;
    margin-bottom: auto;
    margin-bottom: 50px;
  }

  .aboutP3 p {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 23px;
  }

  .aboutP p {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 22px;
  }

  .aboutPNew p {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 18px;
  }

  .aboutPDiv {
    background: rgb(101, 161, 90);
    background: linear-gradient(93deg, rgba(101, 161, 90, 1) 0%, rgba(32, 71, 128, 1) 50%);
    -webkit-clip-path: polygon(0 0, 100% 0%, 98% 100%, 0% 100%);
            clip-path: polygon(0 0, 100% 0%, 98% 100%, 0% 100%);
    width: 90%;
    margin-bottom: 12px;
    margin-top: 12px;
    padding: 20px;
    font-size: 18px;
    line-height: 40px;
  }

  .aboutPDiv2 {
    background-color: #2a32438a;
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
    -webkit-clip-path: polygon(0 0, 100% 0%, 98% 100%, 0% 100%);
            clip-path: polygon(0 0, 100% 0%, 98% 100%, 0% 100%);
    padding: 20px;
    font-size: 20px;
    color: white;
    margin-top: 25px;
    margin-top: 25px;
  }

  .hl {
    color: #1ee03e;
  }


  .bigLetters {
    font-size: 28px;
    line-height: 10px;
  }

  .ironfist {
    margin-top: auto;
    margin-left: -40px;
    z-index: 100000;
  }

  .ironfist img {
    width: 50px;
    height: 50px;

  }

  .storyConAndImg {
    display: flex;
    justify-content: center;
  }

  .aboutP2 {
    width: 50%;
    margin-top: auto;
    margin-bottom: auto;
  }

  .zeus {
    width: 100%;
    height: 100%;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    -webkit-animation: floating 3s ease-in-out infinite;
            animation: floating 3s ease-in-out infinite;
  }

  .zeus2 {
    width: 60%;
    height: 60%;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 50px;
  }

  @-webkit-keyframes floating {

    0%,
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    50% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
    }
  }

  @keyframes floating {

    0%,
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    50% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
    }
  }

  .logos {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
  }

  .logos_mobile {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
    display: none;
  }

  .logoF img {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 85%;
    display: flex;
  }


  .facetoface img {
    width: 50%;
  }

  .facetoface {
    display: flex;
    justify-content: space-between;
    margin-top: -15%;
  }

  .footerImg {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: space-evenly;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
  }

  .footerImg img {
    margin-left: 5px;
    margin-right: 5px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
  }

  .footerImg img:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    cursor: pointer;
  }

  .footer2 {
    justify-content: center;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .copyright {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 13px;
  }

  .footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: rgba(102, 102, 102, 0);
    width: 100%;
  }

  .sword {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 10%;
  }

  .devs {
    color: rgba(255, 255, 255, 0.774);
    text-align: center;
    margin-top: 80px;
  }

  .devs img {
    width: 30px;
    height: 30px;
    opacity: 0.9;
    transition: 0.3s ease;
  }

  .devs:hover {
    color: rgb(255, 255, 255);
    cursor: pointer;
  }

  .devs img:hover {
    width: 30px;
    height: 30px;
  }

  a {
    color: #d1fdfb;
    text-decoration: none;
  }

  a:hover {
    color: #99faf5;
    text-decoration: none;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #020529b7;
    display: none;
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    width: 95%;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    height: 5vh;
  }

  .connect div {
    margin-left: 15px;
    margin-right: 15px;
    color: white;
    margin-top: auto;
    justify-content: space-evenly;
    display: flex;
    cursor: pointer;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    text-shadow: -1px -1px 1px #f899345d, 1px 1px 1px #fe921e6c;

  }

  .connect div:hover {
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
  }

  .icons {
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    width: 33.33vw;
    margin-left: 10px;
  }

  .icons img {
    width: 30px;
    height: 30px;
    margin-left: 10px;
    margin-right: 10px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    z-index: 10000000;
  }

  .icons img:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    cursor: pointer;
  }

  #fontSize {
    font-size: 18px;
    z-index: 10000000;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    align-items: center;

  }

  #fontSize-2 {
    font-size: 25px;
    z-index: 10000000;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    align-items: center;
  }

  /* CSS */
  .button-92 {
    --c: #fff;
    /* text color */
    background: linear-gradient(90deg, #0000 33%, #fff5, #0000 67%) 100%/300% no-repeat,
      #009218;
    background: linear-gradient(90deg, #0000 33%, #fff5, #0000 67%) var(--_p, 100%)/300% no-repeat,
      #009218;
    /* background color */
    color: #0000;
    border: none;
    -webkit-transform: perspective(500px) rotateY(calc(20deg * -1));
            transform: perspective(500px) rotateY(calc(20deg * -1));
    -webkit-transform: perspective(500px) rotateY(calc(20deg * var(--_i, -1)));
            transform: perspective(500px) rotateY(calc(20deg * var(--_i, -1)));
    text-shadow: calc(-1 * 0.08em) -.01em 0 var(--c),
      calc(-1 * -0.08em) .01em 2px #0004;
    text-shadow: calc(var(--_i, -1) * 0.08em) -.01em 0 var(--c),
      calc(var(--_i, -1) * -0.08em) .01em 2px #0004;
    outline-offset: .1em;
    transition: 0.3s;
    width: 150px;
  }

  .button-92:hover,
  .button-92:focus-visible {
    --_p: 0%;
    --_i: 1;
  }

  .button-92:active {
    text-shadow: none;
    color: var(--c);
    box-shadow: inset 0 0 9e9q #0005;
    transition: 0s;
  }

  .button-92 {
    font-weight: bold;
    font-size: 1.5rem;
    margin: 0;
    cursor: pointer;
    padding: .1em .3em;
  }

  .title {
    color: white;
    text-shadow: -1px -1px 1px #f899345d, 1px 1px 1px #fe921e6c;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: center;
    font-size: 25px;
    display: none;
  }

  .logoUp {
    width: 40px;
    height: 40px;
    margin-top: -5px;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;
  }

  /* CSS */
  .button-62 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    background-color: #bb5a3c;
    font-size: 18px;
    padding: 13px;
    width: 200px;
    color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    transition: 0.3s ease;
    border-radius: 7px;
    border: none;
    font-weight: 500;
  }

  .button-62:hover {
    color: rgb(219, 219, 219);
  }

  .logo2 img {
    width: 75px;
    height: 75px;
  }

  .downImg {
    width: 35px;
    -webkit-animation: arrow_down_animation 2s ease-in-out infinite;
            animation: arrow_down_animation 2s ease-in-out infinite;
    cursor: pointer;
  }


  @-webkit-keyframes arrow_down_animation {

    0% {
      -webkit-transform: translate3d(0, 0, 0) scale(1, 1);
              transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;
    }

    30% {
      opacity: 1;
    }

    100% {
      -webkit-transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
              transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;

    }

  }


  @keyframes arrow_down_animation {

    0% {
      -webkit-transform: translate3d(0, 0, 0) scale(1, 1);
              transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;
    }

    30% {
      opacity: 1;
    }

    100% {
      -webkit-transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
              transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;

    }

  }

  .downImgDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -25px;

  }

  .down {
    text-align: center;
    background-color: rgba(0, 0, 0, 0);
  }

  .stickyBtn2 {
    width: 65px;
    height: 65px;
    z-index: 1;
  }

  #sticky-button {
    position: -webkit-sticky;
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 15%;
    margin-left: 92%;
    margin-top: -8%;
    cursor: pointer;
    transition: -webkit-transform .4s;
    transition: transform .4s;
    transition: transform .4s, -webkit-transform .4s;
    z-index: 1;

  }

  .stickyBtn2:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    opacity: 100%;
    z-index: 1;

  }

  .clouds2 {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  .cloud {
    position: absolute;
    top: 0;

  }

  .cloud1 {
    left: 10vw;

  }

  .cloud1 img {
    width: 120%;
    margin-top: -13%;
  }

  .cloud2 img {
    width: 70%;
    margin-top: -8%;
  }

  .cloud3 img {
    width: 100%;

  }

  .cloud1-1 {
    left: 70vw;
    -webkit-animation-name: move-clouds;
            animation-name: move-clouds;
    -webkit-animation-duration: 600s;
            animation-duration: 600s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
  }

  .cloud2 {
    left: 5px;
    -webkit-animation-name: move-clouds;
            animation-name: move-clouds;
    -webkit-animation-duration: 200s;
            animation-duration: 200s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
  }

  .cloud3 {
    left: 3px;

  }

  .cloud3-3 {
    left: 10px;
    -webkit-animation-name: move-clouds;
            animation-name: move-clouds;
    -webkit-animation-duration: 480s;
            animation-duration: 480s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
  }

  .cloud4 {
    left: 10vw;
  }

  .cloud4 img {
    width: 70%;
    margin-top: -5%;
    margin-left: -5%;
  }

  .cloud3 img {
    width: 25%;
  }

  @-webkit-keyframes move-clouds {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(110vw);
              transform: translateX(110vw);
    }
  }

  @keyframes move-clouds {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(110vw);
              transform: translateX(110vw);
    }
  }

  .cloudsLine img {
    width: 100%;
    display: flex;
  }

  .Q {
    font-size: 30px;
    color: white;
    font-weight: 500;
    margin-bottom: 10px;
    text-shadow: 0 0 10px #ecc46d;
  }

  .rmCon {
    color: white;
    font-size: 18px;
  }

  .rmConAndImg {
    margin-bottom: 30px;
    margin-top: 30px;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .rmCon img {
    width: 30px;
    height: 30px;
  }

  .line {
    width: 100%;
  }

  .connect2-2 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    box-shadow: rgba(0, 0, 0, 0.493) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    border: 12px #7f8082;

    border: #ffa528 5px groove;
  }

  .parrot {
    width: 70%;
  }

  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .totalSupply {
    text-align: center;
    font-size: 60px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(255, 255, 255);
    letter-spacing: 1px;
    margin-top: 1%;
  }

  .price {
    text-align: center;
    font-size: 25px;
    color: white;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }


  .center {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
    z-index: 10000000;

  }

  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: none;

    cursor: pointer;
    background-color: #6cfc1900;
    color: rgb(255, 255, 255);
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: 2px solid rgb(255, 255, 255);
    z-index: 1000000000000000;

  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: none;

    cursor: pointer;
    border: 2px solid rgb(255, 255, 255);
    color: rgb(255, 255, 255);
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    background-color: #00000000;
    z-index: 1000000000000000;

  }


  .btnfos-0-2:hover:active {
    background-color: #0e2942;
  }


  .btnfos-0-2-2:hover:active {
    background-color: #0e2942;
  }


  .btnfos-0-3 {
    margin-top: 20px;
    background: rgb(0, 120, 235);
    background: linear-gradient(180deg, rgba(0, 120, 235, 1) 0%, rgba(89, 154, 215, 1) 50%, rgba(0, 120, 235, 1) 100%);
    color: #ffffff;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 28px;
    padding-left: 30px;
    padding-right: 30px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    border: 6px rgb(0, 0, 0);
    border-style: double;
    z-index: 1000000000000000;
    transition: transform .2s;
  }

  .btnfos-0-3:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

  .nftamount {
    color: #ecaa2f;
    font-size: 85px;
  }

  .mintbuttondiv {
    text-align: center;
    z-index: 10000000;

  }

  .successfully {
    text-align: center;
    margin-top: 3%;
    color: #1ee03e;
    font-size: 30px;
    text-shadow: 0 0 20px #9bf179;
  }

  .errorMessage {
    margin-top: 3%;
    font-size: 20px;
    color: #fc4741;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 3%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .loadingText {
    font-size: 20px;
    text-align: center;
    color: rgb(240, 161, 97);
  }

  .loadTextSub {
    text-align: center;
    color: rgba(255, 255, 255, 0.815);
    font-size: 16px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    -webkit-animation: dots 1s steps(5, end) infinite;
            animation: dots 1s steps(5, end) infinite;
  }

  @-webkit-keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(214, 80, 71);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(209, 119, 45),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(214, 80, 71);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(209, 119, 45),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .rm2Box {
    display: flex;
    justify-content: center;
    margin-right: auto;

  }

  .rm3Box {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-top: 100px;
    margin-bottom: 100px;
  }

  .rmbox1 {
    -webkit-clip-path: polygon(0 0, 96% 0, 100% 100%, 0% 100%);
            clip-path: polygon(0 0, 96% 0, 100% 100%, 0% 100%);
    font-size: 25px;
    background-color: #000000a1;
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
    color: rgb(255, 255, 255);
    width: 200px;
    padding: 30px;
    text-align: center;

  }

  .rmbox2 {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 4% 100%);
            clip-path: polygon(0 0, 100% 0, 100% 100%, 4% 100%);
    font-size: 20px;
    background-color: #007906c2;
    color: white;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 30px;
    text-align: center;
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .rmbox1-3 {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 4% 100%);
            clip-path: polygon(0 0, 100% 0, 100% 100%, 4% 100%);
    text-align: center;
    font-size: 25px;
    background-color: #000000a1;

    -webkit-backdrop-filter: blur(5px);

            backdrop-filter: blur(5px);
    color: rgb(255, 255, 255);
    width: 200px;
    padding: 30px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }


  .rmbox2-3 {
    font-size: 20px;
    -webkit-clip-path: polygon(0 0, 96% 0, 100% 100%, 0% 100%);
            clip-path: polygon(0 0, 96% 0, 100% 100%, 0% 100%);
    text-align: center;
    background-color: #007906c2;
    color: white;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 30px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
    text-align: center;

  }

  .rmConMain {
    display: flex;
    justify-content: space-between;
    flex-flow: column nowrap;
    margin-top: 80px;
    display: none;
  }

  .rmConMainMob {
    display: flex;
    justify-content: space-between;
    flex-flow: column nowrap;
    margin-top: 80px;
  }

  .scroll-effect-container {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    margin-bottom: 30px;

  }

  .scroll-effect {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: #ffffff00;
    text-align: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding-left: 20px;
    padding-right: 20px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

  }

  .scroll-effect-relic {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: #ffffff00;
    text-align: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding-left: 20px;
    padding-right: 20px;

  }

  .scroll-effect-mint {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: #ffffff00;
    text-align: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding-left: 20px;
    padding-right: 20px;

  }

  .scroll-effect-tokenomics {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: #ffffff00;
    text-align: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding-left: 20px;
    padding-right: 20px;

  }

  .scroll-effect-footer {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: #ffffff00;
    text-align: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding-left: 50px;
    padding-right: 50px;

  }


  .scroll-effect-rm {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: #ffffff00;
    text-align: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding-left: 50px;
    padding-right: 50px;

  }


  .scroll-effect-footer {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: #ffffff00;
    text-align: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding-left: 50px;
    padding-right: 50px;
  }

  @-webkit-keyframes fill-effect {
    from {
      width: 0%;
    }

    to {
      width: 100%;
    }
  }

  @keyframes fill-effect {
    from {
      width: 0%;
    }

    to {
      width: 100%;
    }
  }

  .animate {
    /*background: rgb(87, 102, 244);
    background: linear-gradient(270deg, rgba(87, 102, 244, 1) 0%, rgba(36, 46, 167, 1) 51%);*/
    background-color: #000000a1;
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
    background-repeat: no-repeat;
    background-size: 0% 100%;
    transition: background-size 1s ease-in-out;
    -webkit-animation: fill-effect 1s ease-in-out;
            animation: fill-effect 1s ease-in-out;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }

  .animate.animate {
    background-size: 100% 100%;

  }

  /*.animateC{
    background: rgb(244, 87, 179);
    background: linear-gradient(270deg, rgb(238, 77, 190) 0%, rgb(139, 8, 89) 51%);  background-repeat: no-repeat;
    background-size: 0% 100%;
    transition: background-size 0.5s ease-in-out;
    animation: fill-effect 0.5s ease-in-out;
    font-style: italic;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }*/

  .animateC.animateC {
    background-size: 100% 100%;

  }

  /* Define the walkingNft class */
  .walkingNft {
    width: 30%;
    position: absolute;
    bottom: -0.8%;
    left: 0;
    -webkit-animation: walk 20s linear infinite;
            animation: walk 20s linear infinite;

  }

  /* Define the walk animation */
  @-webkit-keyframes walk {
    0% {
      -webkit-transform: translateX(-5vw);
              transform: translateX(-5vw);
    }

    10% {
      -webkit-transform: translateX(10vw) rotate(6deg);
              transform: translateX(10vw) rotate(6deg);
    }

    20% {
      -webkit-transform: translateX(20vw) rotate(0deg);
              transform: translateX(20vw) rotate(0deg);
    }

    30% {
      -webkit-transform: translateX(30vw) rotate(6deg);
              transform: translateX(30vw) rotate(6deg);
    }

    40% {
      -webkit-transform: translateX(40vw) rotate(0deg);
              transform: translateX(40vw) rotate(0deg);
    }

    50% {
      -webkit-transform: translateX(50vw) rotate(6deg);
              transform: translateX(50vw) rotate(6deg);
    }

    60% {
      -webkit-transform: translateX(60vw) rotate(0deg);
              transform: translateX(60vw) rotate(0deg);
    }

    70% {
      -webkit-transform: translateX(70vw) rotate(6deg);
              transform: translateX(70vw) rotate(6deg);
    }

    80% {
      -webkit-transform: translateX(80vw) rotate(0deg);
              transform: translateX(80vw) rotate(0deg);
    }

    90% {
      -webkit-transform: translateX(90vw) rotate(6deg);
              transform: translateX(90vw) rotate(6deg);
    }

    100% {
      -webkit-transform: translateX(100vw) rotate(0deg);
              transform: translateX(100vw) rotate(0deg);
    }

  }
  @keyframes walk {
    0% {
      -webkit-transform: translateX(-5vw);
              transform: translateX(-5vw);
    }

    10% {
      -webkit-transform: translateX(10vw) rotate(6deg);
              transform: translateX(10vw) rotate(6deg);
    }

    20% {
      -webkit-transform: translateX(20vw) rotate(0deg);
              transform: translateX(20vw) rotate(0deg);
    }

    30% {
      -webkit-transform: translateX(30vw) rotate(6deg);
              transform: translateX(30vw) rotate(6deg);
    }

    40% {
      -webkit-transform: translateX(40vw) rotate(0deg);
              transform: translateX(40vw) rotate(0deg);
    }

    50% {
      -webkit-transform: translateX(50vw) rotate(6deg);
              transform: translateX(50vw) rotate(6deg);
    }

    60% {
      -webkit-transform: translateX(60vw) rotate(0deg);
              transform: translateX(60vw) rotate(0deg);
    }

    70% {
      -webkit-transform: translateX(70vw) rotate(6deg);
              transform: translateX(70vw) rotate(6deg);
    }

    80% {
      -webkit-transform: translateX(80vw) rotate(0deg);
              transform: translateX(80vw) rotate(0deg);
    }

    90% {
      -webkit-transform: translateX(90vw) rotate(6deg);
              transform: translateX(90vw) rotate(6deg);
    }

    100% {
      -webkit-transform: translateX(100vw) rotate(0deg);
              transform: translateX(100vw) rotate(0deg);
    }

  }


  .images2 {
    display: flex;
    justify-content: space-between;
  }

  .images2 img {
    width: 50%;
    z-index: 10000;
  }



  * {
    box-sizing: border-box;
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 4rem;
    background-image: url(/static/media/ticker.81e610af.png);
    background-size: contain;
    padding-left: 100%;
    z-index: 20000000000000000;
    margin-top: -3.5px;
  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 4rem;
    line-height: 4rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 30s;
    animation-duration: 50s;
    z-index: 1000000000000;

  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 28px;
    font-weight: 800;
    color: white;
    z-index: 1000000000000;

  }

  .line {
    background-color: rgb(255, 255, 255);
    height: 20vh;
    width: 120vw;
    border-bottom: 12px solid rgb(0, 113, 189);
    border-top: 12px solid rgb(0, 113, 189);
    rotate: 10deg;
    margin-left: -50px;
    display: flex;
    justify-content: space-between;
    margin-top: 15%;
  }

  .line2 {
    background-color: rgb(255, 255, 255);
    height: 20vh;
    width: 120vw;
    border-bottom: 12px solid rgb(0, 113, 189);
    border-top: 12px solid rgb(0, 113, 189);
    rotate: 8deg;
    margin-left: -40px;
    display: flex;
    margin-top: 2%;
    justify-content: space-between;
    margin-bottom: 100px;
  }

  .line2 img {
    width: 50px;
    height: 50px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .line img {
    width: 50px;
    height: 50px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .weapon {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    width: 25%;
    margin-top: -15%;
  }

  .potionBottle {
    width: 23%;
    margin-top: -10%;
    z-index: 100000;
    margin-left: 20px;
  }

  .bawl {
    width: 60%;
    margin-top: -5%;
    z-index: 100000;
    margin-left: 20px;
  }

  .ball {
    width: 15%;
    margin-top: -10%;
    z-index: 100000;
    margin-left: 50px;
    rotate: 15deg;
  }


  @-webkit-keyframes float {
    0% {
      -webkit-transform: translate(0, 0);
              transform: translate(0, 0);
    }

    50% {
      -webkit-transform: translate(0, 20px);
              transform: translate(0, 20px);
    }

    100% {
      -webkit-transform: translate(0, 0);
              transform: translate(0, 0);
    }
  }


  @keyframes float {
    0% {
      -webkit-transform: translate(0, 0);
              transform: translate(0, 0);
    }

    50% {
      -webkit-transform: translate(0, 20px);
              transform: translate(0, 20px);
    }

    100% {
      -webkit-transform: translate(0, 0);
              transform: translate(0, 0);
    }
  }

  .mashroom {
    width: 23%;
    margin-top: -20%;
    z-index: 100000;
    margin-left: 70%;
    rotate: 25deg;
  }

  .lucky {
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
  }

  .lucky img {
    width: 20%;
  }

  @-webkit-keyframes shake {
    0% {
      -webkit-transform: translate(0, 0) rotate(0);
              transform: translate(0, 0) rotate(0);
    }

    10% {
      -webkit-transform: translate(-10px, 0) rotate(-5deg);
              transform: translate(-10px, 0) rotate(-5deg);
    }

    20% {
      -webkit-transform: translate(10px, 0) rotate(5deg);
              transform: translate(10px, 0) rotate(5deg);
    }

    30% {
      -webkit-transform: translate(-10px, 0) rotate(-5deg);
              transform: translate(-10px, 0) rotate(-5deg);
    }

    40% {
      -webkit-transform: translate(10px, 0) rotate(5deg);
              transform: translate(10px, 0) rotate(5deg);
    }

    50% {
      -webkit-transform: translate(-10px, 0) rotate(-5deg);
              transform: translate(-10px, 0) rotate(-5deg);
    }

    60% {
      -webkit-transform: translate(10px, 0) rotate(5deg);
              transform: translate(10px, 0) rotate(5deg);
    }

    70% {
      -webkit-transform: translate(-10px, 0) rotate(-5deg);
              transform: translate(-10px, 0) rotate(-5deg);
    }

    80% {
      -webkit-transform: translate(10px, 0) rotate(5deg);
              transform: translate(10px, 0) rotate(5deg);
    }

    90% {
      -webkit-transform: translate(-10px, 0) rotate(-5deg);
              transform: translate(-10px, 0) rotate(-5deg);
    }

    100% {
      -webkit-transform: translate(0, 0) rotate(0);
              transform: translate(0, 0) rotate(0);
    }
  }

  @keyframes shake {
    0% {
      -webkit-transform: translate(0, 0) rotate(0);
              transform: translate(0, 0) rotate(0);
    }

    10% {
      -webkit-transform: translate(-10px, 0) rotate(-5deg);
              transform: translate(-10px, 0) rotate(-5deg);
    }

    20% {
      -webkit-transform: translate(10px, 0) rotate(5deg);
              transform: translate(10px, 0) rotate(5deg);
    }

    30% {
      -webkit-transform: translate(-10px, 0) rotate(-5deg);
              transform: translate(-10px, 0) rotate(-5deg);
    }

    40% {
      -webkit-transform: translate(10px, 0) rotate(5deg);
              transform: translate(10px, 0) rotate(5deg);
    }

    50% {
      -webkit-transform: translate(-10px, 0) rotate(-5deg);
              transform: translate(-10px, 0) rotate(-5deg);
    }

    60% {
      -webkit-transform: translate(10px, 0) rotate(5deg);
              transform: translate(10px, 0) rotate(5deg);
    }

    70% {
      -webkit-transform: translate(-10px, 0) rotate(-5deg);
              transform: translate(-10px, 0) rotate(-5deg);
    }

    80% {
      -webkit-transform: translate(10px, 0) rotate(5deg);
              transform: translate(10px, 0) rotate(5deg);
    }

    90% {
      -webkit-transform: translate(-10px, 0) rotate(-5deg);
              transform: translate(-10px, 0) rotate(-5deg);
    }

    100% {
      -webkit-transform: translate(0, 0) rotate(0);
              transform: translate(0, 0) rotate(0);
    }
  }

  @-webkit-keyframes zoom {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
      /* Start the animation with normal size image */
    }

    50% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1);
      /* Increase the size of the image halfway through the animation */
    }

    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      /* End the animation with normal size image */
    }
  }

  @keyframes zoom {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
      /* Start the animation with normal size image */
    }

    50% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1);
      /* Increase the size of the image halfway through the animation */
    }

    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      /* End the animation with normal size image */
    }
  }


  .gifImg {
    background-image: url(/static/media/pepe.ae6a97c7.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 100vw;
    height: 100vw;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    background-color: rgba(0, 0, 0, 0);

  }

  .gifImg2 {
    background-image: url(/static/media/182.f2954502.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 100vw;
    height: 100vw;
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }

  .gifImg3 {
    background-image: url(/static/media/pepe5.be18fc31.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 100vw;
    height: 100vw;
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }

  .gifImg4 {
    background-image: url(/static/media/new.3aabb953.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 100vw;
    height: 100vw;
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }

  .gifMain {
    background-color: rgba(0, 0, 0, 0);
    margin-top: -10%;
  }

  .bubble-container {
    flex-flow: column;
    display: flex;
  }

}

@media screen and (min-width: 500px) and (max-width: 767px) {

  .page-loader {
    width: 100%;
    height: 100vh;
    background: #242424;
    z-index: 5000000000000000;
    position: fixed;
  }

  .loading-area {
    display: grid;
    place-items: center;
    height: 100vh;
  }

  .containerLoad {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .h1Load {
    font-size: 1.5rem;
    color: #00ff2a;
  }

  .ring {
    width: 200px;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  .ring::before {
    content: '';
    width: 200px;
    height: 200px;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
    -webkit-animation: ring 2s linear infinite;
            animation: ring 2s linear infinite;
  }

  @-webkit-keyframes ring {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
      box-shadow: 0px 5px 5px #008afa;
    }

    25% {
      -webkit-transform: rotate(90deg);
              transform: rotate(90deg);
      box-shadow: 0px 5px 5px #02b7ff;
    }

    50% {
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg);
      box-shadow: 0px 5px 5px #0151ff;
    }

    75% {
      -webkit-transform: rotate(270deg);
              transform: rotate(270deg);
      box-shadow: 0px 5px 5px #367de7;
    }

    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
      box-shadow: 0px 5px 5px #008afa;
    }
  }

  @keyframes ring {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
      box-shadow: 0px 5px 5px #008afa;
    }

    25% {
      -webkit-transform: rotate(90deg);
              transform: rotate(90deg);
      box-shadow: 0px 5px 5px #02b7ff;
    }

    50% {
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg);
      box-shadow: 0px 5px 5px #0151ff;
    }

    75% {
      -webkit-transform: rotate(270deg);
              transform: rotate(270deg);
      box-shadow: 0px 5px 5px #367de7;
    }

    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
      box-shadow: 0px 5px 5px #008afa;
    }
  }

  .rmConMainMob {
    display: none;
  }

  .miniBarMain {
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100vw;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #020529b7;
    display: flex;
    justify-content: space-between;
  }

  .miniBar {
    display: flex;
    justify-content: space-between;
  }

  .navbarMainImgSet {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    align-items: center;
    display: flex;
  }

  .navbar {
    width: 40px;
    justify-content: flex-end;
    cursor: pointer;
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
    display: block;
    align-items: center;

  }

  #fontSize2 {
    font-size: 16px;
    color: white;
    text-align: left;
  }

  #fontSize2:hover {
    color: #818181;
  }

  .littleNav div {
    text-align: left;
    padding: 30px;
    cursor: pointer;
    border-top: #2ba531 1px solid;
  }

  .littleNav {
    background-color: #1a1a1ad8;
    width: 100%;
    height: -webkit-max-content;
    height: max-content;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1000000000;
    flex-flow: column wrap;
    display: flex;
    border-bottom: 5px #196e1e solid;
  }

  .hidden {
    display: none;
  }

  .close {
    width: 35px;
    height: 35px;
    justify-content: flex-end;
    margin-left: auto;
    cursor: pointer;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
  }

  .navLink {
    color: #ff000091;
  }

  .audioPic {
    /* display: none;*/
    width: 100px;
    height: 100px;
    position: sticky;
    position: -webkit-sticky;
    left: 0;
    bottom: 10%;
    z-index: 1000;
  }


  .rmbg {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-bottom: 2%;
    background-color: rgba(0, 0, 0, 0);

  }

  .rmbg2 {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-top: 10%;
    padding-bottom: 2%;
    background: url(/static/media/rmbg.6ed0fbcc.jpg) no-repeat center center fixed;
    background-size: cover;
    width: 97%;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .placeAndWL {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    z-index: 100000000;

  }

  input {
    background-color: rgba(0, 30, 70, 0.568);
    border: white 1px solid;
    font-size: 20px;
    padding: 15px;
    color: #00d6fc;
    z-index: 100000000;
  }

  input:focus {
    background-color: #b3e9ff00;
    color: #00d6fc;
  }

  #wlBtn {
    background-color: rgba(0, 30, 70, 0.568);
    border: 1px solid rgb(16, 192, 223);
    color: #ffffff;
    width: 200px;
    z-index: 100000000;
  }

  #wlBtn:hover {
    font-size: 18px;
  }

  .wlLine {
    background-color: #000000;
    padding: 50px;
  }


  .cloudsMob {
    display: none;
  }

  .connectMob {
    display: none;
  }

  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-top: 100px;
    padding-bottom: 100px;
    background: rgb(52, 52, 52);
    background: linear-gradient(180deg, rgba(52, 52, 52, 1) 12%, rgba(0, 0, 0, 1) 100%);
  }


  .logosMob {
    display: none;
  }

  .allWrap {
    background: rgb(49,89,63);
background: radial-gradient(circle, rgba(49,89,63,1) 0%, rgba(52,74,61,1) 50%, rgba(35,51,41,1) 100%);
    height: 100vh;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    overflow: hidden;
  }

  .cont {
    background: radial-gradient(circle, rgba(0, 0, 0, 0.06684392507002801) 0%, rgba(0, 0, 0, 0.11166185224089631) 50%, rgba(0, 0, 0, 0.3693649334733894) 100%), url(/static/media/BG.b24e41c2.jpg) no-repeat center center fixed;
    background-size: cover;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;

  }




  .in2 p {
    font-size: 28px;
    text-align: center;
    font-family: "Iomanoid";
    letter-spacing: 4px;
    text-shadow: 0 0 10px #ffdc64;
    color: #ffdc64;

  }

  .in2 {
    z-index: 100000000;

  }

  @-webkit-keyframes heartbeat {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }

    50% {
      -webkit-transform: scale(0.9);
              transform: scale(0.9);
    }

    60% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1);
    }

    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }

  @keyframes heartbeat {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }

    50% {
      -webkit-transform: scale(0.9);
              transform: scale(0.9);
    }

    60% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1);
    }

    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }

  .logo2Intro {
    z-index: 200000000000000000000000000000000;
    width: 40%;
    padding-top: 27.9vh;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    -webkit-animation: heartbeat 2s ease-in-out infinite;
            animation: heartbeat 2s ease-in-out infinite;
  }

  .in2 img {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 10vh;
    transition: -webkit-transform 0.8s;
    transition: transform 0.8s;
    transition: transform 0.8s, -webkit-transform 0.8s;
    z-index: 100000000;
  }

  .in2 img:hover {
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
  }


  .introduction {
    font-family: "Iomanoid";
  }

  .twitter img {
    width: 50px;
    height: 50px;
    margin-left: 30px;
    cursor: pointer;
    opacity: 0.6;
    transition: 0.2s ease;
    margin-top: -3%;
    z-index: 200000000000;

  }

  .twitter {
    z-index: 200000000000;
  }

  .twitter img:hover {
    opacity: 100%;
    z-index: 200000000000;

  }

  .boxWrap2Story {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-top: 5%;
    padding-bottom: 2%;
    /*background: linear-gradient(180deg, rgba(9, 28, 45, 0.10325849089635852) 0%, rgba(13, 26, 38, 0.36656381302521013) 35%, rgba(9, 28, 45, 1) 100%), url('./assets/bg1.jpg') no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;*/
    /*background: rgb(12, 22, 42);
    background: linear-gradient(180deg, rgba(12, 22, 42, 1) 11%, rgba(3, 43, 25, 1) 90%);*/
    background-color: rgba(0, 0, 0, 0);
  }

  .boxWrap2StoryR {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    /*background: linear-gradient(180deg, rgba(9, 28, 45, 0.10325849089635852) 0%, rgba(13, 26, 38, 0.36656381302521013) 35%, rgba(9, 28, 45, 1) 100%), url('./assets/bg1.jpg') no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;*/
    /*background: rgb(12, 22, 42);
    background: linear-gradient(180deg, rgba(12, 22, 42, 1) 11%, rgba(3, 43, 25, 1) 90%);*/
    background-color: rgba(0, 0, 0, 0);
    ;
  }

  .boxWrap2StoryT {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-bottom: 2%;
    /*background: linear-gradient(180deg, rgba(9, 28, 45, 0.10325849089635852) 0%, rgba(13, 26, 38, 0.36656381302521013) 35%, rgba(9, 28, 45, 1) 100%), url('./assets/bg1.jpg') no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;*/
    /*background: rgb(12, 22, 42);
    background: linear-gradient(180deg, rgba(12, 22, 42, 1) 11%, rgba(3, 43, 25, 1) 90%);*/
    background-color: rgba(0, 0, 0, 0);
    ;
  }


  .boxWrap2Story2 {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0);

    padding-bottom: 2%;
  }

  .boxWrap2Mint {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-size: cover;
    padding-bottom: 2%;
    z-index: 100000000000;
  }

  .boxWrap2SubR {
    justify-content: space-between;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .boxWrap2Sub1 {}

  .boxWrap2Sub {
    justify-content: space-between;
    width: 98%;
    margin-left: auto;
    margin-right: auto;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.164) 0%, rgba(0, 0, 0, 0.301) 50%, rgba(0, 0, 0, 0.534) 100%), url('https://cdn.discordapp.com/attachments/1028976349920112681/1139504255498195055/NFTHouse9_coins_in_space_cartoon_theme_326f3ecc-8f98-4844-81b1-49ce9a21ba21.png') no-repeat center center fixed;
    background-size: cover;
    padding-top: 10%;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }



  .boxWrap2SubT {
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    background-color: #22e2d9;
  }

  .storyH {
    font-size: 200px;
    color: black;
    font-weight: bolder;
    text-align: center;
    margin-bottom: 10px;
    font-style: normal;
    font-family: 'Exo', sans-serif;
  }

  .outer-div {
    width: 500px;
    height: 100px;
    background-color: #fff;
    transition: background-color 1s ease;
  }

  .storyHK {
    font-size: 80px;
    color: white;
    font-weight: 500;
    text-align: center;
    font-family: "Iomanoid";
    margin-bottom: 2%;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px rgba(255, 255, 0, 0.616), 0 -10px 20px #ff80008c, 0 -18px 40px rgba(255, 0, 0, 0.719);
  }

  .storyH2 {
    font-size: 605px;
    color: rgb(255, 255, 255);
    font-weight: bolder;
    text-align: center;
    margin-bottom: 2%;
    font-family: 'Exo', sans-serif;
  }

  .storyH3 {
    font-size: 100px;
    color: rgb(255, 255, 255);
    font-weight: bolder;
    text-align: center;
    margin-bottom: 2%;
    font-family: 'Exo', sans-serif;
    background: rgb(88, 101, 242);
    background: linear-gradient(180deg, rgb(36, 51, 219) 12%, rgb(67, 3, 185) 100%);
    width: 700px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
    cursor: pointer;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    margin-top: -15%;
  }

  .storyH3:hover {
    background: rgb(38, 47, 222);
    background: linear-gradient(180deg, rgba(38, 47, 222, 1) 12%, rgba(176, 68, 242, 1) 100%);
  }

  /* Animation */
  @-webkit-keyframes fill-div {
    from {
      width: 0;
    }

    to {
      width: 100%;
    }
  }
  @keyframes fill-div {
    from {
      width: 0;
    }

    to {
      width: 100%;
    }
  }

  .bg {
    background-color: #17237e;
    padding-left: 50px;
    padding-right: 50px;
  }

  .storyH2-2 {
    font-size: 50px;
    color: white;
    font-weight: 500;
    text-align: right;
    font-family: "Iomanoid";
    margin-bottom: 10px;
    text-shadow: 0 0 10px #ce9381;
  }

  .about {
    color: white;
    font-size: 18px;
    text-align: left;
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
  }

  .aboutKingdom {
    color: white;
    font-size: 18px;
    text-align: left;
    display: flex;
    justify-content: space-evenly;
  }

  .kingdomsSection {
    color: white;
    font-size: 18px;
    text-align: left;
    display: flex;
    justify-content: space-evenly;
    flex-flow: column nowrap;
  }

  .aboutP {
    width: 90%;
    margin-bottom: 50px;
    margin-top: 30px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
  }

  .aboutPNew {
    width: 90%;
    margin-bottom: 50px;
    margin-top: 30px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
  }

  .aboutPS {
    width: 95%;
    margin-bottom: 50px;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .aboutP3 {
    width: 60%;
    margin-top: auto;
    margin-bottom: auto;
    margin-bottom: 50px;
  }

  .aboutP3 p {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 23px;
  }

  .aboutP p {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 22px;
  }

  .aboutPNew p {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 18px;
  }

  .aboutPDiv {
    background: rgb(101, 161, 90);
    background: linear-gradient(93deg, rgba(101, 161, 90, 1) 0%, rgba(32, 71, 128, 1) 50%);
    -webkit-clip-path: polygon(0 0, 100% 0%, 98% 100%, 0% 100%);
            clip-path: polygon(0 0, 100% 0%, 98% 100%, 0% 100%);
    width: 90%;
    margin-bottom: 12px;
    margin-top: 12px;
    padding: 20px;
    font-size: 18px;
    line-height: 40px;
  }

  .aboutPDiv2 {
    background-color: #2a32438a;
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
    -webkit-clip-path: polygon(0 0, 100% 0%, 98% 100%, 0% 100%);
            clip-path: polygon(0 0, 100% 0%, 98% 100%, 0% 100%);
    padding: 20px;
    font-size: 25px;
    color: white;
    margin-top: 30px;
    margin-top: 30px;
  }

  .hl {
    color: #1ee03e;
  }


  .bigLetters {
    font-size: 28px;
    line-height: 10px;
  }

  .ironfist {
    margin-top: auto;
    margin-left: -40px;
    z-index: 100000;
  }

  .ironfist img {
    width: 50px;
    height: 50px;

  }

  .storyConAndImg {
    display: flex;
    justify-content: center;
  }

  .aboutP2 {
    width: 50%;
    margin-top: auto;
    margin-bottom: auto;
  }

  .zeus {
    width: 100%;
    height: 100%;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    -webkit-animation: floating 3s ease-in-out infinite;
            animation: floating 3s ease-in-out infinite;
  }

  .zeus2 {
    width: 60%;
    height: 60%;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 50px;
  }

  @-webkit-keyframes floating {

    0%,
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    50% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
    }
  }

  @keyframes floating {

    0%,
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    50% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
    }
  }

  .logos {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
  }

  .logos_mobile {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
    display: none;
  }

  .logoF img {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 60%;
    display: flex;
  }


  .facetoface img {
    width: 50%;
  }

  .facetoface {
    display: flex;
    justify-content: space-between;
    margin-top: -15%;
  }

  .footerImg {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: space-evenly;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
  }

  .footerImg img {
    margin-left: 5px;
    margin-right: 5px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
  }

  .footerImg img:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    cursor: pointer;
  }

  .footer2 {
    justify-content: center;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .copyright {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 13px;
  }

  .footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: rgba(102, 102, 102, 0);
    width: 100%;
  }

  .sword {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 10%;
  }

  .devs {
    color: rgba(255, 255, 255, 0.774);
    text-align: center;
    margin-top: 80px;
  }

  .devs img {
    width: 30px;
    height: 30px;
    opacity: 0.9;
    transition: 0.3s ease;
  }

  .devs:hover {
    color: rgb(255, 255, 255);
    cursor: pointer;
  }

  .devs img:hover {
    width: 30px;
    height: 30px;
  }

  a {
    color: #d1fdfb;
    text-decoration: none;
  }

  a:hover {
    color: #99faf5;
    text-decoration: none;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #020529b7;
    display: none;
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    width: 95%;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    height: 5vh;
  }

  .connect div {
    margin-left: 15px;
    margin-right: 15px;
    color: white;
    margin-top: auto;
    justify-content: space-evenly;
    display: flex;
    cursor: pointer;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    text-shadow: -1px -1px 1px #f899345d, 1px 1px 1px #fe921e6c;

  }

  .connect div:hover {
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
  }

  .icons {
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    width: 33.33vw;
    margin-left: 10px;
  }

  .icons img {
    width: 30px;
    height: 30px;
    margin-left: 10px;
    margin-right: 10px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    z-index: 10000000;
  }

  .icons img:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    cursor: pointer;
  }

  #fontSize {
    font-size: 18px;
    z-index: 10000000;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    align-items: center;

  }

  #fontSize-2 {
    font-size: 25px;
    z-index: 10000000;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    align-items: center;
  }

  /* CSS */
  .button-92 {
    --c: #fff;
    /* text color */
    background: linear-gradient(90deg, #0000 33%, #fff5, #0000 67%) 100%/300% no-repeat,
      #009218;
    background: linear-gradient(90deg, #0000 33%, #fff5, #0000 67%) var(--_p, 100%)/300% no-repeat,
      #009218;
    /* background color */
    color: #0000;
    border: none;
    -webkit-transform: perspective(500px) rotateY(calc(20deg * -1));
            transform: perspective(500px) rotateY(calc(20deg * -1));
    -webkit-transform: perspective(500px) rotateY(calc(20deg * var(--_i, -1)));
            transform: perspective(500px) rotateY(calc(20deg * var(--_i, -1)));
    text-shadow: calc(-1 * 0.08em) -.01em 0 var(--c),
      calc(-1 * -0.08em) .01em 2px #0004;
    text-shadow: calc(var(--_i, -1) * 0.08em) -.01em 0 var(--c),
      calc(var(--_i, -1) * -0.08em) .01em 2px #0004;
    outline-offset: .1em;
    transition: 0.3s;
  }

  .button-92:hover,
  .button-92:focus-visible {
    --_p: 0%;
    --_i: 1;
  }

  .button-92:active {
    text-shadow: none;
    color: var(--c);
    box-shadow: inset 0 0 9e9q #0005;
    transition: 0s;
  }

  .button-92 {
    font-weight: bold;
    font-size: 1.8rem;
    margin: 0;
    cursor: pointer;
    padding: .1em .3em;
  }

  .title {
    color: white;
    text-shadow: -1px -1px 1px #f899345d, 1px 1px 1px #fe921e6c;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: center;
    font-size: 25px;
    display: none;
  }

  .logoUp {
    width: 40px;
    height: 40px;
    margin-top: -5px;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;
  }

  /* CSS */
  .button-62 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    background-color: #bb5a3c;
    font-size: 18px;
    padding: 13px;
    width: 200px;
    color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    transition: 0.3s ease;
    border-radius: 7px;
    border: none;
    font-weight: 500;
  }

  .button-62:hover {
    color: rgb(219, 219, 219);
  }

  .logo2 img {
    width: 75px;
    height: 75px;
  }

  .downImg {
    width: 35px;
    -webkit-animation: arrow_down_animation 2s ease-in-out infinite;
            animation: arrow_down_animation 2s ease-in-out infinite;
    cursor: pointer;
  }


  @-webkit-keyframes arrow_down_animation {

    0% {
      -webkit-transform: translate3d(0, 0, 0) scale(1, 1);
              transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;
    }

    30% {
      opacity: 1;
    }

    100% {
      -webkit-transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
              transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;

    }

  }


  @keyframes arrow_down_animation {

    0% {
      -webkit-transform: translate3d(0, 0, 0) scale(1, 1);
              transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;
    }

    30% {
      opacity: 1;
    }

    100% {
      -webkit-transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
              transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;

    }

  }

  .downImgDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -25px;

  }

  .down {
    text-align: center;
    background-color: rgba(0, 0, 0, 0);
  }

  .stickyBtn2 {
    width: 65px;
    height: 65px;
    z-index: 1;
  }

  #sticky-button {
    position: -webkit-sticky;
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 15%;
    margin-left: 92%;
    margin-top: -8%;
    cursor: pointer;
    transition: -webkit-transform .4s;
    transition: transform .4s;
    transition: transform .4s, -webkit-transform .4s;
    z-index: 1;

  }

  .stickyBtn2:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    opacity: 100%;
    z-index: 1;

  }

  .clouds2 {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  .cloud {
    position: absolute;
    top: 0;

  }

  .cloud1 {
    left: 10vw;

  }

  .cloud1 img {
    width: 120%;
    margin-top: -13%;
  }

  .cloud2 img {
    width: 70%;
    margin-top: -8%;
  }

  .cloud3 img {
    width: 100%;

  }

  .cloud1-1 {
    left: 70vw;
    -webkit-animation-name: move-clouds;
            animation-name: move-clouds;
    -webkit-animation-duration: 600s;
            animation-duration: 600s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
  }

  .cloud2 {
    left: 5px;
    -webkit-animation-name: move-clouds;
            animation-name: move-clouds;
    -webkit-animation-duration: 200s;
            animation-duration: 200s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
  }

  .cloud3 {
    left: 3px;

  }

  .cloud3-3 {
    left: 10px;
    -webkit-animation-name: move-clouds;
            animation-name: move-clouds;
    -webkit-animation-duration: 480s;
            animation-duration: 480s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
  }

  .cloud4 {
    left: 10vw;
  }

  .cloud4 img {
    width: 70%;
    margin-top: -5%;
    margin-left: -5%;
  }

  .cloud3 img {
    width: 25%;
  }

  @-webkit-keyframes move-clouds {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(110vw);
              transform: translateX(110vw);
    }
  }

  @keyframes move-clouds {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(110vw);
              transform: translateX(110vw);
    }
  }

  .cloudsLine img {
    width: 100%;
    display: flex;
  }

  .Q {
    font-size: 30px;
    color: white;
    font-weight: 500;
    margin-bottom: 10px;
    text-shadow: 0 0 10px #ecc46d;
  }

  .rmCon {
    color: white;
    font-size: 18px;
  }

  .rmConAndImg {
    margin-bottom: 30px;
    margin-top: 30px;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .rmCon img {
    width: 30px;
    height: 30px;
  }

  .line {
    width: 100%;
  }

  .connect2-2 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    box-shadow: rgba(0, 0, 0, 0.493) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    border: 12px #7f8082;

    border: #ffa528 5px groove;
  }

  .parrot {
    width: 70%;
  }

  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .totalSupply {
    text-align: center;
    font-size: 60px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(255, 255, 255);
    letter-spacing: 1px;
    margin-top: 1%;
  }

  .price {
    text-align: center;
    font-size: 25px;
    color: white;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }


  .center {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
    z-index: 10000000;

  }

  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: none;

    cursor: pointer;
    background-color: #6cfc1900;
    color: rgb(255, 255, 255);
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: 2px solid rgb(255, 255, 255);
    z-index: 1000000000000000;

  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: none;

    cursor: pointer;
    border: 2px solid rgb(255, 255, 255);
    color: rgb(255, 255, 255);
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    background-color: #00000000;
    z-index: 1000000000000000;

  }


  .btnfos-0-2:hover:active {
    background-color: #0e2942;
  }


  .btnfos-0-2-2:hover:active {
    background-color: #0e2942;
  }


  .btnfos-0-3 {
    margin-top: 20px;
    background: rgb(0, 120, 235);
    background: linear-gradient(180deg, rgba(0, 120, 235, 1) 0%, rgba(89, 154, 215, 1) 50%, rgba(0, 120, 235, 1) 100%);
    color: #ffffff;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 28px;
    padding-left: 30px;
    padding-right: 30px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    border: 6px rgb(0, 0, 0);
    border-style: double;
    z-index: 1000000000000000;
    transition: transform .2s;
  }

  .btnfos-0-3:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

  .nftamount {
    color: #ecaa2f;
    font-size: 85px;
  }

  .mintbuttondiv {
    text-align: center;
    z-index: 10000000;

  }

  .successfully {
    text-align: center;
    margin-top: 3%;
    color: #1ee03e;
    font-size: 30px;
    text-shadow: 0 0 20px #9bf179;
  }

  .errorMessage {
    margin-top: 3%;
    font-size: 20px;
    color: #fc4741;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 3%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .loadingText {
    font-size: 20px;
    text-align: center;
    color: rgb(240, 161, 97);
  }

  .loadTextSub {
    text-align: center;
    color: rgba(255, 255, 255, 0.815);
    font-size: 16px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    -webkit-animation: dots 1s steps(5, end) infinite;
            animation: dots 1s steps(5, end) infinite;
  }

  @-webkit-keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(214, 80, 71);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(209, 119, 45),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(214, 80, 71);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(209, 119, 45),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .rm2Box {
    display: flex;
    justify-content: center;
    margin-right: auto;

  }

  .rm3Box {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-top: 100px;
    margin-bottom: 100px;
  }

  .rmbox1 {
    -webkit-clip-path: polygon(0 0, 96% 0, 100% 100%, 0% 100%);
            clip-path: polygon(0 0, 96% 0, 100% 100%, 0% 100%);
    font-size: 25px;
    background-color: #000000a1;
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
    color: rgb(255, 255, 255);
    width: 200px;
    padding: 30px;
    text-align: center;

  }

  .rmbox2 {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 4% 100%);
            clip-path: polygon(0 0, 100% 0, 100% 100%, 4% 100%);
    font-size: 20px;
    background-color: #007906c2;
    color: white;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 30px;
    text-align: center;
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .rmbox1-3 {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 4% 100%);
            clip-path: polygon(0 0, 100% 0, 100% 100%, 4% 100%);
    text-align: center;
    font-size: 25px;
    background-color: #000000a1;

    -webkit-backdrop-filter: blur(5px);

            backdrop-filter: blur(5px);
    color: rgb(255, 255, 255);
    width: 200px;
    padding: 30px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }


  .rmbox2-3 {
    font-size: 20px;
    -webkit-clip-path: polygon(0 0, 96% 0, 100% 100%, 0% 100%);
            clip-path: polygon(0 0, 96% 0, 100% 100%, 0% 100%);
    text-align: center;
    background-color: #37653c;
    color: white;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 30px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
    text-align: center;

  }

  .rmConMain {
    display: flex;
    justify-content: space-between;
    flex-flow: column nowrap;
    margin-top: 80px;
  }

  .scroll-effect-container {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    margin-bottom: 30px;

  }

  .scroll-effect {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: #ffffff00;
    text-align: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding-left: 20px;
    padding-right: 20px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

  }

  .scroll-effect-relic {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: #ffffff00;
    text-align: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding-left: 20px;
    padding-right: 20px;

  }

  .scroll-effect-mint {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: #ffffff00;
    text-align: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding-left: 20px;
    padding-right: 20px;

  }

  .scroll-effect-tokenomics {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: #ffffff00;
    text-align: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding-left: 20px;
    padding-right: 20px;

  }

  .scroll-effect-footer {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: #ffffff00;
    text-align: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding-left: 50px;
    padding-right: 50px;

  }


  .scroll-effect-rm {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: #ffffff00;
    text-align: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding-left: 50px;
    padding-right: 50px;

  }


  .scroll-effect-footer {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: #ffffff00;
    text-align: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding-left: 50px;
    padding-right: 50px;
  }

  @-webkit-keyframes fill-effect {
    from {
      width: 0%;
    }

    to {
      width: 100%;
    }
  }

  @keyframes fill-effect {
    from {
      width: 0%;
    }

    to {
      width: 100%;
    }
  }

  .animate {
    /*background: rgb(87, 102, 244);
    background: linear-gradient(270deg, rgba(87, 102, 244, 1) 0%, rgba(36, 46, 167, 1) 51%);*/
    background-color: #000000a1;
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
    background-repeat: no-repeat;
    background-size: 0% 100%;
    transition: background-size 1s ease-in-out;
    -webkit-animation: fill-effect 1s ease-in-out;
            animation: fill-effect 1s ease-in-out;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }

  .animate.animate {
    background-size: 100% 100%;

  }

  /*.animateC{
    background: rgb(244, 87, 179);
    background: linear-gradient(270deg, rgb(238, 77, 190) 0%, rgb(139, 8, 89) 51%);  background-repeat: no-repeat;
    background-size: 0% 100%;
    transition: background-size 0.5s ease-in-out;
    animation: fill-effect 0.5s ease-in-out;
    font-style: italic;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }*/

  .animateC.animateC {
    background-size: 100% 100%;

  }

  /* Define the walkingNft class */
  .walkingNft {
    width: 30%;
    position: absolute;
    bottom: -0.8%;
    left: 0;
    -webkit-animation: walk 20s linear infinite;
            animation: walk 20s linear infinite;

  }

  /* Define the walk animation */
  @-webkit-keyframes walk {
    0% {
      -webkit-transform: translateX(-5vw);
              transform: translateX(-5vw);
    }

    10% {
      -webkit-transform: translateX(10vw) rotate(6deg);
              transform: translateX(10vw) rotate(6deg);
    }

    20% {
      -webkit-transform: translateX(20vw) rotate(0deg);
              transform: translateX(20vw) rotate(0deg);
    }

    30% {
      -webkit-transform: translateX(30vw) rotate(6deg);
              transform: translateX(30vw) rotate(6deg);
    }

    40% {
      -webkit-transform: translateX(40vw) rotate(0deg);
              transform: translateX(40vw) rotate(0deg);
    }

    50% {
      -webkit-transform: translateX(50vw) rotate(6deg);
              transform: translateX(50vw) rotate(6deg);
    }

    60% {
      -webkit-transform: translateX(60vw) rotate(0deg);
              transform: translateX(60vw) rotate(0deg);
    }

    70% {
      -webkit-transform: translateX(70vw) rotate(6deg);
              transform: translateX(70vw) rotate(6deg);
    }

    80% {
      -webkit-transform: translateX(80vw) rotate(0deg);
              transform: translateX(80vw) rotate(0deg);
    }

    90% {
      -webkit-transform: translateX(90vw) rotate(6deg);
              transform: translateX(90vw) rotate(6deg);
    }

    100% {
      -webkit-transform: translateX(100vw) rotate(0deg);
              transform: translateX(100vw) rotate(0deg);
    }

  }
  @keyframes walk {
    0% {
      -webkit-transform: translateX(-5vw);
              transform: translateX(-5vw);
    }

    10% {
      -webkit-transform: translateX(10vw) rotate(6deg);
              transform: translateX(10vw) rotate(6deg);
    }

    20% {
      -webkit-transform: translateX(20vw) rotate(0deg);
              transform: translateX(20vw) rotate(0deg);
    }

    30% {
      -webkit-transform: translateX(30vw) rotate(6deg);
              transform: translateX(30vw) rotate(6deg);
    }

    40% {
      -webkit-transform: translateX(40vw) rotate(0deg);
              transform: translateX(40vw) rotate(0deg);
    }

    50% {
      -webkit-transform: translateX(50vw) rotate(6deg);
              transform: translateX(50vw) rotate(6deg);
    }

    60% {
      -webkit-transform: translateX(60vw) rotate(0deg);
              transform: translateX(60vw) rotate(0deg);
    }

    70% {
      -webkit-transform: translateX(70vw) rotate(6deg);
              transform: translateX(70vw) rotate(6deg);
    }

    80% {
      -webkit-transform: translateX(80vw) rotate(0deg);
              transform: translateX(80vw) rotate(0deg);
    }

    90% {
      -webkit-transform: translateX(90vw) rotate(6deg);
              transform: translateX(90vw) rotate(6deg);
    }

    100% {
      -webkit-transform: translateX(100vw) rotate(0deg);
              transform: translateX(100vw) rotate(0deg);
    }

  }


  .images2 {
    display: flex;
    justify-content: space-between;
  }

  .images2 img {
    width: 50%;
    margin-top: -10%;
    z-index: 10000;
  }



  * {
    box-sizing: border-box;
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 4rem;
    background-image: url(/static/media/ticker.81e610af.png);
    background-size: contain;
    padding-left: 100%;
    z-index: 20000000000000000;
    margin-top: -3.5px;
  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 4rem;
    line-height: 4rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 30s;
    animation-duration: 50s;
    z-index: 1000000000000;

  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 28px;
    font-weight: 800;
    color: white;
    z-index: 1000000000000;

  }

  .line {
    background-color: rgb(255, 255, 255);
    height: 20vh;
    width: 120vw;
    border-bottom: 12px solid rgb(0, 113, 189);
    border-top: 12px solid rgb(0, 113, 189);
    rotate: 10deg;
    margin-left: -50px;
    display: flex;
    justify-content: space-between;
    margin-top: 15%;
  }

  .line2 {
    background-color: rgb(255, 255, 255);
    height: 20vh;
    width: 120vw;
    border-bottom: 12px solid rgb(0, 113, 189);
    border-top: 12px solid rgb(0, 113, 189);
    rotate: 8deg;
    margin-left: -40px;
    display: flex;
    margin-top: 2%;
    justify-content: space-between;
    margin-bottom: 100px;
  }

  .line2 img {
    width: 50px;
    height: 50px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .line img {
    width: 50px;
    height: 50px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .weapon {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    width: 25%;
    margin-top: -15%;
  }

  .potionBottle {
    width: 23%;
    margin-top: -10%;
    z-index: 100000;
    margin-left: 20px;
  }

  .bawl {
    width: 40%;
    margin-top: -5%;
    z-index: 100000;
    margin-left: 20px;
  }

  .ball {
    width: 18%;
    margin-top: -10%;
    z-index: 100000;
    margin-left: 50px;
    rotate: 15deg;
  }


  @-webkit-keyframes float {
    0% {
      -webkit-transform: translate(0, 0);
              transform: translate(0, 0);
    }

    50% {
      -webkit-transform: translate(0, 20px);
              transform: translate(0, 20px);
    }

    100% {
      -webkit-transform: translate(0, 0);
              transform: translate(0, 0);
    }
  }


  @keyframes float {
    0% {
      -webkit-transform: translate(0, 0);
              transform: translate(0, 0);
    }

    50% {
      -webkit-transform: translate(0, 20px);
              transform: translate(0, 20px);
    }

    100% {
      -webkit-transform: translate(0, 0);
              transform: translate(0, 0);
    }
  }

  .mashroom {
    width: 23%;
    margin-top: -20%;
    z-index: 100000;
    margin-left: 70%;
    rotate: 25deg;
  }

  .lucky {
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
  }

  .lucky img {
    width: 20%;
  }

  @-webkit-keyframes shake {
    0% {
      -webkit-transform: translate(0, 0) rotate(0);
              transform: translate(0, 0) rotate(0);
    }

    10% {
      -webkit-transform: translate(-10px, 0) rotate(-5deg);
              transform: translate(-10px, 0) rotate(-5deg);
    }

    20% {
      -webkit-transform: translate(10px, 0) rotate(5deg);
              transform: translate(10px, 0) rotate(5deg);
    }

    30% {
      -webkit-transform: translate(-10px, 0) rotate(-5deg);
              transform: translate(-10px, 0) rotate(-5deg);
    }

    40% {
      -webkit-transform: translate(10px, 0) rotate(5deg);
              transform: translate(10px, 0) rotate(5deg);
    }

    50% {
      -webkit-transform: translate(-10px, 0) rotate(-5deg);
              transform: translate(-10px, 0) rotate(-5deg);
    }

    60% {
      -webkit-transform: translate(10px, 0) rotate(5deg);
              transform: translate(10px, 0) rotate(5deg);
    }

    70% {
      -webkit-transform: translate(-10px, 0) rotate(-5deg);
              transform: translate(-10px, 0) rotate(-5deg);
    }

    80% {
      -webkit-transform: translate(10px, 0) rotate(5deg);
              transform: translate(10px, 0) rotate(5deg);
    }

    90% {
      -webkit-transform: translate(-10px, 0) rotate(-5deg);
              transform: translate(-10px, 0) rotate(-5deg);
    }

    100% {
      -webkit-transform: translate(0, 0) rotate(0);
              transform: translate(0, 0) rotate(0);
    }
  }

  @keyframes shake {
    0% {
      -webkit-transform: translate(0, 0) rotate(0);
              transform: translate(0, 0) rotate(0);
    }

    10% {
      -webkit-transform: translate(-10px, 0) rotate(-5deg);
              transform: translate(-10px, 0) rotate(-5deg);
    }

    20% {
      -webkit-transform: translate(10px, 0) rotate(5deg);
              transform: translate(10px, 0) rotate(5deg);
    }

    30% {
      -webkit-transform: translate(-10px, 0) rotate(-5deg);
              transform: translate(-10px, 0) rotate(-5deg);
    }

    40% {
      -webkit-transform: translate(10px, 0) rotate(5deg);
              transform: translate(10px, 0) rotate(5deg);
    }

    50% {
      -webkit-transform: translate(-10px, 0) rotate(-5deg);
              transform: translate(-10px, 0) rotate(-5deg);
    }

    60% {
      -webkit-transform: translate(10px, 0) rotate(5deg);
              transform: translate(10px, 0) rotate(5deg);
    }

    70% {
      -webkit-transform: translate(-10px, 0) rotate(-5deg);
              transform: translate(-10px, 0) rotate(-5deg);
    }

    80% {
      -webkit-transform: translate(10px, 0) rotate(5deg);
              transform: translate(10px, 0) rotate(5deg);
    }

    90% {
      -webkit-transform: translate(-10px, 0) rotate(-5deg);
              transform: translate(-10px, 0) rotate(-5deg);
    }

    100% {
      -webkit-transform: translate(0, 0) rotate(0);
              transform: translate(0, 0) rotate(0);
    }
  }

  @-webkit-keyframes zoom {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
      /* Start the animation with normal size image */
    }

    50% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1);
      /* Increase the size of the image halfway through the animation */
    }

    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      /* End the animation with normal size image */
    }
  }

  @keyframes zoom {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
      /* Start the animation with normal size image */
    }

    50% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1);
      /* Increase the size of the image halfway through the animation */
    }

    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      /* End the animation with normal size image */
    }
  }


  .gifImg {
    background-image: url(/static/media/pepe.ae6a97c7.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 100vw;
    height: 100vw;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    background-color: rgba(0, 0, 0, 0);

  }

  .gifImg2 {
    background-image: url(/static/media/182.f2954502.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 100vw;
    height: 100vw;
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }

  .gifImg3 {
    background-image: url(/static/media/pepe5.be18fc31.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 100vw;
    height: 100vw;
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }

  .gifImg4 {
    background-image: url(/static/media/new.3aabb953.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 100vw;
    height: 100vw;
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }

  .gifMain {
    background-color: rgba(0, 0, 0, 0);
    margin-top: -10%;
  }

  .bubble-container {
    flex-flow: column;
    display: flex;
  }

}

@media screen and (min-width: 768px) and (max-width: 991px) {

  .page-loader {
    width: 100%;
    height: 100vh;
    background: #242424;
    z-index: 5000000000000000;
    position: fixed;
  }

  .loading-area {
    display: grid;
    place-items: center;
    height: 100vh;
  }

  .containerLoad {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .h1Load {
    font-size: 1.5rem;
    color: #00ff2a;
  }

  .ring {
    width: 200px;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  .ring::before {
    content: '';
    width: 200px;
    height: 200px;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
    -webkit-animation: ring 2s linear infinite;
            animation: ring 2s linear infinite;
  }

  @-webkit-keyframes ring {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
      box-shadow: 0px 5px 5px #008afa;
    }

    25% {
      -webkit-transform: rotate(90deg);
              transform: rotate(90deg);
      box-shadow: 0px 5px 5px #02b7ff;
    }

    50% {
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg);
      box-shadow: 0px 5px 5px #0151ff;
    }

    75% {
      -webkit-transform: rotate(270deg);
              transform: rotate(270deg);
      box-shadow: 0px 5px 5px #367de7;
    }

    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
      box-shadow: 0px 5px 5px #008afa;
    }
  }

  @keyframes ring {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
      box-shadow: 0px 5px 5px #008afa;
    }

    25% {
      -webkit-transform: rotate(90deg);
              transform: rotate(90deg);
      box-shadow: 0px 5px 5px #02b7ff;
    }

    50% {
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg);
      box-shadow: 0px 5px 5px #0151ff;
    }

    75% {
      -webkit-transform: rotate(270deg);
              transform: rotate(270deg);
      box-shadow: 0px 5px 5px #367de7;
    }

    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
      box-shadow: 0px 5px 5px #008afa;
    }
  }

  .rmConMainMob {
    display: none;
  }

  .miniBarMain {
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100vw;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #020529b7;
    display: flex;
    justify-content: space-between;
  }

  .miniBar {
    display: flex;
    justify-content: space-between;
  }

  .navbarMainImgSet {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    align-items: center;
    display: flex;
  }

  .navbar {
    width: 40px;
    justify-content: flex-end;
    cursor: pointer;
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
    display: block;
    align-items: center;

  }

  #fontSize2 {
    font-size: 16px;
    color: white;
    text-align: left;
  }

  #fontSize2:hover {
    color: #818181;
  }

  .littleNav div {
    text-align: left;
    padding: 30px;
    cursor: pointer;
    border-top: #2ba531 1px solid;
  }

  .littleNav {
    background-color: #1a1a1ad8;
    width: 100%;
    height: -webkit-max-content;
    height: max-content;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1000000000;
    flex-flow: column wrap;
    display: flex;
    border-bottom: 5px #196e1e solid;
  }

  .hidden {
    display: none;
  }

  .close {
    width: 35px;
    height: 35px;
    justify-content: flex-end;
    margin-left: auto;
    cursor: pointer;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
  }

  .navLink {
    color: #ff000091;
  }

  .audioPic {
    /* display: none;*/
    width: 100px;
    height: 100px;
    position: sticky;
    position: -webkit-sticky;
    left: 0;
    bottom: 10%;
    z-index: 1000;
  }


  .rmbg {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-bottom: 2%;
    background-color: rgba(0, 0, 0, 0);

  }

  .rmbg2 {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-top: 10%;
    padding-bottom: 2%;
    background: url(/static/media/rmbg.6ed0fbcc.jpg) no-repeat center center fixed;
    background-size: cover;
    width: 97%;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .placeAndWL {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    z-index: 100000000;

  }

  input {
    background-color: rgba(0, 30, 70, 0.568);
    border: white 1px solid;
    font-size: 20px;
    padding: 15px;
    color: #00d6fc;
    z-index: 100000000;
  }

  input:focus {
    background-color: #b3e9ff00;
    color: #00d6fc;
  }

  #wlBtn {
    background-color: rgba(0, 30, 70, 0.568);
    border: 1px solid rgb(16, 192, 223);
    color: #ffffff;
    width: 200px;
    z-index: 100000000;
  }

  #wlBtn:hover {
    font-size: 18px;
  }

  .wlLine {
    background-color: #000000;
    padding: 50px;
  }


  .cloudsMob {
    display: none;
  }

  .connectMob {
    display: none;
  }

  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-top: 100px;
    padding-bottom: 100px;
    background: rgb(52, 52, 52);
    background: linear-gradient(180deg, rgba(52, 52, 52, 1) 12%, rgba(0, 0, 0, 1) 100%);
  }


  .logosMob {
    display: none;
  }

  .allWrap {
    background: rgb(49,89,63);
background: radial-gradient(circle, rgba(49,89,63,1) 0%, rgba(52,74,61,1) 50%, rgba(35,51,41,1) 100%);
    height: 100vh;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    overflow: hidden;
  }

  .cont {
    background: radial-gradient(circle, rgba(0, 0, 0, 0.06684392507002801) 0%, rgba(0, 0, 0, 0.11166185224089631) 50%, rgba(0, 0, 0, 0.3693649334733894) 100%), url(/static/media/BG.b24e41c2.jpg) no-repeat center center fixed;
    background-size: cover;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;

  }




  .in2 p {
    font-size: 28px;
    text-align: center;
    font-family: "Iomanoid";
    letter-spacing: 4px;
    text-shadow: 0 0 10px #ffdc64;
    color: #ffdc64;

  }

  .in2 {
    z-index: 100000000;

  }

  @-webkit-keyframes heartbeat {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }

    50% {
      -webkit-transform: scale(0.9);
              transform: scale(0.9);
    }

    60% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1);
    }

    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }

  @keyframes heartbeat {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }

    50% {
      -webkit-transform: scale(0.9);
              transform: scale(0.9);
    }

    60% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1);
    }

    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }

  .logo2Intro {
    z-index: 200000000000000000000000000000000;
    width: 40%;
    padding-top: 27.9vh;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    -webkit-animation: heartbeat 2s ease-in-out infinite;
            animation: heartbeat 2s ease-in-out infinite;
  }

  .in2 img {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 75%;
    padding-top: 10vh;
    transition: -webkit-transform 0.8s;
    transition: transform 0.8s;
    transition: transform 0.8s, -webkit-transform 0.8s;
    z-index: 100000000;
  }

  .in2 img:hover {
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
  }


  .introduction {
    font-family: "Iomanoid";
  }

  .twitter img {
    width: 50px;
    height: 50px;
    margin-left: 30px;
    cursor: pointer;
    opacity: 0.6;
    transition: 0.2s ease;
    margin-top: -3%;
    z-index: 200000000000;

  }

  .twitter {
    z-index: 200000000000;
  }

  .twitter img:hover {
    opacity: 100%;
    z-index: 200000000000;

  }

  .boxWrap2Story {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-top: 5%;
    padding-bottom: 2%;
    /*background: linear-gradient(180deg, rgba(9, 28, 45, 0.10325849089635852) 0%, rgba(13, 26, 38, 0.36656381302521013) 35%, rgba(9, 28, 45, 1) 100%), url('./assets/bg1.jpg') no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;*/
    /*background: rgb(12, 22, 42);
    background: linear-gradient(180deg, rgba(12, 22, 42, 1) 11%, rgba(3, 43, 25, 1) 90%);*/
    background-color: rgba(0, 0, 0, 0);
  }

  .boxWrap2StoryR {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    /*background: linear-gradient(180deg, rgba(9, 28, 45, 0.10325849089635852) 0%, rgba(13, 26, 38, 0.36656381302521013) 35%, rgba(9, 28, 45, 1) 100%), url('./assets/bg1.jpg') no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;*/
    /*background: rgb(12, 22, 42);
    background: linear-gradient(180deg, rgba(12, 22, 42, 1) 11%, rgba(3, 43, 25, 1) 90%);*/
    background-color: rgba(0, 0, 0, 0);
    ;
  }

  .boxWrap2StoryT {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-bottom: 2%;
    /*background: linear-gradient(180deg, rgba(9, 28, 45, 0.10325849089635852) 0%, rgba(13, 26, 38, 0.36656381302521013) 35%, rgba(9, 28, 45, 1) 100%), url('./assets/bg1.jpg') no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;*/
    /*background: rgb(12, 22, 42);
    background: linear-gradient(180deg, rgba(12, 22, 42, 1) 11%, rgba(3, 43, 25, 1) 90%);*/
    background-color: rgba(0, 0, 0, 0);
    ;
  }


  .boxWrap2Story2 {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0);

    padding-bottom: 2%;
  }

  .boxWrap2Mint {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-size: cover;
    padding-bottom: 2%;
    z-index: 100000000000;
  }

  .boxWrap2SubR {
    justify-content: space-between;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .boxWrap2Sub1 {}

  .boxWrap2Sub {
    justify-content: space-between;
    width: 98%;
    margin-left: auto;
    margin-right: auto;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.164) 0%, rgba(0, 0, 0, 0.301) 50%, rgba(0, 0, 0, 0.534) 100%), url('https://cdn.discordapp.com/attachments/1028976349920112681/1139504255498195055/NFTHouse9_coins_in_space_cartoon_theme_326f3ecc-8f98-4844-81b1-49ce9a21ba21.png') no-repeat center center fixed;
    background-size: cover;
    padding-top: 10%;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }



  .boxWrap2SubT {
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    background-color: #22e2d9;
  }

  .storyH {
    font-size: 200px;
    color: black;
    font-weight: bolder;
    text-align: center;
    margin-bottom: 10px;
    font-style: normal;
    font-family: 'Exo', sans-serif;
  }

  .outer-div {
    width: 500px;
    height: 100px;
    background-color: #fff;
    transition: background-color 1s ease;
  }

  .storyHK {
    font-size: 80px;
    color: white;
    font-weight: 500;
    text-align: center;
    font-family: "Iomanoid";
    margin-bottom: 2%;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px rgba(255, 255, 0, 0.616), 0 -10px 20px #ff80008c, 0 -18px 40px rgba(255, 0, 0, 0.719);
  }

  .storyH2 {
    font-size: 100px;
    color: rgb(255, 255, 255);
    font-weight: bolder;
    text-align: center;
    margin-bottom: 2%;
    font-family: 'Exo', sans-serif;
  }

  .storyH3 {
    font-size: 100px;
    color: rgb(255, 255, 255);
    font-weight: bolder;
    text-align: center;
    margin-bottom: 2%;
    font-family: 'Exo', sans-serif;
    background: rgb(88, 101, 242);
    background: linear-gradient(180deg, rgb(36, 51, 219) 12%, rgb(67, 3, 185) 100%);
    width: 700px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
    cursor: pointer;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    margin-top: -15%;
  }

  .storyH3:hover {
    background: rgb(38, 47, 222);
    background: linear-gradient(180deg, rgba(38, 47, 222, 1) 12%, rgba(176, 68, 242, 1) 100%);
  }

  /* Animation */
  @-webkit-keyframes fill-div {
    from {
      width: 0;
    }

    to {
      width: 100%;
    }
  }
  @keyframes fill-div {
    from {
      width: 0;
    }

    to {
      width: 100%;
    }
  }

  .bg {
    background-color: #17237e;
    padding-left: 50px;
    padding-right: 50px;
  }

  .storyH2-2 {
    font-size: 50px;
    color: white;
    font-weight: 500;
    text-align: right;
    font-family: "Iomanoid";
    margin-bottom: 10px;
    text-shadow: 0 0 10px #ce9381;
  }

  .about {
    color: white;
    font-size: 18px;
    text-align: left;
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
  }

  .aboutKingdom {
    color: white;
    font-size: 18px;
    text-align: left;
    display: flex;
    justify-content: space-evenly;
  }

  .kingdomsSection {
    color: white;
    font-size: 18px;
    text-align: left;
    display: flex;
    justify-content: space-evenly;
    flex-flow: column nowrap;
  }

  .aboutP {
    width: 90%;
    margin-bottom: 50px;
    margin-top: 30px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
  }

  .aboutPNew {
    width: 80%;
    margin-bottom: 50px;
    margin-top: 30px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
  }

  .aboutPS {
    width: 90%;
    margin-bottom: 50px;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .aboutP3 {
    width: 60%;
    margin-top: auto;
    margin-bottom: auto;
    margin-bottom: 50px;
  }

  .aboutP3 p {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 23px;
  }

  .aboutP p {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 22px;
  }

  .aboutPNew p {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 18px;
  }

  .aboutPDiv {
    background: rgb(101, 161, 90);
    background: linear-gradient(93deg, rgba(101, 161, 90, 1) 0%, rgba(32, 71, 128, 1) 50%);
    -webkit-clip-path: polygon(0 0, 100% 0%, 98% 100%, 0% 100%);
            clip-path: polygon(0 0, 100% 0%, 98% 100%, 0% 100%);
    width: 90%;
    margin-bottom: 12px;
    margin-top: 12px;
    padding: 20px;
    font-size: 18px;
    line-height: 40px;
  }

  .aboutPDiv2 {
    background-color: #2a32438a;
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
    -webkit-clip-path: polygon(0 0, 100% 0%, 98% 100%, 0% 100%);
            clip-path: polygon(0 0, 100% 0%, 98% 100%, 0% 100%);
    margin-bottom: 20px;
    margin-top: 20px;
    padding: 20px;
    font-size: 30px;
    line-height: 40px;
    color: white;
  }

  .hl {
    color: #1ee03e;
  }


  .bigLetters {
    font-size: 37px;
  }

  .ironfist {
    margin-top: auto;
    margin-left: -40px;
    z-index: 100000;
  }

  .ironfist img {
    width: 60px;
    height: 60px;

  }

  .storyConAndImg {
    display: flex;
    justify-content: center;
  }

  .aboutP2 {
    width: 50%;
    margin-top: auto;
    margin-bottom: auto;
  }

  .zeus {
    width: 50%;
    height: 50%;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    -webkit-animation: floating 3s ease-in-out infinite;
            animation: floating 3s ease-in-out infinite;
  }

  .zeus2 {
    width: 45%;
    height: 45%;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 50px;
  }

  @-webkit-keyframes floating {

    0%,
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    50% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
    }
  }

  @keyframes floating {

    0%,
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    50% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
    }
  }

  .logos {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
  }

  .logos_mobile {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
    display: none;
  }

  .logoF img {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 60%;
    display: flex;
  }


  .facetoface img {
    width: 40%;
  }

  .facetoface {
    display: flex;
    justify-content: space-between;
    margin-top: -10%;
  }

  .footerImg {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: space-evenly;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
  }

  .footerImg img {
    margin-left: 5px;
    margin-right: 5px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
  }

  .footerImg img:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    cursor: pointer;
  }

  .footer2 {
    justify-content: center;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .copyright {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 13px;
  }

  .footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: rgba(102, 102, 102, 0);
    width: 100%;
  }

  .sword {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 10%;
  }

  .devs {
    color: rgba(255, 255, 255, 0.774);
    text-align: center;
    margin-top: 80px;
  }

  .devs img {
    width: 30px;
    height: 30px;
    opacity: 0.9;
    transition: 0.3s ease;
  }

  .devs:hover {
    color: rgb(255, 255, 255);
    cursor: pointer;
  }

  .devs img:hover {
    width: 30px;
    height: 30px;
  }

  a {
    color: #d1fdfb;
    text-decoration: none;
  }

  a:hover {
    color: #99faf5;
    text-decoration: none;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #020529b7;
    display: none;
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    width: 95%;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    height: 5vh;
  }

  .connect div {
    margin-left: 15px;
    margin-right: 15px;
    color: white;
    margin-top: auto;
    justify-content: space-evenly;
    display: flex;
    cursor: pointer;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    text-shadow: -1px -1px 1px #f899345d, 1px 1px 1px #fe921e6c;

  }

  .connect div:hover {
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
  }

  .icons {
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    width: 33.33vw;
    margin-left: 10px;
  }

  .icons img {
    width: 30px;
    height: 30px;
    margin-left: 10px;
    margin-right: 10px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    z-index: 10000000;
  }

  .icons img:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    cursor: pointer;
  }

  #fontSize {
    font-size: 18px;
    z-index: 10000000;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    align-items: center;

  }

  #fontSize-2 {
    font-size: 25px;
    z-index: 10000000;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    align-items: center;
  }

  /* CSS */
  .button-92 {
    --c: #fff;
    /* text color */
    background: linear-gradient(90deg, #0000 33%, #fff5, #0000 67%) 100%/300% no-repeat,
      #009218;
    background: linear-gradient(90deg, #0000 33%, #fff5, #0000 67%) var(--_p, 100%)/300% no-repeat,
      #009218;
    /* background color */
    color: #0000;
    border: none;
    -webkit-transform: perspective(500px) rotateY(calc(20deg * -1));
            transform: perspective(500px) rotateY(calc(20deg * -1));
    -webkit-transform: perspective(500px) rotateY(calc(20deg * var(--_i, -1)));
            transform: perspective(500px) rotateY(calc(20deg * var(--_i, -1)));
    text-shadow: calc(-1 * 0.08em) -.01em 0 var(--c),
      calc(-1 * -0.08em) .01em 2px #0004;
    text-shadow: calc(var(--_i, -1) * 0.08em) -.01em 0 var(--c),
      calc(var(--_i, -1) * -0.08em) .01em 2px #0004;
    outline-offset: .1em;
    transition: 0.3s;
  }

  .button-92:hover,
  .button-92:focus-visible {
    --_p: 0%;
    --_i: 1;
  }

  .button-92:active {
    text-shadow: none;
    color: var(--c);
    box-shadow: inset 0 0 9e9q #0005;
    transition: 0s;
  }

  .button-92 {
    font-weight: bold;
    font-size: 1.8rem;
    margin: 0;
    cursor: pointer;
    padding: .1em .3em;
  }

  .title {
    color: white;
    text-shadow: -1px -1px 1px #f899345d, 1px 1px 1px #fe921e6c;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: center;
    font-size: 25px;
  }

  .logoUp {
    width: 40px;
    height: 40px;
    margin-top: -5px;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;
  }

  /* CSS */
  .button-62 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    background-color: #bb5a3c;
    font-size: 18px;
    padding: 13px;
    width: 200px;
    color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    transition: 0.3s ease;
    border-radius: 7px;
    border: none;
    font-weight: 500;
  }

  .button-62:hover {
    color: rgb(219, 219, 219);
  }

  .logo2 img {
    width: 75px;
    height: 75px;
  }

  .downImg {
    width: 35px;
    -webkit-animation: arrow_down_animation 2s ease-in-out infinite;
            animation: arrow_down_animation 2s ease-in-out infinite;
    cursor: pointer;
  }


  @-webkit-keyframes arrow_down_animation {

    0% {
      -webkit-transform: translate3d(0, 0, 0) scale(1, 1);
              transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;
    }

    30% {
      opacity: 1;
    }

    100% {
      -webkit-transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
              transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;

    }

  }


  @keyframes arrow_down_animation {

    0% {
      -webkit-transform: translate3d(0, 0, 0) scale(1, 1);
              transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;
    }

    30% {
      opacity: 1;
    }

    100% {
      -webkit-transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
              transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;

    }

  }

  .downImgDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -25px;

  }

  .down {
    text-align: center;
    background-color: rgba(0, 0, 0, 0);
  }

  .stickyBtn2 {
    width: 65px;
    height: 65px;
    z-index: 1;
  }

  #sticky-button {
    position: -webkit-sticky;
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 15%;
    margin-left: 92%;
    margin-top: -8%;
    cursor: pointer;
    transition: -webkit-transform .4s;
    transition: transform .4s;
    transition: transform .4s, -webkit-transform .4s;
    z-index: 1;

  }

  .stickyBtn2:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    opacity: 100%;
    z-index: 1;

  }

  .clouds2 {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  .cloud {
    position: absolute;
    top: 0;

  }

  .cloud1 {
    left: 10vw;

  }

  .cloud1 img {
    width: 120%;
    margin-top: -13%;
  }

  .cloud2 img {
    width: 70%;
    margin-top: -8%;
  }

  .cloud3 img {
    width: 100%;

  }

  .cloud1-1 {
    left: 70vw;
    -webkit-animation-name: move-clouds;
            animation-name: move-clouds;
    -webkit-animation-duration: 600s;
            animation-duration: 600s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
  }

  .cloud2 {
    left: 5px;
    -webkit-animation-name: move-clouds;
            animation-name: move-clouds;
    -webkit-animation-duration: 200s;
            animation-duration: 200s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
  }

  .cloud3 {
    left: 3px;

  }

  .cloud3-3 {
    left: 10px;
    -webkit-animation-name: move-clouds;
            animation-name: move-clouds;
    -webkit-animation-duration: 480s;
            animation-duration: 480s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
  }

  .cloud4 {
    left: 10vw;
  }

  .cloud4 img {
    width: 70%;
    margin-top: -5%;
    margin-left: -5%;
  }

  .cloud3 img {
    width: 25%;
  }

  @-webkit-keyframes move-clouds {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(110vw);
              transform: translateX(110vw);
    }
  }

  @keyframes move-clouds {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(110vw);
              transform: translateX(110vw);
    }
  }

  .cloudsLine img {
    width: 100%;
    display: flex;
  }

  .Q {
    font-size: 30px;
    color: white;
    font-weight: 500;
    margin-bottom: 10px;
    text-shadow: 0 0 10px #ecc46d;
  }

  .rmCon {
    color: white;
    font-size: 18px;
  }

  .rmConAndImg {
    margin-bottom: 30px;
    margin-top: 30px;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .rmCon img {
    width: 30px;
    height: 30px;
  }

  .line {
    width: 100%;
  }

  .connect2-2 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    box-shadow: rgba(0, 0, 0, 0.493) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    border: 12px #7f8082;

    border: #ffa528 5px groove;
  }

  .parrot {
    width: 70%;
  }

  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .totalSupply {
    text-align: center;
    font-size: 60px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(255, 255, 255);
    letter-spacing: 1px;
    margin-top: 1%;
  }

  .price {
    text-align: center;
    font-size: 25px;
    color: white;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }


  .center {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
    z-index: 10000000;

  }

  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: none;

    cursor: pointer;
    background-color: #6cfc1900;
    color: rgb(255, 255, 255);
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: 2px solid rgb(255, 255, 255);
    z-index: 1000000000000000;

  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: none;

    cursor: pointer;
    border: 2px solid rgb(255, 255, 255);
    color: rgb(255, 255, 255);
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    background-color: #00000000;
    z-index: 1000000000000000;

  }


  .btnfos-0-2:hover:active {
    background-color: #0e2942;
  }


  .btnfos-0-2-2:hover:active {
    background-color: #0e2942;
  }


  .btnfos-0-3 {
    margin-top: 20px;
    background: rgb(0, 120, 235);
    background: linear-gradient(180deg, rgba(0, 120, 235, 1) 0%, rgba(89, 154, 215, 1) 50%, rgba(0, 120, 235, 1) 100%);
    color: #ffffff;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 28px;
    padding-left: 30px;
    padding-right: 30px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    border: 6px rgb(0, 0, 0);
    border-style: double;
    z-index: 1000000000000000;
    transition: transform .2s;
  }

  .btnfos-0-3:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

  .nftamount {
    color: #ecaa2f;
    font-size: 85px;
  }

  .mintbuttondiv {
    text-align: center;
    z-index: 10000000;

  }

  .successfully {
    text-align: center;
    margin-top: 3%;
    color: #1ee03e;
    font-size: 30px;
    text-shadow: 0 0 20px #9bf179;
  }

  .errorMessage {
    margin-top: 3%;
    font-size: 20px;
    color: #fc4741;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 3%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .loadingText {
    font-size: 20px;
    text-align: center;
    color: rgb(240, 161, 97);
  }

  .loadTextSub {
    text-align: center;
    color: rgba(255, 255, 255, 0.815);
    font-size: 16px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    -webkit-animation: dots 1s steps(5, end) infinite;
            animation: dots 1s steps(5, end) infinite;
  }

  @-webkit-keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(214, 80, 71);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(209, 119, 45),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(214, 80, 71);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(209, 119, 45),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .rm2Box {
    display: flex;
    justify-content: center;
    margin-right: auto;

  }

  .rm3Box {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-top: 100px;
    margin-bottom: 100px;
  }

  .rmbox1 {
    -webkit-clip-path: polygon(0 0, 96% 0, 100% 100%, 0% 100%);
            clip-path: polygon(0 0, 96% 0, 100% 100%, 0% 100%);
    font-size: 35px;
    background-color: #000000a1;
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
    color: rgb(255, 255, 255);
    width: 300px;
    padding: 30px;
    text-align: center;

  }

  .rmbox2 {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 4% 100%);
            clip-path: polygon(0 0, 100% 0, 100% 100%, 4% 100%);
    font-size: 25px;
    background-color: #007906c2;
    color: white;
    width: 300px;
    padding: 30px;
    width: 300px;
    padding: 25px;
    text-align: center;
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .rmbox1-3 {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 4% 100%);
            clip-path: polygon(0 0, 100% 0, 100% 100%, 4% 100%);
    text-align: center;
    font-size: 35px;
    background-color: #000000a1;

    -webkit-backdrop-filter: blur(5px);

            backdrop-filter: blur(5px);
    color: rgb(255, 255, 255);
    width: 300px;
    padding: 30px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }


  .rmbox2-3 {
    font-size: 25px;
    -webkit-clip-path: polygon(0 0, 96% 0, 100% 100%, 0% 100%);
            clip-path: polygon(0 0, 96% 0, 100% 100%, 0% 100%);
    text-align: center;
    background-color: #007906c2;
    color: white;
    width: 300px;
    padding: 30px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
    text-align: center;

  }

  .rmConMain {
    display: flex;
    justify-content: space-between;
    flex-flow: column nowrap;
    margin-top: 80px;
  }

  .scroll-effect-container {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    margin-bottom: 30px;

  }

  .scroll-effect {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: #ffffff00;
    text-align: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding-left: 50px;
    padding-right: 50px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

  }

  .scroll-effect-relic {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: #ffffff00;
    text-align: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding-left: 50px;
    padding-right: 50px;

  }

  .scroll-effect-mint {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: #ffffff00;
    text-align: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding-left: 50px;
    padding-right: 50px;

  }

  .scroll-effect-tokenomics {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: #ffffff00;
    text-align: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding-left: 50px;
    padding-right: 50px;

  }

  .scroll-effect-footer {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: #ffffff00;
    text-align: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding-left: 50px;
    padding-right: 50px;

  }


  .scroll-effect-rm {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: #ffffff00;
    text-align: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding-left: 50px;
    padding-right: 50px;

  }


  .scroll-effect-footer {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: #ffffff00;
    text-align: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding-left: 50px;
    padding-right: 50px;
  }

  @-webkit-keyframes fill-effect {
    from {
      width: 0%;
    }

    to {
      width: 100%;
    }
  }

  @keyframes fill-effect {
    from {
      width: 0%;
    }

    to {
      width: 100%;
    }
  }

  .animate {
    /*background: rgb(87, 102, 244);
    background: linear-gradient(270deg, rgba(87, 102, 244, 1) 0%, rgba(36, 46, 167, 1) 51%);*/
    background-color: #000000a1;
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
    background-repeat: no-repeat;
    background-size: 0% 100%;
    transition: background-size 1s ease-in-out;
    -webkit-animation: fill-effect 1s ease-in-out;
            animation: fill-effect 1s ease-in-out;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }

  .animate.animate {
    background-size: 100% 100%;

  }

  /*.animateC{
    background: rgb(244, 87, 179);
    background: linear-gradient(270deg, rgb(238, 77, 190) 0%, rgb(139, 8, 89) 51%);  background-repeat: no-repeat;
    background-size: 0% 100%;
    transition: background-size 0.5s ease-in-out;
    animation: fill-effect 0.5s ease-in-out;
    font-style: italic;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }*/

  .animateC.animateC {
    background-size: 100% 100%;

  }

  /* Define the walkingNft class */
  .walkingNft {
    width: 30%;
    position: absolute;
    bottom: -0.8%;
    left: 0;
    -webkit-animation: walk 20s linear infinite;
            animation: walk 20s linear infinite;

  }

  /* Define the walk animation */
  @-webkit-keyframes walk {
    0% {
      -webkit-transform: translateX(-5vw);
              transform: translateX(-5vw);
    }

    10% {
      -webkit-transform: translateX(10vw) rotate(6deg);
              transform: translateX(10vw) rotate(6deg);
    }

    20% {
      -webkit-transform: translateX(20vw) rotate(0deg);
              transform: translateX(20vw) rotate(0deg);
    }

    30% {
      -webkit-transform: translateX(30vw) rotate(6deg);
              transform: translateX(30vw) rotate(6deg);
    }

    40% {
      -webkit-transform: translateX(40vw) rotate(0deg);
              transform: translateX(40vw) rotate(0deg);
    }

    50% {
      -webkit-transform: translateX(50vw) rotate(6deg);
              transform: translateX(50vw) rotate(6deg);
    }

    60% {
      -webkit-transform: translateX(60vw) rotate(0deg);
              transform: translateX(60vw) rotate(0deg);
    }

    70% {
      -webkit-transform: translateX(70vw) rotate(6deg);
              transform: translateX(70vw) rotate(6deg);
    }

    80% {
      -webkit-transform: translateX(80vw) rotate(0deg);
              transform: translateX(80vw) rotate(0deg);
    }

    90% {
      -webkit-transform: translateX(90vw) rotate(6deg);
              transform: translateX(90vw) rotate(6deg);
    }

    100% {
      -webkit-transform: translateX(100vw) rotate(0deg);
              transform: translateX(100vw) rotate(0deg);
    }

  }
  @keyframes walk {
    0% {
      -webkit-transform: translateX(-5vw);
              transform: translateX(-5vw);
    }

    10% {
      -webkit-transform: translateX(10vw) rotate(6deg);
              transform: translateX(10vw) rotate(6deg);
    }

    20% {
      -webkit-transform: translateX(20vw) rotate(0deg);
              transform: translateX(20vw) rotate(0deg);
    }

    30% {
      -webkit-transform: translateX(30vw) rotate(6deg);
              transform: translateX(30vw) rotate(6deg);
    }

    40% {
      -webkit-transform: translateX(40vw) rotate(0deg);
              transform: translateX(40vw) rotate(0deg);
    }

    50% {
      -webkit-transform: translateX(50vw) rotate(6deg);
              transform: translateX(50vw) rotate(6deg);
    }

    60% {
      -webkit-transform: translateX(60vw) rotate(0deg);
              transform: translateX(60vw) rotate(0deg);
    }

    70% {
      -webkit-transform: translateX(70vw) rotate(6deg);
              transform: translateX(70vw) rotate(6deg);
    }

    80% {
      -webkit-transform: translateX(80vw) rotate(0deg);
              transform: translateX(80vw) rotate(0deg);
    }

    90% {
      -webkit-transform: translateX(90vw) rotate(6deg);
              transform: translateX(90vw) rotate(6deg);
    }

    100% {
      -webkit-transform: translateX(100vw) rotate(0deg);
              transform: translateX(100vw) rotate(0deg);
    }

  }


  .images2 {
    display: flex;
    justify-content: space-between;
  }

  .images2 img {
    width: 40%;
    margin-top: -15%;
    z-index: 10000;
  }



  * {
    box-sizing: border-box;
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 5rem;
    background-image: url(/static/media/ticker.81e610af.png);
    background-size: contain;
    padding-left: 100%;
    z-index: 20000000000000000;
    margin-top: -3.5px;
  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 5rem;
    line-height: 5rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 30s;
    animation-duration: 50s;
    z-index: 1000000000000;

  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 35px;
    font-weight: 800;
    color: white;
    z-index: 1000000000000;

  }

  .line {
    background-color: rgb(255, 255, 255);
    height: 20vh;
    width: 120vw;
    border-bottom: 12px solid rgb(0, 113, 189);
    border-top: 12px solid rgb(0, 113, 189);
    rotate: 10deg;
    margin-left: -50px;
    display: flex;
    justify-content: space-between;
    margin-top: 15%;
  }

  .line2 {
    background-color: rgb(255, 255, 255);
    height: 20vh;
    width: 120vw;
    border-bottom: 12px solid rgb(0, 113, 189);
    border-top: 12px solid rgb(0, 113, 189);
    rotate: 8deg;
    margin-left: -40px;
    display: flex;
    margin-top: 2%;
    justify-content: space-between;
    margin-bottom: 100px;
  }

  .line2 img {
    width: 50px;
    height: 50px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .line img {
    width: 50px;
    height: 50px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .weapon {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    width: 25%;
    margin-top: -15%;
  }

  .potionBottle {
    width: 23%;
    margin-top: -10%;
    z-index: 100000;
    margin-left: 20px;
  }

  .bawl {
    width: 30%;
    margin-top: -5%;
    z-index: 100000;
    margin-left: 20px;
  }

  .ball {
    width: 12%;
    margin-top: -10%;
    z-index: 100000;
    margin-left: 50px;
    rotate: 15deg;
  }


  @-webkit-keyframes float {
    0% {
      -webkit-transform: translate(0, 0);
              transform: translate(0, 0);
    }

    50% {
      -webkit-transform: translate(0, 20px);
              transform: translate(0, 20px);
    }

    100% {
      -webkit-transform: translate(0, 0);
              transform: translate(0, 0);
    }
  }


  @keyframes float {
    0% {
      -webkit-transform: translate(0, 0);
              transform: translate(0, 0);
    }

    50% {
      -webkit-transform: translate(0, 20px);
              transform: translate(0, 20px);
    }

    100% {
      -webkit-transform: translate(0, 0);
              transform: translate(0, 0);
    }
  }

  .mashroom {
    width: 23%;
    margin-top: -20%;
    z-index: 100000;
    margin-left: 70%;
    rotate: 25deg;
  }

  .lucky {
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
  }

  .lucky img {
    width: 20%;
  }

  @-webkit-keyframes shake {
    0% {
      -webkit-transform: translate(0, 0) rotate(0);
              transform: translate(0, 0) rotate(0);
    }

    10% {
      -webkit-transform: translate(-10px, 0) rotate(-5deg);
              transform: translate(-10px, 0) rotate(-5deg);
    }

    20% {
      -webkit-transform: translate(10px, 0) rotate(5deg);
              transform: translate(10px, 0) rotate(5deg);
    }

    30% {
      -webkit-transform: translate(-10px, 0) rotate(-5deg);
              transform: translate(-10px, 0) rotate(-5deg);
    }

    40% {
      -webkit-transform: translate(10px, 0) rotate(5deg);
              transform: translate(10px, 0) rotate(5deg);
    }

    50% {
      -webkit-transform: translate(-10px, 0) rotate(-5deg);
              transform: translate(-10px, 0) rotate(-5deg);
    }

    60% {
      -webkit-transform: translate(10px, 0) rotate(5deg);
              transform: translate(10px, 0) rotate(5deg);
    }

    70% {
      -webkit-transform: translate(-10px, 0) rotate(-5deg);
              transform: translate(-10px, 0) rotate(-5deg);
    }

    80% {
      -webkit-transform: translate(10px, 0) rotate(5deg);
              transform: translate(10px, 0) rotate(5deg);
    }

    90% {
      -webkit-transform: translate(-10px, 0) rotate(-5deg);
              transform: translate(-10px, 0) rotate(-5deg);
    }

    100% {
      -webkit-transform: translate(0, 0) rotate(0);
              transform: translate(0, 0) rotate(0);
    }
  }

  @keyframes shake {
    0% {
      -webkit-transform: translate(0, 0) rotate(0);
              transform: translate(0, 0) rotate(0);
    }

    10% {
      -webkit-transform: translate(-10px, 0) rotate(-5deg);
              transform: translate(-10px, 0) rotate(-5deg);
    }

    20% {
      -webkit-transform: translate(10px, 0) rotate(5deg);
              transform: translate(10px, 0) rotate(5deg);
    }

    30% {
      -webkit-transform: translate(-10px, 0) rotate(-5deg);
              transform: translate(-10px, 0) rotate(-5deg);
    }

    40% {
      -webkit-transform: translate(10px, 0) rotate(5deg);
              transform: translate(10px, 0) rotate(5deg);
    }

    50% {
      -webkit-transform: translate(-10px, 0) rotate(-5deg);
              transform: translate(-10px, 0) rotate(-5deg);
    }

    60% {
      -webkit-transform: translate(10px, 0) rotate(5deg);
              transform: translate(10px, 0) rotate(5deg);
    }

    70% {
      -webkit-transform: translate(-10px, 0) rotate(-5deg);
              transform: translate(-10px, 0) rotate(-5deg);
    }

    80% {
      -webkit-transform: translate(10px, 0) rotate(5deg);
              transform: translate(10px, 0) rotate(5deg);
    }

    90% {
      -webkit-transform: translate(-10px, 0) rotate(-5deg);
              transform: translate(-10px, 0) rotate(-5deg);
    }

    100% {
      -webkit-transform: translate(0, 0) rotate(0);
              transform: translate(0, 0) rotate(0);
    }
  }

  @-webkit-keyframes zoom {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
      /* Start the animation with normal size image */
    }

    50% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1);
      /* Increase the size of the image halfway through the animation */
    }

    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      /* End the animation with normal size image */
    }
  }

  @keyframes zoom {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
      /* Start the animation with normal size image */
    }

    50% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1);
      /* Increase the size of the image halfway through the animation */
    }

    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      /* End the animation with normal size image */
    }
  }


  .gifImg {
    background-image: url(/static/media/pepe.ae6a97c7.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 50vw;
    height: 50vw;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    background-color: rgba(0, 0, 0, 0);

  }

  .gifImg2 {
    background-image: url(/static/media/182.f2954502.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 50vw;
    height: 50vw;
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }

  .gifImg3 {
    background-image: url(/static/media/pepe5.be18fc31.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 50vw;
    height: 50vw;
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }

  .gifImg4 {
    background-image: url(/static/media/new.3aabb953.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 50vw;
    height: 50vw;
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }

  .gifMain {
    background-color: rgba(0, 0, 0, 0);
    margin-top: -10%;
  }

  .bubble-container {
    flex-flow: column;
    display: flex;
  }

}

@media screen and (min-width: 992px) and (max-width: 1199px) {

  .page-loader {
    width: 100%;
    height: 100vh;
    background: #242424;
    z-index: 5000000000000000;
    position: fixed;
  }

  .loading-area {
    display: grid;
    place-items: center;
    height: 100vh;
  }

  .containerLoad {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .h1Load {
    font-size: 1.5rem;
    color: #00ff2a;
  }

  .ring {
    width: 200px;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  .ring::before {
    content: '';
    width: 200px;
    height: 200px;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
    -webkit-animation: ring 2s linear infinite;
            animation: ring 2s linear infinite;
  }

  @-webkit-keyframes ring {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
      box-shadow: 0px 5px 5px #008afa;
    }

    25% {
      -webkit-transform: rotate(90deg);
              transform: rotate(90deg);
      box-shadow: 0px 5px 5px #02b7ff;
    }

    50% {
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg);
      box-shadow: 0px 5px 5px #0151ff;
    }

    75% {
      -webkit-transform: rotate(270deg);
              transform: rotate(270deg);
      box-shadow: 0px 5px 5px #367de7;
    }

    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
      box-shadow: 0px 5px 5px #008afa;
    }
  }

  @keyframes ring {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
      box-shadow: 0px 5px 5px #008afa;
    }

    25% {
      -webkit-transform: rotate(90deg);
              transform: rotate(90deg);
      box-shadow: 0px 5px 5px #02b7ff;
    }

    50% {
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg);
      box-shadow: 0px 5px 5px #0151ff;
    }

    75% {
      -webkit-transform: rotate(270deg);
              transform: rotate(270deg);
      box-shadow: 0px 5px 5px #367de7;
    }

    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
      box-shadow: 0px 5px 5px #008afa;
    }
  }

  .rmConMainMob {
    display: none;
  }

  .miniBarMain {
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100vw;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #020529b7;
    display: flex;
    justify-content: space-between;
  }

  .miniBar {
    display: flex;
    justify-content: space-between;
  }

  .navbarMainImgSet {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    align-items: center;
    display: flex;
  }

  .navbar {
    width: 40px;
    justify-content: flex-end;
    cursor: pointer;
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
    display: block;
    align-items: center;

  }

  #fontSize2 {
    font-size: 16px;
    color: white;
    text-align: left;
  }

  #fontSize2:hover {
    color: #818181;
  }

  .littleNav div {
    text-align: left;
    padding: 30px;
    cursor: pointer;
    border-top: #2ba531 1px solid;
  }

  .littleNav {
    background-color: #1a1a1ad8;
    width: 100%;
    height: -webkit-max-content;
    height: max-content;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1000000000;
    flex-flow: column wrap;
    display: flex;
    border-bottom: 5px #196e1e solid;
  }

  .hidden {
    display: none;
  }

  .close {
    width: 35px;
    height: 35px;
    justify-content: flex-end;
    margin-left: auto;
    cursor: pointer;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
  }

  .navLink {
    color: #ff000091;
  }

  .audioPic {
    /* display: none;*/
    width: 100px;
    height: 100px;
    position: sticky;
    position: -webkit-sticky;
    left: 0;
    bottom: 10%;
    z-index: 1000;
  }


  .rmbg {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-bottom: 2%;
    background-color: rgba(0, 0, 0, 0);

  }

  .rmbg2 {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-top: 10%;
    padding-bottom: 2%;
    background: url(/static/media/rmbg.6ed0fbcc.jpg) no-repeat center center fixed;
    background-size: cover;
    width: 97%;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .placeAndWL {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    z-index: 100000000;

  }

  input {
    background-color: rgba(0, 30, 70, 0.568);
    border: white 1px solid;
    font-size: 20px;
    padding: 15px;
    color: #00d6fc;
    z-index: 100000000;
  }

  input:focus {
    background-color: #b3e9ff00;
    color: #00d6fc;
  }

  #wlBtn {
    background-color: rgba(0, 30, 70, 0.568);
    border: 1px solid rgb(16, 192, 223);
    color: #ffffff;
    width: 200px;
    z-index: 100000000;
  }

  #wlBtn:hover {
    font-size: 18px;
  }

  .wlLine {
    background-color: #000000;
    padding: 50px;
  }


  .cloudsMob {
    display: none;
  }

  .connectMob {
    display: none;
  }

  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-top: 100px;
    padding-bottom: 100px;
    background: rgb(52, 52, 52);
    background: linear-gradient(180deg, rgba(52, 52, 52, 1) 12%, rgba(0, 0, 0, 1) 100%);
  }


  .logosMob {
    display: none;
  }

  .allWrap {
    background: rgb(49,89,63);
background: radial-gradient(circle, rgba(49,89,63,1) 0%, rgba(52,74,61,1) 50%, rgba(35,51,41,1) 100%);
    height: 100vh;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    overflow: hidden;
  }

  .cont {
    background: radial-gradient(circle, rgba(0, 0, 0, 0.06684392507002801) 0%, rgba(0, 0, 0, 0.11166185224089631) 50%, rgba(0, 0, 0, 0.3693649334733894) 100%), url(/static/media/BG.b24e41c2.jpg) no-repeat center center fixed;
    background-size: cover;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;

  }




  .in2 p {
    font-size: 28px;
    text-align: center;
    font-family: "Iomanoid";
    letter-spacing: 4px;
    text-shadow: 0 0 10px #ffdc64;
    color: #ffdc64;

  }

  .in2 {
    z-index: 100000000;

  }

  @-webkit-keyframes heartbeat {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }

    50% {
      -webkit-transform: scale(0.9);
              transform: scale(0.9);
    }

    60% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1);
    }

    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }

  @keyframes heartbeat {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }

    50% {
      -webkit-transform: scale(0.9);
              transform: scale(0.9);
    }

    60% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1);
    }

    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }

  .logo2Intro {
    z-index: 200000000000000000000000000000000;
    width: 40%;
    padding-top: 27.9vh;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    -webkit-animation: heartbeat 2s ease-in-out infinite;
            animation: heartbeat 2s ease-in-out infinite;
  }

  .in2 img {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 55%;
    padding-top: 12vh;
    transition: -webkit-transform 0.8s;
    transition: transform 0.8s;
    transition: transform 0.8s, -webkit-transform 0.8s;
    z-index: 100000000;
  }

  .in2 img:hover {
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
  }


  .introduction {
    font-family: "Iomanoid";
  }

  .twitter img {
    width: 50px;
    height: 50px;
    margin-left: 30px;
    cursor: pointer;
    opacity: 0.6;
    transition: 0.2s ease;
    margin-top: -3%;
    z-index: 200000000000;

  }

  .twitter {
    z-index: 200000000000;
  }

  .twitter img:hover {
    opacity: 100%;
    z-index: 200000000000;

  }

  .boxWrap2Story {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-top: 5%;
    padding-bottom: 2%;
    /*background: linear-gradient(180deg, rgba(9, 28, 45, 0.10325849089635852) 0%, rgba(13, 26, 38, 0.36656381302521013) 35%, rgba(9, 28, 45, 1) 100%), url('./assets/bg1.jpg') no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;*/
    /*background: rgb(12, 22, 42);
    background: linear-gradient(180deg, rgba(12, 22, 42, 1) 11%, rgba(3, 43, 25, 1) 90%);*/
    background-color: rgba(0, 0, 0, 0);
  }

  .boxWrap2StoryR {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    /*background: linear-gradient(180deg, rgba(9, 28, 45, 0.10325849089635852) 0%, rgba(13, 26, 38, 0.36656381302521013) 35%, rgba(9, 28, 45, 1) 100%), url('./assets/bg1.jpg') no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;*/
    /*background: rgb(12, 22, 42);
    background: linear-gradient(180deg, rgba(12, 22, 42, 1) 11%, rgba(3, 43, 25, 1) 90%);*/
    background-color: rgba(0, 0, 0, 0);
    ;
  }

  .boxWrap2StoryT {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-bottom: 2%;
    /*background: linear-gradient(180deg, rgba(9, 28, 45, 0.10325849089635852) 0%, rgba(13, 26, 38, 0.36656381302521013) 35%, rgba(9, 28, 45, 1) 100%), url('./assets/bg1.jpg') no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;*/
    /*background: rgb(12, 22, 42);
    background: linear-gradient(180deg, rgba(12, 22, 42, 1) 11%, rgba(3, 43, 25, 1) 90%);*/
    background-color: rgba(0, 0, 0, 0);
    ;
  }


  .boxWrap2Story2 {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0);

    padding-bottom: 2%;
  }

  .boxWrap2Mint {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-size: cover;
    padding-bottom: 2%;
    z-index: 100000000000;
  }

  .boxWrap2SubR {
    justify-content: space-between;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .boxWrap2Sub1 {}

  .boxWrap2Sub {
    justify-content: space-between;
    width: 98%;
    margin-left: auto;
    margin-right: auto;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.164) 0%, rgba(0, 0, 0, 0.301) 50%, rgba(0, 0, 0, 0.534) 100%), url('https://cdn.discordapp.com/attachments/1028976349920112681/1139504255498195055/NFTHouse9_coins_in_space_cartoon_theme_326f3ecc-8f98-4844-81b1-49ce9a21ba21.png') no-repeat center center fixed;
    background-size: cover;
    padding-top: 10%;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }



  .boxWrap2SubT {
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    background-color: #22e2d9;
  }

  .storyH {
    font-size: 200px;
    color: black;
    font-weight: bolder;
    text-align: center;
    margin-bottom: 10px;
    font-style: normal;
    font-family: 'Exo', sans-serif;
  }

  .outer-div {
    width: 500px;
    height: 100px;
    background-color: #fff;
    transition: background-color 1s ease;
  }

  .storyHK {
    font-size: 80px;
    color: white;
    font-weight: 500;
    text-align: center;
    font-family: "Iomanoid";
    margin-bottom: 2%;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px rgba(255, 255, 0, 0.616), 0 -10px 20px #ff80008c, 0 -18px 40px rgba(255, 0, 0, 0.719);
  }

  .storyH2 {
    font-size: 125px;
    color: rgb(255, 255, 255);
    font-weight: bolder;
    text-align: center;
    margin-bottom: 2%;
    font-family: 'Exo', sans-serif;
  }

  .storyH3 {
    font-size: 100px;
    color: rgb(255, 255, 255);
    font-weight: bolder;
    text-align: center;
    margin-bottom: 2%;
    font-family: 'Exo', sans-serif;
    background: rgb(88, 101, 242);
    background: linear-gradient(180deg, rgb(36, 51, 219) 12%, rgb(67, 3, 185) 100%);
    width: 700px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
    cursor: pointer;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    margin-top: -15%;
  }

  .storyH3:hover {
    background: rgb(38, 47, 222);
    background: linear-gradient(180deg, rgba(38, 47, 222, 1) 12%, rgba(176, 68, 242, 1) 100%);
  }

  /* Animation */
  @-webkit-keyframes fill-div {
    from {
      width: 0;
    }

    to {
      width: 100%;
    }
  }
  @keyframes fill-div {
    from {
      width: 0;
    }

    to {
      width: 100%;
    }
  }

  .bg {
    background-color: #17237e;
    padding-left: 50px;
    padding-right: 50px;
  }

  .storyH2-2 {
    font-size: 50px;
    color: white;
    font-weight: 500;
    text-align: right;
    font-family: "Iomanoid";
    margin-bottom: 10px;
    text-shadow: 0 0 10px #ce9381;
  }

  .about {
    color: white;
    font-size: 18px;
    text-align: left;
    display: flex;
    justify-content: space-evenly;
    margin-left: auto;
    margin-right: auto;
  }

  .aboutKingdom {
    color: white;
    font-size: 18px;
    text-align: left;
    display: flex;
    justify-content: space-evenly;
  }

  .kingdomsSection {
    color: white;
    font-size: 18px;
    text-align: left;
    display: flex;
    justify-content: space-evenly;
    flex-flow: column nowrap;
  }

  .aboutP {
    width: 50%;
    margin-bottom: 50px;
    margin-top: 30px;
    margin-right: auto;
    justify-content: center;
  }

  .aboutPNew {
    width: 50%;
    margin-bottom: 50px;
    margin-top: 30px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
  }

  .aboutPS {
    width: 45%;
    margin-bottom: 50px;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .aboutP3 {
    width: 60%;
    margin-top: auto;
    margin-bottom: auto;
    margin-bottom: 50px;
  }

  .aboutP3 p {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 23px;
  }

  .aboutP p {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 22px;
  }

  .aboutPNew p {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 20px;
  }

  .aboutPDiv {
    background: rgb(101, 161, 90);
    background: linear-gradient(93deg, rgba(101, 161, 90, 1) 0%, rgba(32, 71, 128, 1) 50%);
    -webkit-clip-path: polygon(0 0, 100% 0%, 98% 100%, 0% 100%);
            clip-path: polygon(0 0, 100% 0%, 98% 100%, 0% 100%);
    width: 900px;
    margin-bottom: 12px;
    margin-top: 12px;
    padding: 20px;
    font-size: 18px;
    line-height: 40px;
  }

  .aboutPDiv2 {
    background-color: #2a32438a;
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
    -webkit-clip-path: polygon(0 0, 100% 0%, 98% 100%, 0% 100%);
            clip-path: polygon(0 0, 100% 0%, 98% 100%, 0% 100%);
    margin-bottom: 20px;
    margin-top: 20px;
    padding: 20px;
    font-size: 30px;
    line-height: 40px;
    color: white;
  }

  .hl {
    color: #1ee03e;
  }


  .bigLetters {
    font-size: 37px;
  }

  .ironfist {
    margin-top: auto;
    margin-left: -40px;
    z-index: 100000;
  }

  .ironfist img {
    width: 60px;
    height: 60px;

  }

  .storyConAndImg {
    display: flex;
    justify-content: center;
  }

  .aboutP2 {
    width: 50%;
    margin-top: auto;
    margin-bottom: auto;
  }

  .zeus {
    width: 45%;
    height: 45%;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    -webkit-animation: floating 3s ease-in-out infinite;
            animation: floating 3s ease-in-out infinite;
  }

  .zeus2 {
    width: 35%;
    height: 35%;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;

  }

  @-webkit-keyframes floating {

    0%,
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    50% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
    }
  }

  @keyframes floating {

    0%,
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    50% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
    }
  }

  .logos {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
  }

  .logos_mobile {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
    display: none;
  }

  .logoF img {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 40%;
    display: flex;
  }


  .facetoface img {
    width: 30%;
  }

  .facetoface {
    display: flex;
    justify-content: space-between;
    margin-top: -27%;
  }

  .footerImg {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: space-evenly;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
  }

  .footerImg img {
    margin-left: 5px;
    margin-right: 5px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
  }

  .footerImg img:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    cursor: pointer;
  }

  .footer2 {
    justify-content: center;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .copyright {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 13px;
  }

  .footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: rgba(102, 102, 102, 0);
    width: 100%;
  }

  .sword {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 10%;
  }

  .devs {
    color: rgba(255, 255, 255, 0.774);
    text-align: center;
    margin-top: 80px;
  }

  .devs img {
    width: 30px;
    height: 30px;
    opacity: 0.9;
    transition: 0.3s ease;
  }

  .devs:hover {
    color: rgb(255, 255, 255);
    cursor: pointer;
  }

  .devs img:hover {
    width: 30px;
    height: 30px;
  }

  a {
    color: #d1fdfb;
    text-decoration: none;
  }

  a:hover {
    color: #99faf5;
    text-decoration: none;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #020529b7;
    display: none;
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    width: 95%;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    height: 5vh;
  }

  .connect div {
    margin-left: 15px;
    margin-right: 15px;
    color: white;
    margin-top: auto;
    justify-content: space-evenly;
    display: flex;
    cursor: pointer;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    text-shadow: -1px -1px 1px #f899345d, 1px 1px 1px #fe921e6c;

  }

  .connect div:hover {
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
  }

  .icons {
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    width: 33.33vw;
    margin-left: 10px;
  }

  .icons img {
    width: 30px;
    height: 30px;
    margin-left: 10px;
    margin-right: 10px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    z-index: 10000000;
  }

  .icons img:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    cursor: pointer;
  }

  #fontSize {
    font-size: 18px;
    z-index: 10000000;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    align-items: center;

  }

  #fontSize-2 {
    font-size: 25px;
    z-index: 10000000;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    align-items: center;
  }

  /* CSS */
  .button-92 {
    --c: #fff;
    /* text color */
    background: linear-gradient(90deg, #0000 33%, #fff5, #0000 67%) 100%/300% no-repeat,
      #009218;
    background: linear-gradient(90deg, #0000 33%, #fff5, #0000 67%) var(--_p, 100%)/300% no-repeat,
      #009218;
    /* background color */
    color: #0000;
    border: none;
    -webkit-transform: perspective(500px) rotateY(calc(20deg * -1));
            transform: perspective(500px) rotateY(calc(20deg * -1));
    -webkit-transform: perspective(500px) rotateY(calc(20deg * var(--_i, -1)));
            transform: perspective(500px) rotateY(calc(20deg * var(--_i, -1)));
    text-shadow: calc(-1 * 0.08em) -.01em 0 var(--c),
      calc(-1 * -0.08em) .01em 2px #0004;
    text-shadow: calc(var(--_i, -1) * 0.08em) -.01em 0 var(--c),
      calc(var(--_i, -1) * -0.08em) .01em 2px #0004;
    outline-offset: .1em;
    transition: 0.3s;
  }

  .button-92:hover,
  .button-92:focus-visible {
    --_p: 0%;
    --_i: 1;
  }

  .button-92:active {
    text-shadow: none;
    color: var(--c);
    box-shadow: inset 0 0 9e9q #0005;
    transition: 0s;
  }

  .button-92 {
    font-weight: bold;
    font-size: 1.8rem;
    margin: 0;
    cursor: pointer;
    padding: .1em .3em;
  }

  .title {
    color: white;
    text-shadow: -1px -1px 1px #f899345d, 1px 1px 1px #fe921e6c;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: center;
    font-size: 35px;
  }

  .logoUp {
    width: 50px;
    height: 50px;
    margin-top: -5px;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;
  }

  /* CSS */
  .button-62 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    background-color: #bb5a3c;
    font-size: 18px;
    padding: 13px;
    width: 200px;
    color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    transition: 0.3s ease;
    border-radius: 7px;
    border: none;
    font-weight: 500;
  }

  .button-62:hover {
    color: rgb(219, 219, 219);
  }

  .logo2 img {
    width: 75px;
    height: 75px;
  }

  .downImg {
    width: 35px;
    -webkit-animation: arrow_down_animation 2s ease-in-out infinite;
            animation: arrow_down_animation 2s ease-in-out infinite;
    cursor: pointer;
  }


  @-webkit-keyframes arrow_down_animation {

    0% {
      -webkit-transform: translate3d(0, 0, 0) scale(1, 1);
              transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;
    }

    30% {
      opacity: 1;
    }

    100% {
      -webkit-transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
              transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;

    }

  }


  @keyframes arrow_down_animation {

    0% {
      -webkit-transform: translate3d(0, 0, 0) scale(1, 1);
              transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;
    }

    30% {
      opacity: 1;
    }

    100% {
      -webkit-transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
              transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;

    }

  }

  .downImgDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -25px;

  }

  .down {
    text-align: center;
    background-color: rgba(0, 0, 0, 0);
  }

  .stickyBtn2 {
    width: 65px;
    height: 65px;
    z-index: 1;
  }

  #sticky-button {
    position: -webkit-sticky;
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 15%;
    margin-left: 92%;
    margin-top: -8%;
    cursor: pointer;
    transition: -webkit-transform .4s;
    transition: transform .4s;
    transition: transform .4s, -webkit-transform .4s;
    z-index: 1;

  }

  .stickyBtn2:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    opacity: 100%;
    z-index: 1;

  }

  .clouds2 {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  .cloud {
    position: absolute;
    top: 0;

  }

  .cloud1 {
    left: 10vw;

  }

  .cloud1 img {
    width: 120%;
    margin-top: -13%;
  }

  .cloud2 img {
    width: 70%;
    margin-top: -8%;
  }

  .cloud3 img {
    width: 100%;

  }

  .cloud1-1 {
    left: 70vw;
    -webkit-animation-name: move-clouds;
            animation-name: move-clouds;
    -webkit-animation-duration: 600s;
            animation-duration: 600s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
  }

  .cloud2 {
    left: 5px;
    -webkit-animation-name: move-clouds;
            animation-name: move-clouds;
    -webkit-animation-duration: 200s;
            animation-duration: 200s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
  }

  .cloud3 {
    left: 3px;

  }

  .cloud3-3 {
    left: 10px;
    -webkit-animation-name: move-clouds;
            animation-name: move-clouds;
    -webkit-animation-duration: 480s;
            animation-duration: 480s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
  }

  .cloud4 {
    left: 10vw;
  }

  .cloud4 img {
    width: 70%;
    margin-top: -5%;
    margin-left: -5%;
  }

  .cloud3 img {
    width: 25%;
  }

  @-webkit-keyframes move-clouds {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(110vw);
              transform: translateX(110vw);
    }
  }

  @keyframes move-clouds {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(110vw);
              transform: translateX(110vw);
    }
  }

  .cloudsLine img {
    width: 100%;
    display: flex;
  }

  .Q {
    font-size: 30px;
    color: white;
    font-weight: 500;
    margin-bottom: 10px;
    text-shadow: 0 0 10px #ecc46d;
  }

  .rmCon {
    color: white;
    font-size: 18px;
  }

  .rmConAndImg {
    margin-bottom: 30px;
    margin-top: 30px;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .rmCon img {
    width: 30px;
    height: 30px;
  }

  .line {
    width: 100%;
  }

  .connect2-2 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    box-shadow: rgba(0, 0, 0, 0.493) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    border: 12px #7f8082;

    border: #ffa528 5px groove;
  }

  .parrot {
    width: 70%;
  }

  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .totalSupply {
    text-align: center;
    font-size: 60px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(255, 255, 255);
    letter-spacing: 1px;
    margin-top: 1%;
  }

  .price {
    text-align: center;
    font-size: 25px;
    color: white;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }


  .center {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
    z-index: 10000000;

  }

  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: none;

    cursor: pointer;
    background-color: #6cfc1900;
    color: rgb(255, 255, 255);
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: 2px solid rgb(255, 255, 255);
    z-index: 1000000000000000;

  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: none;

    cursor: pointer;
    border: 2px solid rgb(255, 255, 255);
    color: rgb(255, 255, 255);
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    background-color: #00000000;
    z-index: 1000000000000000;

  }


  .btnfos-0-2:hover:active {
    background-color: #0e2942;
  }


  .btnfos-0-2-2:hover:active {
    background-color: #0e2942;
  }


  .btnfos-0-3 {
    margin-top: 20px;
    background: rgb(0, 120, 235);
    background: linear-gradient(180deg, rgba(0, 120, 235, 1) 0%, rgba(89, 154, 215, 1) 50%, rgba(0, 120, 235, 1) 100%);
    color: #ffffff;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 28px;
    padding-left: 30px;
    padding-right: 30px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    border: 6px rgb(0, 0, 0);
    border-style: double;
    z-index: 1000000000000000;
    transition: transform .2s;
  }

  .btnfos-0-3:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

  .nftamount {
    color: #ecaa2f;
    font-size: 85px;
  }

  .mintbuttondiv {
    text-align: center;
    z-index: 10000000;

  }

  .successfully {
    text-align: center;
    margin-top: 3%;
    color: #1ee03e;
    font-size: 30px;
    text-shadow: 0 0 20px #9bf179;
  }

  .errorMessage {
    margin-top: 3%;
    font-size: 20px;
    color: #fc4741;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 3%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .loadingText {
    font-size: 20px;
    text-align: center;
    color: rgb(240, 161, 97);
  }

  .loadTextSub {
    text-align: center;
    color: rgba(255, 255, 255, 0.815);
    font-size: 16px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    -webkit-animation: dots 1s steps(5, end) infinite;
            animation: dots 1s steps(5, end) infinite;
  }

  @-webkit-keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(214, 80, 71);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(209, 119, 45),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(214, 80, 71);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(209, 119, 45),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .rm2Box {
    display: flex;
    justify-content: center;
    margin-right: auto;

  }

  .rm3Box {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-top: 100px;
    margin-bottom: 100px;
  }

  .rmbox1 {
    -webkit-clip-path: polygon(0 0, 96% 0, 100% 100%, 0% 100%);
            clip-path: polygon(0 0, 96% 0, 100% 100%, 0% 100%);
    font-size: 45px;
    background-color: #000000a1;
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
    color: rgb(255, 255, 255);
    width: 400px;
    padding: 30px;
    text-align: center;

  }

  .rmbox2 {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 4% 100%);
            clip-path: polygon(0 0, 100% 0, 100% 100%, 4% 100%);
    font-size: 35px;
    background-color: #007906c2;
    color: white;
    width: 400px;
    padding: 25px;
    text-align: center;
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .rmbox1-3 {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 4% 100%);
            clip-path: polygon(0 0, 100% 0, 100% 100%, 4% 100%);
    text-align: center;
    font-size: 45px;
    background-color: #000000a1;

    -webkit-backdrop-filter: blur(5px);

            backdrop-filter: blur(5px);
    color: rgb(255, 255, 255);
    width: 400px;
    padding: 30px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }


  .rmbox2-3 {
    font-size: 35px;
    -webkit-clip-path: polygon(0 0, 96% 0, 100% 100%, 0% 100%);
            clip-path: polygon(0 0, 96% 0, 100% 100%, 0% 100%);
    text-align: center;
    background-color: #007906c2;
    color: white;
    width: 400px;
    padding: 30px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
    text-align: center;

  }

  .rmConMain {
    display: flex;
    justify-content: space-between;
    flex-flow: column nowrap;
    margin-top: 80px;
  }

  .scroll-effect-container {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    margin-bottom: 30px;

  }

  .scroll-effect {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: #ffffff00;
    text-align: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding-left: 50px;
    padding-right: 50px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

  }

  .scroll-effect-relic {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: #ffffff00;
    text-align: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding-left: 50px;
    padding-right: 50px;

  }

  .scroll-effect-mint {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: #ffffff00;
    text-align: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding-left: 50px;
    padding-right: 50px;

  }

  .scroll-effect-tokenomics {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: #ffffff00;
    text-align: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding-left: 50px;
    padding-right: 50px;

  }

  .scroll-effect-footer {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: #ffffff00;
    text-align: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding-left: 50px;
    padding-right: 50px;

  }


  .scroll-effect-rm {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: #ffffff00;
    text-align: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding-left: 50px;
    padding-right: 50px;

  }


  .scroll-effect-footer {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: #ffffff00;
    text-align: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding-left: 50px;
    padding-right: 50px;
  }

  @-webkit-keyframes fill-effect {
    from {
      width: 0%;
    }

    to {
      width: 100%;
    }
  }

  @keyframes fill-effect {
    from {
      width: 0%;
    }

    to {
      width: 100%;
    }
  }

  .animate {
    /*background: rgb(87, 102, 244);
    background: linear-gradient(270deg, rgba(87, 102, 244, 1) 0%, rgba(36, 46, 167, 1) 51%);*/
    background-color: #000000a1;
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
    background-repeat: no-repeat;
    background-size: 0% 100%;
    transition: background-size 1s ease-in-out;
    -webkit-animation: fill-effect 1s ease-in-out;
            animation: fill-effect 1s ease-in-out;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }

  .animate.animate {
    background-size: 100% 100%;

  }

  /*.animateC{
    background: rgb(244, 87, 179);
    background: linear-gradient(270deg, rgb(238, 77, 190) 0%, rgb(139, 8, 89) 51%);  background-repeat: no-repeat;
    background-size: 0% 100%;
    transition: background-size 0.5s ease-in-out;
    animation: fill-effect 0.5s ease-in-out;
    font-style: italic;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }*/

  .animateC.animateC {
    background-size: 100% 100%;

  }

  /* Define the walkingNft class */
  .walkingNft {
    width: 30%;
    position: absolute;
    bottom: -0.8%;
    left: 0;
    -webkit-animation: walk 20s linear infinite;
            animation: walk 20s linear infinite;

  }

  /* Define the walk animation */
  @-webkit-keyframes walk {
    0% {
      -webkit-transform: translateX(-5vw);
              transform: translateX(-5vw);
    }

    10% {
      -webkit-transform: translateX(10vw) rotate(6deg);
              transform: translateX(10vw) rotate(6deg);
    }

    20% {
      -webkit-transform: translateX(20vw) rotate(0deg);
              transform: translateX(20vw) rotate(0deg);
    }

    30% {
      -webkit-transform: translateX(30vw) rotate(6deg);
              transform: translateX(30vw) rotate(6deg);
    }

    40% {
      -webkit-transform: translateX(40vw) rotate(0deg);
              transform: translateX(40vw) rotate(0deg);
    }

    50% {
      -webkit-transform: translateX(50vw) rotate(6deg);
              transform: translateX(50vw) rotate(6deg);
    }

    60% {
      -webkit-transform: translateX(60vw) rotate(0deg);
              transform: translateX(60vw) rotate(0deg);
    }

    70% {
      -webkit-transform: translateX(70vw) rotate(6deg);
              transform: translateX(70vw) rotate(6deg);
    }

    80% {
      -webkit-transform: translateX(80vw) rotate(0deg);
              transform: translateX(80vw) rotate(0deg);
    }

    90% {
      -webkit-transform: translateX(90vw) rotate(6deg);
              transform: translateX(90vw) rotate(6deg);
    }

    100% {
      -webkit-transform: translateX(100vw) rotate(0deg);
              transform: translateX(100vw) rotate(0deg);
    }

  }
  @keyframes walk {
    0% {
      -webkit-transform: translateX(-5vw);
              transform: translateX(-5vw);
    }

    10% {
      -webkit-transform: translateX(10vw) rotate(6deg);
              transform: translateX(10vw) rotate(6deg);
    }

    20% {
      -webkit-transform: translateX(20vw) rotate(0deg);
              transform: translateX(20vw) rotate(0deg);
    }

    30% {
      -webkit-transform: translateX(30vw) rotate(6deg);
              transform: translateX(30vw) rotate(6deg);
    }

    40% {
      -webkit-transform: translateX(40vw) rotate(0deg);
              transform: translateX(40vw) rotate(0deg);
    }

    50% {
      -webkit-transform: translateX(50vw) rotate(6deg);
              transform: translateX(50vw) rotate(6deg);
    }

    60% {
      -webkit-transform: translateX(60vw) rotate(0deg);
              transform: translateX(60vw) rotate(0deg);
    }

    70% {
      -webkit-transform: translateX(70vw) rotate(6deg);
              transform: translateX(70vw) rotate(6deg);
    }

    80% {
      -webkit-transform: translateX(80vw) rotate(0deg);
              transform: translateX(80vw) rotate(0deg);
    }

    90% {
      -webkit-transform: translateX(90vw) rotate(6deg);
              transform: translateX(90vw) rotate(6deg);
    }

    100% {
      -webkit-transform: translateX(100vw) rotate(0deg);
              transform: translateX(100vw) rotate(0deg);
    }

  }


  .images2 {
    display: flex;
    justify-content: space-between;
  }

  .images2 img {
    width: 40%;
    margin-top: -18%;
    z-index: 10000;
  }



  * {
    box-sizing: border-box;
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 5rem;
    background-image: url(/static/media/ticker.81e610af.png);
    background-size: contain;
    padding-left: 100%;
    z-index: 20000000000000000;
    margin-top: -3.5px;
  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 5rem;
    line-height: 5rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 30s;
    animation-duration: 50s;
    z-index: 1000000000000;

  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 35px;
    font-weight: 800;
    color: white;
    z-index: 1000000000000;

  }

  .line {
    background-color: rgb(255, 255, 255);
    height: 20vh;
    width: 120vw;
    border-bottom: 12px solid rgb(0, 113, 189);
    border-top: 12px solid rgb(0, 113, 189);
    rotate: 10deg;
    margin-left: -50px;
    display: flex;
    justify-content: space-between;
    margin-top: 15%;
  }

  .line2 {
    background-color: rgb(255, 255, 255);
    height: 20vh;
    width: 120vw;
    border-bottom: 12px solid rgb(0, 113, 189);
    border-top: 12px solid rgb(0, 113, 189);
    rotate: 8deg;
    margin-left: -40px;
    display: flex;
    margin-top: 2%;
    justify-content: space-between;
    margin-bottom: 100px;
  }

  .line2 img {
    width: 50px;
    height: 50px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .line img {
    width: 50px;
    height: 50px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .weapon {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    width: 25%;
    margin-top: -15%;
  }

  .potionBottle {
    width: 23%;
    margin-top: -10%;
    z-index: 100000;
    margin-left: 20px;
  }

  .bawl {
    width: 26%;
    margin-top: -5%;
    z-index: 100000;
    margin-left: 20px;
  }

  .ball {
    width: 9%;
    margin-top: -10%;
    z-index: 100000;
    margin-left: 100px;
    rotate: 15deg;
  }


  @-webkit-keyframes float {
    0% {
      -webkit-transform: translate(0, 0);
              transform: translate(0, 0);
    }

    50% {
      -webkit-transform: translate(0, 20px);
              transform: translate(0, 20px);
    }

    100% {
      -webkit-transform: translate(0, 0);
              transform: translate(0, 0);
    }
  }


  @keyframes float {
    0% {
      -webkit-transform: translate(0, 0);
              transform: translate(0, 0);
    }

    50% {
      -webkit-transform: translate(0, 20px);
              transform: translate(0, 20px);
    }

    100% {
      -webkit-transform: translate(0, 0);
              transform: translate(0, 0);
    }
  }

  .mashroom {
    width: 23%;
    margin-top: -20%;
    z-index: 100000;
    margin-left: 70%;
    rotate: 25deg;
  }

  .lucky {
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
  }

  .lucky img {
    width: 20%;
  }

  @-webkit-keyframes shake {
    0% {
      -webkit-transform: translate(0, 0) rotate(0);
              transform: translate(0, 0) rotate(0);
    }

    10% {
      -webkit-transform: translate(-10px, 0) rotate(-5deg);
              transform: translate(-10px, 0) rotate(-5deg);
    }

    20% {
      -webkit-transform: translate(10px, 0) rotate(5deg);
              transform: translate(10px, 0) rotate(5deg);
    }

    30% {
      -webkit-transform: translate(-10px, 0) rotate(-5deg);
              transform: translate(-10px, 0) rotate(-5deg);
    }

    40% {
      -webkit-transform: translate(10px, 0) rotate(5deg);
              transform: translate(10px, 0) rotate(5deg);
    }

    50% {
      -webkit-transform: translate(-10px, 0) rotate(-5deg);
              transform: translate(-10px, 0) rotate(-5deg);
    }

    60% {
      -webkit-transform: translate(10px, 0) rotate(5deg);
              transform: translate(10px, 0) rotate(5deg);
    }

    70% {
      -webkit-transform: translate(-10px, 0) rotate(-5deg);
              transform: translate(-10px, 0) rotate(-5deg);
    }

    80% {
      -webkit-transform: translate(10px, 0) rotate(5deg);
              transform: translate(10px, 0) rotate(5deg);
    }

    90% {
      -webkit-transform: translate(-10px, 0) rotate(-5deg);
              transform: translate(-10px, 0) rotate(-5deg);
    }

    100% {
      -webkit-transform: translate(0, 0) rotate(0);
              transform: translate(0, 0) rotate(0);
    }
  }

  @keyframes shake {
    0% {
      -webkit-transform: translate(0, 0) rotate(0);
              transform: translate(0, 0) rotate(0);
    }

    10% {
      -webkit-transform: translate(-10px, 0) rotate(-5deg);
              transform: translate(-10px, 0) rotate(-5deg);
    }

    20% {
      -webkit-transform: translate(10px, 0) rotate(5deg);
              transform: translate(10px, 0) rotate(5deg);
    }

    30% {
      -webkit-transform: translate(-10px, 0) rotate(-5deg);
              transform: translate(-10px, 0) rotate(-5deg);
    }

    40% {
      -webkit-transform: translate(10px, 0) rotate(5deg);
              transform: translate(10px, 0) rotate(5deg);
    }

    50% {
      -webkit-transform: translate(-10px, 0) rotate(-5deg);
              transform: translate(-10px, 0) rotate(-5deg);
    }

    60% {
      -webkit-transform: translate(10px, 0) rotate(5deg);
              transform: translate(10px, 0) rotate(5deg);
    }

    70% {
      -webkit-transform: translate(-10px, 0) rotate(-5deg);
              transform: translate(-10px, 0) rotate(-5deg);
    }

    80% {
      -webkit-transform: translate(10px, 0) rotate(5deg);
              transform: translate(10px, 0) rotate(5deg);
    }

    90% {
      -webkit-transform: translate(-10px, 0) rotate(-5deg);
              transform: translate(-10px, 0) rotate(-5deg);
    }

    100% {
      -webkit-transform: translate(0, 0) rotate(0);
              transform: translate(0, 0) rotate(0);
    }
  }

  @-webkit-keyframes zoom {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
      /* Start the animation with normal size image */
    }

    50% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1);
      /* Increase the size of the image halfway through the animation */
    }

    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      /* End the animation with normal size image */
    }
  }

  @keyframes zoom {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
      /* Start the animation with normal size image */
    }

    50% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1);
      /* Increase the size of the image halfway through the animation */
    }

    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      /* End the animation with normal size image */
    }
  }


  .gifImg {
    background-image: url(/static/media/pepe.ae6a97c7.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 50vw;
    height: 50vw;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    background-color: rgba(0, 0, 0, 0);

  }

  .gifImg2 {
    background-image: url(/static/media/182.f2954502.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 50vw;
    height: 50vw;
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }

  .gifImg3 {
    background-image: url(/static/media/pepe5.be18fc31.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 50vw;
    height: 50vw;
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }

  .gifImg4 {
    background-image: url(/static/media/new.3aabb953.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 50vw;
    height: 50vw;
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }

  .gifMain {
    background-color: rgba(0, 0, 0, 0);
    margin-top: -10%;
  }

  .bubble-container {
    flex-flow: column;
    display: flex;
  }

}

@media screen and (min-width: 1200px) and (max-width: 1919px) {

  .page-loader {
    width: 100%;
    height: 100vh;
    background: #242424;
    z-index: 5000000000000000;
    position: fixed;
  }

  .loading-area {
    display: grid;
    place-items: center;
    height: 100vh;
  }

  .containerLoad {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .h1Load {
    font-size: 1.5rem;
    color: #00ff2a;
  }

  .ring {
    width: 200px;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  .ring::before {
    content: '';
    width: 200px;
    height: 200px;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
    -webkit-animation: ring 2s linear infinite;
            animation: ring 2s linear infinite;
  }

  @-webkit-keyframes ring {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
      box-shadow: 0px 5px 5px #008afa;
    }

    25% {
      -webkit-transform: rotate(90deg);
              transform: rotate(90deg);
      box-shadow: 0px 5px 5px #02b7ff;
    }

    50% {
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg);
      box-shadow: 0px 5px 5px #0151ff;
    }

    75% {
      -webkit-transform: rotate(270deg);
              transform: rotate(270deg);
      box-shadow: 0px 5px 5px #367de7;
    }

    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
      box-shadow: 0px 5px 5px #008afa;
    }
  }

  @keyframes ring {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
      box-shadow: 0px 5px 5px #008afa;
    }

    25% {
      -webkit-transform: rotate(90deg);
              transform: rotate(90deg);
      box-shadow: 0px 5px 5px #02b7ff;
    }

    50% {
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg);
      box-shadow: 0px 5px 5px #0151ff;
    }

    75% {
      -webkit-transform: rotate(270deg);
              transform: rotate(270deg);
      box-shadow: 0px 5px 5px #367de7;
    }

    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
      box-shadow: 0px 5px 5px #008afa;
    }
  }

  .rmConMainMob {
    display: none;
  }

  .audioPic {
    /* display: none;*/
    width: 100px;
    height: 100px;
    position: sticky;
    position: -webkit-sticky;
    left: 0;
    bottom: 10%;
    z-index: 1000;
  }


  .rmbg {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-bottom: 2%;
    background-color: rgba(0, 0, 0, 0);

  }

  .rmbg2 {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-top: 10%;
    padding-bottom: 2%;
    background: url(/static/media/rmbg.6ed0fbcc.jpg) no-repeat center center fixed;
    background-size: cover;
    width: 97%;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .placeAndWL {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    z-index: 100000000;

  }

  input {
    background-color: rgba(0, 30, 70, 0.568);
    border: white 1px solid;
    font-size: 20px;
    padding: 15px;
    color: #00d6fc;
    z-index: 100000000;
  }

  input:focus {
    background-color: #b3e9ff00;
    color: #00d6fc;
  }

  #wlBtn {
    background-color: rgba(0, 30, 70, 0.568);
    border: 1px solid rgb(16, 192, 223);
    color: #ffffff;
    width: 200px;
    z-index: 100000000;
  }

  #wlBtn:hover {
    font-size: 18px;
  }

  .wlLine {
    background-color: #000000;
    padding: 50px;
  }


  .cloudsMob {
    display: none;
  }

  .connectMob {
    display: none;
  }

  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-top: 100px;
    padding-bottom: 100px;
    background: rgb(52, 52, 52);
    background: linear-gradient(180deg, rgba(52, 52, 52, 1) 12%, rgba(0, 0, 0, 1) 100%);
  }

  .littleNav {
    display: none;
  }

  .miniBarMain {
    display: none;
  }

  .logosMob {
    display: none;
  }

  .allWrap {
    background: rgb(49,89,63);
background: radial-gradient(circle, rgba(49,89,63,1) 0%, rgba(52,74,61,1) 50%, rgba(35,51,41,1) 100%);
    height: 100vh;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    overflow: hidden;
  }

  .cont {
    background: radial-gradient(circle, rgba(0, 0, 0, 0.06684392507002801) 0%, rgba(0, 0, 0, 0.11166185224089631) 50%, rgba(0, 0, 0, 0.3693649334733894) 100%), url(/static/media/BG.b24e41c2.jpg) no-repeat center center fixed;
    background-size: cover;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;

  }

  .in2 p {
    font-size: 28px;
    text-align: center;
    font-family: "Iomanoid";
    letter-spacing: 4px;
    text-shadow: 0 0 10px #ffdc64;
    color: #ffdc64;

  }

  .in2 {
    z-index: 100000000;

  }

  @-webkit-keyframes heartbeat {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }

    50% {
      -webkit-transform: scale(0.9);
              transform: scale(0.9);
    }

    60% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1);
    }

    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }

  @keyframes heartbeat {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }

    50% {
      -webkit-transform: scale(0.9);
              transform: scale(0.9);
    }

    60% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1);
    }

    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }

  .logo2Intro {
    z-index: 200000000000000000000000000000000;
    width: 40%;
    padding-top: 27.9vh;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    -webkit-animation: heartbeat 2s ease-in-out infinite;
            animation: heartbeat 2s ease-in-out infinite;
  }

  .in2 img {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 40%;
    padding-top: 12vh;
    transition: -webkit-transform 0.8s;
    transition: transform 0.8s;
    transition: transform 0.8s, -webkit-transform 0.8s;
    z-index: 100000000;
  }

  .in2 img:hover {
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
  }


  .introduction {
    font-family: "Iomanoid";
  }

  .twitter img {
    width: 50px;
    height: 50px;
    margin-left: 30px;
    cursor: pointer;
    opacity: 0.6;
    transition: 0.2s ease;
    margin-top: -3%;
    z-index: 200000000000;

  }

  .twitter {
    z-index: 200000000000;
  }

  .twitter img:hover {
    opacity: 100%;
    z-index: 200000000000;

  }

  .boxWrap2Story {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-top: 5%;
    padding-bottom: 2%;
    /*background: linear-gradient(180deg, rgba(9, 28, 45, 0.10325849089635852) 0%, rgba(13, 26, 38, 0.36656381302521013) 35%, rgba(9, 28, 45, 1) 100%), url('./assets/bg1.jpg') no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;*/
    /*background: rgb(12, 22, 42);
  background: linear-gradient(180deg, rgba(12, 22, 42, 1) 11%, rgba(3, 43, 25, 1) 90%);*/
    background-color: rgba(0, 0, 0, 0);
  }

  .boxWrap2StoryR {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    /*background: linear-gradient(180deg, rgba(9, 28, 45, 0.10325849089635852) 0%, rgba(13, 26, 38, 0.36656381302521013) 35%, rgba(9, 28, 45, 1) 100%), url('./assets/bg1.jpg') no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;*/
    /*background: rgb(12, 22, 42);
  background: linear-gradient(180deg, rgba(12, 22, 42, 1) 11%, rgba(3, 43, 25, 1) 90%);*/
    background-color: rgba(0, 0, 0, 0);
    ;
  }

  .boxWrap2StoryT {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-bottom: 2%;
    /*background: linear-gradient(180deg, rgba(9, 28, 45, 0.10325849089635852) 0%, rgba(13, 26, 38, 0.36656381302521013) 35%, rgba(9, 28, 45, 1) 100%), url('./assets/bg1.jpg') no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;*/
    /*background: rgb(12, 22, 42);
  background: linear-gradient(180deg, rgba(12, 22, 42, 1) 11%, rgba(3, 43, 25, 1) 90%);*/
    background-color: rgba(0, 0, 0, 0);
    ;
  }


  .boxWrap2Story2 {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0);

    padding-bottom: 2%;
  }

  .boxWrap2Mint {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-size: cover;
    padding-bottom: 2%;
    z-index: 100000000000;
  }

  .boxWrap2SubR {
    justify-content: space-between;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .boxWrap2Sub1 {}

  .boxWrap2Sub {
    justify-content: space-between;
    width: 98%;
    margin-left: auto;
    margin-right: auto;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.164) 0%, rgba(0, 0, 0, 0.301) 50%, rgba(0, 0, 0, 0.534) 100%), url('https://cdn.discordapp.com/attachments/1028976349920112681/1139504255498195055/NFTHouse9_coins_in_space_cartoon_theme_326f3ecc-8f98-4844-81b1-49ce9a21ba21.png') no-repeat center center fixed;
    background-size: cover;
    padding-top: 10%;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .boxWrap2SubT {
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    background-color: #22e2d9;
  }

  .storyH {
    font-size: 200px;
    color: black;
    font-weight: bolder;
    text-align: center;
    margin-bottom: 10px;
    font-style: normal;
    font-family: 'Exo', sans-serif;
  }

  .outer-div {
    width: 500px;
    height: 100px;
    background-color: #fff;
    transition: background-color 1s ease;
  }

  .storyHK {
    font-size: 80px;
    color: white;
    font-weight: 500;
    text-align: center;
    font-family: "Iomanoid";
    margin-bottom: 2%;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px rgba(255, 255, 0, 0.616), 0 -10px 20px #ff80008c, 0 -18px 40px rgba(255, 0, 0, 0.719);
  }

  .storyH2 {
    font-size: 175px;
    color: rgb(255, 255, 255);
    font-weight: bolder;
    text-align: center;
    margin-bottom: 2%;
    font-family: 'Exo', sans-serif;
  }

  .storyH3 {
    font-size: 100px;
    color: rgb(255, 255, 255);
    font-weight: bolder;
    text-align: center;
    margin-bottom: 2%;
    font-family: 'Exo', sans-serif;
    background: rgb(88, 101, 242);
    background: linear-gradient(180deg, rgb(36, 51, 219) 12%, rgb(67, 3, 185) 100%);
    width: 700px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
    cursor: pointer;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    margin-top: -15%;
  }

  .storyH3:hover {
    background: rgb(38, 47, 222);
    background: linear-gradient(180deg, rgba(38, 47, 222, 1) 12%, rgba(176, 68, 242, 1) 100%);
  }

  /* Animation */
  @-webkit-keyframes fill-div {
    from {
      width: 0;
    }

    to {
      width: 100%;
    }
  }
  @keyframes fill-div {
    from {
      width: 0;
    }

    to {
      width: 100%;
    }
  }

  .bg {
    background-color: #17237e;
    padding-left: 50px;
    padding-right: 50px;
  }

  .storyH2-2 {
    font-size: 50px;
    color: white;
    font-weight: 500;
    text-align: right;
    font-family: "Iomanoid";
    margin-bottom: 10px;
    text-shadow: 0 0 10px #ce9381;
  }

  .about {
    color: white;
    font-size: 18px;
    text-align: left;
    display: flex;
    justify-content: space-evenly;
    margin-left: auto;
    margin-right: auto;
  }

  .aboutKingdom {
    color: white;
    font-size: 18px;
    text-align: left;
    display: flex;
    justify-content: space-evenly;
  }

  .kingdomsSection {
    color: white;
    font-size: 18px;
    text-align: left;
    display: flex;
    justify-content: space-evenly;
    flex-flow: column nowrap;
  }

  .aboutP {
    width: 50%;
    margin-bottom: 50px;
    margin-top: 30px;
    margin-right: auto;
    justify-content: center;
  }

  .aboutPNew {
    width: 50%;
    margin-bottom: 50px;
    margin-top: 30px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
  }

  .aboutPS {
    width: 45%;
    margin-bottom: 50px;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .aboutP3 {
    width: 60%;
    margin-top: auto;
    margin-bottom: auto;
    margin-bottom: 50px;
  }

  .aboutP3 p {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 23px;
  }

  .aboutP p {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 22px;
  }

  .aboutPNew p {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 23px;
  }

  .aboutPDiv {
    background: rgb(101, 161, 90);
    background: linear-gradient(93deg, rgba(101, 161, 90, 1) 0%, rgba(32, 71, 128, 1) 50%);
    -webkit-clip-path: polygon(0 0, 100% 0%, 98% 100%, 0% 100%);
            clip-path: polygon(0 0, 100% 0%, 98% 100%, 0% 100%);
    width: 900px;
    margin-bottom: 12px;
    margin-top: 12px;
    padding: 20px;
    font-size: 20px;
    line-height: 40px;
  }

  .aboutPDiv2 {
    background-color: #2a32438a;
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
    -webkit-clip-path: polygon(0 0, 100% 0%, 98% 100%, 0% 100%);
            clip-path: polygon(0 0, 100% 0%, 98% 100%, 0% 100%);
    margin-bottom: 20px;
    margin-top: 20px;
    padding: 20px;
    font-size: 30px;
    line-height: 40px;
    color: white;
  }

  .hl {
    color: #1ee03e;
  }


  .bigLetters {
    font-size: 45px;
  }

  .ironfist {
    margin-top: auto;
    margin-left: -40px;
    z-index: 100000;
  }

  .ironfist img {
    width: 80px;
    height: 80px;

  }

  .storyConAndImg {
    display: flex;
    justify-content: center;
  }

  .aboutP2 {
    width: 50%;
    margin-top: auto;
    margin-bottom: auto;
  }

  .zeus {
    width: 45%;
    height: 45%;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    -webkit-animation: floating 3s ease-in-out infinite;
            animation: floating 3s ease-in-out infinite;
  }

  .zeus2 {
    width: 35%;
    height: 35%;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;

  }

  @-webkit-keyframes floating {

    0%,
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    50% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
    }
  }

  @keyframes floating {

    0%,
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    50% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
    }
  }

  .logos {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
  }

  .logos_mobile {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
    display: none;
  }

  .logoF img {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 40%;
    display: flex;
  }


  .facetoface img {
    width: 30%;
  }

  .facetoface {
    display: flex;
    justify-content: space-between;
    margin-top: -27%;
  }

  .footerImg {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: space-evenly;
  }

  .footerImg img {
    margin-left: 5px;
    margin-right: 5px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
  }

  .footerImg img:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    cursor: pointer;
  }

  .footer2 {
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .copyright {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 13px;
  }

  .footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: rgba(102, 102, 102, 0);
    width: 100%;
  }

  .sword {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 10%;
  }

  .devs {
    color: rgba(255, 255, 255, 0.774);
    text-align: center;
    margin-top: 80px;
  }

  .devs img {
    width: 30px;
    height: 30px;
    opacity: 0.9;
    transition: 0.3s ease;
  }

  .devs:hover {
    color: rgb(255, 255, 255);
    cursor: pointer;
  }

  .devs img:hover {
    width: 30px;
    height: 30px;
  }

  a {
    color: #d1fdfb;
    text-decoration: none;
  }

  a:hover {
    color: #99faf5;
    text-decoration: none;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #020529b7;

  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    width: 95%;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    height: 5vh;
  }

  .connect div {
    margin-left: 15px;
    margin-right: 15px;
    color: white;
    margin-top: auto;
    justify-content: space-evenly;
    display: flex;
    cursor: pointer;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    text-shadow: -1px -1px 1px #f899345d, 1px 1px 1px #fe921e6c;

  }

  .connect div:hover {
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
  }

  .icons {
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-left: 10px;
  }

  .icons img {
    width: 30px;
    height: 30px;
    margin-left: 10px;
    margin-right: 10px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    z-index: 10000000;
  }

  .icons img:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    cursor: pointer;
  }

  #fontSize {
    font-size: 18px;
    z-index: 10000000;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    align-items: center;

  }

  #fontSize-2 {
    font-size: 25px;
    z-index: 10000000;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    align-items: center;
  }

  /* CSS */
  .button-92 {
    --c: #fff;
    /* text color */
    background: linear-gradient(90deg, #0000 33%, #fff5, #0000 67%) 100%/300% no-repeat,
      #009218;
    background: linear-gradient(90deg, #0000 33%, #fff5, #0000 67%) var(--_p, 100%)/300% no-repeat,
      #009218;
    /* background color */
    color: #0000;
    border: none;
    -webkit-transform: perspective(500px) rotateY(calc(20deg * -1));
            transform: perspective(500px) rotateY(calc(20deg * -1));
    -webkit-transform: perspective(500px) rotateY(calc(20deg * var(--_i, -1)));
            transform: perspective(500px) rotateY(calc(20deg * var(--_i, -1)));
    text-shadow: calc(-1 * 0.08em) -.01em 0 var(--c),
      calc(-1 * -0.08em) .01em 2px #0004;
    text-shadow: calc(var(--_i, -1) * 0.08em) -.01em 0 var(--c),
      calc(var(--_i, -1) * -0.08em) .01em 2px #0004;
    outline-offset: .1em;
    transition: 0.3s;
  }

  .button-92:hover,
  .button-92:focus-visible {
    --_p: 0%;
    --_i: 1;
  }

  .button-92:active {
    text-shadow: none;
    color: var(--c);
    box-shadow: inset 0 0 9e9q #0005;
    transition: 0s;
  }

  .button-92 {
    font-weight: bold;
    font-size: 1.8rem;
    margin: 0;
    cursor: pointer;
    padding: .1em .3em;
  }

  .title {
    color: white;
    text-shadow: -1px -1px 1px #f899345d, 1px 1px 1px #fe921e6c;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: center;
    font-size: 35px;
  }

  .logoUp {
    width: 50px;
    height: 50px;
    margin-top: -8px;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;
  }

  /* CSS */
  .button-62 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    background-color: #bb5a3c;
    font-size: 18px;
    padding: 13px;
    width: 200px;
    color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    transition: 0.3s ease;
    border-radius: 7px;
    border: none;
    font-weight: 500;
  }

  .button-62:hover {
    color: rgb(219, 219, 219);
  }

  .logo2 img {
    width: 75px;
    height: 75px;
  }

  .downImg {
    width: 35px;
    -webkit-animation: arrow_down_animation 2s ease-in-out infinite;
            animation: arrow_down_animation 2s ease-in-out infinite;
    cursor: pointer;
  }


  @-webkit-keyframes arrow_down_animation {

    0% {
      -webkit-transform: translate3d(0, 0, 0) scale(1, 1);
              transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;
    }

    30% {
      opacity: 1;
    }

    100% {
      -webkit-transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
              transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;

    }

  }


  @keyframes arrow_down_animation {

    0% {
      -webkit-transform: translate3d(0, 0, 0) scale(1, 1);
              transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;
    }

    30% {
      opacity: 1;
    }

    100% {
      -webkit-transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
              transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;

    }

  }

  .downImgDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -25px;

  }

  .down {
    text-align: center;
    background-color: rgba(0, 0, 0, 0);
  }

  .stickyBtn2 {
    width: 65px;
    height: 65px;
    z-index: 1;
  }

  #sticky-button {
    position: -webkit-sticky;
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 15%;
    margin-left: 92%;
    margin-top: -8%;
    cursor: pointer;
    transition: -webkit-transform .4s;
    transition: transform .4s;
    transition: transform .4s, -webkit-transform .4s;
    z-index: 1;

  }

  .stickyBtn2:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    opacity: 100%;
    z-index: 1;

  }

  .clouds2 {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  .cloud {
    position: absolute;
    top: 0;

  }

  .cloud1 {
    left: 10vw;

  }

  .cloud1 img {
    width: 120%;
    margin-top: -13%;
  }

  .cloud2 img {
    width: 70%;
    margin-top: -8%;
  }

  .cloud3 img {
    width: 100%;

  }

  .cloud1-1 {
    left: 70vw;
    -webkit-animation-name: move-clouds;
            animation-name: move-clouds;
    -webkit-animation-duration: 600s;
            animation-duration: 600s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
  }

  .cloud2 {
    left: 5px;
    -webkit-animation-name: move-clouds;
            animation-name: move-clouds;
    -webkit-animation-duration: 200s;
            animation-duration: 200s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
  }

  .cloud3 {
    left: 3px;

  }

  .cloud3-3 {
    left: 10px;
    -webkit-animation-name: move-clouds;
            animation-name: move-clouds;
    -webkit-animation-duration: 480s;
            animation-duration: 480s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
  }

  .cloud4 {
    left: 10vw;
  }

  .cloud4 img {
    width: 70%;
    margin-top: -5%;
    margin-left: -5%;
  }

  .cloud3 img {
    width: 25%;
  }

  @-webkit-keyframes move-clouds {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(110vw);
              transform: translateX(110vw);
    }
  }

  @keyframes move-clouds {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(110vw);
              transform: translateX(110vw);
    }
  }

  .cloudsLine img {
    width: 100%;
    display: flex;
  }

  .Q {
    font-size: 30px;
    color: white;
    font-weight: 500;
    margin-bottom: 10px;
    text-shadow: 0 0 10px #ecc46d;
  }

  .rmCon {
    color: white;
    font-size: 18px;
  }

  .rmConAndImg {
    margin-bottom: 30px;
    margin-top: 30px;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .rmCon img {
    width: 30px;
    height: 30px;
  }

  .line {
    width: 100%;
  }

  .connect2-2 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    box-shadow: rgba(0, 0, 0, 0.493) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    border: 12px #7f8082;

    border: #ffa528 5px groove;
  }

  .parrot {
    width: 70%;
  }

  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .totalSupply {
    text-align: center;
    font-size: 60px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(255, 255, 255);
    letter-spacing: 1px;
    margin-top: 1%;
  }

  .price {
    text-align: center;
    font-size: 25px;
    color: white;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }


  .center {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
    z-index: 10000000;

  }

  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: none;

    cursor: pointer;
    background-color: #6cfc1900;
    color: rgb(255, 255, 255);
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: 2px solid rgb(255, 255, 255);
    z-index: 1000000000000000;

  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: none;

    cursor: pointer;
    border: 2px solid rgb(255, 255, 255);
    color: rgb(255, 255, 255);
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    background-color: #00000000;
    z-index: 1000000000000000;

  }


  .btnfos-0-2:hover:active {
    background-color: #0e2942;
  }


  .btnfos-0-2-2:hover:active {
    background-color: #0e2942;
  }


  .btnfos-0-3 {
    margin-top: 20px;
    background: rgb(0, 120, 235);
    background: linear-gradient(180deg, rgba(0, 120, 235, 1) 0%, rgba(89, 154, 215, 1) 50%, rgba(0, 120, 235, 1) 100%);
    color: #ffffff;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 28px;
    padding-left: 30px;
    padding-right: 30px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    border: 6px rgb(0, 0, 0);
    border-style: double;
    z-index: 1000000000000000;
    transition: transform .2s;
  }

  .btnfos-0-3:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

  .nftamount {
    color: #ecaa2f;
    font-size: 85px;
  }

  .mintbuttondiv {
    text-align: center;
    z-index: 10000000;

  }

  .successfully {
    text-align: center;
    margin-top: 3%;
    color: #1ee03e;
    font-size: 30px;
    text-shadow: 0 0 20px #9bf179;
  }

  .errorMessage {
    margin-top: 3%;
    font-size: 20px;
    color: #fc4741;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 3%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .loadingText {
    font-size: 20px;
    text-align: center;
    color: rgb(240, 161, 97);
  }

  .loadTextSub {
    text-align: center;
    color: rgba(255, 255, 255, 0.815);
    font-size: 16px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    -webkit-animation: dots 1s steps(5, end) infinite;
            animation: dots 1s steps(5, end) infinite;
  }

  @-webkit-keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(214, 80, 71);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(209, 119, 45),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(214, 80, 71);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(209, 119, 45),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .rm2Box {
    display: flex;
    justify-content: center;
    margin-right: auto;

  }

  .rm3Box {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-top: 100px;
    margin-bottom: 100px;
  }

  .rmbox1 {
    -webkit-clip-path: polygon(0 0, 96% 0, 100% 100%, 0% 100%);
            clip-path: polygon(0 0, 96% 0, 100% 100%, 0% 100%);
    font-size: 45px;
    background-color: #000000a1;
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
    color: rgb(255, 255, 255);
    width: 400px;
    padding: 30px;
    text-align: center;

  }

  .rmbox2 {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 4% 100%);
            clip-path: polygon(0 0, 100% 0, 100% 100%, 4% 100%);
    font-size: 35px;
    background-color: #007906c2;
    color: white;
    width: 400px;
    padding: 25px;
    text-align: center;
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .rmbox1-3 {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 4% 100%);
            clip-path: polygon(0 0, 100% 0, 100% 100%, 4% 100%);
    text-align: center;
    font-size: 45px;
    background-color: #000000a1;

    -webkit-backdrop-filter: blur(5px);

            backdrop-filter: blur(5px);
    color: rgb(255, 255, 255);
    width: 400px;
    padding: 30px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }


  .rmbox2-3 {
    font-size: 35px;
    -webkit-clip-path: polygon(0 0, 96% 0, 100% 100%, 0% 100%);
            clip-path: polygon(0 0, 96% 0, 100% 100%, 0% 100%);
    text-align: center;
    background-color: #007906c2;
    color: white;
    width: 400px;
    padding: 30px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
    text-align: center;

  }

  .rmConMain {
    display: flex;
    justify-content: space-between;
    flex-flow: column nowrap;
    margin-top: 80px;
  }

  .scroll-effect-container {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    margin-bottom: 30px;

  }

  .scroll-effect {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: #fff;
    text-align: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding-left: 50px;
    padding-right: 50px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

  }

  .scroll-effect-relic {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: #fff;
    text-align: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding-left: 50px;
    padding-right: 50px;

  }

  .scroll-effect-mint {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: #fff;
    text-align: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding-left: 50px;
    padding-right: 50px;

  }

  .scroll-effect-tokenomics {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: #fff;
    text-align: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding-left: 50px;
    padding-right: 50px;

  }

  .scroll-effect-footer {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: #fff;
    text-align: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding-left: 50px;
    padding-right: 50px;

  }


  .scroll-effect-rm {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: #fff;
    text-align: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding-left: 50px;
    padding-right: 50px;

  }


  .scroll-effect-footer {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: #fff;
    text-align: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding-left: 50px;
    padding-right: 50px;
  }

  @-webkit-keyframes fill-effect {
    from {
      width: 0%;
    }

    to {
      width: 100%;
    }
  }

  @keyframes fill-effect {
    from {
      width: 0%;
    }

    to {
      width: 100%;
    }
  }

  .animate {
    /*background: rgb(87, 102, 244);
  background: linear-gradient(270deg, rgba(87, 102, 244, 1) 0%, rgba(36, 46, 167, 1) 51%);*/
    background-color: #000000a1;
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
    background-repeat: no-repeat;
    background-size: 0% 100%;
    transition: background-size 1s ease-in-out;
    -webkit-animation: fill-effect 1s ease-in-out;
            animation: fill-effect 1s ease-in-out;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }

  .animate.animate {
    background-size: 100% 100%;

  }

  /*.animateC{
  background: rgb(244, 87, 179);
  background: linear-gradient(270deg, rgb(238, 77, 190) 0%, rgb(139, 8, 89) 51%);  background-repeat: no-repeat;
  background-size: 0% 100%;
  transition: background-size 0.5s ease-in-out;
  animation: fill-effect 0.5s ease-in-out;
  font-style: italic;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}*/

  .animateC.animateC {
    background-size: 100% 100%;

  }

  /* Define the walkingNft class */
  .walkingNft {
    width: 30%;
    position: absolute;
    bottom: -0.8%;
    left: 0;
    -webkit-animation: walk 20s linear infinite;
            animation: walk 20s linear infinite;

  }

  /* Define the walk animation */
  @-webkit-keyframes walk {
    0% {
      -webkit-transform: translateX(-5vw);
              transform: translateX(-5vw);
    }

    10% {
      -webkit-transform: translateX(10vw) rotate(6deg);
              transform: translateX(10vw) rotate(6deg);
    }

    20% {
      -webkit-transform: translateX(20vw) rotate(0deg);
              transform: translateX(20vw) rotate(0deg);
    }

    30% {
      -webkit-transform: translateX(30vw) rotate(6deg);
              transform: translateX(30vw) rotate(6deg);
    }

    40% {
      -webkit-transform: translateX(40vw) rotate(0deg);
              transform: translateX(40vw) rotate(0deg);
    }

    50% {
      -webkit-transform: translateX(50vw) rotate(6deg);
              transform: translateX(50vw) rotate(6deg);
    }

    60% {
      -webkit-transform: translateX(60vw) rotate(0deg);
              transform: translateX(60vw) rotate(0deg);
    }

    70% {
      -webkit-transform: translateX(70vw) rotate(6deg);
              transform: translateX(70vw) rotate(6deg);
    }

    80% {
      -webkit-transform: translateX(80vw) rotate(0deg);
              transform: translateX(80vw) rotate(0deg);
    }

    90% {
      -webkit-transform: translateX(90vw) rotate(6deg);
              transform: translateX(90vw) rotate(6deg);
    }

    100% {
      -webkit-transform: translateX(100vw) rotate(0deg);
              transform: translateX(100vw) rotate(0deg);
    }

  }
  @keyframes walk {
    0% {
      -webkit-transform: translateX(-5vw);
              transform: translateX(-5vw);
    }

    10% {
      -webkit-transform: translateX(10vw) rotate(6deg);
              transform: translateX(10vw) rotate(6deg);
    }

    20% {
      -webkit-transform: translateX(20vw) rotate(0deg);
              transform: translateX(20vw) rotate(0deg);
    }

    30% {
      -webkit-transform: translateX(30vw) rotate(6deg);
              transform: translateX(30vw) rotate(6deg);
    }

    40% {
      -webkit-transform: translateX(40vw) rotate(0deg);
              transform: translateX(40vw) rotate(0deg);
    }

    50% {
      -webkit-transform: translateX(50vw) rotate(6deg);
              transform: translateX(50vw) rotate(6deg);
    }

    60% {
      -webkit-transform: translateX(60vw) rotate(0deg);
              transform: translateX(60vw) rotate(0deg);
    }

    70% {
      -webkit-transform: translateX(70vw) rotate(6deg);
              transform: translateX(70vw) rotate(6deg);
    }

    80% {
      -webkit-transform: translateX(80vw) rotate(0deg);
              transform: translateX(80vw) rotate(0deg);
    }

    90% {
      -webkit-transform: translateX(90vw) rotate(6deg);
              transform: translateX(90vw) rotate(6deg);
    }

    100% {
      -webkit-transform: translateX(100vw) rotate(0deg);
              transform: translateX(100vw) rotate(0deg);
    }

  }


  .images2 {
    display: flex;
    justify-content: space-between;
  }

  .images2 img {
    width: 40%;
    margin-top: -22%;
    z-index: 10000;
  }



  * {
    box-sizing: border-box;
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 5rem;
    background-image: url(/static/media/ticker.81e610af.png);
    background-size: contain;
    padding-left: 100%;
    z-index: 20000000000000000;
    margin-top: -3.5px;
  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 5rem;
    line-height: 5rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 30s;
    animation-duration: 50s;
    z-index: 1000000000000;

  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 35px;
    font-weight: 800;
    color: white;
    z-index: 1000000000000;

  }

  .line {
    background-color: rgb(255, 255, 255);
    height: 20vh;
    width: 120vw;
    border-bottom: 12px solid rgb(0, 113, 189);
    border-top: 12px solid rgb(0, 113, 189);
    rotate: 10deg;
    margin-left: -50px;
    display: flex;
    justify-content: space-between;
    margin-top: 15%;
  }

  .line2 {
    background-color: rgb(255, 255, 255);
    height: 20vh;
    width: 120vw;
    border-bottom: 12px solid rgb(0, 113, 189);
    border-top: 12px solid rgb(0, 113, 189);
    rotate: 8deg;
    margin-left: -40px;
    display: flex;
    margin-top: 2%;
    justify-content: space-between;
    margin-bottom: 100px;
  }

  .line2 img {
    width: 50px;
    height: 50px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .line img {
    width: 50px;
    height: 50px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .weapon {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    width: 25%;
    margin-top: -15%;
  }

  .potionBottle {
    width: 23%;
    margin-top: -10%;
    z-index: 100000;
    margin-left: 20px;
  }

  .bawl {
    width: 26%;
    margin-top: -5%;
    z-index: 100000;
    margin-left: 20px;
  }

  .ball {
    width: 9%;
    margin-top: -10%;
    z-index: 100000;
    margin-left: 100px;
    rotate: 15deg;
  }


  @-webkit-keyframes float {
    0% {
      -webkit-transform: translate(0, 0);
              transform: translate(0, 0);
    }

    50% {
      -webkit-transform: translate(0, 20px);
              transform: translate(0, 20px);
    }

    100% {
      -webkit-transform: translate(0, 0);
              transform: translate(0, 0);
    }
  }


  @keyframes float {
    0% {
      -webkit-transform: translate(0, 0);
              transform: translate(0, 0);
    }

    50% {
      -webkit-transform: translate(0, 20px);
              transform: translate(0, 20px);
    }

    100% {
      -webkit-transform: translate(0, 0);
              transform: translate(0, 0);
    }
  }

  .mashroom {
    width: 23%;
    margin-top: -20%;
    z-index: 100000;
    margin-left: 70%;
    rotate: 25deg;
  }

  .lucky {
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
  }

  .lucky img {
    width: 20%;
  }

  @-webkit-keyframes shake {
    0% {
      -webkit-transform: translate(0, 0) rotate(0);
              transform: translate(0, 0) rotate(0);
    }

    10% {
      -webkit-transform: translate(-10px, 0) rotate(-5deg);
              transform: translate(-10px, 0) rotate(-5deg);
    }

    20% {
      -webkit-transform: translate(10px, 0) rotate(5deg);
              transform: translate(10px, 0) rotate(5deg);
    }

    30% {
      -webkit-transform: translate(-10px, 0) rotate(-5deg);
              transform: translate(-10px, 0) rotate(-5deg);
    }

    40% {
      -webkit-transform: translate(10px, 0) rotate(5deg);
              transform: translate(10px, 0) rotate(5deg);
    }

    50% {
      -webkit-transform: translate(-10px, 0) rotate(-5deg);
              transform: translate(-10px, 0) rotate(-5deg);
    }

    60% {
      -webkit-transform: translate(10px, 0) rotate(5deg);
              transform: translate(10px, 0) rotate(5deg);
    }

    70% {
      -webkit-transform: translate(-10px, 0) rotate(-5deg);
              transform: translate(-10px, 0) rotate(-5deg);
    }

    80% {
      -webkit-transform: translate(10px, 0) rotate(5deg);
              transform: translate(10px, 0) rotate(5deg);
    }

    90% {
      -webkit-transform: translate(-10px, 0) rotate(-5deg);
              transform: translate(-10px, 0) rotate(-5deg);
    }

    100% {
      -webkit-transform: translate(0, 0) rotate(0);
              transform: translate(0, 0) rotate(0);
    }
  }

  @keyframes shake {
    0% {
      -webkit-transform: translate(0, 0) rotate(0);
              transform: translate(0, 0) rotate(0);
    }

    10% {
      -webkit-transform: translate(-10px, 0) rotate(-5deg);
              transform: translate(-10px, 0) rotate(-5deg);
    }

    20% {
      -webkit-transform: translate(10px, 0) rotate(5deg);
              transform: translate(10px, 0) rotate(5deg);
    }

    30% {
      -webkit-transform: translate(-10px, 0) rotate(-5deg);
              transform: translate(-10px, 0) rotate(-5deg);
    }

    40% {
      -webkit-transform: translate(10px, 0) rotate(5deg);
              transform: translate(10px, 0) rotate(5deg);
    }

    50% {
      -webkit-transform: translate(-10px, 0) rotate(-5deg);
              transform: translate(-10px, 0) rotate(-5deg);
    }

    60% {
      -webkit-transform: translate(10px, 0) rotate(5deg);
              transform: translate(10px, 0) rotate(5deg);
    }

    70% {
      -webkit-transform: translate(-10px, 0) rotate(-5deg);
              transform: translate(-10px, 0) rotate(-5deg);
    }

    80% {
      -webkit-transform: translate(10px, 0) rotate(5deg);
              transform: translate(10px, 0) rotate(5deg);
    }

    90% {
      -webkit-transform: translate(-10px, 0) rotate(-5deg);
              transform: translate(-10px, 0) rotate(-5deg);
    }

    100% {
      -webkit-transform: translate(0, 0) rotate(0);
              transform: translate(0, 0) rotate(0);
    }
  }

  @-webkit-keyframes zoom {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
      /* Start the animation with normal size image */
    }

    50% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1);
      /* Increase the size of the image halfway through the animation */
    }

    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      /* End the animation with normal size image */
    }
  }

  @keyframes zoom {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
      /* Start the animation with normal size image */
    }

    50% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1);
      /* Increase the size of the image halfway through the animation */
    }

    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      /* End the animation with normal size image */
    }
  }


  .gifImg {
    background-image: url(/static/media/pepe.ae6a97c7.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 50vw;
    height: 50vw;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    background-color: rgba(0, 0, 0, 0);

  }

  .gifImg2 {
    background-image: url(/static/media/182.f2954502.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 50vw;
    height: 50vw;
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }

  .gifImg3 {
    background-image: url(/static/media/pepe5.be18fc31.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 50vw;
    height: 50vw;
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }

  .gifImg4 {
    background-image: url(/static/media/new.3aabb953.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 50vw;
    height: 50vw;
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }

  .gifMain {
    background-color: rgba(0, 0, 0, 0);
    margin-top: -10%;
  }

  .bubble-container {
    flex-flow: column;
    display: flex;
  }

}

@media screen and (min-width: 1920px) {

  .page-loader {
    width: 100%;
    height: 100vh;
    background: #242424;
    z-index: 5000000000000000;
    position: fixed;
  }

  .loading-area {
    display: grid;
    place-items: center;
    height: 100vh;
  }

  .containerLoad {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .h1Load {
    font-size: 1.5rem;
    color: #00ff2a;
  }

  .ring {
    width: 200px;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  .ring::before {
    content: '';
    width: 200px;
    height: 200px;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
    -webkit-animation: ring 2s linear infinite;
            animation: ring 2s linear infinite;
  }

  @-webkit-keyframes ring {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
      box-shadow: 0px 5px 5px #008afa;
    }

    25% {
      -webkit-transform: rotate(90deg);
              transform: rotate(90deg);
      box-shadow: 0px 5px 5px #02b7ff;
    }

    50% {
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg);
      box-shadow: 0px 5px 5px #0151ff;
    }

    75% {
      -webkit-transform: rotate(270deg);
              transform: rotate(270deg);
      box-shadow: 0px 5px 5px #367de7;
    }

    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
      box-shadow: 0px 5px 5px #008afa;
    }
  }

  @keyframes ring {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
      box-shadow: 0px 5px 5px #008afa;
    }

    25% {
      -webkit-transform: rotate(90deg);
              transform: rotate(90deg);
      box-shadow: 0px 5px 5px #02b7ff;
    }

    50% {
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg);
      box-shadow: 0px 5px 5px #0151ff;
    }

    75% {
      -webkit-transform: rotate(270deg);
              transform: rotate(270deg);
      box-shadow: 0px 5px 5px #367de7;
    }

    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
      box-shadow: 0px 5px 5px #008afa;
    }
  }

  .rmConMainMob {
    display: none;
  }

  .audioPic {
    /* display: none;*/
    width: 100px;
    height: 100px;
    position: sticky;
    position: -webkit-sticky;
    left: 0;
    bottom: 10%;
    z-index: 1000;
  }


  .rmbg {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-bottom: 2%;
    background-color: rgba(0, 0, 0, 0);

  }

  .rmbg2 {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-top: 10%;
    padding-bottom: 2%;
    background: url(/static/media/rmbg.6ed0fbcc.jpg) no-repeat center center fixed;
    background-size: cover;
    width: 97%;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .placeAndWL {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    z-index: 100000000;

  }

  input {
    background-color: rgba(0, 30, 70, 0.568);
    border: white 1px solid;
    font-size: 20px;
    padding: 15px;
    color: #00d6fc;
    z-index: 100000000;
  }

  input:focus {
    background-color: #b3e9ff00;
    color: #00d6fc;
  }

  #wlBtn {
    background-color: rgba(0, 30, 70, 0.568);
    border: 1px solid rgb(16, 192, 223);
    color: #ffffff;
    width: 200px;
    z-index: 100000000;
  }

  #wlBtn:hover {
    font-size: 18px;
  }

  .wlLine {
    background-color: #000000;
    padding: 50px;
  }


  .cloudsMob {
    display: none;
  }

  .connectMob {
    display: none;
  }

  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-top: 100px;
    padding-bottom: 100px;
    background: rgb(52, 52, 52);
    background: linear-gradient(180deg, rgba(52, 52, 52, 1) 12%, rgba(0, 0, 0, 1) 100%);
  }

  .littleNav {
    display: none;
  }

  .miniBarMain {
    display: none;
  }

  .logosMob {
    display: none;
  }

  .allWrap {
    background: rgb(49,89,63);
background: radial-gradient(circle, rgba(49,89,63,1) 0%, rgba(52,74,61,1) 50%, rgba(35,51,41,1) 100%);
    height: 100vh;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    overflow: hidden;
  }

  .cont {
    background: radial-gradient(circle, rgba(0, 0, 0, 0.06684392507002801) 0%, rgba(0, 0, 0, 0.11166185224089631) 50%, rgba(0, 0, 0, 0.3693649334733894) 100%), url(/static/media/BG.b24e41c2.jpg) no-repeat center center fixed;
    background-size: cover;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;

  }




  .in2 p {
    font-size: 28px;
    text-align: center;
    font-family: "Iomanoid";
    letter-spacing: 4px;
    text-shadow: 0 0 10px #ffdc64;
    color: #ffdc64;

  }

  .in2 {
    z-index: 100000000;

  }

  @-webkit-keyframes heartbeat {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }

    50% {
      -webkit-transform: scale(0.9);
              transform: scale(0.9);
    }

    60% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1);
    }

    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }

  @keyframes heartbeat {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }

    50% {
      -webkit-transform: scale(0.9);
              transform: scale(0.9);
    }

    60% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1);
    }

    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }

  .logo2Intro {
    z-index: 200000000000000000000000000000000;
    width: 40%;
    padding-top: 27.9vh;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    -webkit-animation: heartbeat 2s ease-in-out infinite;
            animation: heartbeat 2s ease-in-out infinite;
  }

  .in2 img {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 45%;
    padding-top: 12vh;
    transition: -webkit-transform 0.8s;
    transition: transform 0.8s;
    transition: transform 0.8s, -webkit-transform 0.8s;
    z-index: 100000000;
  }

  .in2 img:hover {
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
  }


  .introduction {
    font-family: "Iomanoid";
  }

  .twitter img {
    width: 50px;
    height: 50px;
    margin-left: 30px;
    cursor: pointer;
    opacity: 0.6;
    transition: 0.2s ease;
    margin-top: -3%;
    z-index: 200000000000;

  }

  .twitter {
    z-index: 200000000000;
  }

  .twitter img:hover {
    opacity: 100%;
    z-index: 200000000000;

  }

  .boxWrap2Story {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-top: 5%;
    padding-bottom: 2%;
    /*background: linear-gradient(180deg, rgba(9, 28, 45, 0.10325849089635852) 0%, rgba(13, 26, 38, 0.36656381302521013) 35%, rgba(9, 28, 45, 1) 100%), url('./assets/bg1.jpg') no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;*/
    /*background: rgb(12, 22, 42);
    background: linear-gradient(180deg, rgba(12, 22, 42, 1) 11%, rgba(3, 43, 25, 1) 90%);*/
    background-color: rgba(0, 0, 0, 0);
  }

  .boxWrap2StoryR {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    /*background: linear-gradient(180deg, rgba(9, 28, 45, 0.10325849089635852) 0%, rgba(13, 26, 38, 0.36656381302521013) 35%, rgba(9, 28, 45, 1) 100%), url('./assets/bg1.jpg') no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;*/
    /*background: rgb(12, 22, 42);
    background: linear-gradient(180deg, rgba(12, 22, 42, 1) 11%, rgba(3, 43, 25, 1) 90%);*/
    background-color: rgba(0, 0, 0, 0);
    ;
  }

  .boxWrap2StoryT {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-bottom: 2%;
    /*background: linear-gradient(180deg, rgba(9, 28, 45, 0.10325849089635852) 0%, rgba(13, 26, 38, 0.36656381302521013) 35%, rgba(9, 28, 45, 1) 100%), url('./assets/bg1.jpg') no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;*/
    /*background: rgb(12, 22, 42);
    background: linear-gradient(180deg, rgba(12, 22, 42, 1) 11%, rgba(3, 43, 25, 1) 90%);*/
    background-color: rgba(0, 0, 0, 0);
    ;
  }


  .boxWrap2Story2 {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0);

    padding-bottom: 2%;
  }

  .boxWrap2Mint {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-size: cover;
    padding-bottom: 2%;
    z-index: 100000000000;
  }

  .boxWrap2SubR {
    justify-content: space-between;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .boxWrap2Sub1 {}

  .boxWrap2Sub {
    justify-content: space-between;
    width: 98%;
    margin-left: auto;
    margin-right: auto;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.164) 0%, rgba(0, 0, 0, 0.301) 50%, rgba(0, 0, 0, 0.534) 100%), url('https://cdn.discordapp.com/attachments/1028976349920112681/1139504255498195055/NFTHouse9_coins_in_space_cartoon_theme_326f3ecc-8f98-4844-81b1-49ce9a21ba21.png') no-repeat center center fixed;
    background-size: cover;
    padding-top: 10%;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }



  .boxWrap2SubT {
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    background-color: #22e2d9;
  }

  .storyH {
    font-size: 200px;
    color: black;
    font-weight: bolder;
    text-align: center;
    margin-bottom: 10px;
    font-style: normal;
    font-family: 'Exo', sans-serif;
  }

  .outer-div {
    width: 500px;
    height: 100px;
    background-color: #fff;
    transition: background-color 1s ease;
  }

  .storyHK {
    font-size: 80px;
    color: white;
    font-weight: 500;
    text-align: center;
    font-family: "Iomanoid";
    margin-bottom: 2%;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px rgba(255, 255, 0, 0.616), 0 -10px 20px #ff80008c, 0 -18px 40px rgba(255, 0, 0, 0.719);
  }

  .storyH2 {
    font-size: 270px;
    color: rgb(255, 255, 255);
    font-weight: bolder;
    text-align: center;
    margin-bottom: 2%;
    font-family: 'Exo', sans-serif;
  }

  .storyH3 {
    font-size: 100px;
    color: rgb(255, 255, 255);
    font-weight: bolder;
    text-align: center;
    margin-bottom: 2%;
    font-family: 'Exo', sans-serif;
    background: rgb(88, 101, 242);
    background: linear-gradient(180deg, rgb(36, 51, 219) 12%, rgb(67, 3, 185) 100%);
    width: 700px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
    cursor: pointer;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    margin-top: -15%;
  }

  .storyH3:hover {
    background: rgb(38, 47, 222);
    background: linear-gradient(180deg, rgba(38, 47, 222, 1) 12%, rgba(176, 68, 242, 1) 100%);
  }

  /* Animation */
  @-webkit-keyframes fill-div {
    from {
      width: 0;
    }

    to {
      width: 100%;
    }
  }
  @keyframes fill-div {
    from {
      width: 0;
    }

    to {
      width: 100%;
    }
  }

  .bg {
    background-color: #17237e;
    padding-left: 50px;
    padding-right: 50px;
  }

  .storyH2-2 {
    font-size: 50px;
    color: white;
    font-weight: 500;
    text-align: right;
    font-family: "Iomanoid";
    margin-bottom: 10px;
    text-shadow: 0 0 10px #ce9381;
  }

  .about {
    color: white;
    font-size: 18px;
    text-align: left;
    display: flex;
    justify-content: space-evenly;
    margin-left: auto;
    margin-right: auto;
  }

  .aboutKingdom {
    color: white;
    font-size: 18px;
    text-align: left;
    display: flex;
    justify-content: space-evenly;
  }

  .kingdomsSection {
    color: white;
    font-size: 18px;
    text-align: left;
    display: flex;
    justify-content: space-evenly;
    flex-flow: column nowrap;
  }

  .aboutP {
    width: 50%;
    margin-bottom: 50px;
    margin-top: 30px;
    margin-right: auto;
    justify-content: center;
  }

  .aboutPNew {
    width: 50%;
    margin-bottom: 50px;
    margin-top: 30px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
  }

  .aboutPS {
    width: 45%;
    margin-bottom: 50px;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .aboutP3 {
    width: 60%;
    margin-top: auto;
    margin-bottom: auto;
    margin-bottom: 50px;
  }

  .aboutP3 p {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 23px;
  }

  .aboutP p {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 22px;
  }

  .aboutPNew p {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 35px;
  }

  .aboutPDiv {
    background: rgb(101, 161, 90);
    background: linear-gradient(93deg, rgba(101, 161, 90, 1) 0%, rgba(32, 71, 128, 1) 50%);
    -webkit-clip-path: polygon(0 0, 100% 0%, 98% 100%, 0% 100%);
            clip-path: polygon(0 0, 100% 0%, 98% 100%, 0% 100%);
    width: 900px;
    margin-bottom: 16px;
    margin-top: 16px;
    padding: 20px;
    font-size: 30px;
    line-height: 60px;
  }

  .aboutPDiv2 {
    background-color: #2a32438a;
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
    -webkit-clip-path: polygon(0 0, 100% 0%, 98% 100%, 0% 100%);
            clip-path: polygon(0 0, 100% 0%, 98% 100%, 0% 100%);
    margin-bottom: 40px;
    margin-top: 40px;
    padding: 30px;
    font-size: 45px;
    line-height: 40px;
    color: white;
  }

  .hl {
    color: #1ee03e;
  }


  .bigLetters {
    font-size: 65px;
  }

  .ironfist {
    margin-top: auto;
    margin-left: -40px;
    z-index: 100000;
  }

  .ironfist img {
    width: 110px;
    height: 110px;

  }

  .storyConAndImg {
    display: flex;
    justify-content: center;
  }

  .aboutP2 {
    width: 50%;
    margin-top: auto;
    margin-bottom: auto;
  }

  .zeus {
    width: 45%;
    height: 45%;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    -webkit-animation: floating 3s ease-in-out infinite;
            animation: floating 3s ease-in-out infinite;
  }

  .zeus2 {
    width: 35%;
    height: 35%;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;

  }

  @-webkit-keyframes floating {

    0%,
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    50% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
    }
  }

  @keyframes floating {

    0%,
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    50% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
    }
  }

  .logos {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
  }

  .logos_mobile {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
    display: none;
  }

  .logoF img {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 40%;
    display: flex;
  }


  .facetoface img {
    width: 30%;
  }

  .facetoface {
    display: flex;
    justify-content: space-between;
    margin-top: -27%;
  }

  .footerImg {
    width: 28px;
    height: 28px;
    display: flex;
    justify-content: space-evenly;
  }

  .footerImg img {
    margin-left: 5px;
    margin-right: 5px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
  }

  .footerImg img:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    cursor: pointer;
  }

  .footer2 {
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }


  .copyright {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 18px;
  }

  .footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: rgba(102, 102, 102, 0);
    width: 100%;
  }

  .sword {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 10%;
  }

  .devs {
    color: rgba(255, 255, 255, 0.774);
    text-align: center;
    margin-top: 80px;
  }

  .devs img {
    width: 30px;
    height: 30px;
    opacity: 0.9;
    transition: 0.3s ease;
  }

  .devs:hover {
    color: rgb(255, 255, 255);
    cursor: pointer;
  }

  .devs img:hover {
    width: 30px;
    height: 30px;
  }

  a {
    color: #d1fdfb;
    text-decoration: none;
  }

  a:hover {
    color: #99faf5;
    text-decoration: none;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-bottom: 25px;
    padding-top: 25px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #020529b7;

  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    width: 95%;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    height: 5vh;
  }

  .connect div {
    margin-left: 25px;
    margin-right: 25px;
    color: white;
    margin-top: auto;
    justify-content: space-evenly;
    display: flex;
    cursor: pointer;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    text-shadow: -1px -1px 1px #f899345d, 1px 1px 1px #fe921e6c;

  }

  .connect div:hover {
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
  }

  .icons {
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-left: 10px;
  }

  .icons img {
    width: 45px;
    height: 45px;
    margin-left: 15px;
    margin-right: 15px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    z-index: 10000000;
  }

  .icons img:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    cursor: pointer;
  }

  #fontSize {
    font-size: 28px;
    z-index: 10000000;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    align-items: center;

  }

  #fontSize-2 {
    font-size: 25px;
    z-index: 10000000;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    align-items: center;
  }

  /* CSS */
  .button-92 {
    --c: #fff;
    /* text color */
    background: linear-gradient(90deg, #0000 33%, #fff5, #0000 67%) 100%/300% no-repeat,
      #009218;
    background: linear-gradient(90deg, #0000 33%, #fff5, #0000 67%) var(--_p, 100%)/300% no-repeat,
      #009218;
    /* background color */
    color: #0000;
    border: none;
    -webkit-transform: perspective(500px) rotateY(calc(20deg * -1));
            transform: perspective(500px) rotateY(calc(20deg * -1));
    -webkit-transform: perspective(500px) rotateY(calc(20deg * var(--_i, -1)));
            transform: perspective(500px) rotateY(calc(20deg * var(--_i, -1)));
    text-shadow: calc(-1 * 0.08em) -.01em 0 var(--c),
      calc(-1 * -0.08em) .01em 2px #0004;
    text-shadow: calc(var(--_i, -1) * 0.08em) -.01em 0 var(--c),
      calc(var(--_i, -1) * -0.08em) .01em 2px #0004;
    outline-offset: .1em;
    transition: 0.3s;
  }

  .button-92:hover,
  .button-92:focus-visible {
    --_p: 0%;
    --_i: 1;
  }

  .button-92:active {
    text-shadow: none;
    color: var(--c);
    box-shadow: inset 0 0 9e9q #0005;
    transition: 0s;
  }

  .button-92 {
    font-weight: bold;
    font-size: 2.8rem;
    margin: 0;
    cursor: pointer;
    padding: .1em .3em;
  }

  .title {
    color: white;
    text-shadow: -1px -1px 1px #f899345d, 1px 1px 1px #fe921e6c;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: center;
    font-size: 50px;
  }

  .logoUp {
    width: 70px;
    height: 70px;
    margin-top: -10px;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;
  }

  /* CSS */
  .button-62 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    background-color: #bb5a3c;
    font-size: 18px;
    padding: 13px;
    width: 200px;
    color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    transition: 0.3s ease;
    border-radius: 7px;
    border: none;
    font-weight: 500;
  }

  .button-62:hover {
    color: rgb(219, 219, 219);
  }

  .logo2 img {
    width: 75px;
    height: 75px;
  }

  .downImg {
    width: 35px;
    -webkit-animation: arrow_down_animation 2s ease-in-out infinite;
            animation: arrow_down_animation 2s ease-in-out infinite;
    cursor: pointer;
  }


  @-webkit-keyframes arrow_down_animation {

    0% {
      -webkit-transform: translate3d(0, 0, 0) scale(1, 1);
              transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;
    }

    30% {
      opacity: 1;
    }

    100% {
      -webkit-transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
              transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;

    }

  }


  @keyframes arrow_down_animation {

    0% {
      -webkit-transform: translate3d(0, 0, 0) scale(1, 1);
              transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;
    }

    30% {
      opacity: 1;
    }

    100% {
      -webkit-transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
              transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;

    }

  }

  .downImgDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -25px;

  }

  .down {
    text-align: center;
    background-color: rgba(0, 0, 0, 0);
  }

  .stickyBtn2 {
    width: 65px;
    height: 65px;
    z-index: 1;
  }

  #sticky-button {
    position: -webkit-sticky;
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 15%;
    margin-left: 92%;
    margin-top: -8%;
    cursor: pointer;
    transition: -webkit-transform .4s;
    transition: transform .4s;
    transition: transform .4s, -webkit-transform .4s;
    z-index: 1;

  }

  .stickyBtn2:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    opacity: 100%;
    z-index: 1;

  }

  .clouds2 {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  .cloud {
    position: absolute;
    top: 0;

  }

  .cloud1 {
    left: 10vw;

  }

  .cloud1 img {
    width: 120%;
    margin-top: -13%;
  }

  .cloud2 img {
    width: 70%;
    margin-top: -8%;
  }

  .cloud3 img {
    width: 100%;

  }

  .cloud1-1 {
    left: 70vw;
    -webkit-animation-name: move-clouds;
            animation-name: move-clouds;
    -webkit-animation-duration: 600s;
            animation-duration: 600s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
  }

  .cloud2 {
    left: 5px;
    -webkit-animation-name: move-clouds;
            animation-name: move-clouds;
    -webkit-animation-duration: 200s;
            animation-duration: 200s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
  }

  .cloud3 {
    left: 3px;

  }

  .cloud3-3 {
    left: 10px;
    -webkit-animation-name: move-clouds;
            animation-name: move-clouds;
    -webkit-animation-duration: 480s;
            animation-duration: 480s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
  }

  .cloud4 {
    left: 10vw;
  }

  .cloud4 img {
    width: 70%;
    margin-top: -5%;
    margin-left: -5%;
  }

  .cloud3 img {
    width: 25%;
  }

  @-webkit-keyframes move-clouds {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(110vw);
              transform: translateX(110vw);
    }
  }

  @keyframes move-clouds {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(110vw);
              transform: translateX(110vw);
    }
  }

  .cloudsLine img {
    width: 100%;
    display: flex;
  }

  .Q {
    font-size: 30px;
    color: white;
    font-weight: 500;
    margin-bottom: 10px;
    text-shadow: 0 0 10px #ecc46d;
  }

  .rmCon {
    color: white;
    font-size: 18px;
  }

  .rmConAndImg {
    margin-bottom: 30px;
    margin-top: 30px;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .rmCon img {
    width: 30px;
    height: 30px;
  }

  .line {
    width: 100%;
  }

  .connect2-2 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    box-shadow: rgba(0, 0, 0, 0.493) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    border: 12px #7f8082;

    border: #ffa528 5px groove;
  }

  .parrot {
    width: 70%;
  }

  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .totalSupply {
    text-align: center;
    font-size: 60px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(255, 255, 255);
    letter-spacing: 1px;
    margin-top: 1%;
  }

  .price {
    text-align: center;
    font-size: 25px;
    color: white;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }


  .center {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
    z-index: 10000000;

  }

  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: none;

    cursor: pointer;
    background-color: #6cfc1900;
    color: rgb(255, 255, 255);
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: 2px solid rgb(255, 255, 255);
    z-index: 1000000000000000;

  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: none;

    cursor: pointer;
    border: 2px solid rgb(255, 255, 255);
    color: rgb(255, 255, 255);
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    background-color: #00000000;
    z-index: 1000000000000000;

  }


  .btnfos-0-2:hover:active {
    background-color: #0e2942;
  }


  .btnfos-0-2-2:hover:active {
    background-color: #0e2942;
  }


  .btnfos-0-3 {
    margin-top: 20px;
    background: rgb(0, 120, 235);
    background: linear-gradient(180deg, rgba(0, 120, 235, 1) 0%, rgba(89, 154, 215, 1) 50%, rgba(0, 120, 235, 1) 100%);
    color: #ffffff;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 28px;
    padding-left: 30px;
    padding-right: 30px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    border: 6px rgb(0, 0, 0);
    border-style: double;
    z-index: 1000000000000000;
    transition: transform .2s;
  }

  .btnfos-0-3:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

  .nftamount {
    color: #ecaa2f;
    font-size: 85px;
  }

  .mintbuttondiv {
    text-align: center;
    z-index: 10000000;

  }

  .successfully {
    text-align: center;
    margin-top: 3%;
    color: #1ee03e;
    font-size: 30px;
    text-shadow: 0 0 20px #9bf179;
  }

  .errorMessage {
    margin-top: 3%;
    font-size: 20px;
    color: #fc4741;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 3%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .loadingText {
    font-size: 20px;
    text-align: center;
    color: rgb(240, 161, 97);
  }

  .loadTextSub {
    text-align: center;
    color: rgba(255, 255, 255, 0.815);
    font-size: 16px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    -webkit-animation: dots 1s steps(5, end) infinite;
            animation: dots 1s steps(5, end) infinite;
  }

  @-webkit-keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(214, 80, 71);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(209, 119, 45),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(214, 80, 71);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(209, 119, 45),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .rm2Box {
    display: flex;
    justify-content: center;
    margin-right: auto;

  }

  .rm3Box {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-top: 200px;
    margin-bottom: 200px;
  }

  .rmbox1 {
    -webkit-clip-path: polygon(0 0, 96% 0, 100% 100%, 0% 100%);
            clip-path: polygon(0 0, 96% 0, 100% 100%, 0% 100%);
    font-size: 80px;
    background-color: #000000a1;
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
    color: rgb(255, 255, 255);
    width: 600px;
    padding: 40px;
    text-align: center;

  }

  .rmbox2 {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 4% 100%);
            clip-path: polygon(0 0, 100% 0, 100% 100%, 4% 100%);
    font-size: 55px;
    background-color: #007906c2;
    color: white;
    width: 600px;
    padding: 35px;
    text-align: center;
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .rmbox1-3 {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 4% 100%);
            clip-path: polygon(0 0, 100% 0, 100% 100%, 4% 100%);
    text-align: center;
    font-size: 55px;
    background-color: #000000a1;

    -webkit-backdrop-filter: blur(5px);

            backdrop-filter: blur(5px);
    color: rgb(255, 255, 255);
    width: 600px;
    padding: 35px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }


  .rmbox2-3 {
    font-size: 55px;
    -webkit-clip-path: polygon(0 0, 96% 0, 100% 100%, 0% 100%);
            clip-path: polygon(0 0, 96% 0, 100% 100%, 0% 100%);
    text-align: center;
    background-color: #007906c2;
    color: white;
    width: 600px;
    padding: 30px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
    text-align: center;

  }

  .rmConMain {
    display: flex;
    justify-content: space-between;
    flex-flow: column nowrap;
    margin-top: 80px;
  }

  .scroll-effect-container {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    margin-bottom: 30px;

  }

  .scroll-effect {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: #ffffff00;
    text-align: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding-left: 50px;
    padding-right: 50px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

  }

  .scroll-effect-relic {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: #ffffff00;
    text-align: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding-left: 50px;
    padding-right: 50px;

  }

  .scroll-effect-mint {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: #ffffff00;
    text-align: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding-left: 50px;
    padding-right: 50px;

  }

  .scroll-effect-tokenomics {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: #ffffff00;
    text-align: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding-left: 50px;
    padding-right: 50px;

  }

  .scroll-effect-footer {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: #ffffff00;
    text-align: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding-left: 50px;
    padding-right: 50px;

  }


  .scroll-effect-rm {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: #ffffff00;
    text-align: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding-left: 50px;
    padding-right: 50px;

  }


  .scroll-effect-footer {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: #ffffff00;
    text-align: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding-left: 50px;
    padding-right: 50px;
  }

  @-webkit-keyframes fill-effect {
    from {
      width: 0%;
    }

    to {
      width: 100%;
    }
  }

  @keyframes fill-effect {
    from {
      width: 0%;
    }

    to {
      width: 100%;
    }
  }

  .animate {
    /*background: rgb(87, 102, 244);
    background: linear-gradient(270deg, rgba(87, 102, 244, 1) 0%, rgba(36, 46, 167, 1) 51%);*/
    background-color: #000000a1;
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
    background-repeat: no-repeat;
    background-size: 0% 100%;
    transition: background-size 1s ease-in-out;
    -webkit-animation: fill-effect 1s ease-in-out;
            animation: fill-effect 1s ease-in-out;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }

  .animate.animate {
    background-size: 100% 100%;

  }

  /*.animateC{
    background: rgb(244, 87, 179);
    background: linear-gradient(270deg, rgb(238, 77, 190) 0%, rgb(139, 8, 89) 51%);  background-repeat: no-repeat;
    background-size: 0% 100%;
    transition: background-size 0.5s ease-in-out;
    animation: fill-effect 0.5s ease-in-out;
    font-style: italic;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }*/

  .animateC.animateC {
    background-size: 100% 100%;

  }

  /* Define the walkingNft class */
  .walkingNft {
    width: 30%;
    position: absolute;
    bottom: -0.8%;
    left: 0;
    -webkit-animation: walk 20s linear infinite;
            animation: walk 20s linear infinite;

  }

  /* Define the walk animation */
  @-webkit-keyframes walk {
    0% {
      -webkit-transform: translateX(-5vw);
              transform: translateX(-5vw);
    }

    10% {
      -webkit-transform: translateX(10vw) rotate(6deg);
              transform: translateX(10vw) rotate(6deg);
    }

    20% {
      -webkit-transform: translateX(20vw) rotate(0deg);
              transform: translateX(20vw) rotate(0deg);
    }

    30% {
      -webkit-transform: translateX(30vw) rotate(6deg);
              transform: translateX(30vw) rotate(6deg);
    }

    40% {
      -webkit-transform: translateX(40vw) rotate(0deg);
              transform: translateX(40vw) rotate(0deg);
    }

    50% {
      -webkit-transform: translateX(50vw) rotate(6deg);
              transform: translateX(50vw) rotate(6deg);
    }

    60% {
      -webkit-transform: translateX(60vw) rotate(0deg);
              transform: translateX(60vw) rotate(0deg);
    }

    70% {
      -webkit-transform: translateX(70vw) rotate(6deg);
              transform: translateX(70vw) rotate(6deg);
    }

    80% {
      -webkit-transform: translateX(80vw) rotate(0deg);
              transform: translateX(80vw) rotate(0deg);
    }

    90% {
      -webkit-transform: translateX(90vw) rotate(6deg);
              transform: translateX(90vw) rotate(6deg);
    }

    100% {
      -webkit-transform: translateX(100vw) rotate(0deg);
              transform: translateX(100vw) rotate(0deg);
    }

  }
  @keyframes walk {
    0% {
      -webkit-transform: translateX(-5vw);
              transform: translateX(-5vw);
    }

    10% {
      -webkit-transform: translateX(10vw) rotate(6deg);
              transform: translateX(10vw) rotate(6deg);
    }

    20% {
      -webkit-transform: translateX(20vw) rotate(0deg);
              transform: translateX(20vw) rotate(0deg);
    }

    30% {
      -webkit-transform: translateX(30vw) rotate(6deg);
              transform: translateX(30vw) rotate(6deg);
    }

    40% {
      -webkit-transform: translateX(40vw) rotate(0deg);
              transform: translateX(40vw) rotate(0deg);
    }

    50% {
      -webkit-transform: translateX(50vw) rotate(6deg);
              transform: translateX(50vw) rotate(6deg);
    }

    60% {
      -webkit-transform: translateX(60vw) rotate(0deg);
              transform: translateX(60vw) rotate(0deg);
    }

    70% {
      -webkit-transform: translateX(70vw) rotate(6deg);
              transform: translateX(70vw) rotate(6deg);
    }

    80% {
      -webkit-transform: translateX(80vw) rotate(0deg);
              transform: translateX(80vw) rotate(0deg);
    }

    90% {
      -webkit-transform: translateX(90vw) rotate(6deg);
              transform: translateX(90vw) rotate(6deg);
    }

    100% {
      -webkit-transform: translateX(100vw) rotate(0deg);
              transform: translateX(100vw) rotate(0deg);
    }

  }


  .images2 {
    display: flex;
    justify-content: space-between;
  }

  .images2 img {
    width: 40%;
    margin-top: -18%;
    z-index: 10000;
  }



  * {
    box-sizing: border-box;
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 7rem;
    background-image: url(/static/media/ticker.81e610af.png);
    background-size: contain;
    padding-left: 100%;
    z-index: 20000000000000000;
    margin-top: -3.5px;
  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 7rem;
    line-height: 7rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 30s;
    animation-duration: 50s;
    z-index: 1000000000000;

  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 2.5rem;
    font-size: 45px;
    font-weight: 800;
    color: white;
    z-index: 1000000000000;

  }

  .line {
    background-color: rgb(255, 255, 255);
    height: 17vh;
    width: 120vw;
    border-bottom: 12px solid rgb(7, 153, 250);
    border-top: 12px solid rgb(7, 153, 250);
    rotate: 10deg;
    margin-left: -60px;
    display: flex;
    justify-content: space-between;
    margin-top: 15%;
  }

  .line2 {
    background-color: rgb(255, 255, 255);
    height: 17vh;
    width: 120vw;
    border-bottom: 12px solid rgb(0, 113, 189);
    border-top: 12px solid rgb(0, 113, 189);
    rotate: 8deg;
    margin-left: -30px;
    display: flex;
    margin-top: 4%;
    justify-content: space-between;
    margin-bottom: 100px;
  }

  .line2 img {
    width: 75px;
    height: 75px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .line img {
    width: 75px;
    height: 75px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .weapon {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    width: 25%;
    margin-top: -15%;
  }

  .potionBottle {
    width: 23%;
    margin-top: -10%;
    z-index: 100000;
    margin-left: 20px;
  }

  .bawl {
    width: 26%;
    margin-top: -5%;
    z-index: 100000;
    margin-left: 20px;
  }

  .ball {
    width: 9%;
    margin-top: -10%;
    z-index: 100000;
    margin-left: 100px;
    rotate: 15deg;
  }


  @-webkit-keyframes float {
    0% {
      -webkit-transform: translate(0, 0);
              transform: translate(0, 0);
    }

    50% {
      -webkit-transform: translate(0, 20px);
              transform: translate(0, 20px);
    }

    100% {
      -webkit-transform: translate(0, 0);
              transform: translate(0, 0);
    }
  }


  @keyframes float {
    0% {
      -webkit-transform: translate(0, 0);
              transform: translate(0, 0);
    }

    50% {
      -webkit-transform: translate(0, 20px);
              transform: translate(0, 20px);
    }

    100% {
      -webkit-transform: translate(0, 0);
              transform: translate(0, 0);
    }
  }

  .mashroom {
    width: 23%;
    margin-top: -20%;
    z-index: 100000;
    margin-left: 70%;
    rotate: 25deg;
  }

  .lucky {
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
  }

  .lucky img {
    width: 20%;
  }

  @-webkit-keyframes shake {
    0% {
      -webkit-transform: translate(0, 0) rotate(0);
              transform: translate(0, 0) rotate(0);
    }

    10% {
      -webkit-transform: translate(-10px, 0) rotate(-5deg);
              transform: translate(-10px, 0) rotate(-5deg);
    }

    20% {
      -webkit-transform: translate(10px, 0) rotate(5deg);
              transform: translate(10px, 0) rotate(5deg);
    }

    30% {
      -webkit-transform: translate(-10px, 0) rotate(-5deg);
              transform: translate(-10px, 0) rotate(-5deg);
    }

    40% {
      -webkit-transform: translate(10px, 0) rotate(5deg);
              transform: translate(10px, 0) rotate(5deg);
    }

    50% {
      -webkit-transform: translate(-10px, 0) rotate(-5deg);
              transform: translate(-10px, 0) rotate(-5deg);
    }

    60% {
      -webkit-transform: translate(10px, 0) rotate(5deg);
              transform: translate(10px, 0) rotate(5deg);
    }

    70% {
      -webkit-transform: translate(-10px, 0) rotate(-5deg);
              transform: translate(-10px, 0) rotate(-5deg);
    }

    80% {
      -webkit-transform: translate(10px, 0) rotate(5deg);
              transform: translate(10px, 0) rotate(5deg);
    }

    90% {
      -webkit-transform: translate(-10px, 0) rotate(-5deg);
              transform: translate(-10px, 0) rotate(-5deg);
    }

    100% {
      -webkit-transform: translate(0, 0) rotate(0);
              transform: translate(0, 0) rotate(0);
    }
  }

  @keyframes shake {
    0% {
      -webkit-transform: translate(0, 0) rotate(0);
              transform: translate(0, 0) rotate(0);
    }

    10% {
      -webkit-transform: translate(-10px, 0) rotate(-5deg);
              transform: translate(-10px, 0) rotate(-5deg);
    }

    20% {
      -webkit-transform: translate(10px, 0) rotate(5deg);
              transform: translate(10px, 0) rotate(5deg);
    }

    30% {
      -webkit-transform: translate(-10px, 0) rotate(-5deg);
              transform: translate(-10px, 0) rotate(-5deg);
    }

    40% {
      -webkit-transform: translate(10px, 0) rotate(5deg);
              transform: translate(10px, 0) rotate(5deg);
    }

    50% {
      -webkit-transform: translate(-10px, 0) rotate(-5deg);
              transform: translate(-10px, 0) rotate(-5deg);
    }

    60% {
      -webkit-transform: translate(10px, 0) rotate(5deg);
              transform: translate(10px, 0) rotate(5deg);
    }

    70% {
      -webkit-transform: translate(-10px, 0) rotate(-5deg);
              transform: translate(-10px, 0) rotate(-5deg);
    }

    80% {
      -webkit-transform: translate(10px, 0) rotate(5deg);
              transform: translate(10px, 0) rotate(5deg);
    }

    90% {
      -webkit-transform: translate(-10px, 0) rotate(-5deg);
              transform: translate(-10px, 0) rotate(-5deg);
    }

    100% {
      -webkit-transform: translate(0, 0) rotate(0);
              transform: translate(0, 0) rotate(0);
    }
  }

  @-webkit-keyframes zoom {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
      /* Start the animation with normal size image */
    }

    50% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1);
      /* Increase the size of the image halfway through the animation */
    }

    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      /* End the animation with normal size image */
    }
  }

  @keyframes zoom {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
      /* Start the animation with normal size image */
    }

    50% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1);
      /* Increase the size of the image halfway through the animation */
    }

    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      /* End the animation with normal size image */
    }
  }


  .gifImg {
    background-image: url(/static/media/pepe.ae6a97c7.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 50vw;
    height: 50vw;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    background-color: rgba(0, 0, 0, 0);

  }

  .gifImg2 {
    background-image: url(/static/media/182.f2954502.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 50vw;
    height: 50vw;
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }

  .gifImg3 {
    background-image: url(/static/media/pepe5.be18fc31.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 50vw;
    height: 50vw;
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }

  .gifImg4 {
    background-image: url(/static/media/new.3aabb953.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 50vw;
    height: 50vw;
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }

  .gifMain {
    background-color: rgba(0, 0, 0, 0);
    margin-top: -10%;
  }

  .bubble-container {
    flex-flow: column;
    display: flex;
  }

}

@media screen and (min-width: 1921px) and (max-width: 3839px) {

  .page-loader {
    width: 100%;
    height: 100vh;
    background: #242424;
    z-index: 5000000000000000;
    position: fixed;
  }

  .loading-area {
    display: grid;
    place-items: center;
    height: 100vh;
  }

  .containerLoad {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .h1Load {
    font-size: 1.5rem;
    color: #00ff2a;
  }

  .ring {
    width: 200px;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  .ring::before {
    content: '';
    width: 200px;
    height: 200px;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
    -webkit-animation: ring 2s linear infinite;
            animation: ring 2s linear infinite;
  }

  @-webkit-keyframes ring {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
      box-shadow: 0px 5px 5px #008afa;
    }

    25% {
      -webkit-transform: rotate(90deg);
              transform: rotate(90deg);
      box-shadow: 0px 5px 5px #02b7ff;
    }

    50% {
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg);
      box-shadow: 0px 5px 5px #0151ff;
    }

    75% {
      -webkit-transform: rotate(270deg);
              transform: rotate(270deg);
      box-shadow: 0px 5px 5px #367de7;
    }

    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
      box-shadow: 0px 5px 5px #008afa;
    }
  }

  @keyframes ring {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
      box-shadow: 0px 5px 5px #008afa;
    }

    25% {
      -webkit-transform: rotate(90deg);
              transform: rotate(90deg);
      box-shadow: 0px 5px 5px #02b7ff;
    }

    50% {
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg);
      box-shadow: 0px 5px 5px #0151ff;
    }

    75% {
      -webkit-transform: rotate(270deg);
              transform: rotate(270deg);
      box-shadow: 0px 5px 5px #367de7;
    }

    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
      box-shadow: 0px 5px 5px #008afa;
    }
  }

  .rmConMainMob {
    display: none;
  }

  .audioPic {
    /* display: none;*/
    width: 100px;
    height: 100px;
    position: sticky;
    position: -webkit-sticky;
    left: 0;
    bottom: 10%;
    z-index: 1000;
  }


  .rmbg {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-bottom: 2%;
    background-color: rgba(0, 0, 0, 0);

  }

  .rmbg2 {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-top: 10%;
    padding-bottom: 2%;
    background: url(/static/media/rmbg.6ed0fbcc.jpg) no-repeat center center fixed;
    background-size: cover;
    width: 97%;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .placeAndWL {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    z-index: 100000000;

  }

  input {
    background-color: rgba(0, 30, 70, 0.568);
    border: white 1px solid;
    font-size: 20px;
    padding: 15px;
    color: #00d6fc;
    z-index: 100000000;
  }

  input:focus {
    background-color: #b3e9ff00;
    color: #00d6fc;
  }

  #wlBtn {
    background-color: rgba(0, 30, 70, 0.568);
    border: 1px solid rgb(16, 192, 223);
    color: #ffffff;
    width: 200px;
    z-index: 100000000;
  }

  #wlBtn:hover {
    font-size: 18px;
  }

  .wlLine {
    background-color: #000000;
    padding: 50px;
  }


  .cloudsMob {
    display: none;
  }

  .connectMob {
    display: none;
  }

  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-top: 100px;
    padding-bottom: 100px;
    background: rgb(52, 52, 52);
    background: linear-gradient(180deg, rgba(52, 52, 52, 1) 12%, rgba(0, 0, 0, 1) 100%);
  }

  .littleNav {
    display: none;
  }

  .miniBarMain {
    display: none;
  }

  .logosMob {
    display: none;
  }

  .allWrap {
    background: rgb(49,89,63);
background: radial-gradient(circle, rgba(49,89,63,1) 0%, rgba(52,74,61,1) 50%, rgba(35,51,41,1) 100%);
    height: 100vh;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    overflow: hidden;
  }

  .cont {
    background: radial-gradient(circle, rgba(0, 0, 0, 0.06684392507002801) 0%, rgba(0, 0, 0, 0.11166185224089631) 50%, rgba(0, 0, 0, 0.3693649334733894) 100%), url(/static/media/BG.b24e41c2.jpg) no-repeat center center fixed;
    background-size: cover;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;

  }




  .in2 p {
    font-size: 28px;
    text-align: center;
    font-family: "Iomanoid";
    letter-spacing: 4px;
    text-shadow: 0 0 10px #ffdc64;
    color: #ffdc64;

  }

  .in2 {
    z-index: 100000000;

  }

  @-webkit-keyframes heartbeat {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }

    50% {
      -webkit-transform: scale(0.9);
              transform: scale(0.9);
    }

    60% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1);
    }

    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }

  @keyframes heartbeat {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }

    50% {
      -webkit-transform: scale(0.9);
              transform: scale(0.9);
    }

    60% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1);
    }

    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }

  .logo2Intro {
    z-index: 200000000000000000000000000000000;
    width: 40%;
    padding-top: 27.9vh;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    -webkit-animation: heartbeat 2s ease-in-out infinite;
            animation: heartbeat 2s ease-in-out infinite;
  }

  .in2 img {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 47%;
    padding-top: 12vh;
    transition: -webkit-transform 0.8s;
    transition: transform 0.8s;
    transition: transform 0.8s, -webkit-transform 0.8s;
    z-index: 100000000;
  }

  .in2 img:hover {
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
  }


  .introduction {
    font-family: "Iomanoid";
  }

  .twitter img {
    width: 50px;
    height: 50px;
    margin-left: 30px;
    cursor: pointer;
    opacity: 0.6;
    transition: 0.2s ease;
    margin-top: -3%;
    z-index: 200000000000;

  }

  .twitter {
    z-index: 200000000000;
  }

  .twitter img:hover {
    opacity: 100%;
    z-index: 200000000000;

  }

  .boxWrap2Story {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-top: 5%;
    padding-bottom: 2%;
    /*background: linear-gradient(180deg, rgba(9, 28, 45, 0.10325849089635852) 0%, rgba(13, 26, 38, 0.36656381302521013) 35%, rgba(9, 28, 45, 1) 100%), url('./assets/bg1.jpg') no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;*/
    /*background: rgb(12, 22, 42);
    background: linear-gradient(180deg, rgba(12, 22, 42, 1) 11%, rgba(3, 43, 25, 1) 90%);*/
    background-color: rgba(0, 0, 0, 0);
  }

  .boxWrap2StoryR {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    /*background: linear-gradient(180deg, rgba(9, 28, 45, 0.10325849089635852) 0%, rgba(13, 26, 38, 0.36656381302521013) 35%, rgba(9, 28, 45, 1) 100%), url('./assets/bg1.jpg') no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;*/
    /*background: rgb(12, 22, 42);
    background: linear-gradient(180deg, rgba(12, 22, 42, 1) 11%, rgba(3, 43, 25, 1) 90%);*/
    background-color: rgba(0, 0, 0, 0);
    ;
  }

  .boxWrap2StoryT {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-bottom: 2%;
    /*background: linear-gradient(180deg, rgba(9, 28, 45, 0.10325849089635852) 0%, rgba(13, 26, 38, 0.36656381302521013) 35%, rgba(9, 28, 45, 1) 100%), url('./assets/bg1.jpg') no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;*/
    /*background: rgb(12, 22, 42);
    background: linear-gradient(180deg, rgba(12, 22, 42, 1) 11%, rgba(3, 43, 25, 1) 90%);*/
    background-color: rgba(0, 0, 0, 0);
    ;
  }


  .boxWrap2Story2 {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0);

    padding-bottom: 2%;
  }

  .boxWrap2Mint {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-size: cover;
    padding-bottom: 2%;
    z-index: 100000000000;
  }

  .boxWrap2SubR {
    justify-content: space-between;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .boxWrap2Sub1 {}

  .boxWrap2Sub {
    justify-content: space-between;
    width: 98%;
    margin-left: auto;
    margin-right: auto;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.164) 0%, rgba(0, 0, 0, 0.301) 50%, rgba(0, 0, 0, 0.534) 100%), url('https://cdn.discordapp.com/attachments/1028976349920112681/1139504255498195055/NFTHouse9_coins_in_space_cartoon_theme_326f3ecc-8f98-4844-81b1-49ce9a21ba21.png') no-repeat center center fixed;
    background-size: cover;
    padding-top: 10%;
    border-radius: 30px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .boxWrap2SubT {
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    background-color: #22e2d9;
  }

  .storyH {
    font-size: 200px;
    color: black;
    font-weight: bolder;
    text-align: center;
    margin-bottom: 10px;
    font-style: normal;
    font-family: 'Exo', sans-serif;
  }

  .outer-div {
    width: 500px;
    height: 100px;
    background-color: #fff;
    transition: background-color 1s ease;
  }

  .storyHK {
    font-size: 80px;
    color: white;
    font-weight: 500;
    text-align: center;
    font-family: "Iomanoid";
    margin-bottom: 2%;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px rgba(255, 255, 0, 0.616), 0 -10px 20px #ff80008c, 0 -18px 40px rgba(255, 0, 0, 0.719);
  }

  .storyH2 {
    font-size: 320px;
    color: rgb(255, 255, 255);
    font-weight: bolder;
    text-align: center;
    margin-bottom: 2%;
    font-family: 'Exo', sans-serif;
  }

  .storyH3 {
    font-size: 100px;
    color: rgb(255, 255, 255);
    font-weight: bolder;
    text-align: center;
    margin-bottom: 2%;
    font-family: 'Exo', sans-serif;
    background: rgb(88, 101, 242);
    background: linear-gradient(180deg, rgb(36, 51, 219) 12%, rgb(67, 3, 185) 100%);
    width: 700px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
    cursor: pointer;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    margin-top: -15%;
  }

  .storyH3:hover {
    background: rgb(38, 47, 222);
    background: linear-gradient(180deg, rgba(38, 47, 222, 1) 12%, rgba(176, 68, 242, 1) 100%);
  }

  /* Animation */
  @-webkit-keyframes fill-div {
    from {
      width: 0;
    }

    to {
      width: 100%;
    }
  }
  @keyframes fill-div {
    from {
      width: 0;
    }

    to {
      width: 100%;
    }
  }

  .bg {
    background-color: #17237e;
    padding-left: 50px;
    padding-right: 50px;
  }

  .storyH2-2 {
    font-size: 50px;
    color: white;
    font-weight: 500;
    text-align: right;
    font-family: "Iomanoid";
    margin-bottom: 10px;
    text-shadow: 0 0 10px #ce9381;
  }

  .about {
    color: white;
    font-size: 18px;
    text-align: left;
    display: flex;
    justify-content: space-evenly;
    margin-left: auto;
    margin-right: auto;
  }

  .aboutKingdom {
    color: white;
    font-size: 18px;
    text-align: left;
    display: flex;
    justify-content: space-evenly;
  }

  .kingdomsSection {
    color: white;
    font-size: 18px;
    text-align: left;
    display: flex;
    justify-content: space-evenly;
    flex-flow: column nowrap;
  }

  .aboutP {
    width: 50%;
    margin-bottom: 50px;
    margin-top: 30px;
    margin-right: auto;
    justify-content: center;
  }

  .aboutPNew {
    width: 50%;
    margin-bottom: 50px;
    margin-top: 30px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
  }

  .aboutPS {
    width: 45%;
    margin-bottom: 50px;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .aboutP3 {
    width: 60%;
    margin-top: auto;
    margin-bottom: auto;
    margin-bottom: 50px;
  }

  .aboutP3 p {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 23px;
  }

  .aboutP p {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 22px;
  }

  .aboutPNew p {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 45px;
  }

  .aboutPDiv {
    background: rgb(101, 161, 90);
    background: linear-gradient(93deg, rgba(101, 161, 90, 1) 0%, rgba(32, 71, 128, 1) 50%);
    -webkit-clip-path: polygon(0 0, 100% 0%, 98% 100%, 0% 100%);
            clip-path: polygon(0 0, 100% 0%, 98% 100%, 0% 100%);
    width: 1300px;
    margin-bottom: 16px;
    margin-top: 16px;
    padding: 20px;
    font-size: 37px;
    line-height: 60px;
  }

  .aboutPDiv2 {
    background-color: #2a32438a;
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
    -webkit-clip-path: polygon(0 0, 100% 0%, 98% 100%, 0% 100%);
            clip-path: polygon(0 0, 100% 0%, 98% 100%, 0% 100%);
    margin-bottom: 60px;
    margin-top: 60px;
    padding: 45px;
    font-size: 60px;
    line-height: 50px;
    color: white;
  }

  .hl {
    color: #1ee03e;
  }


  .bigLetters {
    font-size: 75px;
    line-height: 80px;
  }

  .ironfist {
    margin-top: auto;
    margin-left: -60px;
    z-index: 100000;
  }

  .ironfist img {
    width: 130px;
    height: 130px;

  }

  .storyConAndImg {
    display: flex;
    justify-content: center;
  }

  .aboutP2 {
    width: 50%;
    margin-top: auto;
    margin-bottom: auto;
  }

  .zeus {
    width: 45%;
    height: 45%;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    -webkit-animation: floating 3s ease-in-out infinite;
            animation: floating 3s ease-in-out infinite;
  }

  .zeus2 {
    width: 35%;
    height: 35%;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;

  }

  @-webkit-keyframes floating {

    0%,
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    50% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
    }
  }

  @keyframes floating {

    0%,
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    50% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
    }
  }

  .logos {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
  }

  .logos_mobile {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
    display: none;
  }

  .logoF img {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 40%;
    display: flex;
  }


  .facetoface img {
    width: 30%;
  }

  .facetoface {
    display: flex;
    justify-content: space-between;
    margin-top: -27%;
  }

  .footerImg {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: space-evenly;
  }

  .footerImg img {
    margin-left: 10px;
    margin-right: 10px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
  }

  .footerImg img:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    cursor: pointer;
  }

  .footer2 {
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .copyright {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 25px;
  }

  .footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: rgba(102, 102, 102, 0);
    width: 100%;
  }

  .sword {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 10%;
  }

  .devs {
    color: rgba(255, 255, 255, 0.774);
    text-align: center;
    margin-top: 80px;
  }

  .devs img {
    width: 30px;
    height: 30px;
    opacity: 0.9;
    transition: 0.3s ease;
  }

  .devs:hover {
    color: rgb(255, 255, 255);
    cursor: pointer;
  }

  .devs img:hover {
    width: 30px;
    height: 30px;
  }

  a {
    color: #d1fdfb;
    text-decoration: none;
  }

  a:hover {
    color: #99faf5;
    text-decoration: none;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-bottom: 25px;
    padding-top: 25px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #020529b7;

  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    width: 95%;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
  }

  .connect div {
    margin-left: 40px;
    margin-right: 40px;
    color: white;
    margin-top: auto;
    justify-content: space-evenly;
    display: flex;
    cursor: pointer;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    text-shadow: -1px -1px 1px #f899345d, 1px 1px 1px #fe921e6c;

  }

  .connect div:hover {
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
  }

  .icons {
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-left: 10px;
  }

  .icons img {
    width: 55px;
    height: 55px;
    margin-left: 20px;
    margin-right: 20px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    z-index: 10000000;
  }

  .icons img:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    cursor: pointer;
  }

  #fontSize {
    font-size: 35px;
    z-index: 10000000;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    align-items: center;

  }

  #fontSize-2 {
    font-size: 25px;
    z-index: 10000000;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    align-items: center;
  }

  /* CSS */
  .button-92 {
    --c: #fff;
    /* text color */
    background: linear-gradient(90deg, #0000 33%, #fff5, #0000 67%) 100%/300% no-repeat,
      #009218;
    background: linear-gradient(90deg, #0000 33%, #fff5, #0000 67%) var(--_p, 100%)/300% no-repeat,
      #009218;
    /* background color */
    color: #0000;
    border: none;
    -webkit-transform: perspective(500px) rotateY(calc(20deg * -1));
            transform: perspective(500px) rotateY(calc(20deg * -1));
    -webkit-transform: perspective(500px) rotateY(calc(20deg * var(--_i, -1)));
            transform: perspective(500px) rotateY(calc(20deg * var(--_i, -1)));
    text-shadow: calc(-1 * 0.08em) -.01em 0 var(--c),
      calc(-1 * -0.08em) .01em 2px #0004;
    text-shadow: calc(var(--_i, -1) * 0.08em) -.01em 0 var(--c),
      calc(var(--_i, -1) * -0.08em) .01em 2px #0004;
    outline-offset: .1em;
    transition: 0.3s;
  }

  .button-92:hover,
  .button-92:focus-visible {
    --_p: 0%;
    --_i: 1;
  }

  .button-92:active {
    text-shadow: none;
    color: var(--c);
    box-shadow: inset 0 0 9e9q #0005;
    transition: 0s;
  }

  .button-92 {
    font-weight: bold;
    font-size: 3.7rem;
    margin: 0;
    cursor: pointer;
    padding: .1em .3em;
  }

  .title {
    color: white;
    text-shadow: -1px -1px 1px #f899345d, 1px 1px 1px #fe921e6c;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: center;
    font-size: 70px;
  }

  .logoUp {
    width: 95px;
    height: 95px;
    margin-top: -10px;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;
  }

  /* CSS */
  .button-62 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    background-color: #bb5a3c;
    font-size: 18px;
    padding: 13px;
    width: 200px;
    color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    transition: 0.3s ease;
    border-radius: 7px;
    border: none;
    font-weight: 500;
  }

  .button-62:hover {
    color: rgb(219, 219, 219);
  }

  .logo2 img {
    width: 75px;
    height: 75px;
  }

  .downImg {
    width: 35px;
    -webkit-animation: arrow_down_animation 2s ease-in-out infinite;
            animation: arrow_down_animation 2s ease-in-out infinite;
    cursor: pointer;
  }


  @-webkit-keyframes arrow_down_animation {

    0% {
      -webkit-transform: translate3d(0, 0, 0) scale(1, 1);
              transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;
    }

    30% {
      opacity: 1;
    }

    100% {
      -webkit-transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
              transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;

    }

  }


  @keyframes arrow_down_animation {

    0% {
      -webkit-transform: translate3d(0, 0, 0) scale(1, 1);
              transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;
    }

    30% {
      opacity: 1;
    }

    100% {
      -webkit-transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
              transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;

    }

  }

  .downImgDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -25px;

  }

  .down {
    text-align: center;
    background-color: rgba(0, 0, 0, 0);
  }

  .stickyBtn2 {
    width: 65px;
    height: 65px;
    z-index: 1;
  }

  #sticky-button {
    position: -webkit-sticky;
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 15%;
    margin-left: 92%;
    margin-top: -8%;
    cursor: pointer;
    transition: -webkit-transform .4s;
    transition: transform .4s;
    transition: transform .4s, -webkit-transform .4s;
    z-index: 1;

  }

  .stickyBtn2:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    opacity: 100%;
    z-index: 1;

  }

  .clouds2 {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  .cloud {
    position: absolute;
    top: 0;

  }

  .cloud1 {
    left: 10vw;

  }

  .cloud1 img {
    width: 120%;
    margin-top: -13%;
  }

  .cloud2 img {
    width: 70%;
    margin-top: -8%;
  }

  .cloud3 img {
    width: 100%;

  }

  .cloud1-1 {
    left: 70vw;
    -webkit-animation-name: move-clouds;
            animation-name: move-clouds;
    -webkit-animation-duration: 600s;
            animation-duration: 600s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
  }

  .cloud2 {
    left: 5px;
    -webkit-animation-name: move-clouds;
            animation-name: move-clouds;
    -webkit-animation-duration: 200s;
            animation-duration: 200s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
  }

  .cloud3 {
    left: 3px;

  }

  .cloud3-3 {
    left: 10px;
    -webkit-animation-name: move-clouds;
            animation-name: move-clouds;
    -webkit-animation-duration: 480s;
            animation-duration: 480s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
  }

  .cloud4 {
    left: 10vw;
  }

  .cloud4 img {
    width: 70%;
    margin-top: -5%;
    margin-left: -5%;
  }

  .cloud3 img {
    width: 25%;
  }

  @-webkit-keyframes move-clouds {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(110vw);
              transform: translateX(110vw);
    }
  }

  @keyframes move-clouds {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(110vw);
              transform: translateX(110vw);
    }
  }

  .cloudsLine img {
    width: 100%;
    display: flex;
  }

  .Q {
    font-size: 30px;
    color: white;
    font-weight: 500;
    margin-bottom: 10px;
    text-shadow: 0 0 10px #ecc46d;
  }

  .rmCon {
    color: white;
    font-size: 18px;
  }

  .rmConAndImg {
    margin-bottom: 30px;
    margin-top: 30px;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .rmCon img {
    width: 30px;
    height: 30px;
  }

  .line {
    width: 100%;
  }

  .connect2-2 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    box-shadow: rgba(0, 0, 0, 0.493) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    border: 12px #7f8082;

    border: #ffa528 5px groove;
  }

  .parrot {
    width: 70%;
  }

  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .totalSupply {
    text-align: center;
    font-size: 60px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(255, 255, 255);
    letter-spacing: 1px;
    margin-top: 1%;
  }

  .price {
    text-align: center;
    font-size: 25px;
    color: white;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }


  .center {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
    z-index: 10000000;

  }

  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: none;

    cursor: pointer;
    background-color: #6cfc1900;
    color: rgb(255, 255, 255);
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: 2px solid rgb(255, 255, 255);
    z-index: 1000000000000000;

  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: none;

    cursor: pointer;
    border: 2px solid rgb(255, 255, 255);
    color: rgb(255, 255, 255);
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    background-color: #00000000;
    z-index: 1000000000000000;

  }


  .btnfos-0-2:hover:active {
    background-color: #0e2942;
  }


  .btnfos-0-2-2:hover:active {
    background-color: #0e2942;
  }


  .btnfos-0-3 {
    margin-top: 20px;
    background: rgb(0, 120, 235);
    background: linear-gradient(180deg, rgba(0, 120, 235, 1) 0%, rgba(89, 154, 215, 1) 50%, rgba(0, 120, 235, 1) 100%);
    color: #ffffff;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 28px;
    padding-left: 30px;
    padding-right: 30px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    border: 6px rgb(0, 0, 0);
    border-style: double;
    z-index: 1000000000000000;
    transition: transform .2s;
  }

  .btnfos-0-3:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

  .nftamount {
    color: #ecaa2f;
    font-size: 85px;
  }

  .mintbuttondiv {
    text-align: center;
    z-index: 10000000;

  }

  .successfully {
    text-align: center;
    margin-top: 3%;
    color: #1ee03e;
    font-size: 30px;
    text-shadow: 0 0 20px #9bf179;
  }

  .errorMessage {
    margin-top: 3%;
    font-size: 20px;
    color: #fc4741;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 3%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .loadingText {
    font-size: 20px;
    text-align: center;
    color: rgb(240, 161, 97);
  }

  .loadTextSub {
    text-align: center;
    color: rgba(255, 255, 255, 0.815);
    font-size: 16px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    -webkit-animation: dots 1s steps(5, end) infinite;
            animation: dots 1s steps(5, end) infinite;
  }

  @-webkit-keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(214, 80, 71);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(209, 119, 45),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(214, 80, 71);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(209, 119, 45),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .rm2Box {
    display: flex;
    justify-content: center;
    margin-right: auto;

  }

  .rm3Box {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-top: 300px;
    margin-bottom: 300px;
  }

  .rmbox1 {
    -webkit-clip-path: polygon(0 0, 96% 0, 100% 100%, 0% 100%);
            clip-path: polygon(0 0, 96% 0, 100% 100%, 0% 100%);
    font-size: 100px;
    background-color: #000000a1;
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
    color: rgb(255, 255, 255);
    width: 800px;
    padding: 60px;
    text-align: center;

  }

  .rmbox2 {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 4% 100%);
            clip-path: polygon(0 0, 100% 0, 100% 100%, 4% 100%);
    font-size: 75px;
    background-color: #007906c2;
    color: white;
    width: 800px;
    padding: 60px;
    text-align: center;
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .rmbox1-3 {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 4% 100%);
            clip-path: polygon(0 0, 100% 0, 100% 100%, 4% 100%);
    text-align: center;
    font-size: 100px;
    background-color: #000000a1;

    -webkit-backdrop-filter: blur(5px);

            backdrop-filter: blur(5px);
    color: rgb(255, 255, 255);
    width: 800px;
    padding: 60px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }


  .rmbox2-3 {
    font-size: 75px;
    -webkit-clip-path: polygon(0 0, 96% 0, 100% 100%, 0% 100%);
            clip-path: polygon(0 0, 96% 0, 100% 100%, 0% 100%);
    text-align: center;
    background-color: #007906c2;
    color: white;
    width: 800px;
    padding: 60px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
    text-align: center;

  }

  .rmConMain {
    display: flex;
    justify-content: space-between;
    flex-flow: column nowrap;
    margin-top: 80px;
  }

  .scroll-effect-container {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    margin-bottom: 30px;

  }

  .scroll-effect {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: #ffffff00;
    text-align: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding-left: 50px;
    padding-right: 50px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

  }

  .scroll-effect-relic {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: #ffffff00;
    text-align: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding-left: 50px;
    padding-right: 50px;

  }

  .scroll-effect-mint {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: #ffffff00;
    text-align: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding-left: 50px;
    padding-right: 50px;

  }

  .scroll-effect-tokenomics {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: #ffffff00;
    text-align: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding-left: 50px;
    padding-right: 50px;

  }

  .scroll-effect-footer {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: #ffffff00;
    text-align: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding-left: 50px;
    padding-right: 50px;

  }


  .scroll-effect-rm {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: #ffffff00;
    text-align: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding-left: 50px;
    padding-right: 50px;

  }


  .scroll-effect-footer {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: #ffffff00;
    text-align: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding-left: 50px;
    padding-right: 50px;
  }

  @-webkit-keyframes fill-effect {
    from {
      width: 0%;
    }

    to {
      width: 100%;
    }
  }

  @keyframes fill-effect {
    from {
      width: 0%;
    }

    to {
      width: 100%;
    }
  }

  .animate {
    /*background: rgb(87, 102, 244);
    background: linear-gradient(270deg, rgba(87, 102, 244, 1) 0%, rgba(36, 46, 167, 1) 51%);*/
    background-color: #000000a1;
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
    background-repeat: no-repeat;
    background-size: 0% 100%;
    transition: background-size 1s ease-in-out;
    -webkit-animation: fill-effect 1s ease-in-out;
            animation: fill-effect 1s ease-in-out;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }

  .animate.animate {
    background-size: 100% 100%;

  }

  /*.animateC{
    background: rgb(244, 87, 179);
    background: linear-gradient(270deg, rgb(238, 77, 190) 0%, rgb(139, 8, 89) 51%);  background-repeat: no-repeat;
    background-size: 0% 100%;
    transition: background-size 0.5s ease-in-out;
    animation: fill-effect 0.5s ease-in-out;
    font-style: italic;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }*/

  .animateC.animateC {
    background-size: 100% 100%;

  }

  /* Define the walkingNft class */
  .walkingNft {
    width: 30%;
    position: absolute;
    bottom: -0.8%;
    left: 0;
    -webkit-animation: walk 20s linear infinite;
            animation: walk 20s linear infinite;

  }

  /* Define the walk animation */
  @-webkit-keyframes walk {
    0% {
      -webkit-transform: translateX(-5vw);
              transform: translateX(-5vw);
    }

    10% {
      -webkit-transform: translateX(10vw) rotate(6deg);
              transform: translateX(10vw) rotate(6deg);
    }

    20% {
      -webkit-transform: translateX(20vw) rotate(0deg);
              transform: translateX(20vw) rotate(0deg);
    }

    30% {
      -webkit-transform: translateX(30vw) rotate(6deg);
              transform: translateX(30vw) rotate(6deg);
    }

    40% {
      -webkit-transform: translateX(40vw) rotate(0deg);
              transform: translateX(40vw) rotate(0deg);
    }

    50% {
      -webkit-transform: translateX(50vw) rotate(6deg);
              transform: translateX(50vw) rotate(6deg);
    }

    60% {
      -webkit-transform: translateX(60vw) rotate(0deg);
              transform: translateX(60vw) rotate(0deg);
    }

    70% {
      -webkit-transform: translateX(70vw) rotate(6deg);
              transform: translateX(70vw) rotate(6deg);
    }

    80% {
      -webkit-transform: translateX(80vw) rotate(0deg);
              transform: translateX(80vw) rotate(0deg);
    }

    90% {
      -webkit-transform: translateX(90vw) rotate(6deg);
              transform: translateX(90vw) rotate(6deg);
    }

    100% {
      -webkit-transform: translateX(100vw) rotate(0deg);
              transform: translateX(100vw) rotate(0deg);
    }

  }
  @keyframes walk {
    0% {
      -webkit-transform: translateX(-5vw);
              transform: translateX(-5vw);
    }

    10% {
      -webkit-transform: translateX(10vw) rotate(6deg);
              transform: translateX(10vw) rotate(6deg);
    }

    20% {
      -webkit-transform: translateX(20vw) rotate(0deg);
              transform: translateX(20vw) rotate(0deg);
    }

    30% {
      -webkit-transform: translateX(30vw) rotate(6deg);
              transform: translateX(30vw) rotate(6deg);
    }

    40% {
      -webkit-transform: translateX(40vw) rotate(0deg);
              transform: translateX(40vw) rotate(0deg);
    }

    50% {
      -webkit-transform: translateX(50vw) rotate(6deg);
              transform: translateX(50vw) rotate(6deg);
    }

    60% {
      -webkit-transform: translateX(60vw) rotate(0deg);
              transform: translateX(60vw) rotate(0deg);
    }

    70% {
      -webkit-transform: translateX(70vw) rotate(6deg);
              transform: translateX(70vw) rotate(6deg);
    }

    80% {
      -webkit-transform: translateX(80vw) rotate(0deg);
              transform: translateX(80vw) rotate(0deg);
    }

    90% {
      -webkit-transform: translateX(90vw) rotate(6deg);
              transform: translateX(90vw) rotate(6deg);
    }

    100% {
      -webkit-transform: translateX(100vw) rotate(0deg);
              transform: translateX(100vw) rotate(0deg);
    }

  }


  .images2 {
    display: flex;
    justify-content: space-between;
  }

  .images2 img {
    width: 40%;
    margin-top: -18%;
    z-index: 10000;
  }



  * {
    box-sizing: border-box;
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 9rem;
    background-image: url(/static/media/ticker.81e610af.png);
    background-size: contain;
    padding-left: 100%;
    z-index: 20000000000000000;
    margin-top: -3.5px;
  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 9rem;
    line-height: 9rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 30s;
    animation-duration: 50s;
    z-index: 1000000000000;

  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 3rem;
    font-size: 55px;
    font-weight: 800;
    color: white;
    z-index: 1000000000000;

  }

  .line {
    background-color: white;
    height: 17vh;
    width: 120vw;
    border-bottom: 18px solid rgb(0, 113, 189);
    border-top: 18px solid rgb(0, 113, 189);
    rotate: 10deg;
    margin-left: -100px;
    display: flex;
    justify-content: space-between;
    margin-top: 15%;
  }

  .line2 {
    background-color: white;
    height: 15vh;
    width: 120vw;
    border-bottom: 18px solid rgb(0, 113, 189);
    border-top: 18px solid rgb(0, 113, 189);
    rotate: 5deg;
    margin-left: -40px;
    display: flex;
    margin-top: 1%;
    justify-content: space-between;
    margin-bottom: 100px;
  }

  .line2 img {
    width: 90px;
    height: 90px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .line img {
    width: 90px;
    height: 90px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .weapon {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    width: 25%;
    margin-top: -15%;
  }

  .potionBottle {
    width: 23%;
    margin-top: -10%;
    z-index: 100000;
    margin-left: 20px;
  }

  .bawl {
    width: 26%;
    margin-top: -5%;
    z-index: 100000;
    margin-left: 20px;
  }

  .ball {
    width: 9%;
    margin-top: -10%;
    z-index: 100000;
    margin-left: 250px;
    rotate: 15deg;
  }


  @-webkit-keyframes float {
    0% {
      -webkit-transform: translate(0, 0);
              transform: translate(0, 0);
    }

    50% {
      -webkit-transform: translate(0, 20px);
              transform: translate(0, 20px);
    }

    100% {
      -webkit-transform: translate(0, 0);
              transform: translate(0, 0);
    }
  }


  @keyframes float {
    0% {
      -webkit-transform: translate(0, 0);
              transform: translate(0, 0);
    }

    50% {
      -webkit-transform: translate(0, 20px);
              transform: translate(0, 20px);
    }

    100% {
      -webkit-transform: translate(0, 0);
              transform: translate(0, 0);
    }
  }

  .mashroom {
    width: 23%;
    margin-top: -20%;
    z-index: 100000;
    margin-left: 70%;
    rotate: 25deg;
  }

  .lucky {
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
  }

  .lucky img {
    width: 20%;
  }

  @-webkit-keyframes shake {
    0% {
      -webkit-transform: translate(0, 0) rotate(0);
              transform: translate(0, 0) rotate(0);
    }

    10% {
      -webkit-transform: translate(-10px, 0) rotate(-5deg);
              transform: translate(-10px, 0) rotate(-5deg);
    }

    20% {
      -webkit-transform: translate(10px, 0) rotate(5deg);
              transform: translate(10px, 0) rotate(5deg);
    }

    30% {
      -webkit-transform: translate(-10px, 0) rotate(-5deg);
              transform: translate(-10px, 0) rotate(-5deg);
    }

    40% {
      -webkit-transform: translate(10px, 0) rotate(5deg);
              transform: translate(10px, 0) rotate(5deg);
    }

    50% {
      -webkit-transform: translate(-10px, 0) rotate(-5deg);
              transform: translate(-10px, 0) rotate(-5deg);
    }

    60% {
      -webkit-transform: translate(10px, 0) rotate(5deg);
              transform: translate(10px, 0) rotate(5deg);
    }

    70% {
      -webkit-transform: translate(-10px, 0) rotate(-5deg);
              transform: translate(-10px, 0) rotate(-5deg);
    }

    80% {
      -webkit-transform: translate(10px, 0) rotate(5deg);
              transform: translate(10px, 0) rotate(5deg);
    }

    90% {
      -webkit-transform: translate(-10px, 0) rotate(-5deg);
              transform: translate(-10px, 0) rotate(-5deg);
    }

    100% {
      -webkit-transform: translate(0, 0) rotate(0);
              transform: translate(0, 0) rotate(0);
    }
  }

  @keyframes shake {
    0% {
      -webkit-transform: translate(0, 0) rotate(0);
              transform: translate(0, 0) rotate(0);
    }

    10% {
      -webkit-transform: translate(-10px, 0) rotate(-5deg);
              transform: translate(-10px, 0) rotate(-5deg);
    }

    20% {
      -webkit-transform: translate(10px, 0) rotate(5deg);
              transform: translate(10px, 0) rotate(5deg);
    }

    30% {
      -webkit-transform: translate(-10px, 0) rotate(-5deg);
              transform: translate(-10px, 0) rotate(-5deg);
    }

    40% {
      -webkit-transform: translate(10px, 0) rotate(5deg);
              transform: translate(10px, 0) rotate(5deg);
    }

    50% {
      -webkit-transform: translate(-10px, 0) rotate(-5deg);
              transform: translate(-10px, 0) rotate(-5deg);
    }

    60% {
      -webkit-transform: translate(10px, 0) rotate(5deg);
              transform: translate(10px, 0) rotate(5deg);
    }

    70% {
      -webkit-transform: translate(-10px, 0) rotate(-5deg);
              transform: translate(-10px, 0) rotate(-5deg);
    }

    80% {
      -webkit-transform: translate(10px, 0) rotate(5deg);
              transform: translate(10px, 0) rotate(5deg);
    }

    90% {
      -webkit-transform: translate(-10px, 0) rotate(-5deg);
              transform: translate(-10px, 0) rotate(-5deg);
    }

    100% {
      -webkit-transform: translate(0, 0) rotate(0);
              transform: translate(0, 0) rotate(0);
    }
  }

  @-webkit-keyframes zoom {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
      /* Start the animation with normal size image */
    }

    50% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1);
      /* Increase the size of the image halfway through the animation */
    }

    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      /* End the animation with normal size image */
    }
  }

  @keyframes zoom {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
      /* Start the animation with normal size image */
    }

    50% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1);
      /* Increase the size of the image halfway through the animation */
    }

    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      /* End the animation with normal size image */
    }
  }


  .gifImg {
    background-image: url(/static/media/pepe.ae6a97c7.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 50vw;
    height: 50vw;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    background-color: rgba(0, 0, 0, 0);

  }

  .gifImg2 {
    background-image: url(/static/media/182.f2954502.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 50vw;
    height: 50vw;
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }

  .gifImg3 {
    background-image: url(/static/media/pepe5.be18fc31.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 50vw;
    height: 50vw;
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }

  .gifImg4 {
    background-image: url(/static/media/new.3aabb953.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 50vw;
    height: 50vw;
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }

  .gifMain {
    background-color: rgba(0, 0, 0, 0);
    margin-top: -5%;
  }

  .bubble-container {
    flex-flow: column;
    display: flex;
  }

}

@media screen and (min-width: 3840px) {

  .page-loader {
    width: 100%;
    height: 100vh;
    background: #242424;
    z-index: 5000000000000000;
    position: fixed;
  }

  .loading-area {
    display: grid;
    place-items: center;
    height: 100vh;
  }

  .containerLoad {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .h1Load {
    font-size: 1.5rem;
    color: #00ff2a;
  }

  .ring {
    width: 200px;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  .ring::before {
    content: '';
    width: 200px;
    height: 200px;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
    -webkit-animation: ring 2s linear infinite;
            animation: ring 2s linear infinite;
  }

  @-webkit-keyframes ring {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
      box-shadow: 0px 5px 5px #008afa;
    }

    25% {
      -webkit-transform: rotate(90deg);
              transform: rotate(90deg);
      box-shadow: 0px 5px 5px #02b7ff;
    }

    50% {
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg);
      box-shadow: 0px 5px 5px #0151ff;
    }

    75% {
      -webkit-transform: rotate(270deg);
              transform: rotate(270deg);
      box-shadow: 0px 5px 5px #367de7;
    }

    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
      box-shadow: 0px 5px 5px #008afa;
    }
  }

  @keyframes ring {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
      box-shadow: 0px 5px 5px #008afa;
    }

    25% {
      -webkit-transform: rotate(90deg);
              transform: rotate(90deg);
      box-shadow: 0px 5px 5px #02b7ff;
    }

    50% {
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg);
      box-shadow: 0px 5px 5px #0151ff;
    }

    75% {
      -webkit-transform: rotate(270deg);
              transform: rotate(270deg);
      box-shadow: 0px 5px 5px #367de7;
    }

    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
      box-shadow: 0px 5px 5px #008afa;
    }
  }

  .rmConMainMob {
    display: none;
  }

  .audioPic {
    /* display: none;*/
    width: 100px;
    height: 100px;
    position: sticky;
    position: -webkit-sticky;
    left: 0;
    bottom: 10%;
    z-index: 1000;
  }


  .rmbg {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-bottom: 2%;
    background-color: rgba(0, 0, 0, 0);

  }

  .rmbg2 {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-top: 10%;
    padding-bottom: 2%;
    background: url(/static/media/rmbg.6ed0fbcc.jpg) no-repeat center center fixed;
    background-size: cover;
    width: 97%;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .placeAndWL {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    z-index: 100000000;

  }

  input {
    background-color: rgba(0, 30, 70, 0.568);
    border: white 1px solid;
    font-size: 20px;
    padding: 15px;
    color: #00d6fc;
    z-index: 100000000;
  }

  input:focus {
    background-color: #b3e9ff00;
    color: #00d6fc;
  }

  #wlBtn {
    background-color: rgba(0, 30, 70, 0.568);
    border: 1px solid rgb(16, 192, 223);
    color: #ffffff;
    width: 200px;
    z-index: 100000000;
  }

  #wlBtn:hover {
    font-size: 18px;
  }

  .wlLine {
    background-color: #000000;
    padding: 50px;
  }


  .cloudsMob {
    display: none;
  }

  .connectMob {
    display: none;
  }

  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-top: 100px;
    padding-bottom: 100px;
    background: rgb(52, 52, 52);
    background: linear-gradient(180deg, rgba(52, 52, 52, 1) 12%, rgba(0, 0, 0, 1) 100%);
  }

  .littleNav {
    display: none;
  }

  .miniBarMain {
    display: none;
  }

  .logosMob {
    display: none;
  }

  .allWrap {
    background: rgb(49,89,63);
background: radial-gradient(circle, rgba(49,89,63,1) 0%, rgba(52,74,61,1) 50%, rgba(35,51,41,1) 100%);
    height: 100vh;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    overflow: hidden;
  }

  .cont {
    background: radial-gradient(circle, rgba(0, 0, 0, 0.06684392507002801) 0%, rgba(0, 0, 0, 0.11166185224089631) 50%, rgba(0, 0, 0, 0.3693649334733894) 100%), url(/static/media/BG.b24e41c2.jpg) no-repeat center center fixed;
    background-size: cover;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;

  }




  .in2 p {
    font-size: 28px;
    text-align: center;
    font-family: "Iomanoid";
    letter-spacing: 4px;
    text-shadow: 0 0 10px #ffdc64;
    color: #ffdc64;

  }

  .in2 {
    z-index: 100000000;

  }

  @-webkit-keyframes heartbeat {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }

    50% {
      -webkit-transform: scale(0.9);
              transform: scale(0.9);
    }

    60% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1);
    }

    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }

  @keyframes heartbeat {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }

    50% {
      -webkit-transform: scale(0.9);
              transform: scale(0.9);
    }

    60% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1);
    }

    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }

  .logo2Intro {
    z-index: 200000000000000000000000000000000;
    width: 40%;
    padding-top: 27.9vh;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    -webkit-animation: heartbeat 2s ease-in-out infinite;
            animation: heartbeat 2s ease-in-out infinite;
  }

  .in2 img {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 47%;
    padding-top: 12vh;
    transition: -webkit-transform 0.8s;
    transition: transform 0.8s;
    transition: transform 0.8s, -webkit-transform 0.8s;
    z-index: 100000000;
  }

  .in2 img:hover {
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
  }


  .introduction {
    font-family: "Iomanoid";
  }

  .twitter img {
    width: 50px;
    height: 50px;
    margin-left: 30px;
    cursor: pointer;
    opacity: 0.6;
    transition: 0.2s ease;
    margin-top: -3%;
    z-index: 200000000000;

  }

  .twitter {
    z-index: 200000000000;
  }

  .twitter img:hover {
    opacity: 100%;
    z-index: 200000000000;

  }

  .boxWrap2Story {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-top: 5%;
    padding-bottom: 2%;
    /*background: linear-gradient(180deg, rgba(9, 28, 45, 0.10325849089635852) 0%, rgba(13, 26, 38, 0.36656381302521013) 35%, rgba(9, 28, 45, 1) 100%), url('./assets/bg1.jpg') no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;*/
    /*background: rgb(12, 22, 42);
    background: linear-gradient(180deg, rgba(12, 22, 42, 1) 11%, rgba(3, 43, 25, 1) 90%);*/
    background-color: rgba(0, 0, 0, 0);
  }

  .boxWrap2StoryR {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    /*background: linear-gradient(180deg, rgba(9, 28, 45, 0.10325849089635852) 0%, rgba(13, 26, 38, 0.36656381302521013) 35%, rgba(9, 28, 45, 1) 100%), url('./assets/bg1.jpg') no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;*/
    /*background: rgb(12, 22, 42);
    background: linear-gradient(180deg, rgba(12, 22, 42, 1) 11%, rgba(3, 43, 25, 1) 90%);*/
    background-color: rgba(0, 0, 0, 0);
    ;
  }

  .boxWrap2StoryT {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-bottom: 2%;
    /*background: linear-gradient(180deg, rgba(9, 28, 45, 0.10325849089635852) 0%, rgba(13, 26, 38, 0.36656381302521013) 35%, rgba(9, 28, 45, 1) 100%), url('./assets/bg1.jpg') no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;*/
    /*background: rgb(12, 22, 42);
    background: linear-gradient(180deg, rgba(12, 22, 42, 1) 11%, rgba(3, 43, 25, 1) 90%);*/
    background-color: rgba(0, 0, 0, 0);
    ;
  }


  .boxWrap2Story2 {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0);

    padding-bottom: 2%;
  }

  .boxWrap2Mint {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-size: cover;
    padding-bottom: 2%;
    z-index: 100000000000;
  }

  .boxWrap2SubR {
    justify-content: space-between;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .boxWrap2Sub1 {}

  .boxWrap2Sub {
    justify-content: space-between;
    width: 98%;
    margin-left: auto;
    margin-right: auto;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.164) 0%, rgba(0, 0, 0, 0.301) 50%, rgba(0, 0, 0, 0.534) 100%), url('https://cdn.discordapp.com/attachments/1028976349920112681/1139504255498195055/NFTHouse9_coins_in_space_cartoon_theme_326f3ecc-8f98-4844-81b1-49ce9a21ba21.png') no-repeat center center fixed;
    background-size: cover;
    padding-top: 10%;
    border-radius: 60px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .boxWrap2SubT {
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    background-color: #22e2d9;
  }

  .storyH {
    font-size: 200px;
    color: black;
    font-weight: bolder;
    text-align: center;
    margin-bottom: 10px;
    font-style: normal;
    font-family: 'Exo', sans-serif;
  }

  .outer-div {
    width: 500px;
    height: 100px;
    background-color: #fff;
    transition: background-color 1s ease;
  }

  .storyHK {
    font-size: 80px;
    color: white;
    font-weight: 500;
    text-align: center;
    font-family: "Iomanoid";
    margin-bottom: 2%;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px rgba(255, 255, 0, 0.616), 0 -10px 20px #ff80008c, 0 -18px 40px rgba(255, 0, 0, 0.719);
  }

  .storyH2 {
    font-size: 490px;
    color: rgb(255, 255, 255);
    font-weight: bolder;
    text-align: center;
    margin-bottom: 2%;
    font-family: 'Exo', sans-serif;
  }

  .storyH3 {
    font-size: 100px;
    color: rgb(255, 255, 255);
    font-weight: bolder;
    text-align: center;
    margin-bottom: 2%;
    font-family: 'Exo', sans-serif;
    background: rgb(88, 101, 242);
    background: linear-gradient(180deg, rgb(36, 51, 219) 12%, rgb(67, 3, 185) 100%);
    width: 700px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
    cursor: pointer;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    margin-top: -15%;
  }

  .storyH3:hover {
    background: rgb(38, 47, 222);
    background: linear-gradient(180deg, rgba(38, 47, 222, 1) 12%, rgba(176, 68, 242, 1) 100%);
  }

  /* Animation */
  @-webkit-keyframes fill-div {
    from {
      width: 0;
    }

    to {
      width: 100%;
    }
  }
  @keyframes fill-div {
    from {
      width: 0;
    }

    to {
      width: 100%;
    }
  }

  .bg {
    background-color: #17237e;
    padding-left: 50px;
    padding-right: 50px;
  }

  .storyH2-2 {
    font-size: 50px;
    color: white;
    font-weight: 500;
    text-align: right;
    font-family: "Iomanoid";
    margin-bottom: 10px;
    text-shadow: 0 0 10px #ce9381;
  }

  .about {
    color: white;
    font-size: 18px;
    text-align: left;
    display: flex;
    justify-content: space-evenly;
    margin-left: auto;
    margin-right: auto;
  }

  .aboutKingdom {
    color: white;
    font-size: 18px;
    text-align: left;
    display: flex;
    justify-content: space-evenly;
  }

  .kingdomsSection {
    color: white;
    font-size: 18px;
    text-align: left;
    display: flex;
    justify-content: space-evenly;
    flex-flow: column nowrap;
  }

  .aboutP {
    width: 50%;
    margin-bottom: 50px;
    margin-top: 30px;
    margin-right: auto;
    justify-content: center;
  }

  .aboutPNew {
    width: 50%;
    margin-bottom: 50px;
    margin-top: 30px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
  }

  .aboutPS {
    width: 45%;
    margin-bottom: 50px;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .aboutP3 {
    width: 60%;
    margin-top: auto;
    margin-bottom: auto;
    margin-bottom: 50px;
  }

  .aboutP3 p {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 23px;
  }

  .aboutP p {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 22px;
  }

  .aboutPNew p {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 70px;
  }

  .aboutPDiv {
    background: rgb(101, 161, 90);
    background: linear-gradient(93deg, rgba(101, 161, 90, 1) 0%, rgba(32, 71, 128, 1) 50%);
    -webkit-clip-path: polygon(0 0, 100% 0%, 98% 100%, 0% 100%);
            clip-path: polygon(0 0, 100% 0%, 98% 100%, 0% 100%);
    width: 1600px;
    margin-bottom: 16px;
    margin-top: 16px;
    padding: 40px;
    font-size: 50px;
    line-height: 60px;
  }

  .aboutPDiv2 {
    background-color: #2a32438a;
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
    -webkit-clip-path: polygon(0 0, 100% 0%, 98% 100%, 0% 100%);
            clip-path: polygon(0 0, 100% 0%, 98% 100%, 0% 100%);
    margin-bottom: 95px;
    margin-top: 95px;
    padding: 60px;
    font-size: 75px;
    line-height: 50px;
    color: white;
  }

  .hl {
    color: #1ee03e;
  }


  .bigLetters {
    font-size: 100px;
    line-height: 130px;
  }

  .ironfist {
    margin-top: auto;
    margin-left: -90px;
    z-index: 100000;
  }

  .ironfist img {
    width: 190px;
    height: 190px;

  }

  .storyConAndImg {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .aboutP2 {
    width: 50%;
    margin-top: auto;
    margin-bottom: auto;
  }

  .zeus {
    width: 45%;
    height: 45%;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    -webkit-animation: floating 3s ease-in-out infinite;
            animation: floating 3s ease-in-out infinite;
  }

  .zeus2 {
    width: 35%;
    height: 35%;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;

  }

  @-webkit-keyframes floating {

    0%,
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    50% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
    }
  }

  @keyframes floating {

    0%,
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    50% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
    }
  }

  .logos {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
  }

  .logos_mobile {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
    display: none;
  }

  .logoF img {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 40%;
    display: flex;
  }


  .facetoface img {
    width: 30%;
  }

  .facetoface {
    display: flex;
    justify-content: space-between;
    margin-top: -27%;
  }

  .footerImg {
    width: 55px;
    height: 55px;
    display: flex;
    justify-content: space-evenly;
  }

  .footerImg img {
    margin-left: 15px;
    margin-right: 15px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
  }

  .footerImg img:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    cursor: pointer;
  }

  .footer2 {
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .copyright {
    color: rgb(255, 255, 255);
    text-align: right;
    font-size: 40px;
  }

  .footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: rgba(102, 102, 102, 0);
    width: 100%;
  }

  .sword {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 10%;
  }

  .devs {
    color: rgba(255, 255, 255, 0.774);
    text-align: center;
    margin-top: 80px;
  }

  .devs img {
    width: 30px;
    height: 30px;
    opacity: 0.9;
    transition: 0.3s ease;
  }

  .devs:hover {
    color: rgb(255, 255, 255);
    cursor: pointer;
  }

  .devs img:hover {
    width: 30px;
    height: 30px;
  }

  a {
    color: #d1fdfb;
    text-decoration: none;
  }

  a:hover {
    color: #99faf5;
    text-decoration: none;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-bottom: 55px;
    padding-top: 55px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #020529b7;

  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    width: 95%;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
  }

  .connect div {
    margin-left: 55px;
    margin-right: 55px;
    color: white;
    margin-top: auto;
    justify-content: space-evenly;
    display: flex;
    cursor: pointer;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    text-shadow: -1px -1px 1px #f899345d, 1px 1px 1px #fe921e6c;

  }

  .connect div:hover {
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
  }

  .icons {
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-left: 10px;
  }

  .icons img {
    width: 80px;
    height: 80px;
    margin-left: 30px;
    margin-right: 30px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    z-index: 10000000;
  }

  .icons img:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    cursor: pointer;
  }

  #fontSize {
    font-size: 52px;
    z-index: 10000000;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    align-items: center;

  }

  #fontSize-2 {
    font-size: 25px;
    z-index: 10000000;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    align-items: center;
  }

  /* CSS */
  .button-92 {
    --c: #fff;
    /* text color */
    background: linear-gradient(90deg, #0000 33%, #fff5, #0000 67%) 100%/300% no-repeat,
      #009218;
    background: linear-gradient(90deg, #0000 33%, #fff5, #0000 67%) var(--_p, 100%)/300% no-repeat,
      #009218;
    /* background color */
    color: #0000;
    border: none;
    -webkit-transform: perspective(500px) rotateY(calc(20deg * -1));
            transform: perspective(500px) rotateY(calc(20deg * -1));
    -webkit-transform: perspective(500px) rotateY(calc(20deg * var(--_i, -1)));
            transform: perspective(500px) rotateY(calc(20deg * var(--_i, -1)));
    text-shadow: calc(-1 * 0.08em) -.01em 0 var(--c),
      calc(-1 * -0.08em) .01em 2px #0004;
    text-shadow: calc(var(--_i, -1) * 0.08em) -.01em 0 var(--c),
      calc(var(--_i, -1) * -0.08em) .01em 2px #0004;
    outline-offset: .1em;
    transition: 0.3s;
  }

  .button-92:hover,
  .button-92:focus-visible {
    --_p: 0%;
    --_i: 1;
  }

  .button-92:active {
    text-shadow: none;
    color: var(--c);
    box-shadow: inset 0 0 9e9q #0005;
    transition: 0s;
  }

  .button-92 {
    font-weight: bold;
    font-size: 5rem;
    margin: 0;
    cursor: pointer;
    padding: .1em .3em;
  }

  .title {
    color: white;
    text-shadow: -1px -1px 1px #f899345d, 1px 1px 1px #fe921e6c;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: center;
    font-size: 95px;

  }

  .logoUp {
    width: 130px;
    height: 130px;
    margin-top: -20px;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;
  }

  /* CSS */
  .button-62 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    background-color: #bb5a3c;
    font-size: 18px;
    padding: 13px;
    width: 200px;
    color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    transition: 0.3s ease;
    border-radius: 7px;
    border: none;
    font-weight: 500;
  }

  .button-62:hover {
    color: rgb(219, 219, 219);
  }

  .logo2 img {
    width: 75px;
    height: 75px;
  }

  .downImg {
    width: 35px;
    -webkit-animation: arrow_down_animation 2s ease-in-out infinite;
            animation: arrow_down_animation 2s ease-in-out infinite;
    cursor: pointer;
  }


  @-webkit-keyframes arrow_down_animation {

    0% {
      -webkit-transform: translate3d(0, 0, 0) scale(1, 1);
              transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;
    }

    30% {
      opacity: 1;
    }

    100% {
      -webkit-transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
              transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;

    }

  }


  @keyframes arrow_down_animation {

    0% {
      -webkit-transform: translate3d(0, 0, 0) scale(1, 1);
              transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;
    }

    30% {
      opacity: 1;
    }

    100% {
      -webkit-transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
              transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;

    }

  }

  .downImgDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -25px;

  }

  .down {
    text-align: center;
    background-color: rgba(0, 0, 0, 0);
  }

  .stickyBtn2 {
    width: 65px;
    height: 65px;
    z-index: 1;
  }

  #sticky-button {
    position: -webkit-sticky;
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 15%;
    margin-left: 92%;
    margin-top: -8%;
    cursor: pointer;
    transition: -webkit-transform .4s;
    transition: transform .4s;
    transition: transform .4s, -webkit-transform .4s;
    z-index: 1;

  }

  .stickyBtn2:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    opacity: 100%;
    z-index: 1;

  }

  .clouds2 {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  .cloud {
    position: absolute;
    top: 0;

  }

  .cloud1 {
    left: 10vw;

  }

  .cloud1 img {
    width: 120%;
    margin-top: -13%;
  }

  .cloud2 img {
    width: 70%;
    margin-top: -8%;
  }

  .cloud3 img {
    width: 100%;

  }

  .cloud1-1 {
    left: 70vw;
    -webkit-animation-name: move-clouds;
            animation-name: move-clouds;
    -webkit-animation-duration: 600s;
            animation-duration: 600s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
  }

  .cloud2 {
    left: 5px;
    -webkit-animation-name: move-clouds;
            animation-name: move-clouds;
    -webkit-animation-duration: 200s;
            animation-duration: 200s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
  }

  .cloud3 {
    left: 3px;

  }

  .cloud3-3 {
    left: 10px;
    -webkit-animation-name: move-clouds;
            animation-name: move-clouds;
    -webkit-animation-duration: 480s;
            animation-duration: 480s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
  }

  .cloud4 {
    left: 10vw;
  }

  .cloud4 img {
    width: 70%;
    margin-top: -5%;
    margin-left: -5%;
  }

  .cloud3 img {
    width: 25%;
  }

  @-webkit-keyframes move-clouds {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(110vw);
              transform: translateX(110vw);
    }
  }

  @keyframes move-clouds {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(110vw);
              transform: translateX(110vw);
    }
  }

  .cloudsLine img {
    width: 100%;
    display: flex;
  }

  .Q {
    font-size: 30px;
    color: white;
    font-weight: 500;
    margin-bottom: 10px;
    text-shadow: 0 0 10px #ecc46d;
  }

  .rmCon {
    color: white;
    font-size: 18px;
  }

  .rmConAndImg {
    margin-bottom: 30px;
    margin-top: 30px;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .rmCon img {
    width: 30px;
    height: 30px;
  }

  .line {
    width: 100%;
  }

  .connect2-2 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    box-shadow: rgba(0, 0, 0, 0.493) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    border: 12px #7f8082;

    border: #ffa528 5px groove;
  }

  .parrot {
    width: 70%;
  }

  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .totalSupply {
    text-align: center;
    font-size: 60px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(255, 255, 255);
    letter-spacing: 1px;
    margin-top: 1%;
  }

  .price {
    text-align: center;
    font-size: 25px;
    color: white;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }


  .center {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
    z-index: 10000000;

  }

  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: none;

    cursor: pointer;
    background-color: #6cfc1900;
    color: rgb(255, 255, 255);
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: 2px solid rgb(255, 255, 255);
    z-index: 1000000000000000;

  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: none;

    cursor: pointer;
    border: 2px solid rgb(255, 255, 255);
    color: rgb(255, 255, 255);
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    background-color: #00000000;
    z-index: 1000000000000000;

  }


  .btnfos-0-2:hover:active {
    background-color: #0e2942;
  }


  .btnfos-0-2-2:hover:active {
    background-color: #0e2942;
  }


  .btnfos-0-3 {
    margin-top: 20px;
    background: rgb(0, 120, 235);
    background: linear-gradient(180deg, rgba(0, 120, 235, 1) 0%, rgba(89, 154, 215, 1) 50%, rgba(0, 120, 235, 1) 100%);
    color: #ffffff;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 28px;
    padding-left: 30px;
    padding-right: 30px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    border: 6px rgb(0, 0, 0);
    border-style: double;
    z-index: 1000000000000000;
    transition: transform .2s;
  }

  .btnfos-0-3:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

  .nftamount {
    color: #ecaa2f;
    font-size: 85px;
  }

  .mintbuttondiv {
    text-align: center;
    z-index: 10000000;

  }

  .successfully {
    text-align: center;
    margin-top: 3%;
    color: #1ee03e;
    font-size: 30px;
    text-shadow: 0 0 20px #9bf179;
  }

  .errorMessage {
    margin-top: 3%;
    font-size: 20px;
    color: #fc4741;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 3%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .loadingText {
    font-size: 20px;
    text-align: center;
    color: rgb(240, 161, 97);
  }

  .loadTextSub {
    text-align: center;
    color: rgba(255, 255, 255, 0.815);
    font-size: 16px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    -webkit-animation: dots 1s steps(5, end) infinite;
            animation: dots 1s steps(5, end) infinite;
  }

  @-webkit-keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(214, 80, 71);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(209, 119, 45),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(214, 80, 71);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(209, 119, 45),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .rm2Box {
    display: flex;
    justify-content: center;
    margin-right: auto;

  }

  .rm3Box {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-top: 300px;
    margin-bottom: 300px;
  }

  .rmbox1 {
    -webkit-clip-path: polygon(0 0, 96% 0, 100% 100%, 0% 100%);
            clip-path: polygon(0 0, 96% 0, 100% 100%, 0% 100%);
    font-size: 150px;
    background-color: #000000a1;
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
    color: rgb(255, 255, 255);
    width: 1200px;
    padding: 65px;
    text-align: center;

  }

  .rmbox2 {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 4% 100%);
            clip-path: polygon(0 0, 100% 0, 100% 100%, 4% 100%);
    font-size: 100px;
    background-color: #007906c2;
    color: white;
    width: 1200px;
    padding: 65px;
    text-align: center;
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .rmbox1-3 {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 4% 100%);
            clip-path: polygon(0 0, 100% 0, 100% 100%, 4% 100%);
    text-align: center;
    font-size: 150px;
    background-color: #000000a1;

    -webkit-backdrop-filter: blur(5px);

            backdrop-filter: blur(5px);
    color: rgb(255, 255, 255);
    width: 1200px;
    padding: 65px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }


  .rmbox2-3 {
    font-size: 100px;
    -webkit-clip-path: polygon(0 0, 96% 0, 100% 100%, 0% 100%);
            clip-path: polygon(0 0, 96% 0, 100% 100%, 0% 100%);
    text-align: center;
    background-color: #007906c2;
    color: white;
    width: 1200px;
    padding: 65px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
    text-align: center;

  }

  .rmConMain {
    display: flex;
    justify-content: space-between;
    flex-flow: column nowrap;
    margin-top: 80px;
  }

  .scroll-effect-container {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    margin-bottom: 30px;

  }

  .scroll-effect {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: #ffffff00;
    text-align: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding-left: 50px;
    padding-right: 50px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

  }

  .scroll-effect-relic {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: #ffffff00;
    text-align: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding-left: 50px;
    padding-right: 50px;

  }

  .scroll-effect-mint {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: #ffffff00;
    text-align: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding-left: 50px;
    padding-right: 50px;

  }

  .scroll-effect-tokenomics {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: #ffffff00;
    text-align: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding-left: 50px;
    padding-right: 50px;

  }

  .scroll-effect-footer {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: #ffffff00;
    text-align: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding-left: 50px;
    padding-right: 50px;

  }


  .scroll-effect-rm {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: #ffffff00;
    text-align: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding-left: 50px;
    padding-right: 50px;

  }


  .scroll-effect-footer {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: #ffffff00;
    text-align: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding-left: 50px;
    padding-right: 50px;
  }

  @-webkit-keyframes fill-effect {
    from {
      width: 0%;
    }

    to {
      width: 100%;
    }
  }

  @keyframes fill-effect {
    from {
      width: 0%;
    }

    to {
      width: 100%;
    }
  }

  .animate {
    /*background: rgb(87, 102, 244);
    background: linear-gradient(270deg, rgba(87, 102, 244, 1) 0%, rgba(36, 46, 167, 1) 51%);*/
    background-color: #000000a1;
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
    background-repeat: no-repeat;
    background-size: 0% 100%;
    transition: background-size 1s ease-in-out;
    -webkit-animation: fill-effect 1s ease-in-out;
            animation: fill-effect 1s ease-in-out;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }

  .animate.animate {
    background-size: 100% 100%;

  }

  /*.animateC{
    background: rgb(244, 87, 179);
    background: linear-gradient(270deg, rgb(238, 77, 190) 0%, rgb(139, 8, 89) 51%);  background-repeat: no-repeat;
    background-size: 0% 100%;
    transition: background-size 0.5s ease-in-out;
    animation: fill-effect 0.5s ease-in-out;
    font-style: italic;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }*/

  .animateC.animateC {
    background-size: 100% 100%;

  }

  /* Define the walkingNft class */
  .walkingNft {
    width: 30%;
    position: absolute;
    bottom: -0.8%;
    left: 0;
    -webkit-animation: walk 20s linear infinite;
            animation: walk 20s linear infinite;

  }

  /* Define the walk animation */
  @-webkit-keyframes walk {
    0% {
      -webkit-transform: translateX(-5vw);
              transform: translateX(-5vw);
    }

    10% {
      -webkit-transform: translateX(10vw) rotate(6deg);
              transform: translateX(10vw) rotate(6deg);
    }

    20% {
      -webkit-transform: translateX(20vw) rotate(0deg);
              transform: translateX(20vw) rotate(0deg);
    }

    30% {
      -webkit-transform: translateX(30vw) rotate(6deg);
              transform: translateX(30vw) rotate(6deg);
    }

    40% {
      -webkit-transform: translateX(40vw) rotate(0deg);
              transform: translateX(40vw) rotate(0deg);
    }

    50% {
      -webkit-transform: translateX(50vw) rotate(6deg);
              transform: translateX(50vw) rotate(6deg);
    }

    60% {
      -webkit-transform: translateX(60vw) rotate(0deg);
              transform: translateX(60vw) rotate(0deg);
    }

    70% {
      -webkit-transform: translateX(70vw) rotate(6deg);
              transform: translateX(70vw) rotate(6deg);
    }

    80% {
      -webkit-transform: translateX(80vw) rotate(0deg);
              transform: translateX(80vw) rotate(0deg);
    }

    90% {
      -webkit-transform: translateX(90vw) rotate(6deg);
              transform: translateX(90vw) rotate(6deg);
    }

    100% {
      -webkit-transform: translateX(100vw) rotate(0deg);
              transform: translateX(100vw) rotate(0deg);
    }

  }
  @keyframes walk {
    0% {
      -webkit-transform: translateX(-5vw);
              transform: translateX(-5vw);
    }

    10% {
      -webkit-transform: translateX(10vw) rotate(6deg);
              transform: translateX(10vw) rotate(6deg);
    }

    20% {
      -webkit-transform: translateX(20vw) rotate(0deg);
              transform: translateX(20vw) rotate(0deg);
    }

    30% {
      -webkit-transform: translateX(30vw) rotate(6deg);
              transform: translateX(30vw) rotate(6deg);
    }

    40% {
      -webkit-transform: translateX(40vw) rotate(0deg);
              transform: translateX(40vw) rotate(0deg);
    }

    50% {
      -webkit-transform: translateX(50vw) rotate(6deg);
              transform: translateX(50vw) rotate(6deg);
    }

    60% {
      -webkit-transform: translateX(60vw) rotate(0deg);
              transform: translateX(60vw) rotate(0deg);
    }

    70% {
      -webkit-transform: translateX(70vw) rotate(6deg);
              transform: translateX(70vw) rotate(6deg);
    }

    80% {
      -webkit-transform: translateX(80vw) rotate(0deg);
              transform: translateX(80vw) rotate(0deg);
    }

    90% {
      -webkit-transform: translateX(90vw) rotate(6deg);
              transform: translateX(90vw) rotate(6deg);
    }

    100% {
      -webkit-transform: translateX(100vw) rotate(0deg);
              transform: translateX(100vw) rotate(0deg);
    }

  }


  .images2 {
    display: flex;
    justify-content: space-between;
  }

  .images2 img {
    width: 40%;
    margin-top: -20%;
    z-index: 10000;
  }



  * {
    box-sizing: border-box;
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 15rem;
    background-image: url(/static/media/ticker.81e610af.png);
    background-size: contain;
    padding-left: 100%;
    z-index: 20000000000000000;
    margin-top: -3.5px;
  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 15rem;
    line-height: 15rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 30s;
    animation-duration: 50s;
    z-index: 1000000000000;
  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 4.5rem;
    font-size: 100px;
    font-weight: 800;
    color: white;
    z-index: 1000000000000;

  }

  .line {
    background-color: white;
    height: 12vh;
    width: 120vw;
    border-bottom: 25px solid rgb(0, 113, 189);
    border-top: 25px solid rgb(0, 113, 189);
    rotate: 10deg;
    margin-left: -130px;
    display: flex;
    justify-content: space-between;
    margin-top: 15%;
  }

  .line2 {
    background-color: white;
    height: 12vh;
    width: 120vw;
    border-bottom: 25px solid rgb(0, 113, 189);
    border-top: 25px solid rgb(0, 113, 189);
    rotate: 3deg;
    margin-left: -40px;
    display: flex;
    margin-top: 1%;
    justify-content: space-between;
    margin-bottom: 100px;
  }

  .line2 img {
    width: 110px;
    height: 110px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .line img {
    width: 110px;
    height: 110px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .weapon {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    width: 25%;
    margin-top: -15%;
  }

  .potionBottle {
    width: 23%;
    margin-top: -10%;
    z-index: 100000;
    margin-left: 20px;
  }

  .bawl {
    width: 26%;
    margin-top: -5%;
    z-index: 100000;
    margin-left: 20px;
  }

  .ball {
    width: 9%;
    margin-top: -8%;
    z-index: 100000;
    margin-left: 400px;
    rotate: 15deg;
  }


  @-webkit-keyframes float {
    0% {
      -webkit-transform: translate(0, 0);
              transform: translate(0, 0);
    }

    50% {
      -webkit-transform: translate(0, 20px);
              transform: translate(0, 20px);
    }

    100% {
      -webkit-transform: translate(0, 0);
              transform: translate(0, 0);
    }
  }


  @keyframes float {
    0% {
      -webkit-transform: translate(0, 0);
              transform: translate(0, 0);
    }

    50% {
      -webkit-transform: translate(0, 20px);
              transform: translate(0, 20px);
    }

    100% {
      -webkit-transform: translate(0, 0);
              transform: translate(0, 0);
    }
  }

  .mashroom {
    width: 23%;
    margin-top: -20%;
    z-index: 100000;
    margin-left: 70%;
    rotate: 25deg;
  }

  .lucky {
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
  }

  .lucky img {
    width: 20%;
  }

  @-webkit-keyframes shake {
    0% {
      -webkit-transform: translate(0, 0) rotate(0);
              transform: translate(0, 0) rotate(0);
    }

    10% {
      -webkit-transform: translate(-10px, 0) rotate(-5deg);
              transform: translate(-10px, 0) rotate(-5deg);
    }

    20% {
      -webkit-transform: translate(10px, 0) rotate(5deg);
              transform: translate(10px, 0) rotate(5deg);
    }

    30% {
      -webkit-transform: translate(-10px, 0) rotate(-5deg);
              transform: translate(-10px, 0) rotate(-5deg);
    }

    40% {
      -webkit-transform: translate(10px, 0) rotate(5deg);
              transform: translate(10px, 0) rotate(5deg);
    }

    50% {
      -webkit-transform: translate(-10px, 0) rotate(-5deg);
              transform: translate(-10px, 0) rotate(-5deg);
    }

    60% {
      -webkit-transform: translate(10px, 0) rotate(5deg);
              transform: translate(10px, 0) rotate(5deg);
    }

    70% {
      -webkit-transform: translate(-10px, 0) rotate(-5deg);
              transform: translate(-10px, 0) rotate(-5deg);
    }

    80% {
      -webkit-transform: translate(10px, 0) rotate(5deg);
              transform: translate(10px, 0) rotate(5deg);
    }

    90% {
      -webkit-transform: translate(-10px, 0) rotate(-5deg);
              transform: translate(-10px, 0) rotate(-5deg);
    }

    100% {
      -webkit-transform: translate(0, 0) rotate(0);
              transform: translate(0, 0) rotate(0);
    }
  }

  @keyframes shake {
    0% {
      -webkit-transform: translate(0, 0) rotate(0);
              transform: translate(0, 0) rotate(0);
    }

    10% {
      -webkit-transform: translate(-10px, 0) rotate(-5deg);
              transform: translate(-10px, 0) rotate(-5deg);
    }

    20% {
      -webkit-transform: translate(10px, 0) rotate(5deg);
              transform: translate(10px, 0) rotate(5deg);
    }

    30% {
      -webkit-transform: translate(-10px, 0) rotate(-5deg);
              transform: translate(-10px, 0) rotate(-5deg);
    }

    40% {
      -webkit-transform: translate(10px, 0) rotate(5deg);
              transform: translate(10px, 0) rotate(5deg);
    }

    50% {
      -webkit-transform: translate(-10px, 0) rotate(-5deg);
              transform: translate(-10px, 0) rotate(-5deg);
    }

    60% {
      -webkit-transform: translate(10px, 0) rotate(5deg);
              transform: translate(10px, 0) rotate(5deg);
    }

    70% {
      -webkit-transform: translate(-10px, 0) rotate(-5deg);
              transform: translate(-10px, 0) rotate(-5deg);
    }

    80% {
      -webkit-transform: translate(10px, 0) rotate(5deg);
              transform: translate(10px, 0) rotate(5deg);
    }

    90% {
      -webkit-transform: translate(-10px, 0) rotate(-5deg);
              transform: translate(-10px, 0) rotate(-5deg);
    }

    100% {
      -webkit-transform: translate(0, 0) rotate(0);
              transform: translate(0, 0) rotate(0);
    }
  }

  @-webkit-keyframes zoom {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
      /* Start the animation with normal size image */
    }

    50% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1);
      /* Increase the size of the image halfway through the animation */
    }

    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      /* End the animation with normal size image */
    }
  }

  @keyframes zoom {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
      /* Start the animation with normal size image */
    }

    50% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1);
      /* Increase the size of the image halfway through the animation */
    }

    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      /* End the animation with normal size image */
    }
  }


  .gifImg {
    background-image: url(/static/media/pepe.ae6a97c7.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 50vw;
    height: 50vw;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    background-color: rgba(0, 0, 0, 0);

  }

  .gifImg2 {
    background-image: url(/static/media/182.f2954502.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 50vw;
    height: 50vw;
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }

  .gifImg3 {
    background-image: url(/static/media/pepe5.be18fc31.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 50vw;
    height: 50vw;
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }

  .gifImg4 {
    background-image: url(/static/media/new.3aabb953.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 50vw;
    height: 50vw;
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }

  .gifMain {
    background-color: rgba(0, 0, 0, 0);
    margin-top: -5%;
  }

  .bubble-container {
    flex-flow: column;
    display: flex;
  }

}

.circle-container2 {
  position: fixed;
  -webkit-transform: translateY(-10vh);
          transform: translateY(-10vh);
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  height: 0;
}

.circle-container2 .circle {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  mix-blend-mode: screen;
  background-image: radial-gradient(#ffffff, rgba(250, 223, 133, 0.753) 10%, rgba(153, 255, 255, 0) 56%);
  -webkit-animation: fadein-frames 200ms infinite, scale-frames 2s infinite;
          animation: fadein-frames 200ms infinite, scale-frames 2s infinite;
}


@-webkit-keyframes fade-frames {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.7;
  }

  100% {
    opacity: 1;
  }
}


@keyframes fade-frames {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.7;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes scale-frames {
  0% {
    -webkit-transform: scale3d(0.4, 0.4, 1);
            transform: scale3d(0.4, 0.4, 1);
  }

  50% {
    -webkit-transform: scale3d(2.2, 2.2, 1);
            transform: scale3d(2.2, 2.2, 1);
  }

  100% {
    -webkit-transform: scale3d(0.4, 0.4, 1);
            transform: scale3d(0.4, 0.4, 1);
  }
}

@keyframes scale-frames {
  0% {
    -webkit-transform: scale3d(0.4, 0.4, 1);
            transform: scale3d(0.4, 0.4, 1);
  }

  50% {
    -webkit-transform: scale3d(2.2, 2.2, 1);
            transform: scale3d(2.2, 2.2, 1);
  }

  100% {
    -webkit-transform: scale3d(0.4, 0.4, 1);
            transform: scale3d(0.4, 0.4, 1);
  }
}

.circle-container2:nth-child(1) {
  width: 1px;
  height: 1px;
  -webkit-animation-name: move-frames-1;
          animation-name: move-frames-1;
  -webkit-animation-duration: 29142ms;
          animation-duration: 29142ms;
  -webkit-animation-delay: 19846ms;
          animation-delay: 19846ms;
}

@-webkit-keyframes move-frames-1 {
  from {
    -webkit-transform: translate3d(48vw, 101vh, 0);
            transform: translate3d(48vw, 101vh, 0);
  }

  to {
    -webkit-transform: translate3d(35vw, -102vh, 0);
            transform: translate3d(35vw, -102vh, 0);
  }
}

@keyframes move-frames-1 {
  from {
    -webkit-transform: translate3d(48vw, 101vh, 0);
            transform: translate3d(48vw, 101vh, 0);
  }

  to {
    -webkit-transform: translate3d(35vw, -102vh, 0);
            transform: translate3d(35vw, -102vh, 0);
  }
}

.circle-container2:nth-child(1) .circle {
  -webkit-animation-delay: 2519ms;
          animation-delay: 2519ms;
}

.circle-container2:nth-child(2) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-2;
          animation-name: move-frames-2;
  -webkit-animation-duration: 36368ms;
          animation-duration: 36368ms;
  -webkit-animation-delay: 19125ms;
          animation-delay: 19125ms;
}

@-webkit-keyframes move-frames-2 {
  from {
    -webkit-transform: translate3d(36vw, 107vh, 0);
            transform: translate3d(36vw, 107vh, 0);
  }

  to {
    -webkit-transform: translate3d(68vw, -128vh, 0);
            transform: translate3d(68vw, -128vh, 0);
  }
}

@keyframes move-frames-2 {
  from {
    -webkit-transform: translate3d(36vw, 107vh, 0);
            transform: translate3d(36vw, 107vh, 0);
  }

  to {
    -webkit-transform: translate3d(68vw, -128vh, 0);
            transform: translate3d(68vw, -128vh, 0);
  }
}

.circle-container2:nth-child(2) .circle {
  -webkit-animation-delay: 1084ms;
          animation-delay: 1084ms;
}

.circle-container2:nth-child(3) {
  width: 7px;
  height: 7px;
  -webkit-animation-name: move-frames-3;
          animation-name: move-frames-3;
  -webkit-animation-duration: 33108ms;
          animation-duration: 33108ms;
  -webkit-animation-delay: 1936ms;
          animation-delay: 1936ms;
}

@-webkit-keyframes move-frames-3 {
  from {
    -webkit-transform: translate3d(51vw, 104vh, 0);
            transform: translate3d(51vw, 104vh, 0);
  }

  to {
    -webkit-transform: translate3d(92vw, -124vh, 0);
            transform: translate3d(92vw, -124vh, 0);
  }
}

@keyframes move-frames-3 {
  from {
    -webkit-transform: translate3d(51vw, 104vh, 0);
            transform: translate3d(51vw, 104vh, 0);
  }

  to {
    -webkit-transform: translate3d(92vw, -124vh, 0);
            transform: translate3d(92vw, -124vh, 0);
  }
}

.circle-container2:nth-child(3) .circle {
  -webkit-animation-delay: 1406ms;
          animation-delay: 1406ms;
}

.circle-container2:nth-child(4) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-4;
          animation-name: move-frames-4;
  -webkit-animation-duration: 28877ms;
          animation-duration: 28877ms;
  -webkit-animation-delay: 30762ms;
          animation-delay: 30762ms;
}

@-webkit-keyframes move-frames-4 {
  from {
    -webkit-transform: translate3d(50vw, 108vh, 0);
            transform: translate3d(50vw, 108vh, 0);
  }

  to {
    -webkit-transform: translate3d(57vw, -113vh, 0);
            transform: translate3d(57vw, -113vh, 0);
  }
}

@keyframes move-frames-4 {
  from {
    -webkit-transform: translate3d(50vw, 108vh, 0);
            transform: translate3d(50vw, 108vh, 0);
  }

  to {
    -webkit-transform: translate3d(57vw, -113vh, 0);
            transform: translate3d(57vw, -113vh, 0);
  }
}

.circle-container2:nth-child(4) .circle {
  -webkit-animation-delay: 2955ms;
          animation-delay: 2955ms;
}

.circle-container2:nth-child(5) {
  width: 1px;
  height: 1px;
  -webkit-animation-name: move-frames-5;
          animation-name: move-frames-5;
  -webkit-animation-duration: 35824ms;
          animation-duration: 35824ms;
  -webkit-animation-delay: 31074ms;
          animation-delay: 31074ms;
}

@-webkit-keyframes move-frames-5 {
  from {
    -webkit-transform: translate3d(50vw, 108vh, 0);
            transform: translate3d(50vw, 108vh, 0);
  }

  to {
    -webkit-transform: translate3d(31vw, -124vh, 0);
            transform: translate3d(31vw, -124vh, 0);
  }
}

@keyframes move-frames-5 {
  from {
    -webkit-transform: translate3d(50vw, 108vh, 0);
            transform: translate3d(50vw, 108vh, 0);
  }

  to {
    -webkit-transform: translate3d(31vw, -124vh, 0);
            transform: translate3d(31vw, -124vh, 0);
  }
}

.circle-container2:nth-child(5) .circle {
  -webkit-animation-delay: 1469ms;
          animation-delay: 1469ms;
}

.circle-container2:nth-child(6) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-6;
          animation-name: move-frames-6;
  -webkit-animation-duration: 32429ms;
          animation-duration: 32429ms;
  -webkit-animation-delay: 35335ms;
          animation-delay: 35335ms;
}

@-webkit-keyframes move-frames-6 {
  from {
    -webkit-transform: translate3d(31vw, 101vh, 0);
            transform: translate3d(31vw, 101vh, 0);
  }

  to {
    -webkit-transform: translate3d(86vw, -116vh, 0);
            transform: translate3d(86vw, -116vh, 0);
  }
}

@keyframes move-frames-6 {
  from {
    -webkit-transform: translate3d(31vw, 101vh, 0);
            transform: translate3d(31vw, 101vh, 0);
  }

  to {
    -webkit-transform: translate3d(86vw, -116vh, 0);
            transform: translate3d(86vw, -116vh, 0);
  }
}

.circle-container2:nth-child(6) .circle {
  -webkit-animation-delay: 653ms;
          animation-delay: 653ms;
}

.circle-container2:nth-child(7) {
  width: 7px;
  height: 7px;
  -webkit-animation-name: move-frames-7;
          animation-name: move-frames-7;
  -webkit-animation-duration: 31901ms;
          animation-duration: 31901ms;
  -webkit-animation-delay: 31819ms;
          animation-delay: 31819ms;
}

@-webkit-keyframes move-frames-7 {
  from {
    -webkit-transform: translate3d(77vw, 101vh, 0);
            transform: translate3d(77vw, 101vh, 0);
  }

  to {
    -webkit-transform: translate3d(43vw, -110vh, 0);
            transform: translate3d(43vw, -110vh, 0);
  }
}

@keyframes move-frames-7 {
  from {
    -webkit-transform: translate3d(77vw, 101vh, 0);
            transform: translate3d(77vw, 101vh, 0);
  }

  to {
    -webkit-transform: translate3d(43vw, -110vh, 0);
            transform: translate3d(43vw, -110vh, 0);
  }
}

.circle-container2:nth-child(7) .circle {
  -webkit-animation-delay: 2166ms;
          animation-delay: 2166ms;
}

.circle-container2:nth-child(8) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-8;
          animation-name: move-frames-8;
  -webkit-animation-duration: 28634ms;
          animation-duration: 28634ms;
  -webkit-animation-delay: 18091ms;
          animation-delay: 18091ms;
}

@-webkit-keyframes move-frames-8 {
  from {
    -webkit-transform: translate3d(81vw, 105vh, 0);
            transform: translate3d(81vw, 105vh, 0);
  }

  to {
    -webkit-transform: translate3d(20vw, -119vh, 0);
            transform: translate3d(20vw, -119vh, 0);
  }
}

@keyframes move-frames-8 {
  from {
    -webkit-transform: translate3d(81vw, 105vh, 0);
            transform: translate3d(81vw, 105vh, 0);
  }

  to {
    -webkit-transform: translate3d(20vw, -119vh, 0);
            transform: translate3d(20vw, -119vh, 0);
  }
}

.circle-container2:nth-child(8) .circle {
  -webkit-animation-delay: 1433ms;
          animation-delay: 1433ms;
}

.circle-container2:nth-child(9) {
  width: 1px;
  height: 1px;
  -webkit-animation-name: move-frames-9;
          animation-name: move-frames-9;
  -webkit-animation-duration: 31744ms;
          animation-duration: 31744ms;
  -webkit-animation-delay: 15233ms;
          animation-delay: 15233ms;
}

@-webkit-keyframes move-frames-9 {
  from {
    -webkit-transform: translate3d(80vw, 101vh, 0);
            transform: translate3d(80vw, 101vh, 0);
  }

  to {
    -webkit-transform: translate3d(54vw, -118vh, 0);
            transform: translate3d(54vw, -118vh, 0);
  }
}

@keyframes move-frames-9 {
  from {
    -webkit-transform: translate3d(80vw, 101vh, 0);
            transform: translate3d(80vw, 101vh, 0);
  }

  to {
    -webkit-transform: translate3d(54vw, -118vh, 0);
            transform: translate3d(54vw, -118vh, 0);
  }
}

.circle-container2:nth-child(9) .circle {
  -webkit-animation-delay: 2399ms;
          animation-delay: 2399ms;
}

.circle-container2:nth-child(10) {
  width: 7px;
  height: 7px;
  -webkit-animation-name: move-frames-10;
          animation-name: move-frames-10;
  -webkit-animation-duration: 29461ms;
          animation-duration: 29461ms;
  -webkit-animation-delay: 5651ms;
          animation-delay: 5651ms;
}

@-webkit-keyframes move-frames-10 {
  from {
    -webkit-transform: translate3d(24vw, 109vh, 0);
            transform: translate3d(24vw, 109vh, 0);
  }

  to {
    -webkit-transform: translate3d(35vw, -130vh, 0);
            transform: translate3d(35vw, -130vh, 0);
  }
}

@keyframes move-frames-10 {
  from {
    -webkit-transform: translate3d(24vw, 109vh, 0);
            transform: translate3d(24vw, 109vh, 0);
  }

  to {
    -webkit-transform: translate3d(35vw, -130vh, 0);
            transform: translate3d(35vw, -130vh, 0);
  }
}

.circle-container2:nth-child(10) .circle {
  -webkit-animation-delay: 1080ms;
          animation-delay: 1080ms;
}

.circle-container2:nth-child(11) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-11;
          animation-name: move-frames-11;
  -webkit-animation-duration: 30609ms;
          animation-duration: 30609ms;
  -webkit-animation-delay: 34893ms;
          animation-delay: 34893ms;
}

@-webkit-keyframes move-frames-11 {
  from {
    -webkit-transform: translate3d(16vw, 103vh, 0);
            transform: translate3d(16vw, 103vh, 0);
  }

  to {
    -webkit-transform: translate3d(94vw, -124vh, 0);
            transform: translate3d(94vw, -124vh, 0);
  }
}

@keyframes move-frames-11 {
  from {
    -webkit-transform: translate3d(16vw, 103vh, 0);
            transform: translate3d(16vw, 103vh, 0);
  }

  to {
    -webkit-transform: translate3d(94vw, -124vh, 0);
            transform: translate3d(94vw, -124vh, 0);
  }
}

.circle-container2:nth-child(11) .circle {
  -webkit-animation-delay: 1477ms;
          animation-delay: 1477ms;
}

.circle-container2:nth-child(12) {
  width: 7px;
  height: 7px;
  -webkit-animation-name: move-frames-12;
          animation-name: move-frames-12;
  -webkit-animation-duration: 29877ms;
          animation-duration: 29877ms;
  -webkit-animation-delay: 33535ms;
          animation-delay: 33535ms;
}

@-webkit-keyframes move-frames-12 {
  from {
    -webkit-transform: translate3d(25vw, 107vh, 0);
            transform: translate3d(25vw, 107vh, 0);
  }

  to {
    -webkit-transform: translate3d(32vw, -110vh, 0);
            transform: translate3d(32vw, -110vh, 0);
  }
}

@keyframes move-frames-12 {
  from {
    -webkit-transform: translate3d(25vw, 107vh, 0);
            transform: translate3d(25vw, 107vh, 0);
  }

  to {
    -webkit-transform: translate3d(32vw, -110vh, 0);
            transform: translate3d(32vw, -110vh, 0);
  }
}

.circle-container2:nth-child(12) .circle {
  -webkit-animation-delay: 1105ms;
          animation-delay: 1105ms;
}

.circle-container2:nth-child(13) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-13;
          animation-name: move-frames-13;
  -webkit-animation-duration: 34198ms;
          animation-duration: 34198ms;
  -webkit-animation-delay: 21871ms;
          animation-delay: 21871ms;
}

@-webkit-keyframes move-frames-13 {
  from {
    -webkit-transform: translate3d(59vw, 101vh, 0);
            transform: translate3d(59vw, 101vh, 0);
  }

  to {
    -webkit-transform: translate3d(92vw, -113vh, 0);
            transform: translate3d(92vw, -113vh, 0);
  }
}

@keyframes move-frames-13 {
  from {
    -webkit-transform: translate3d(59vw, 101vh, 0);
            transform: translate3d(59vw, 101vh, 0);
  }

  to {
    -webkit-transform: translate3d(92vw, -113vh, 0);
            transform: translate3d(92vw, -113vh, 0);
  }
}

.circle-container2:nth-child(13) .circle {
  -webkit-animation-delay: 3982ms;
          animation-delay: 3982ms;
}

.circle-container2:nth-child(14) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-14;
          animation-name: move-frames-14;
  -webkit-animation-duration: 31477ms;
          animation-duration: 31477ms;
  -webkit-animation-delay: 23360ms;
          animation-delay: 23360ms;
}

@-webkit-keyframes move-frames-14 {
  from {
    -webkit-transform: translate3d(27vw, 105vh, 0);
            transform: translate3d(27vw, 105vh, 0);
  }

  to {
    -webkit-transform: translate3d(46vw, -125vh, 0);
            transform: translate3d(46vw, -125vh, 0);
  }
}

@keyframes move-frames-14 {
  from {
    -webkit-transform: translate3d(27vw, 105vh, 0);
            transform: translate3d(27vw, 105vh, 0);
  }

  to {
    -webkit-transform: translate3d(46vw, -125vh, 0);
            transform: translate3d(46vw, -125vh, 0);
  }
}

.circle-container2:nth-child(14) .circle {
  -webkit-animation-delay: 1912ms;
          animation-delay: 1912ms;
}

.circle-container2:nth-child(15) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-15;
          animation-name: move-frames-15;
  -webkit-animation-duration: 35088ms;
          animation-duration: 35088ms;
  -webkit-animation-delay: 19665ms;
          animation-delay: 19665ms;
}

@-webkit-keyframes move-frames-15 {
  from {
    -webkit-transform: translate3d(64vw, 104vh, 0);
            transform: translate3d(64vw, 104vh, 0);
  }

  to {
    -webkit-transform: translate3d(93vw, -118vh, 0);
            transform: translate3d(93vw, -118vh, 0);
  }
}

@keyframes move-frames-15 {
  from {
    -webkit-transform: translate3d(64vw, 104vh, 0);
            transform: translate3d(64vw, 104vh, 0);
  }

  to {
    -webkit-transform: translate3d(93vw, -118vh, 0);
            transform: translate3d(93vw, -118vh, 0);
  }
}

.circle-container2:nth-child(15) .circle {
  -webkit-animation-delay: 1404ms;
          animation-delay: 1404ms;
}

.circle-container2:nth-child(16) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-16;
          animation-name: move-frames-16;
  -webkit-animation-duration: 31494ms;
          animation-duration: 31494ms;
  -webkit-animation-delay: 1568ms;
          animation-delay: 1568ms;
}

@-webkit-keyframes move-frames-16 {
  from {
    -webkit-transform: translate3d(10vw, 109vh, 0);
            transform: translate3d(10vw, 109vh, 0);
  }

  to {
    -webkit-transform: translate3d(21vw, -136vh, 0);
            transform: translate3d(21vw, -136vh, 0);
  }
}

@keyframes move-frames-16 {
  from {
    -webkit-transform: translate3d(10vw, 109vh, 0);
            transform: translate3d(10vw, 109vh, 0);
  }

  to {
    -webkit-transform: translate3d(21vw, -136vh, 0);
            transform: translate3d(21vw, -136vh, 0);
  }
}

.circle-container2:nth-child(16) .circle {
  -webkit-animation-delay: 3706ms;
          animation-delay: 3706ms;
}

.circle-container2:nth-child(17) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-17;
          animation-name: move-frames-17;
  -webkit-animation-duration: 33208ms;
          animation-duration: 33208ms;
  -webkit-animation-delay: 30298ms;
          animation-delay: 30298ms;
}

@-webkit-keyframes move-frames-17 {
  from {
    -webkit-transform: translate3d(39vw, 101vh, 0);
            transform: translate3d(39vw, 101vh, 0);
  }

  to {
    -webkit-transform: translate3d(75vw, -110vh, 0);
            transform: translate3d(75vw, -110vh, 0);
  }
}

@keyframes move-frames-17 {
  from {
    -webkit-transform: translate3d(39vw, 101vh, 0);
            transform: translate3d(39vw, 101vh, 0);
  }

  to {
    -webkit-transform: translate3d(75vw, -110vh, 0);
            transform: translate3d(75vw, -110vh, 0);
  }
}

.circle-container2:nth-child(17) .circle {
  -webkit-animation-delay: 309ms;
          animation-delay: 309ms;
}

.circle-container2:nth-child(18) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-18;
          animation-name: move-frames-18;
  -webkit-animation-duration: 30460ms;
          animation-duration: 30460ms;
  -webkit-animation-delay: 10375ms;
          animation-delay: 10375ms;
}

@-webkit-keyframes move-frames-18 {
  from {
    -webkit-transform: translate3d(59vw, 110vh, 0);
            transform: translate3d(59vw, 110vh, 0);
  }

  to {
    -webkit-transform: translate3d(59vw, -138vh, 0);
            transform: translate3d(59vw, -138vh, 0);
  }
}

@keyframes move-frames-18 {
  from {
    -webkit-transform: translate3d(59vw, 110vh, 0);
            transform: translate3d(59vw, 110vh, 0);
  }

  to {
    -webkit-transform: translate3d(59vw, -138vh, 0);
            transform: translate3d(59vw, -138vh, 0);
  }
}

.circle-container2:nth-child(18) .circle {
  -webkit-animation-delay: 1454ms;
          animation-delay: 1454ms;
}

.circle-container2:nth-child(19) {
  width: 1px;
  height: 1px;
  -webkit-animation-name: move-frames-19;
          animation-name: move-frames-19;
  -webkit-animation-duration: 36863ms;
          animation-duration: 36863ms;
  -webkit-animation-delay: 13714ms;
          animation-delay: 13714ms;
}

@-webkit-keyframes move-frames-19 {
  from {
    -webkit-transform: translate3d(74vw, 102vh, 0);
            transform: translate3d(74vw, 102vh, 0);
  }

  to {
    -webkit-transform: translate3d(89vw, -117vh, 0);
            transform: translate3d(89vw, -117vh, 0);
  }
}

@keyframes move-frames-19 {
  from {
    -webkit-transform: translate3d(74vw, 102vh, 0);
            transform: translate3d(74vw, 102vh, 0);
  }

  to {
    -webkit-transform: translate3d(89vw, -117vh, 0);
            transform: translate3d(89vw, -117vh, 0);
  }
}

.circle-container2:nth-child(19) .circle {
  -webkit-animation-delay: 2304ms;
          animation-delay: 2304ms;
}

.circle-container2:nth-child(20) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-20;
          animation-name: move-frames-20;
  -webkit-animation-duration: 31143ms;
          animation-duration: 31143ms;
  -webkit-animation-delay: 30049ms;
          animation-delay: 30049ms;
}

@-webkit-keyframes move-frames-20 {
  from {
    -webkit-transform: translate3d(62vw, 105vh, 0);
            transform: translate3d(62vw, 105vh, 0);
  }

  to {
    -webkit-transform: translate3d(70vw, -126vh, 0);
            transform: translate3d(70vw, -126vh, 0);
  }
}

@keyframes move-frames-20 {
  from {
    -webkit-transform: translate3d(62vw, 105vh, 0);
            transform: translate3d(62vw, 105vh, 0);
  }

  to {
    -webkit-transform: translate3d(70vw, -126vh, 0);
            transform: translate3d(70vw, -126vh, 0);
  }
}

.circle-container2:nth-child(20) .circle {
  -webkit-animation-delay: 923ms;
          animation-delay: 923ms;
}

.circle-container2:nth-child(21) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-21;
          animation-name: move-frames-21;
  -webkit-animation-duration: 29000ms;
          animation-duration: 29000ms;
  -webkit-animation-delay: 33203ms;
          animation-delay: 33203ms;
}

@-webkit-keyframes move-frames-21 {
  from {
    -webkit-transform: translate3d(78vw, 103vh, 0);
            transform: translate3d(78vw, 103vh, 0);
  }

  to {
    -webkit-transform: translate3d(48vw, -131vh, 0);
            transform: translate3d(48vw, -131vh, 0);
  }
}

@keyframes move-frames-21 {
  from {
    -webkit-transform: translate3d(78vw, 103vh, 0);
            transform: translate3d(78vw, 103vh, 0);
  }

  to {
    -webkit-transform: translate3d(48vw, -131vh, 0);
            transform: translate3d(48vw, -131vh, 0);
  }
}

.circle-container2:nth-child(21) .circle {
  -webkit-animation-delay: 2225ms;
          animation-delay: 2225ms;
}

.circle-container2:nth-child(22) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-22;
          animation-name: move-frames-22;
  -webkit-animation-duration: 36652ms;
          animation-duration: 36652ms;
  -webkit-animation-delay: 17612ms;
          animation-delay: 17612ms;
}

@-webkit-keyframes move-frames-22 {
  from {
    -webkit-transform: translate3d(80vw, 104vh, 0);
            transform: translate3d(80vw, 104vh, 0);
  }

  to {
    -webkit-transform: translate3d(74vw, -114vh, 0);
            transform: translate3d(74vw, -114vh, 0);
  }
}

@keyframes move-frames-22 {
  from {
    -webkit-transform: translate3d(80vw, 104vh, 0);
            transform: translate3d(80vw, 104vh, 0);
  }

  to {
    -webkit-transform: translate3d(74vw, -114vh, 0);
            transform: translate3d(74vw, -114vh, 0);
  }
}

.circle-container2:nth-child(22) .circle {
  -webkit-animation-delay: 1317ms;
          animation-delay: 1317ms;
}

.circle-container2:nth-child(23) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-23;
          animation-name: move-frames-23;
  -webkit-animation-duration: 34731ms;
          animation-duration: 34731ms;
  -webkit-animation-delay: 22173ms;
          animation-delay: 22173ms;
}

@-webkit-keyframes move-frames-23 {
  from {
    -webkit-transform: translate3d(96vw, 109vh, 0);
            transform: translate3d(96vw, 109vh, 0);
  }

  to {
    -webkit-transform: translate3d(88vw, -135vh, 0);
            transform: translate3d(88vw, -135vh, 0);
  }
}

@keyframes move-frames-23 {
  from {
    -webkit-transform: translate3d(96vw, 109vh, 0);
            transform: translate3d(96vw, 109vh, 0);
  }

  to {
    -webkit-transform: translate3d(88vw, -135vh, 0);
            transform: translate3d(88vw, -135vh, 0);
  }
}

.circle-container2:nth-child(23) .circle {
  -webkit-animation-delay: 3929ms;
          animation-delay: 3929ms;
}

.circle-container2:nth-child(24) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-24;
          animation-name: move-frames-24;
  -webkit-animation-duration: 32220ms;
          animation-duration: 32220ms;
  -webkit-animation-delay: 3198ms;
          animation-delay: 3198ms;
}

@-webkit-keyframes move-frames-24 {
  from {
    -webkit-transform: translate3d(58vw, 102vh, 0);
            transform: translate3d(58vw, 102vh, 0);
  }

  to {
    -webkit-transform: translate3d(50vw, -109vh, 0);
            transform: translate3d(50vw, -109vh, 0);
  }
}

@keyframes move-frames-24 {
  from {
    -webkit-transform: translate3d(58vw, 102vh, 0);
            transform: translate3d(58vw, 102vh, 0);
  }

  to {
    -webkit-transform: translate3d(50vw, -109vh, 0);
            transform: translate3d(50vw, -109vh, 0);
  }
}

.circle-container2:nth-child(24) .circle {
  -webkit-animation-delay: 91ms;
          animation-delay: 91ms;
}

.circle-container2:nth-child(25) {
  width: 7px;
  height: 7px;
  -webkit-animation-name: move-frames-25;
          animation-name: move-frames-25;
  -webkit-animation-duration: 35525ms;
          animation-duration: 35525ms;
  -webkit-animation-delay: 29589ms;
          animation-delay: 29589ms;
}

@-webkit-keyframes move-frames-25 {
  from {
    -webkit-transform: translate3d(68vw, 109vh, 0);
            transform: translate3d(68vw, 109vh, 0);
  }

  to {
    -webkit-transform: translate3d(10vw, -115vh, 0);
            transform: translate3d(10vw, -115vh, 0);
  }
}

@keyframes move-frames-25 {
  from {
    -webkit-transform: translate3d(68vw, 109vh, 0);
            transform: translate3d(68vw, 109vh, 0);
  }

  to {
    -webkit-transform: translate3d(10vw, -115vh, 0);
            transform: translate3d(10vw, -115vh, 0);
  }
}

.circle-container2:nth-child(25) .circle {
  -webkit-animation-delay: 2158ms;
          animation-delay: 2158ms;
}

.circle-container2:nth-child(26) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-26;
          animation-name: move-frames-26;
  -webkit-animation-duration: 35875ms;
          animation-duration: 35875ms;
  -webkit-animation-delay: 33656ms;
          animation-delay: 33656ms;
}

@-webkit-keyframes move-frames-26 {
  from {
    -webkit-transform: translate3d(44vw, 109vh, 0);
            transform: translate3d(44vw, 109vh, 0);
  }

  to {
    -webkit-transform: translate3d(9vw, -135vh, 0);
            transform: translate3d(9vw, -135vh, 0);
  }
}

@keyframes move-frames-26 {
  from {
    -webkit-transform: translate3d(44vw, 109vh, 0);
            transform: translate3d(44vw, 109vh, 0);
  }

  to {
    -webkit-transform: translate3d(9vw, -135vh, 0);
            transform: translate3d(9vw, -135vh, 0);
  }
}

.circle-container2:nth-child(26) .circle {
  -webkit-animation-delay: 456ms;
          animation-delay: 456ms;
}

.circle-container2:nth-child(27) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-27;
          animation-name: move-frames-27;
  -webkit-animation-duration: 34577ms;
          animation-duration: 34577ms;
  -webkit-animation-delay: 12215ms;
          animation-delay: 12215ms;
}

@-webkit-keyframes move-frames-27 {
  from {
    -webkit-transform: translate3d(85vw, 108vh, 0);
            transform: translate3d(85vw, 108vh, 0);
  }

  to {
    -webkit-transform: translate3d(92vw, -116vh, 0);
            transform: translate3d(92vw, -116vh, 0);
  }
}

@keyframes move-frames-27 {
  from {
    -webkit-transform: translate3d(85vw, 108vh, 0);
            transform: translate3d(85vw, 108vh, 0);
  }

  to {
    -webkit-transform: translate3d(92vw, -116vh, 0);
            transform: translate3d(92vw, -116vh, 0);
  }
}

.circle-container2:nth-child(27) .circle {
  -webkit-animation-delay: 1184ms;
          animation-delay: 1184ms;
}

.circle-container2:nth-child(28) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-28;
          animation-name: move-frames-28;
  -webkit-animation-duration: 31908ms;
          animation-duration: 31908ms;
  -webkit-animation-delay: 26869ms;
          animation-delay: 26869ms;
}

@-webkit-keyframes move-frames-28 {
  from {
    -webkit-transform: translate3d(41vw, 105vh, 0);
            transform: translate3d(41vw, 105vh, 0);
  }

  to {
    -webkit-transform: translate3d(24vw, -118vh, 0);
            transform: translate3d(24vw, -118vh, 0);
  }
}

@keyframes move-frames-28 {
  from {
    -webkit-transform: translate3d(41vw, 105vh, 0);
            transform: translate3d(41vw, 105vh, 0);
  }

  to {
    -webkit-transform: translate3d(24vw, -118vh, 0);
            transform: translate3d(24vw, -118vh, 0);
  }
}

.circle-container2:nth-child(28) .circle {
  -webkit-animation-delay: 673ms;
          animation-delay: 673ms;
}

.circle-container2:nth-child(29) {
  width: 1px;
  height: 1px;
  -webkit-animation-name: move-frames-29;
          animation-name: move-frames-29;
  -webkit-animation-duration: 33853ms;
          animation-duration: 33853ms;
  -webkit-animation-delay: 288ms;
          animation-delay: 288ms;
}

@-webkit-keyframes move-frames-29 {
  from {
    -webkit-transform: translate3d(26vw, 110vh, 0);
            transform: translate3d(26vw, 110vh, 0);
  }

  to {
    -webkit-transform: translate3d(50vw, -128vh, 0);
            transform: translate3d(50vw, -128vh, 0);
  }
}

@keyframes move-frames-29 {
  from {
    -webkit-transform: translate3d(26vw, 110vh, 0);
            transform: translate3d(26vw, 110vh, 0);
  }

  to {
    -webkit-transform: translate3d(50vw, -128vh, 0);
            transform: translate3d(50vw, -128vh, 0);
  }
}

.circle-container2:nth-child(29) .circle {
  -webkit-animation-delay: 676ms;
          animation-delay: 676ms;
}

.circle-container2:nth-child(30) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-30;
          animation-name: move-frames-30;
  -webkit-animation-duration: 32724ms;
          animation-duration: 32724ms;
  -webkit-animation-delay: 9766ms;
          animation-delay: 9766ms;
}

@-webkit-keyframes move-frames-30 {
  from {
    -webkit-transform: translate3d(50vw, 108vh, 0);
            transform: translate3d(50vw, 108vh, 0);
  }

  to {
    -webkit-transform: translate3d(52vw, -129vh, 0);
            transform: translate3d(52vw, -129vh, 0);
  }
}

@keyframes move-frames-30 {
  from {
    -webkit-transform: translate3d(50vw, 108vh, 0);
            transform: translate3d(50vw, 108vh, 0);
  }

  to {
    -webkit-transform: translate3d(52vw, -129vh, 0);
            transform: translate3d(52vw, -129vh, 0);
  }
}

.circle-container2:nth-child(30) .circle {
  -webkit-animation-delay: 3712ms;
          animation-delay: 3712ms;
}

.circle-container2:nth-child(31) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-31;
          animation-name: move-frames-31;
  -webkit-animation-duration: 30306ms;
          animation-duration: 30306ms;
  -webkit-animation-delay: 4355ms;
          animation-delay: 4355ms;
}

@-webkit-keyframes move-frames-31 {
  from {
    -webkit-transform: translate3d(52vw, 109vh, 0);
            transform: translate3d(52vw, 109vh, 0);
  }

  to {
    -webkit-transform: translate3d(55vw, -131vh, 0);
            transform: translate3d(55vw, -131vh, 0);
  }
}

@keyframes move-frames-31 {
  from {
    -webkit-transform: translate3d(52vw, 109vh, 0);
            transform: translate3d(52vw, 109vh, 0);
  }

  to {
    -webkit-transform: translate3d(55vw, -131vh, 0);
            transform: translate3d(55vw, -131vh, 0);
  }
}

.circle-container2:nth-child(31) .circle {
  -webkit-animation-delay: 1652ms;
          animation-delay: 1652ms;
}

.circle-container2:nth-child(32) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-32;
          animation-name: move-frames-32;
  -webkit-animation-duration: 36466ms;
          animation-duration: 36466ms;
  -webkit-animation-delay: 23222ms;
          animation-delay: 23222ms;
}

@-webkit-keyframes move-frames-32 {
  from {
    -webkit-transform: translate3d(48vw, 107vh, 0);
            transform: translate3d(48vw, 107vh, 0);
  }

  to {
    -webkit-transform: translate3d(33vw, -128vh, 0);
            transform: translate3d(33vw, -128vh, 0);
  }
}

@keyframes move-frames-32 {
  from {
    -webkit-transform: translate3d(48vw, 107vh, 0);
            transform: translate3d(48vw, 107vh, 0);
  }

  to {
    -webkit-transform: translate3d(33vw, -128vh, 0);
            transform: translate3d(33vw, -128vh, 0);
  }
}

.circle-container2:nth-child(32) .circle {
  -webkit-animation-delay: 2661ms;
          animation-delay: 2661ms;
}

.circle-container2:nth-child(33) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-33;
          animation-name: move-frames-33;
  -webkit-animation-duration: 28746ms;
          animation-duration: 28746ms;
  -webkit-animation-delay: 31176ms;
          animation-delay: 31176ms;
}

@-webkit-keyframes move-frames-33 {
  from {
    -webkit-transform: translate3d(21vw, 109vh, 0);
            transform: translate3d(21vw, 109vh, 0);
  }

  to {
    -webkit-transform: translate3d(28vw, -125vh, 0);
            transform: translate3d(28vw, -125vh, 0);
  }
}

@keyframes move-frames-33 {
  from {
    -webkit-transform: translate3d(21vw, 109vh, 0);
            transform: translate3d(21vw, 109vh, 0);
  }

  to {
    -webkit-transform: translate3d(28vw, -125vh, 0);
            transform: translate3d(28vw, -125vh, 0);
  }
}

.circle-container2:nth-child(33) .circle {
  -webkit-animation-delay: 1657ms;
          animation-delay: 1657ms;
}

.circle-container2:nth-child(34) {
  width: 1px;
  height: 1px;
  -webkit-animation-name: move-frames-34;
          animation-name: move-frames-34;
  -webkit-animation-duration: 36366ms;
          animation-duration: 36366ms;
  -webkit-animation-delay: 10018ms;
          animation-delay: 10018ms;
}

@-webkit-keyframes move-frames-34 {
  from {
    -webkit-transform: translate3d(72vw, 106vh, 0);
            transform: translate3d(72vw, 106vh, 0);
  }

  to {
    -webkit-transform: translate3d(69vw, -123vh, 0);
            transform: translate3d(69vw, -123vh, 0);
  }
}

@keyframes move-frames-34 {
  from {
    -webkit-transform: translate3d(72vw, 106vh, 0);
            transform: translate3d(72vw, 106vh, 0);
  }

  to {
    -webkit-transform: translate3d(69vw, -123vh, 0);
            transform: translate3d(69vw, -123vh, 0);
  }
}

.circle-container2:nth-child(34) .circle {
  -webkit-animation-delay: 3772ms;
          animation-delay: 3772ms;
}

.circle-container2:nth-child(35) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-35;
          animation-name: move-frames-35;
  -webkit-animation-duration: 29767ms;
          animation-duration: 29767ms;
  -webkit-animation-delay: 22107ms;
          animation-delay: 22107ms;
}

@-webkit-keyframes move-frames-35 {
  from {
    -webkit-transform: translate3d(34vw, 105vh, 0);
            transform: translate3d(34vw, 105vh, 0);
  }

  to {
    -webkit-transform: translate3d(53vw, -126vh, 0);
            transform: translate3d(53vw, -126vh, 0);
  }
}

@keyframes move-frames-35 {
  from {
    -webkit-transform: translate3d(34vw, 105vh, 0);
            transform: translate3d(34vw, 105vh, 0);
  }

  to {
    -webkit-transform: translate3d(53vw, -126vh, 0);
            transform: translate3d(53vw, -126vh, 0);
  }
}

.circle-container2:nth-child(35) .circle {
  -webkit-animation-delay: 3793ms;
          animation-delay: 3793ms;
}

.circle-container2:nth-child(36) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-36;
          animation-name: move-frames-36;
  -webkit-animation-duration: 33419ms;
          animation-duration: 33419ms;
  -webkit-animation-delay: 28077ms;
          animation-delay: 28077ms;
}

@-webkit-keyframes move-frames-36 {
  from {
    -webkit-transform: translate3d(29vw, 104vh, 0);
            transform: translate3d(29vw, 104vh, 0);
  }

  to {
    -webkit-transform: translate3d(90vw, -119vh, 0);
            transform: translate3d(90vw, -119vh, 0);
  }
}

@keyframes move-frames-36 {
  from {
    -webkit-transform: translate3d(29vw, 104vh, 0);
            transform: translate3d(29vw, 104vh, 0);
  }

  to {
    -webkit-transform: translate3d(90vw, -119vh, 0);
            transform: translate3d(90vw, -119vh, 0);
  }
}

.circle-container2:nth-child(36) .circle {
  -webkit-animation-delay: 2534ms;
          animation-delay: 2534ms;
}

.circle-container2:nth-child(37) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-37;
          animation-name: move-frames-37;
  -webkit-animation-duration: 30556ms;
          animation-duration: 30556ms;
  -webkit-animation-delay: 30570ms;
          animation-delay: 30570ms;
}

@-webkit-keyframes move-frames-37 {
  from {
    -webkit-transform: translate3d(46vw, 105vh, 0);
            transform: translate3d(46vw, 105vh, 0);
  }

  to {
    -webkit-transform: translate3d(27vw, -133vh, 0);
            transform: translate3d(27vw, -133vh, 0);
  }
}

@keyframes move-frames-37 {
  from {
    -webkit-transform: translate3d(46vw, 105vh, 0);
            transform: translate3d(46vw, 105vh, 0);
  }

  to {
    -webkit-transform: translate3d(27vw, -133vh, 0);
            transform: translate3d(27vw, -133vh, 0);
  }
}

.circle-container2:nth-child(37) .circle {
  -webkit-animation-delay: 323ms;
          animation-delay: 323ms;
}

.circle-container2:nth-child(38) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-38;
          animation-name: move-frames-38;
  -webkit-animation-duration: 33679ms;
          animation-duration: 33679ms;
  -webkit-animation-delay: 27547ms;
          animation-delay: 27547ms;
}

@-webkit-keyframes move-frames-38 {
  from {
    -webkit-transform: translate3d(74vw, 108vh, 0);
            transform: translate3d(74vw, 108vh, 0);
  }

  to {
    -webkit-transform: translate3d(20vw, -123vh, 0);
            transform: translate3d(20vw, -123vh, 0);
  }
}

@keyframes move-frames-38 {
  from {
    -webkit-transform: translate3d(74vw, 108vh, 0);
            transform: translate3d(74vw, 108vh, 0);
  }

  to {
    -webkit-transform: translate3d(20vw, -123vh, 0);
            transform: translate3d(20vw, -123vh, 0);
  }
}

.circle-container2:nth-child(38) .circle {
  -webkit-animation-delay: 3503ms;
          animation-delay: 3503ms;
}

.circle-container2:nth-child(39) {
  width: 1px;
  height: 1px;
  -webkit-animation-name: move-frames-39;
          animation-name: move-frames-39;
  -webkit-animation-duration: 36428ms;
          animation-duration: 36428ms;
  -webkit-animation-delay: 130ms;
          animation-delay: 130ms;
}

@-webkit-keyframes move-frames-39 {
  from {
    -webkit-transform: translate3d(92vw, 104vh, 0);
            transform: translate3d(92vw, 104vh, 0);
  }

  to {
    -webkit-transform: translate3d(99vw, -120vh, 0);
            transform: translate3d(99vw, -120vh, 0);
  }
}

@keyframes move-frames-39 {
  from {
    -webkit-transform: translate3d(92vw, 104vh, 0);
            transform: translate3d(92vw, 104vh, 0);
  }

  to {
    -webkit-transform: translate3d(99vw, -120vh, 0);
            transform: translate3d(99vw, -120vh, 0);
  }
}

.circle-container2:nth-child(39) .circle {
  -webkit-animation-delay: 2613ms;
          animation-delay: 2613ms;
}

.circle-container2:nth-child(40) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-40;
          animation-name: move-frames-40;
  -webkit-animation-duration: 36739ms;
          animation-duration: 36739ms;
  -webkit-animation-delay: 33514ms;
          animation-delay: 33514ms;
}

@-webkit-keyframes move-frames-40 {
  from {
    -webkit-transform: translate3d(74vw, 106vh, 0);
            transform: translate3d(74vw, 106vh, 0);
  }

  to {
    -webkit-transform: translate3d(49vw, -132vh, 0);
            transform: translate3d(49vw, -132vh, 0);
  }
}

@keyframes move-frames-40 {
  from {
    -webkit-transform: translate3d(74vw, 106vh, 0);
            transform: translate3d(74vw, 106vh, 0);
  }

  to {
    -webkit-transform: translate3d(49vw, -132vh, 0);
            transform: translate3d(49vw, -132vh, 0);
  }
}

.circle-container2:nth-child(40) .circle {
  -webkit-animation-delay: 2325ms;
          animation-delay: 2325ms;
}

.circle-container2:nth-child(41) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-41;
          animation-name: move-frames-41;
  -webkit-animation-duration: 30196ms;
          animation-duration: 30196ms;
  -webkit-animation-delay: 20811ms;
          animation-delay: 20811ms;
}

@-webkit-keyframes move-frames-41 {
  from {
    -webkit-transform: translate3d(45vw, 106vh, 0);
            transform: translate3d(45vw, 106vh, 0);
  }

  to {
    -webkit-transform: translate3d(56vw, -121vh, 0);
            transform: translate3d(56vw, -121vh, 0);
  }
}

@keyframes move-frames-41 {
  from {
    -webkit-transform: translate3d(45vw, 106vh, 0);
            transform: translate3d(45vw, 106vh, 0);
  }

  to {
    -webkit-transform: translate3d(56vw, -121vh, 0);
            transform: translate3d(56vw, -121vh, 0);
  }
}

.circle-container2:nth-child(41) .circle {
  -webkit-animation-delay: 3688ms;
          animation-delay: 3688ms;
}

.circle-container2:nth-child(42) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-42;
          animation-name: move-frames-42;
  -webkit-animation-duration: 36980ms;
          animation-duration: 36980ms;
  -webkit-animation-delay: 20720ms;
          animation-delay: 20720ms;
}

@-webkit-keyframes move-frames-42 {
  from {
    -webkit-transform: translate3d(89vw, 109vh, 0);
            transform: translate3d(89vw, 109vh, 0);
  }

  to {
    -webkit-transform: translate3d(33vw, -123vh, 0);
            transform: translate3d(33vw, -123vh, 0);
  }
}

@keyframes move-frames-42 {
  from {
    -webkit-transform: translate3d(89vw, 109vh, 0);
            transform: translate3d(89vw, 109vh, 0);
  }

  to {
    -webkit-transform: translate3d(33vw, -123vh, 0);
            transform: translate3d(33vw, -123vh, 0);
  }
}

.circle-container2:nth-child(42) .circle {
  -webkit-animation-delay: 744ms;
          animation-delay: 744ms;
}

.circle-container2:nth-child(43) {
  width: 1px;
  height: 1px;
  -webkit-animation-name: move-frames-43;
          animation-name: move-frames-43;
  -webkit-animation-duration: 34954ms;
          animation-duration: 34954ms;
  -webkit-animation-delay: 28366ms;
          animation-delay: 28366ms;
}

@-webkit-keyframes move-frames-43 {
  from {
    -webkit-transform: translate3d(12vw, 106vh, 0);
            transform: translate3d(12vw, 106vh, 0);
  }

  to {
    -webkit-transform: translate3d(47vw, -136vh, 0);
            transform: translate3d(47vw, -136vh, 0);
  }
}

@keyframes move-frames-43 {
  from {
    -webkit-transform: translate3d(12vw, 106vh, 0);
            transform: translate3d(12vw, 106vh, 0);
  }

  to {
    -webkit-transform: translate3d(47vw, -136vh, 0);
            transform: translate3d(47vw, -136vh, 0);
  }
}

.circle-container2:nth-child(43) .circle {
  -webkit-animation-delay: 763ms;
          animation-delay: 763ms;
}

.circle-container2:nth-child(44) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-44;
          animation-name: move-frames-44;
  -webkit-animation-duration: 35359ms;
          animation-duration: 35359ms;
  -webkit-animation-delay: 8123ms;
          animation-delay: 8123ms;
}

@-webkit-keyframes move-frames-44 {
  from {
    -webkit-transform: translate3d(31vw, 104vh, 0);
            transform: translate3d(31vw, 104vh, 0);
  }

  to {
    -webkit-transform: translate3d(73vw, -124vh, 0);
            transform: translate3d(73vw, -124vh, 0);
  }
}

@keyframes move-frames-44 {
  from {
    -webkit-transform: translate3d(31vw, 104vh, 0);
            transform: translate3d(31vw, 104vh, 0);
  }

  to {
    -webkit-transform: translate3d(73vw, -124vh, 0);
            transform: translate3d(73vw, -124vh, 0);
  }
}

.circle-container2:nth-child(44) .circle {
  -webkit-animation-delay: 2923ms;
          animation-delay: 2923ms;
}

.circle-container2:nth-child(45) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-45;
          animation-name: move-frames-45;
  -webkit-animation-duration: 32140ms;
          animation-duration: 32140ms;
  -webkit-animation-delay: 3024ms;
          animation-delay: 3024ms;
}

@-webkit-keyframes move-frames-45 {
  from {
    -webkit-transform: translate3d(11vw, 110vh, 0);
            transform: translate3d(11vw, 110vh, 0);
  }

  to {
    -webkit-transform: translate3d(89vw, -133vh, 0);
            transform: translate3d(89vw, -133vh, 0);
  }
}

@keyframes move-frames-45 {
  from {
    -webkit-transform: translate3d(11vw, 110vh, 0);
            transform: translate3d(11vw, 110vh, 0);
  }

  to {
    -webkit-transform: translate3d(89vw, -133vh, 0);
            transform: translate3d(89vw, -133vh, 0);
  }
}

.circle-container2:nth-child(45) .circle {
  -webkit-animation-delay: 1863ms;
          animation-delay: 1863ms;
}

.circle-container2:nth-child(46) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-46;
          animation-name: move-frames-46;
  -webkit-animation-duration: 28466ms;
          animation-duration: 28466ms;
  -webkit-animation-delay: 11332ms;
          animation-delay: 11332ms;
}

@-webkit-keyframes move-frames-46 {
  from {
    -webkit-transform: translate3d(87vw, 102vh, 0);
            transform: translate3d(87vw, 102vh, 0);
  }

  to {
    -webkit-transform: translate3d(82vw, -108vh, 0);
            transform: translate3d(82vw, -108vh, 0);
  }
}

@keyframes move-frames-46 {
  from {
    -webkit-transform: translate3d(87vw, 102vh, 0);
            transform: translate3d(87vw, 102vh, 0);
  }

  to {
    -webkit-transform: translate3d(82vw, -108vh, 0);
            transform: translate3d(82vw, -108vh, 0);
  }
}

.circle-container2:nth-child(46) .circle {
  -webkit-animation-delay: 1494ms;
          animation-delay: 1494ms;
}

.circle-container2:nth-child(47) {
  width: 7px;
  height: 7px;
  -webkit-animation-name: move-frames-47;
          animation-name: move-frames-47;
  -webkit-animation-duration: 30852ms;
          animation-duration: 30852ms;
  -webkit-animation-delay: 26331ms;
          animation-delay: 26331ms;
}

@-webkit-keyframes move-frames-47 {
  from {
    -webkit-transform: translate3d(95vw, 101vh, 0);
            transform: translate3d(95vw, 101vh, 0);
  }

  to {
    -webkit-transform: translate3d(97vw, -105vh, 0);
            transform: translate3d(97vw, -105vh, 0);
  }
}

@keyframes move-frames-47 {
  from {
    -webkit-transform: translate3d(95vw, 101vh, 0);
            transform: translate3d(95vw, 101vh, 0);
  }

  to {
    -webkit-transform: translate3d(97vw, -105vh, 0);
            transform: translate3d(97vw, -105vh, 0);
  }
}

.circle-container2:nth-child(47) .circle {
  -webkit-animation-delay: 3888ms;
          animation-delay: 3888ms;
}

.circle-container2:nth-child(48) {
  width: 1px;
  height: 1px;
  -webkit-animation-name: move-frames-48;
          animation-name: move-frames-48;
  -webkit-animation-duration: 32146ms;
          animation-duration: 32146ms;
  -webkit-animation-delay: 33093ms;
          animation-delay: 33093ms;
}

@-webkit-keyframes move-frames-48 {
  from {
    -webkit-transform: translate3d(48vw, 102vh, 0);
            transform: translate3d(48vw, 102vh, 0);
  }

  to {
    -webkit-transform: translate3d(45vw, -106vh, 0);
            transform: translate3d(45vw, -106vh, 0);
  }
}

@keyframes move-frames-48 {
  from {
    -webkit-transform: translate3d(48vw, 102vh, 0);
            transform: translate3d(48vw, 102vh, 0);
  }

  to {
    -webkit-transform: translate3d(45vw, -106vh, 0);
            transform: translate3d(45vw, -106vh, 0);
  }
}

.circle-container2:nth-child(48) .circle {
  -webkit-animation-delay: 1095ms;
          animation-delay: 1095ms;
}

.circle-container2:nth-child(49) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-49;
          animation-name: move-frames-49;
  -webkit-animation-duration: 36524ms;
          animation-duration: 36524ms;
  -webkit-animation-delay: 5829ms;
          animation-delay: 5829ms;
}

@-webkit-keyframes move-frames-49 {
  from {
    -webkit-transform: translate3d(22vw, 102vh, 0);
            transform: translate3d(22vw, 102vh, 0);
  }

  to {
    -webkit-transform: translate3d(51vw, -127vh, 0);
            transform: translate3d(51vw, -127vh, 0);
  }
}

@keyframes move-frames-49 {
  from {
    -webkit-transform: translate3d(22vw, 102vh, 0);
            transform: translate3d(22vw, 102vh, 0);
  }

  to {
    -webkit-transform: translate3d(51vw, -127vh, 0);
            transform: translate3d(51vw, -127vh, 0);
  }
}

.circle-container2:nth-child(49) .circle {
  -webkit-animation-delay: 1664ms;
          animation-delay: 1664ms;
}

.circle-container2:nth-child(50) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-50;
          animation-name: move-frames-50;
  -webkit-animation-duration: 32664ms;
          animation-duration: 32664ms;
  -webkit-animation-delay: 20738ms;
          animation-delay: 20738ms;
}

@-webkit-keyframes move-frames-50 {
  from {
    -webkit-transform: translate3d(21vw, 104vh, 0);
            transform: translate3d(21vw, 104vh, 0);
  }

  to {
    -webkit-transform: translate3d(73vw, -107vh, 0);
            transform: translate3d(73vw, -107vh, 0);
  }
}

@keyframes move-frames-50 {
  from {
    -webkit-transform: translate3d(21vw, 104vh, 0);
            transform: translate3d(21vw, 104vh, 0);
  }

  to {
    -webkit-transform: translate3d(73vw, -107vh, 0);
            transform: translate3d(73vw, -107vh, 0);
  }
}

.circle-container2:nth-child(50) .circle {
  -webkit-animation-delay: 2185ms;
          animation-delay: 2185ms;
}

.circle-container2:nth-child(51) {
  width: 7px;
  height: 7px;
  -webkit-animation-name: move-frames-51;
          animation-name: move-frames-51;
  -webkit-animation-duration: 35935ms;
          animation-duration: 35935ms;
  -webkit-animation-delay: 34643ms;
          animation-delay: 34643ms;
}

@-webkit-keyframes move-frames-51 {
  from {
    -webkit-transform: translate3d(11vw, 105vh, 0);
            transform: translate3d(11vw, 105vh, 0);
  }

  to {
    -webkit-transform: translate3d(55vw, -117vh, 0);
            transform: translate3d(55vw, -117vh, 0);
  }
}

@keyframes move-frames-51 {
  from {
    -webkit-transform: translate3d(11vw, 105vh, 0);
            transform: translate3d(11vw, 105vh, 0);
  }

  to {
    -webkit-transform: translate3d(55vw, -117vh, 0);
            transform: translate3d(55vw, -117vh, 0);
  }
}

.circle-container2:nth-child(51) .circle {
  -webkit-animation-delay: 3170ms;
          animation-delay: 3170ms;
}

.circle-container2:nth-child(52) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-52;
          animation-name: move-frames-52;
  -webkit-animation-duration: 35002ms;
          animation-duration: 35002ms;
  -webkit-animation-delay: 9255ms;
          animation-delay: 9255ms;
}

@-webkit-keyframes move-frames-52 {
  from {
    -webkit-transform: translate3d(37vw, 107vh, 0);
            transform: translate3d(37vw, 107vh, 0);
  }

  to {
    -webkit-transform: translate3d(27vw, -119vh, 0);
            transform: translate3d(27vw, -119vh, 0);
  }
}

@keyframes move-frames-52 {
  from {
    -webkit-transform: translate3d(37vw, 107vh, 0);
            transform: translate3d(37vw, 107vh, 0);
  }

  to {
    -webkit-transform: translate3d(27vw, -119vh, 0);
            transform: translate3d(27vw, -119vh, 0);
  }
}

.circle-container2:nth-child(52) .circle {
  -webkit-animation-delay: 2869ms;
          animation-delay: 2869ms;
}

.circle-container2:nth-child(53) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-53;
          animation-name: move-frames-53;
  -webkit-animation-duration: 31411ms;
          animation-duration: 31411ms;
  -webkit-animation-delay: 5029ms;
          animation-delay: 5029ms;
}

@-webkit-keyframes move-frames-53 {
  from {
    -webkit-transform: translate3d(100vw, 109vh, 0);
            transform: translate3d(100vw, 109vh, 0);
  }

  to {
    -webkit-transform: translate3d(3vw, -138vh, 0);
            transform: translate3d(3vw, -138vh, 0);
  }
}

@keyframes move-frames-53 {
  from {
    -webkit-transform: translate3d(100vw, 109vh, 0);
            transform: translate3d(100vw, 109vh, 0);
  }

  to {
    -webkit-transform: translate3d(3vw, -138vh, 0);
            transform: translate3d(3vw, -138vh, 0);
  }
}

.circle-container2:nth-child(53) .circle {
  -webkit-animation-delay: 2710ms;
          animation-delay: 2710ms;
}

.circle-container2:nth-child(54) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-54;
          animation-name: move-frames-54;
  -webkit-animation-duration: 34484ms;
          animation-duration: 34484ms;
  -webkit-animation-delay: 3960ms;
          animation-delay: 3960ms;
}

@-webkit-keyframes move-frames-54 {
  from {
    -webkit-transform: translate3d(59vw, 102vh, 0);
            transform: translate3d(59vw, 102vh, 0);
  }

  to {
    -webkit-transform: translate3d(99vw, -132vh, 0);
            transform: translate3d(99vw, -132vh, 0);
  }
}

@keyframes move-frames-54 {
  from {
    -webkit-transform: translate3d(59vw, 102vh, 0);
            transform: translate3d(59vw, 102vh, 0);
  }

  to {
    -webkit-transform: translate3d(99vw, -132vh, 0);
            transform: translate3d(99vw, -132vh, 0);
  }
}

.circle-container2:nth-child(54) .circle {
  -webkit-animation-delay: 932ms;
          animation-delay: 932ms;
}

.circle-container2:nth-child(55) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-55;
          animation-name: move-frames-55;
  -webkit-animation-duration: 30870ms;
          animation-duration: 30870ms;
  -webkit-animation-delay: 8766ms;
          animation-delay: 8766ms;
}

@-webkit-keyframes move-frames-55 {
  from {
    -webkit-transform: translate3d(17vw, 107vh, 0);
            transform: translate3d(17vw, 107vh, 0);
  }

  to {
    -webkit-transform: translate3d(87vw, -137vh, 0);
            transform: translate3d(87vw, -137vh, 0);
  }
}

@keyframes move-frames-55 {
  from {
    -webkit-transform: translate3d(17vw, 107vh, 0);
            transform: translate3d(17vw, 107vh, 0);
  }

  to {
    -webkit-transform: translate3d(87vw, -137vh, 0);
            transform: translate3d(87vw, -137vh, 0);
  }
}

.circle-container2:nth-child(55) .circle {
  -webkit-animation-delay: 2816ms;
          animation-delay: 2816ms;
}

.circle-container2:nth-child(56) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-56;
          animation-name: move-frames-56;
  -webkit-animation-duration: 31465ms;
          animation-duration: 31465ms;
  -webkit-animation-delay: 15049ms;
          animation-delay: 15049ms;
}

@-webkit-keyframes move-frames-56 {
  from {
    -webkit-transform: translate3d(11vw, 104vh, 0);
            transform: translate3d(11vw, 104vh, 0);
  }

  to {
    -webkit-transform: translate3d(59vw, -106vh, 0);
            transform: translate3d(59vw, -106vh, 0);
  }
}

@keyframes move-frames-56 {
  from {
    -webkit-transform: translate3d(11vw, 104vh, 0);
            transform: translate3d(11vw, 104vh, 0);
  }

  to {
    -webkit-transform: translate3d(59vw, -106vh, 0);
            transform: translate3d(59vw, -106vh, 0);
  }
}

.circle-container2:nth-child(56) .circle {
  -webkit-animation-delay: 502ms;
          animation-delay: 502ms;
}

.circle-container2:nth-child(57) {
  width: 7px;
  height: 7px;
  -webkit-animation-name: move-frames-57;
          animation-name: move-frames-57;
  -webkit-animation-duration: 34746ms;
          animation-duration: 34746ms;
  -webkit-animation-delay: 24610ms;
          animation-delay: 24610ms;
}

@-webkit-keyframes move-frames-57 {
  from {
    -webkit-transform: translate3d(8vw, 106vh, 0);
            transform: translate3d(8vw, 106vh, 0);
  }

  to {
    -webkit-transform: translate3d(6vw, -113vh, 0);
            transform: translate3d(6vw, -113vh, 0);
  }
}

@keyframes move-frames-57 {
  from {
    -webkit-transform: translate3d(8vw, 106vh, 0);
            transform: translate3d(8vw, 106vh, 0);
  }

  to {
    -webkit-transform: translate3d(6vw, -113vh, 0);
            transform: translate3d(6vw, -113vh, 0);
  }
}

.circle-container2:nth-child(57) .circle {
  -webkit-animation-delay: 903ms;
          animation-delay: 903ms;
}

.circle-container2:nth-child(58) {
  width: 1px;
  height: 1px;
  -webkit-animation-name: move-frames-58;
          animation-name: move-frames-58;
  -webkit-animation-duration: 31745ms;
          animation-duration: 31745ms;
  -webkit-animation-delay: 23189ms;
          animation-delay: 23189ms;
}

@-webkit-keyframes move-frames-58 {
  from {
    -webkit-transform: translate3d(49vw, 106vh, 0);
            transform: translate3d(49vw, 106vh, 0);
  }

  to {
    -webkit-transform: translate3d(57vw, -128vh, 0);
            transform: translate3d(57vw, -128vh, 0);
  }
}

@keyframes move-frames-58 {
  from {
    -webkit-transform: translate3d(49vw, 106vh, 0);
            transform: translate3d(49vw, 106vh, 0);
  }

  to {
    -webkit-transform: translate3d(57vw, -128vh, 0);
            transform: translate3d(57vw, -128vh, 0);
  }
}

.circle-container2:nth-child(58) .circle {
  -webkit-animation-delay: 3601ms;
          animation-delay: 3601ms;
}

.circle-container2:nth-child(59) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-59;
          animation-name: move-frames-59;
  -webkit-animation-duration: 30816ms;
          animation-duration: 30816ms;
  -webkit-animation-delay: 31745ms;
          animation-delay: 31745ms;
}

@-webkit-keyframes move-frames-59 {
  from {
    -webkit-transform: translate3d(59vw, 105vh, 0);
            transform: translate3d(59vw, 105vh, 0);
  }

  to {
    -webkit-transform: translate3d(61vw, -123vh, 0);
            transform: translate3d(61vw, -123vh, 0);
  }
}

@keyframes move-frames-59 {
  from {
    -webkit-transform: translate3d(59vw, 105vh, 0);
            transform: translate3d(59vw, 105vh, 0);
  }

  to {
    -webkit-transform: translate3d(61vw, -123vh, 0);
            transform: translate3d(61vw, -123vh, 0);
  }
}

.circle-container2:nth-child(59) .circle {
  -webkit-animation-delay: 1368ms;
          animation-delay: 1368ms;
}

.circle-container2:nth-child(60) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-60;
          animation-name: move-frames-60;
  -webkit-animation-duration: 33647ms;
          animation-duration: 33647ms;
  -webkit-animation-delay: 5961ms;
          animation-delay: 5961ms;
}

@-webkit-keyframes move-frames-60 {
  from {
    -webkit-transform: translate3d(88vw, 106vh, 0);
            transform: translate3d(88vw, 106vh, 0);
  }

  to {
    -webkit-transform: translate3d(18vw, -126vh, 0);
            transform: translate3d(18vw, -126vh, 0);
  }
}

@keyframes move-frames-60 {
  from {
    -webkit-transform: translate3d(88vw, 106vh, 0);
            transform: translate3d(88vw, 106vh, 0);
  }

  to {
    -webkit-transform: translate3d(18vw, -126vh, 0);
            transform: translate3d(18vw, -126vh, 0);
  }
}

.circle-container2:nth-child(60) .circle {
  -webkit-animation-delay: 679ms;
          animation-delay: 679ms;
}

.circle-container2:nth-child(61) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-61;
          animation-name: move-frames-61;
  -webkit-animation-duration: 31448ms;
          animation-duration: 31448ms;
  -webkit-animation-delay: 29691ms;
          animation-delay: 29691ms;
}

@-webkit-keyframes move-frames-61 {
  from {
    -webkit-transform: translate3d(82vw, 106vh, 0);
            transform: translate3d(82vw, 106vh, 0);
  }

  to {
    -webkit-transform: translate3d(15vw, -118vh, 0);
            transform: translate3d(15vw, -118vh, 0);
  }
}

@keyframes move-frames-61 {
  from {
    -webkit-transform: translate3d(82vw, 106vh, 0);
            transform: translate3d(82vw, 106vh, 0);
  }

  to {
    -webkit-transform: translate3d(15vw, -118vh, 0);
            transform: translate3d(15vw, -118vh, 0);
  }
}

.circle-container2:nth-child(61) .circle {
  -webkit-animation-delay: 1833ms;
          animation-delay: 1833ms;
}

.circle-container2:nth-child(62) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-62;
          animation-name: move-frames-62;
  -webkit-animation-duration: 28308ms;
          animation-duration: 28308ms;
  -webkit-animation-delay: 19263ms;
          animation-delay: 19263ms;
}

@-webkit-keyframes move-frames-62 {
  from {
    -webkit-transform: translate3d(55vw, 104vh, 0);
            transform: translate3d(55vw, 104vh, 0);
  }

  to {
    -webkit-transform: translate3d(86vw, -128vh, 0);
            transform: translate3d(86vw, -128vh, 0);
  }
}

@keyframes move-frames-62 {
  from {
    -webkit-transform: translate3d(55vw, 104vh, 0);
            transform: translate3d(55vw, 104vh, 0);
  }

  to {
    -webkit-transform: translate3d(86vw, -128vh, 0);
            transform: translate3d(86vw, -128vh, 0);
  }
}

.circle-container2:nth-child(62) .circle {
  -webkit-animation-delay: 3453ms;
          animation-delay: 3453ms;
}

.circle-container2:nth-child(63) {
  width: 1px;
  height: 1px;
  -webkit-animation-name: move-frames-63;
          animation-name: move-frames-63;
  -webkit-animation-duration: 30972ms;
          animation-duration: 30972ms;
  -webkit-animation-delay: 3844ms;
          animation-delay: 3844ms;
}

@-webkit-keyframes move-frames-63 {
  from {
    -webkit-transform: translate3d(49vw, 101vh, 0);
            transform: translate3d(49vw, 101vh, 0);
  }

  to {
    -webkit-transform: translate3d(87vw, -107vh, 0);
            transform: translate3d(87vw, -107vh, 0);
  }
}

@keyframes move-frames-63 {
  from {
    -webkit-transform: translate3d(49vw, 101vh, 0);
            transform: translate3d(49vw, 101vh, 0);
  }

  to {
    -webkit-transform: translate3d(87vw, -107vh, 0);
            transform: translate3d(87vw, -107vh, 0);
  }
}

.circle-container2:nth-child(63) .circle {
  -webkit-animation-delay: 626ms;
          animation-delay: 626ms;
}

.circle-container2:nth-child(64) {
  width: 1px;
  height: 1px;
  -webkit-animation-name: move-frames-64;
          animation-name: move-frames-64;
  -webkit-animation-duration: 33170ms;
          animation-duration: 33170ms;
  -webkit-animation-delay: 144ms;
          animation-delay: 144ms;
}

@-webkit-keyframes move-frames-64 {
  from {
    -webkit-transform: translate3d(41vw, 105vh, 0);
            transform: translate3d(41vw, 105vh, 0);
  }

  to {
    -webkit-transform: translate3d(34vw, -111vh, 0);
            transform: translate3d(34vw, -111vh, 0);
  }
}

@keyframes move-frames-64 {
  from {
    -webkit-transform: translate3d(41vw, 105vh, 0);
            transform: translate3d(41vw, 105vh, 0);
  }

  to {
    -webkit-transform: translate3d(34vw, -111vh, 0);
            transform: translate3d(34vw, -111vh, 0);
  }
}

.circle-container2:nth-child(64) .circle {
  -webkit-animation-delay: 1099ms;
          animation-delay: 1099ms;
}

.circle-container2:nth-child(65) {
  width: 1px;
  height: 1px;
  -webkit-animation-name: move-frames-65;
          animation-name: move-frames-65;
  -webkit-animation-duration: 36461ms;
          animation-duration: 36461ms;
  -webkit-animation-delay: 851ms;
          animation-delay: 851ms;
}

@-webkit-keyframes move-frames-65 {
  from {
    -webkit-transform: translate3d(25vw, 103vh, 0);
            transform: translate3d(25vw, 103vh, 0);
  }

  to {
    -webkit-transform: translate3d(79vw, -131vh, 0);
            transform: translate3d(79vw, -131vh, 0);
  }
}

@keyframes move-frames-65 {
  from {
    -webkit-transform: translate3d(25vw, 103vh, 0);
            transform: translate3d(25vw, 103vh, 0);
  }

  to {
    -webkit-transform: translate3d(79vw, -131vh, 0);
            transform: translate3d(79vw, -131vh, 0);
  }
}

.circle-container2:nth-child(65) .circle {
  -webkit-animation-delay: 2827ms;
          animation-delay: 2827ms;
}

.circle-container2:nth-child(66) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-66;
          animation-name: move-frames-66;
  -webkit-animation-duration: 32334ms;
          animation-duration: 32334ms;
  -webkit-animation-delay: 21586ms;
          animation-delay: 21586ms;
}

@-webkit-keyframes move-frames-66 {
  from {
    -webkit-transform: translate3d(52vw, 107vh, 0);
            transform: translate3d(52vw, 107vh, 0);
  }

  to {
    -webkit-transform: translate3d(44vw, -133vh, 0);
            transform: translate3d(44vw, -133vh, 0);
  }
}

@keyframes move-frames-66 {
  from {
    -webkit-transform: translate3d(52vw, 107vh, 0);
            transform: translate3d(52vw, 107vh, 0);
  }

  to {
    -webkit-transform: translate3d(44vw, -133vh, 0);
            transform: translate3d(44vw, -133vh, 0);
  }
}

.circle-container2:nth-child(66) .circle {
  -webkit-animation-delay: 3780ms;
          animation-delay: 3780ms;
}

.circle-container2:nth-child(67) {
  width: 7px;
  height: 7px;
  -webkit-animation-name: move-frames-67;
          animation-name: move-frames-67;
  -webkit-animation-duration: 34301ms;
          animation-duration: 34301ms;
  -webkit-animation-delay: 34104ms;
          animation-delay: 34104ms;
}

@-webkit-keyframes move-frames-67 {
  from {
    -webkit-transform: translate3d(77vw, 101vh, 0);
            transform: translate3d(77vw, 101vh, 0);
  }

  to {
    -webkit-transform: translate3d(29vw, -113vh, 0);
            transform: translate3d(29vw, -113vh, 0);
  }
}

@keyframes move-frames-67 {
  from {
    -webkit-transform: translate3d(77vw, 101vh, 0);
            transform: translate3d(77vw, 101vh, 0);
  }

  to {
    -webkit-transform: translate3d(29vw, -113vh, 0);
            transform: translate3d(29vw, -113vh, 0);
  }
}

.circle-container2:nth-child(67) .circle {
  -webkit-animation-delay: 26ms;
          animation-delay: 26ms;
}

.circle-container2:nth-child(68) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-68;
          animation-name: move-frames-68;
  -webkit-animation-duration: 34599ms;
          animation-duration: 34599ms;
  -webkit-animation-delay: 36245ms;
          animation-delay: 36245ms;
}

@-webkit-keyframes move-frames-68 {
  from {
    -webkit-transform: translate3d(74vw, 101vh, 0);
            transform: translate3d(74vw, 101vh, 0);
  }

  to {
    -webkit-transform: translate3d(86vw, -103vh, 0);
            transform: translate3d(86vw, -103vh, 0);
  }
}

@keyframes move-frames-68 {
  from {
    -webkit-transform: translate3d(74vw, 101vh, 0);
            transform: translate3d(74vw, 101vh, 0);
  }

  to {
    -webkit-transform: translate3d(86vw, -103vh, 0);
            transform: translate3d(86vw, -103vh, 0);
  }
}

.circle-container2:nth-child(68) .circle {
  -webkit-animation-delay: 2759ms;
          animation-delay: 2759ms;
}

.circle-container2:nth-child(69) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-69;
          animation-name: move-frames-69;
  -webkit-animation-duration: 36460ms;
          animation-duration: 36460ms;
  -webkit-animation-delay: 9061ms;
          animation-delay: 9061ms;
}

@-webkit-keyframes move-frames-69 {
  from {
    -webkit-transform: translate3d(39vw, 101vh, 0);
            transform: translate3d(39vw, 101vh, 0);
  }

  to {
    -webkit-transform: translate3d(80vw, -122vh, 0);
            transform: translate3d(80vw, -122vh, 0);
  }
}

@keyframes move-frames-69 {
  from {
    -webkit-transform: translate3d(39vw, 101vh, 0);
            transform: translate3d(39vw, 101vh, 0);
  }

  to {
    -webkit-transform: translate3d(80vw, -122vh, 0);
            transform: translate3d(80vw, -122vh, 0);
  }
}

.circle-container2:nth-child(69) .circle {
  -webkit-animation-delay: 2452ms;
          animation-delay: 2452ms;
}

.circle-container2:nth-child(70) {
  width: 1px;
  height: 1px;
  -webkit-animation-name: move-frames-70;
          animation-name: move-frames-70;
  -webkit-animation-duration: 33436ms;
          animation-duration: 33436ms;
  -webkit-animation-delay: 36789ms;
          animation-delay: 36789ms;
}

@-webkit-keyframes move-frames-70 {
  from {
    -webkit-transform: translate3d(74vw, 108vh, 0);
            transform: translate3d(74vw, 108vh, 0);
  }

  to {
    -webkit-transform: translate3d(37vw, -124vh, 0);
            transform: translate3d(37vw, -124vh, 0);
  }
}

@keyframes move-frames-70 {
  from {
    -webkit-transform: translate3d(74vw, 108vh, 0);
            transform: translate3d(74vw, 108vh, 0);
  }

  to {
    -webkit-transform: translate3d(37vw, -124vh, 0);
            transform: translate3d(37vw, -124vh, 0);
  }
}

.circle-container2:nth-child(70) .circle {
  -webkit-animation-delay: 3893ms;
          animation-delay: 3893ms;
}

.circle-container2:nth-child(71) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-71;
          animation-name: move-frames-71;
  -webkit-animation-duration: 29878ms;
          animation-duration: 29878ms;
  -webkit-animation-delay: 9226ms;
          animation-delay: 9226ms;
}

@-webkit-keyframes move-frames-71 {
  from {
    -webkit-transform: translate3d(62vw, 106vh, 0);
            transform: translate3d(62vw, 106vh, 0);
  }

  to {
    -webkit-transform: translate3d(69vw, -135vh, 0);
            transform: translate3d(69vw, -135vh, 0);
  }
}

@keyframes move-frames-71 {
  from {
    -webkit-transform: translate3d(62vw, 106vh, 0);
            transform: translate3d(62vw, 106vh, 0);
  }

  to {
    -webkit-transform: translate3d(69vw, -135vh, 0);
            transform: translate3d(69vw, -135vh, 0);
  }
}

.circle-container2:nth-child(71) .circle {
  -webkit-animation-delay: 2395ms;
          animation-delay: 2395ms;
}

.circle-container2:nth-child(72) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-72;
          animation-name: move-frames-72;
  -webkit-animation-duration: 36655ms;
          animation-duration: 36655ms;
  -webkit-animation-delay: 33632ms;
          animation-delay: 33632ms;
}

@-webkit-keyframes move-frames-72 {
  from {
    -webkit-transform: translate3d(41vw, 103vh, 0);
            transform: translate3d(41vw, 103vh, 0);
  }

  to {
    -webkit-transform: translate3d(3vw, -117vh, 0);
            transform: translate3d(3vw, -117vh, 0);
  }
}

@keyframes move-frames-72 {
  from {
    -webkit-transform: translate3d(41vw, 103vh, 0);
            transform: translate3d(41vw, 103vh, 0);
  }

  to {
    -webkit-transform: translate3d(3vw, -117vh, 0);
            transform: translate3d(3vw, -117vh, 0);
  }
}

.circle-container2:nth-child(72) .circle {
  -webkit-animation-delay: 2080ms;
          animation-delay: 2080ms;
}

.circle-container2:nth-child(73) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-73;
          animation-name: move-frames-73;
  -webkit-animation-duration: 34472ms;
          animation-duration: 34472ms;
  -webkit-animation-delay: 26092ms;
          animation-delay: 26092ms;
}

@-webkit-keyframes move-frames-73 {
  from {
    -webkit-transform: translate3d(12vw, 109vh, 0);
            transform: translate3d(12vw, 109vh, 0);
  }

  to {
    -webkit-transform: translate3d(24vw, -134vh, 0);
            transform: translate3d(24vw, -134vh, 0);
  }
}

@keyframes move-frames-73 {
  from {
    -webkit-transform: translate3d(12vw, 109vh, 0);
            transform: translate3d(12vw, 109vh, 0);
  }

  to {
    -webkit-transform: translate3d(24vw, -134vh, 0);
            transform: translate3d(24vw, -134vh, 0);
  }
}

.circle-container2:nth-child(73) .circle {
  -webkit-animation-delay: 313ms;
          animation-delay: 313ms;
}

.circle-container2:nth-child(74) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-74;
          animation-name: move-frames-74;
  -webkit-animation-duration: 31693ms;
          animation-duration: 31693ms;
  -webkit-animation-delay: 27190ms;
          animation-delay: 27190ms;
}

@-webkit-keyframes move-frames-74 {
  from {
    -webkit-transform: translate3d(28vw, 102vh, 0);
            transform: translate3d(28vw, 102vh, 0);
  }

  to {
    -webkit-transform: translate3d(71vw, -120vh, 0);
            transform: translate3d(71vw, -120vh, 0);
  }
}

@keyframes move-frames-74 {
  from {
    -webkit-transform: translate3d(28vw, 102vh, 0);
            transform: translate3d(28vw, 102vh, 0);
  }

  to {
    -webkit-transform: translate3d(71vw, -120vh, 0);
            transform: translate3d(71vw, -120vh, 0);
  }
}

.circle-container2:nth-child(74) .circle {
  -webkit-animation-delay: 938ms;
          animation-delay: 938ms;
}

.circle-container2:nth-child(75) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-75;
          animation-name: move-frames-75;
  -webkit-animation-duration: 34065ms;
          animation-duration: 34065ms;
  -webkit-animation-delay: 18116ms;
          animation-delay: 18116ms;
}

@-webkit-keyframes move-frames-75 {
  from {
    -webkit-transform: translate3d(42vw, 104vh, 0);
            transform: translate3d(42vw, 104vh, 0);
  }

  to {
    -webkit-transform: translate3d(99vw, -107vh, 0);
            transform: translate3d(99vw, -107vh, 0);
  }
}

@keyframes move-frames-75 {
  from {
    -webkit-transform: translate3d(42vw, 104vh, 0);
            transform: translate3d(42vw, 104vh, 0);
  }

  to {
    -webkit-transform: translate3d(99vw, -107vh, 0);
            transform: translate3d(99vw, -107vh, 0);
  }
}

.circle-container2:nth-child(75) .circle {
  -webkit-animation-delay: 588ms;
          animation-delay: 588ms;
}

.circle-container2:nth-child(76) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-76;
          animation-name: move-frames-76;
  -webkit-animation-duration: 30502ms;
          animation-duration: 30502ms;
  -webkit-animation-delay: 26354ms;
          animation-delay: 26354ms;
}

@-webkit-keyframes move-frames-76 {
  from {
    -webkit-transform: translate3d(36vw, 104vh, 0);
            transform: translate3d(36vw, 104vh, 0);
  }

  to {
    -webkit-transform: translate3d(89vw, -112vh, 0);
            transform: translate3d(89vw, -112vh, 0);
  }
}

@keyframes move-frames-76 {
  from {
    -webkit-transform: translate3d(36vw, 104vh, 0);
            transform: translate3d(36vw, 104vh, 0);
  }

  to {
    -webkit-transform: translate3d(89vw, -112vh, 0);
            transform: translate3d(89vw, -112vh, 0);
  }
}

.circle-container2:nth-child(76) .circle {
  -webkit-animation-delay: 2460ms;
          animation-delay: 2460ms;
}

.circle-container2:nth-child(77) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-77;
          animation-name: move-frames-77;
  -webkit-animation-duration: 31963ms;
          animation-duration: 31963ms;
  -webkit-animation-delay: 23345ms;
          animation-delay: 23345ms;
}

@-webkit-keyframes move-frames-77 {
  from {
    -webkit-transform: translate3d(63vw, 105vh, 0);
            transform: translate3d(63vw, 105vh, 0);
  }

  to {
    -webkit-transform: translate3d(94vw, -115vh, 0);
            transform: translate3d(94vw, -115vh, 0);
  }
}

@keyframes move-frames-77 {
  from {
    -webkit-transform: translate3d(63vw, 105vh, 0);
            transform: translate3d(63vw, 105vh, 0);
  }

  to {
    -webkit-transform: translate3d(94vw, -115vh, 0);
            transform: translate3d(94vw, -115vh, 0);
  }
}

.circle-container2:nth-child(77) .circle {
  -webkit-animation-delay: 1193ms;
          animation-delay: 1193ms;
}

.circle-container2:nth-child(78) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-78;
          animation-name: move-frames-78;
  -webkit-animation-duration: 30431ms;
          animation-duration: 30431ms;
  -webkit-animation-delay: 22141ms;
          animation-delay: 22141ms;
}

@-webkit-keyframes move-frames-78 {
  from {
    -webkit-transform: translate3d(75vw, 109vh, 0);
            transform: translate3d(75vw, 109vh, 0);
  }

  to {
    -webkit-transform: translate3d(16vw, -134vh, 0);
            transform: translate3d(16vw, -134vh, 0);
  }
}

@keyframes move-frames-78 {
  from {
    -webkit-transform: translate3d(75vw, 109vh, 0);
            transform: translate3d(75vw, 109vh, 0);
  }

  to {
    -webkit-transform: translate3d(16vw, -134vh, 0);
            transform: translate3d(16vw, -134vh, 0);
  }
}

.circle-container2:nth-child(78) .circle {
  -webkit-animation-delay: 3693ms;
          animation-delay: 3693ms;
}

.circle-container2:nth-child(79) {
  width: 1px;
  height: 1px;
  -webkit-animation-name: move-frames-79;
          animation-name: move-frames-79;
  -webkit-animation-duration: 36905ms;
          animation-duration: 36905ms;
  -webkit-animation-delay: 20196ms;
          animation-delay: 20196ms;
}

@-webkit-keyframes move-frames-79 {
  from {
    -webkit-transform: translate3d(45vw, 102vh, 0);
            transform: translate3d(45vw, 102vh, 0);
  }

  to {
    -webkit-transform: translate3d(67vw, -104vh, 0);
            transform: translate3d(67vw, -104vh, 0);
  }
}

@keyframes move-frames-79 {
  from {
    -webkit-transform: translate3d(45vw, 102vh, 0);
            transform: translate3d(45vw, 102vh, 0);
  }

  to {
    -webkit-transform: translate3d(67vw, -104vh, 0);
            transform: translate3d(67vw, -104vh, 0);
  }
}

.circle-container2:nth-child(79) .circle {
  -webkit-animation-delay: 3138ms;
          animation-delay: 3138ms;
}

.circle-container2:nth-child(80) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-80;
          animation-name: move-frames-80;
  -webkit-animation-duration: 33414ms;
          animation-duration: 33414ms;
  -webkit-animation-delay: 28543ms;
          animation-delay: 28543ms;
}

@-webkit-keyframes move-frames-80 {
  from {
    -webkit-transform: translate3d(76vw, 109vh, 0);
            transform: translate3d(76vw, 109vh, 0);
  }

  to {
    -webkit-transform: translate3d(90vw, -126vh, 0);
            transform: translate3d(90vw, -126vh, 0);
  }
}

@keyframes move-frames-80 {
  from {
    -webkit-transform: translate3d(76vw, 109vh, 0);
            transform: translate3d(76vw, 109vh, 0);
  }

  to {
    -webkit-transform: translate3d(90vw, -126vh, 0);
            transform: translate3d(90vw, -126vh, 0);
  }
}

.circle-container2:nth-child(80) .circle {
  -webkit-animation-delay: 2014ms;
          animation-delay: 2014ms;
}

.circle-container2:nth-child(81) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-81;
          animation-name: move-frames-81;
  -webkit-animation-duration: 34416ms;
          animation-duration: 34416ms;
  -webkit-animation-delay: 26911ms;
          animation-delay: 26911ms;
}

@-webkit-keyframes move-frames-81 {
  from {
    -webkit-transform: translate3d(7vw, 103vh, 0);
            transform: translate3d(7vw, 103vh, 0);
  }

  to {
    -webkit-transform: translate3d(67vw, -115vh, 0);
            transform: translate3d(67vw, -115vh, 0);
  }
}

@keyframes move-frames-81 {
  from {
    -webkit-transform: translate3d(7vw, 103vh, 0);
            transform: translate3d(7vw, 103vh, 0);
  }

  to {
    -webkit-transform: translate3d(67vw, -115vh, 0);
            transform: translate3d(67vw, -115vh, 0);
  }
}

.circle-container2:nth-child(81) .circle {
  -webkit-animation-delay: 3870ms;
          animation-delay: 3870ms;
}

.circle-container2:nth-child(82) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-82;
          animation-name: move-frames-82;
  -webkit-animation-duration: 29375ms;
          animation-duration: 29375ms;
  -webkit-animation-delay: 36449ms;
          animation-delay: 36449ms;
}

@-webkit-keyframes move-frames-82 {
  from {
    -webkit-transform: translate3d(50vw, 109vh, 0);
            transform: translate3d(50vw, 109vh, 0);
  }

  to {
    -webkit-transform: translate3d(87vw, -127vh, 0);
            transform: translate3d(87vw, -127vh, 0);
  }
}

@keyframes move-frames-82 {
  from {
    -webkit-transform: translate3d(50vw, 109vh, 0);
            transform: translate3d(50vw, 109vh, 0);
  }

  to {
    -webkit-transform: translate3d(87vw, -127vh, 0);
            transform: translate3d(87vw, -127vh, 0);
  }
}

.circle-container2:nth-child(82) .circle {
  -webkit-animation-delay: 1207ms;
          animation-delay: 1207ms;
}

.circle-container2:nth-child(83) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-83;
          animation-name: move-frames-83;
  -webkit-animation-duration: 34734ms;
          animation-duration: 34734ms;
  -webkit-animation-delay: 2453ms;
          animation-delay: 2453ms;
}

@-webkit-keyframes move-frames-83 {
  from {
    -webkit-transform: translate3d(75vw, 108vh, 0);
            transform: translate3d(75vw, 108vh, 0);
  }

  to {
    -webkit-transform: translate3d(23vw, -125vh, 0);
            transform: translate3d(23vw, -125vh, 0);
  }
}

@keyframes move-frames-83 {
  from {
    -webkit-transform: translate3d(75vw, 108vh, 0);
            transform: translate3d(75vw, 108vh, 0);
  }

  to {
    -webkit-transform: translate3d(23vw, -125vh, 0);
            transform: translate3d(23vw, -125vh, 0);
  }
}

.circle-container2:nth-child(83) .circle {
  -webkit-animation-delay: 3365ms;
          animation-delay: 3365ms;
}

.circle-container2:nth-child(84) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-84;
          animation-name: move-frames-84;
  -webkit-animation-duration: 32584ms;
          animation-duration: 32584ms;
  -webkit-animation-delay: 22435ms;
          animation-delay: 22435ms;
}

@-webkit-keyframes move-frames-84 {
  from {
    -webkit-transform: translate3d(52vw, 106vh, 0);
            transform: translate3d(52vw, 106vh, 0);
  }

  to {
    -webkit-transform: translate3d(17vw, -111vh, 0);
            transform: translate3d(17vw, -111vh, 0);
  }
}

@keyframes move-frames-84 {
  from {
    -webkit-transform: translate3d(52vw, 106vh, 0);
            transform: translate3d(52vw, 106vh, 0);
  }

  to {
    -webkit-transform: translate3d(17vw, -111vh, 0);
            transform: translate3d(17vw, -111vh, 0);
  }
}

.circle-container2:nth-child(84) .circle {
  -webkit-animation-delay: 2065ms;
          animation-delay: 2065ms;
}

.circle-container2:nth-child(85) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-85;
          animation-name: move-frames-85;
  -webkit-animation-duration: 30914ms;
          animation-duration: 30914ms;
  -webkit-animation-delay: 4700ms;
          animation-delay: 4700ms;
}

@-webkit-keyframes move-frames-85 {
  from {
    -webkit-transform: translate3d(31vw, 103vh, 0);
            transform: translate3d(31vw, 103vh, 0);
  }

  to {
    -webkit-transform: translate3d(29vw, -121vh, 0);
            transform: translate3d(29vw, -121vh, 0);
  }
}

@keyframes move-frames-85 {
  from {
    -webkit-transform: translate3d(31vw, 103vh, 0);
            transform: translate3d(31vw, 103vh, 0);
  }

  to {
    -webkit-transform: translate3d(29vw, -121vh, 0);
            transform: translate3d(29vw, -121vh, 0);
  }
}

.circle-container2:nth-child(85) .circle {
  -webkit-animation-delay: 471ms;
          animation-delay: 471ms;
}

.circle-container2:nth-child(86) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-86;
          animation-name: move-frames-86;
  -webkit-animation-duration: 34295ms;
          animation-duration: 34295ms;
  -webkit-animation-delay: 36675ms;
          animation-delay: 36675ms;
}

@-webkit-keyframes move-frames-86 {
  from {
    -webkit-transform: translate3d(78vw, 101vh, 0);
            transform: translate3d(78vw, 101vh, 0);
  }

  to {
    -webkit-transform: translate3d(35vw, -117vh, 0);
            transform: translate3d(35vw, -117vh, 0);
  }
}

@keyframes move-frames-86 {
  from {
    -webkit-transform: translate3d(78vw, 101vh, 0);
            transform: translate3d(78vw, 101vh, 0);
  }

  to {
    -webkit-transform: translate3d(35vw, -117vh, 0);
            transform: translate3d(35vw, -117vh, 0);
  }
}

.circle-container2:nth-child(86) .circle {
  -webkit-animation-delay: 2669ms;
          animation-delay: 2669ms;
}

.circle-container2:nth-child(87) {
  width: 1px;
  height: 1px;
  -webkit-animation-name: move-frames-87;
          animation-name: move-frames-87;
  -webkit-animation-duration: 31904ms;
          animation-duration: 31904ms;
  -webkit-animation-delay: 7428ms;
          animation-delay: 7428ms;
}

@-webkit-keyframes move-frames-87 {
  from {
    -webkit-transform: translate3d(30vw, 109vh, 0);
            transform: translate3d(30vw, 109vh, 0);
  }

  to {
    -webkit-transform: translate3d(70vw, -132vh, 0);
            transform: translate3d(70vw, -132vh, 0);
  }
}

@keyframes move-frames-87 {
  from {
    -webkit-transform: translate3d(30vw, 109vh, 0);
            transform: translate3d(30vw, 109vh, 0);
  }

  to {
    -webkit-transform: translate3d(70vw, -132vh, 0);
            transform: translate3d(70vw, -132vh, 0);
  }
}

.circle-container2:nth-child(87) .circle {
  -webkit-animation-delay: 2520ms;
          animation-delay: 2520ms;
}

.circle-container2:nth-child(88) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-88;
          animation-name: move-frames-88;
  -webkit-animation-duration: 32334ms;
          animation-duration: 32334ms;
  -webkit-animation-delay: 17801ms;
          animation-delay: 17801ms;
}

@-webkit-keyframes move-frames-88 {
  from {
    -webkit-transform: translate3d(39vw, 101vh, 0);
            transform: translate3d(39vw, 101vh, 0);
  }

  to {
    -webkit-transform: translate3d(61vw, -107vh, 0);
            transform: translate3d(61vw, -107vh, 0);
  }
}

@keyframes move-frames-88 {
  from {
    -webkit-transform: translate3d(39vw, 101vh, 0);
            transform: translate3d(39vw, 101vh, 0);
  }

  to {
    -webkit-transform: translate3d(61vw, -107vh, 0);
            transform: translate3d(61vw, -107vh, 0);
  }
}

.circle-container2:nth-child(88) .circle {
  -webkit-animation-delay: 2175ms;
          animation-delay: 2175ms;
}

.circle-container2:nth-child(89) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-89;
          animation-name: move-frames-89;
  -webkit-animation-duration: 34126ms;
          animation-duration: 34126ms;
  -webkit-animation-delay: 30329ms;
          animation-delay: 30329ms;
}

@-webkit-keyframes move-frames-89 {
  from {
    -webkit-transform: translate3d(71vw, 103vh, 0);
            transform: translate3d(71vw, 103vh, 0);
  }

  to {
    -webkit-transform: translate3d(44vw, -125vh, 0);
            transform: translate3d(44vw, -125vh, 0);
  }
}

@keyframes move-frames-89 {
  from {
    -webkit-transform: translate3d(71vw, 103vh, 0);
            transform: translate3d(71vw, 103vh, 0);
  }

  to {
    -webkit-transform: translate3d(44vw, -125vh, 0);
            transform: translate3d(44vw, -125vh, 0);
  }
}

.circle-container2:nth-child(89) .circle {
  -webkit-animation-delay: 979ms;
          animation-delay: 979ms;
}

.circle-container2:nth-child(90) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-90;
          animation-name: move-frames-90;
  -webkit-animation-duration: 30221ms;
          animation-duration: 30221ms;
  -webkit-animation-delay: 27967ms;
          animation-delay: 27967ms;
}

@-webkit-keyframes move-frames-90 {
  from {
    -webkit-transform: translate3d(91vw, 110vh, 0);
            transform: translate3d(91vw, 110vh, 0);
  }

  to {
    -webkit-transform: translate3d(43vw, -131vh, 0);
            transform: translate3d(43vw, -131vh, 0);
  }
}

@keyframes move-frames-90 {
  from {
    -webkit-transform: translate3d(91vw, 110vh, 0);
            transform: translate3d(91vw, 110vh, 0);
  }

  to {
    -webkit-transform: translate3d(43vw, -131vh, 0);
            transform: translate3d(43vw, -131vh, 0);
  }
}

.circle-container2:nth-child(90) .circle {
  -webkit-animation-delay: 3340ms;
          animation-delay: 3340ms;
}

.circle-container2:nth-child(91) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-91;
          animation-name: move-frames-91;
  -webkit-animation-duration: 33856ms;
          animation-duration: 33856ms;
  -webkit-animation-delay: 22877ms;
          animation-delay: 22877ms;
}

@-webkit-keyframes move-frames-91 {
  from {
    -webkit-transform: translate3d(38vw, 101vh, 0);
            transform: translate3d(38vw, 101vh, 0);
  }

  to {
    -webkit-transform: translate3d(50vw, -109vh, 0);
            transform: translate3d(50vw, -109vh, 0);
  }
}

@keyframes move-frames-91 {
  from {
    -webkit-transform: translate3d(38vw, 101vh, 0);
            transform: translate3d(38vw, 101vh, 0);
  }

  to {
    -webkit-transform: translate3d(50vw, -109vh, 0);
            transform: translate3d(50vw, -109vh, 0);
  }
}

.circle-container2:nth-child(91) .circle {
  -webkit-animation-delay: 2601ms;
          animation-delay: 2601ms;
}

.circle-container2:nth-child(92) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-92;
          animation-name: move-frames-92;
  -webkit-animation-duration: 33790ms;
          animation-duration: 33790ms;
  -webkit-animation-delay: 25047ms;
          animation-delay: 25047ms;
}

@-webkit-keyframes move-frames-92 {
  from {
    -webkit-transform: translate3d(28vw, 105vh, 0);
            transform: translate3d(28vw, 105vh, 0);
  }

  to {
    -webkit-transform: translate3d(67vw, -109vh, 0);
            transform: translate3d(67vw, -109vh, 0);
  }
}

@keyframes move-frames-92 {
  from {
    -webkit-transform: translate3d(28vw, 105vh, 0);
            transform: translate3d(28vw, 105vh, 0);
  }

  to {
    -webkit-transform: translate3d(67vw, -109vh, 0);
            transform: translate3d(67vw, -109vh, 0);
  }
}

.circle-container2:nth-child(92) .circle {
  -webkit-animation-delay: 995ms;
          animation-delay: 995ms;
}

.circle-container2:nth-child(93) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-93;
          animation-name: move-frames-93;
  -webkit-animation-duration: 32289ms;
          animation-duration: 32289ms;
  -webkit-animation-delay: 8081ms;
          animation-delay: 8081ms;
}

@-webkit-keyframes move-frames-93 {
  from {
    -webkit-transform: translate3d(7vw, 109vh, 0);
            transform: translate3d(7vw, 109vh, 0);
  }

  to {
    -webkit-transform: translate3d(25vw, -129vh, 0);
            transform: translate3d(25vw, -129vh, 0);
  }
}

@keyframes move-frames-93 {
  from {
    -webkit-transform: translate3d(7vw, 109vh, 0);
            transform: translate3d(7vw, 109vh, 0);
  }

  to {
    -webkit-transform: translate3d(25vw, -129vh, 0);
            transform: translate3d(25vw, -129vh, 0);
  }
}

.circle-container2:nth-child(93) .circle {
  -webkit-animation-delay: 1297ms;
          animation-delay: 1297ms;
}

.circle-container2:nth-child(94) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-94;
          animation-name: move-frames-94;
  -webkit-animation-duration: 36464ms;
          animation-duration: 36464ms;
  -webkit-animation-delay: 35818ms;
          animation-delay: 35818ms;
}

@-webkit-keyframes move-frames-94 {
  from {
    -webkit-transform: translate3d(98vw, 102vh, 0);
            transform: translate3d(98vw, 102vh, 0);
  }

  to {
    -webkit-transform: translate3d(73vw, -104vh, 0);
            transform: translate3d(73vw, -104vh, 0);
  }
}

@keyframes move-frames-94 {
  from {
    -webkit-transform: translate3d(98vw, 102vh, 0);
            transform: translate3d(98vw, 102vh, 0);
  }

  to {
    -webkit-transform: translate3d(73vw, -104vh, 0);
            transform: translate3d(73vw, -104vh, 0);
  }
}

.circle-container2:nth-child(94) .circle {
  -webkit-animation-delay: 1388ms;
          animation-delay: 1388ms;
}

.circle-container2:nth-child(95) {
  width: 7px;
  height: 7px;
  -webkit-animation-name: move-frames-95;
          animation-name: move-frames-95;
  -webkit-animation-duration: 35765ms;
          animation-duration: 35765ms;
  -webkit-animation-delay: 21102ms;
          animation-delay: 21102ms;
}

@-webkit-keyframes move-frames-95 {
  from {
    -webkit-transform: translate3d(39vw, 104vh, 0);
            transform: translate3d(39vw, 104vh, 0);
  }

  to {
    -webkit-transform: translate3d(36vw, -111vh, 0);
            transform: translate3d(36vw, -111vh, 0);
  }
}

@keyframes move-frames-95 {
  from {
    -webkit-transform: translate3d(39vw, 104vh, 0);
            transform: translate3d(39vw, 104vh, 0);
  }

  to {
    -webkit-transform: translate3d(36vw, -111vh, 0);
            transform: translate3d(36vw, -111vh, 0);
  }
}

.circle-container2:nth-child(95) .circle {
  -webkit-animation-delay: 2921ms;
          animation-delay: 2921ms;
}

.circle-container2:nth-child(96) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-96;
          animation-name: move-frames-96;
  -webkit-animation-duration: 30502ms;
          animation-duration: 30502ms;
  -webkit-animation-delay: 27214ms;
          animation-delay: 27214ms;
}

@-webkit-keyframes move-frames-96 {
  from {
    -webkit-transform: translate3d(15vw, 108vh, 0);
            transform: translate3d(15vw, 108vh, 0);
  }

  to {
    -webkit-transform: translate3d(13vw, -136vh, 0);
            transform: translate3d(13vw, -136vh, 0);
  }
}

@keyframes move-frames-96 {
  from {
    -webkit-transform: translate3d(15vw, 108vh, 0);
            transform: translate3d(15vw, 108vh, 0);
  }

  to {
    -webkit-transform: translate3d(13vw, -136vh, 0);
            transform: translate3d(13vw, -136vh, 0);
  }
}

.circle-container2:nth-child(96) .circle {
  -webkit-animation-delay: 3485ms;
          animation-delay: 3485ms;
}

.circle-container2:nth-child(97) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-97;
          animation-name: move-frames-97;
  -webkit-animation-duration: 35275ms;
          animation-duration: 35275ms;
  -webkit-animation-delay: 12588ms;
          animation-delay: 12588ms;
}

@-webkit-keyframes move-frames-97 {
  from {
    -webkit-transform: translate3d(42vw, 109vh, 0);
            transform: translate3d(42vw, 109vh, 0);
  }

  to {
    -webkit-transform: translate3d(11vw, -122vh, 0);
            transform: translate3d(11vw, -122vh, 0);
  }
}

@keyframes move-frames-97 {
  from {
    -webkit-transform: translate3d(42vw, 109vh, 0);
            transform: translate3d(42vw, 109vh, 0);
  }

  to {
    -webkit-transform: translate3d(11vw, -122vh, 0);
            transform: translate3d(11vw, -122vh, 0);
  }
}

.circle-container2:nth-child(97) .circle {
  -webkit-animation-delay: 2892ms;
          animation-delay: 2892ms;
}

.circle-container2:nth-child(98) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-98;
          animation-name: move-frames-98;
  -webkit-animation-duration: 35993ms;
          animation-duration: 35993ms;
  -webkit-animation-delay: 27568ms;
          animation-delay: 27568ms;
}

@-webkit-keyframes move-frames-98 {
  from {
    -webkit-transform: translate3d(18vw, 101vh, 0);
            transform: translate3d(18vw, 101vh, 0);
  }

  to {
    -webkit-transform: translate3d(33vw, -119vh, 0);
            transform: translate3d(33vw, -119vh, 0);
  }
}

@keyframes move-frames-98 {
  from {
    -webkit-transform: translate3d(18vw, 101vh, 0);
            transform: translate3d(18vw, 101vh, 0);
  }

  to {
    -webkit-transform: translate3d(33vw, -119vh, 0);
            transform: translate3d(33vw, -119vh, 0);
  }
}

.circle-container2:nth-child(98) .circle {
  -webkit-animation-delay: 74ms;
          animation-delay: 74ms;
}

.circle-container2:nth-child(99) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-99;
          animation-name: move-frames-99;
  -webkit-animation-duration: 28213ms;
          animation-duration: 28213ms;
  -webkit-animation-delay: 33567ms;
          animation-delay: 33567ms;
}

@-webkit-keyframes move-frames-99 {
  from {
    -webkit-transform: translate3d(95vw, 106vh, 0);
            transform: translate3d(95vw, 106vh, 0);
  }

  to {
    -webkit-transform: translate3d(79vw, -135vh, 0);
            transform: translate3d(79vw, -135vh, 0);
  }
}

@keyframes move-frames-99 {
  from {
    -webkit-transform: translate3d(95vw, 106vh, 0);
            transform: translate3d(95vw, 106vh, 0);
  }

  to {
    -webkit-transform: translate3d(79vw, -135vh, 0);
            transform: translate3d(79vw, -135vh, 0);
  }
}

.circle-container2:nth-child(99) .circle {
  -webkit-animation-delay: 198ms;
          animation-delay: 198ms;
}

.circle-container2:nth-child(100) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-100;
          animation-name: move-frames-100;
  -webkit-animation-duration: 29405ms;
          animation-duration: 29405ms;
  -webkit-animation-delay: 30490ms;
          animation-delay: 30490ms;
}

@-webkit-keyframes move-frames-100 {
  from {
    -webkit-transform: translate3d(5vw, 103vh, 0);
            transform: translate3d(5vw, 103vh, 0);
  }

  to {
    -webkit-transform: translate3d(5vw, -110vh, 0);
            transform: translate3d(5vw, -110vh, 0);
  }
}

@keyframes move-frames-100 {
  from {
    -webkit-transform: translate3d(5vw, 103vh, 0);
            transform: translate3d(5vw, 103vh, 0);
  }

  to {
    -webkit-transform: translate3d(5vw, -110vh, 0);
            transform: translate3d(5vw, -110vh, 0);
  }
}

.circle-container2:nth-child(100) .circle {
  -webkit-animation-delay: 3981ms;
          animation-delay: 3981ms;
}

.circle-container2:nth-child(101) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-101;
          animation-name: move-frames-101;
  -webkit-animation-duration: 29968ms;
          animation-duration: 29968ms;
  -webkit-animation-delay: 9125ms;
          animation-delay: 9125ms;
}

@-webkit-keyframes move-frames-101 {
  from {
    -webkit-transform: translate3d(85vw, 107vh, 0);
            transform: translate3d(85vw, 107vh, 0);
  }

  to {
    -webkit-transform: translate3d(71vw, -110vh, 0);
            transform: translate3d(71vw, -110vh, 0);
  }
}

@keyframes move-frames-101 {
  from {
    -webkit-transform: translate3d(85vw, 107vh, 0);
            transform: translate3d(85vw, 107vh, 0);
  }

  to {
    -webkit-transform: translate3d(71vw, -110vh, 0);
            transform: translate3d(71vw, -110vh, 0);
  }
}

.circle-container2:nth-child(101) .circle {
  -webkit-animation-delay: 2201ms;
          animation-delay: 2201ms;
}

.circle-container2:nth-child(102) {
  width: 7px;
  height: 7px;
  -webkit-animation-name: move-frames-102;
          animation-name: move-frames-102;
  -webkit-animation-duration: 28916ms;
          animation-duration: 28916ms;
  -webkit-animation-delay: 22698ms;
          animation-delay: 22698ms;
}

@-webkit-keyframes move-frames-102 {
  from {
    -webkit-transform: translate3d(37vw, 110vh, 0);
            transform: translate3d(37vw, 110vh, 0);
  }

  to {
    -webkit-transform: translate3d(20vw, -120vh, 0);
            transform: translate3d(20vw, -120vh, 0);
  }
}

@keyframes move-frames-102 {
  from {
    -webkit-transform: translate3d(37vw, 110vh, 0);
            transform: translate3d(37vw, 110vh, 0);
  }

  to {
    -webkit-transform: translate3d(20vw, -120vh, 0);
            transform: translate3d(20vw, -120vh, 0);
  }
}

.circle-container2:nth-child(102) .circle {
  -webkit-animation-delay: 3872ms;
          animation-delay: 3872ms;
}

.circle-container2:nth-child(103) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-103;
          animation-name: move-frames-103;
  -webkit-animation-duration: 29503ms;
          animation-duration: 29503ms;
  -webkit-animation-delay: 21791ms;
          animation-delay: 21791ms;
}

@-webkit-keyframes move-frames-103 {
  from {
    -webkit-transform: translate3d(86vw, 104vh, 0);
            transform: translate3d(86vw, 104vh, 0);
  }

  to {
    -webkit-transform: translate3d(2vw, -134vh, 0);
            transform: translate3d(2vw, -134vh, 0);
  }
}

@keyframes move-frames-103 {
  from {
    -webkit-transform: translate3d(86vw, 104vh, 0);
            transform: translate3d(86vw, 104vh, 0);
  }

  to {
    -webkit-transform: translate3d(2vw, -134vh, 0);
            transform: translate3d(2vw, -134vh, 0);
  }
}

.circle-container2:nth-child(103) .circle {
  -webkit-animation-delay: 3570ms;
          animation-delay: 3570ms;
}

.circle-container2:nth-child(104) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-104;
          animation-name: move-frames-104;
  -webkit-animation-duration: 30734ms;
          animation-duration: 30734ms;
  -webkit-animation-delay: 30465ms;
          animation-delay: 30465ms;
}

@-webkit-keyframes move-frames-104 {
  from {
    -webkit-transform: translate3d(98vw, 101vh, 0);
            transform: translate3d(98vw, 101vh, 0);
  }

  to {
    -webkit-transform: translate3d(35vw, -109vh, 0);
            transform: translate3d(35vw, -109vh, 0);
  }
}

@keyframes move-frames-104 {
  from {
    -webkit-transform: translate3d(98vw, 101vh, 0);
            transform: translate3d(98vw, 101vh, 0);
  }

  to {
    -webkit-transform: translate3d(35vw, -109vh, 0);
            transform: translate3d(35vw, -109vh, 0);
  }
}

.circle-container2:nth-child(104) .circle {
  -webkit-animation-delay: 3690ms;
          animation-delay: 3690ms;
}

.circle-container2:nth-child(105) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-105;
          animation-name: move-frames-105;
  -webkit-animation-duration: 30204ms;
          animation-duration: 30204ms;
  -webkit-animation-delay: 14424ms;
          animation-delay: 14424ms;
}

@-webkit-keyframes move-frames-105 {
  from {
    -webkit-transform: translate3d(25vw, 109vh, 0);
            transform: translate3d(25vw, 109vh, 0);
  }

  to {
    -webkit-transform: translate3d(71vw, -126vh, 0);
            transform: translate3d(71vw, -126vh, 0);
  }
}

@keyframes move-frames-105 {
  from {
    -webkit-transform: translate3d(25vw, 109vh, 0);
            transform: translate3d(25vw, 109vh, 0);
  }

  to {
    -webkit-transform: translate3d(71vw, -126vh, 0);
            transform: translate3d(71vw, -126vh, 0);
  }
}

.circle-container2:nth-child(105) .circle {
  -webkit-animation-delay: 3170ms;
          animation-delay: 3170ms;
}

.circle-container2:nth-child(106) {
  width: 1px;
  height: 1px;
  -webkit-animation-name: move-frames-106;
          animation-name: move-frames-106;
  -webkit-animation-duration: 32148ms;
          animation-duration: 32148ms;
  -webkit-animation-delay: 19165ms;
          animation-delay: 19165ms;
}

@-webkit-keyframes move-frames-106 {
  from {
    -webkit-transform: translate3d(84vw, 105vh, 0);
            transform: translate3d(84vw, 105vh, 0);
  }

  to {
    -webkit-transform: translate3d(52vw, -128vh, 0);
            transform: translate3d(52vw, -128vh, 0);
  }
}

@keyframes move-frames-106 {
  from {
    -webkit-transform: translate3d(84vw, 105vh, 0);
            transform: translate3d(84vw, 105vh, 0);
  }

  to {
    -webkit-transform: translate3d(52vw, -128vh, 0);
            transform: translate3d(52vw, -128vh, 0);
  }
}

.circle-container2:nth-child(106) .circle {
  -webkit-animation-delay: 3842ms;
          animation-delay: 3842ms;
}

.circle-container2:nth-child(107) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-107;
          animation-name: move-frames-107;
  -webkit-animation-duration: 36943ms;
          animation-duration: 36943ms;
  -webkit-animation-delay: 12405ms;
          animation-delay: 12405ms;
}

@-webkit-keyframes move-frames-107 {
  from {
    -webkit-transform: translate3d(16vw, 110vh, 0);
            transform: translate3d(16vw, 110vh, 0);
  }

  to {
    -webkit-transform: translate3d(1vw, -112vh, 0);
            transform: translate3d(1vw, -112vh, 0);
  }
}

@keyframes move-frames-107 {
  from {
    -webkit-transform: translate3d(16vw, 110vh, 0);
            transform: translate3d(16vw, 110vh, 0);
  }

  to {
    -webkit-transform: translate3d(1vw, -112vh, 0);
            transform: translate3d(1vw, -112vh, 0);
  }
}

.circle-container2:nth-child(107) .circle {
  -webkit-animation-delay: 3906ms;
          animation-delay: 3906ms;
}

.circle-container2:nth-child(108) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-108;
          animation-name: move-frames-108;
  -webkit-animation-duration: 35467ms;
          animation-duration: 35467ms;
  -webkit-animation-delay: 32037ms;
          animation-delay: 32037ms;
}

@-webkit-keyframes move-frames-108 {
  from {
    -webkit-transform: translate3d(56vw, 110vh, 0);
            transform: translate3d(56vw, 110vh, 0);
  }

  to {
    -webkit-transform: translate3d(93vw, -118vh, 0);
            transform: translate3d(93vw, -118vh, 0);
  }
}

@keyframes move-frames-108 {
  from {
    -webkit-transform: translate3d(56vw, 110vh, 0);
            transform: translate3d(56vw, 110vh, 0);
  }

  to {
    -webkit-transform: translate3d(93vw, -118vh, 0);
            transform: translate3d(93vw, -118vh, 0);
  }
}

.circle-container2:nth-child(108) .circle {
  -webkit-animation-delay: 1682ms;
          animation-delay: 1682ms;
}

.circle-container2:nth-child(109) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-109;
          animation-name: move-frames-109;
  -webkit-animation-duration: 32289ms;
          animation-duration: 32289ms;
  -webkit-animation-delay: 25940ms;
          animation-delay: 25940ms;
}

@-webkit-keyframes move-frames-109 {
  from {
    -webkit-transform: translate3d(46vw, 105vh, 0);
            transform: translate3d(46vw, 105vh, 0);
  }

  to {
    -webkit-transform: translate3d(78vw, -133vh, 0);
            transform: translate3d(78vw, -133vh, 0);
  }
}

@keyframes move-frames-109 {
  from {
    -webkit-transform: translate3d(46vw, 105vh, 0);
            transform: translate3d(46vw, 105vh, 0);
  }

  to {
    -webkit-transform: translate3d(78vw, -133vh, 0);
            transform: translate3d(78vw, -133vh, 0);
  }
}

.circle-container2:nth-child(109) .circle {
  -webkit-animation-delay: 859ms;
          animation-delay: 859ms;
}

.circle-container2:nth-child(110) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-110;
          animation-name: move-frames-110;
  -webkit-animation-duration: 33249ms;
          animation-duration: 33249ms;
  -webkit-animation-delay: 11206ms;
          animation-delay: 11206ms;
}

@-webkit-keyframes move-frames-110 {
  from {
    -webkit-transform: translate3d(78vw, 107vh, 0);
            transform: translate3d(78vw, 107vh, 0);
  }

  to {
    -webkit-transform: translate3d(42vw, -128vh, 0);
            transform: translate3d(42vw, -128vh, 0);
  }
}

@keyframes move-frames-110 {
  from {
    -webkit-transform: translate3d(78vw, 107vh, 0);
            transform: translate3d(78vw, 107vh, 0);
  }

  to {
    -webkit-transform: translate3d(42vw, -128vh, 0);
            transform: translate3d(42vw, -128vh, 0);
  }
}

.circle-container2:nth-child(110) .circle {
  -webkit-animation-delay: 1543ms;
          animation-delay: 1543ms;
}

.circle-container2:nth-child(111) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-111;
          animation-name: move-frames-111;
  -webkit-animation-duration: 32235ms;
          animation-duration: 32235ms;
  -webkit-animation-delay: 22721ms;
          animation-delay: 22721ms;
}

@-webkit-keyframes move-frames-111 {
  from {
    -webkit-transform: translate3d(93vw, 107vh, 0);
            transform: translate3d(93vw, 107vh, 0);
  }

  to {
    -webkit-transform: translate3d(72vw, -129vh, 0);
            transform: translate3d(72vw, -129vh, 0);
  }
}

@keyframes move-frames-111 {
  from {
    -webkit-transform: translate3d(93vw, 107vh, 0);
            transform: translate3d(93vw, 107vh, 0);
  }

  to {
    -webkit-transform: translate3d(72vw, -129vh, 0);
            transform: translate3d(72vw, -129vh, 0);
  }
}

.circle-container2:nth-child(111) .circle {
  -webkit-animation-delay: 926ms;
          animation-delay: 926ms;
}

.circle-container2:nth-child(112) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-112;
          animation-name: move-frames-112;
  -webkit-animation-duration: 36584ms;
          animation-duration: 36584ms;
  -webkit-animation-delay: 17442ms;
          animation-delay: 17442ms;
}

@-webkit-keyframes move-frames-112 {
  from {
    -webkit-transform: translate3d(66vw, 103vh, 0);
            transform: translate3d(66vw, 103vh, 0);
  }

  to {
    -webkit-transform: translate3d(84vw, -128vh, 0);
            transform: translate3d(84vw, -128vh, 0);
  }
}

@keyframes move-frames-112 {
  from {
    -webkit-transform: translate3d(66vw, 103vh, 0);
            transform: translate3d(66vw, 103vh, 0);
  }

  to {
    -webkit-transform: translate3d(84vw, -128vh, 0);
            transform: translate3d(84vw, -128vh, 0);
  }
}

.circle-container2:nth-child(112) .circle {
  -webkit-animation-delay: 3359ms;
          animation-delay: 3359ms;
}

.circle-container2:nth-child(113) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-113;
          animation-name: move-frames-113;
  -webkit-animation-duration: 31260ms;
          animation-duration: 31260ms;
  -webkit-animation-delay: 8370ms;
          animation-delay: 8370ms;
}

@-webkit-keyframes move-frames-113 {
  from {
    -webkit-transform: translate3d(46vw, 108vh, 0);
            transform: translate3d(46vw, 108vh, 0);
  }

  to {
    -webkit-transform: translate3d(42vw, -132vh, 0);
            transform: translate3d(42vw, -132vh, 0);
  }
}

@keyframes move-frames-113 {
  from {
    -webkit-transform: translate3d(46vw, 108vh, 0);
            transform: translate3d(46vw, 108vh, 0);
  }

  to {
    -webkit-transform: translate3d(42vw, -132vh, 0);
            transform: translate3d(42vw, -132vh, 0);
  }
}

.circle-container2:nth-child(113) .circle {
  -webkit-animation-delay: 1133ms;
          animation-delay: 1133ms;
}

.circle-container2:nth-child(114) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-114;
          animation-name: move-frames-114;
  -webkit-animation-duration: 29389ms;
          animation-duration: 29389ms;
  -webkit-animation-delay: 5792ms;
          animation-delay: 5792ms;
}

@-webkit-keyframes move-frames-114 {
  from {
    -webkit-transform: translate3d(3vw, 106vh, 0);
            transform: translate3d(3vw, 106vh, 0);
  }

  to {
    -webkit-transform: translate3d(58vw, -126vh, 0);
            transform: translate3d(58vw, -126vh, 0);
  }
}

@keyframes move-frames-114 {
  from {
    -webkit-transform: translate3d(3vw, 106vh, 0);
            transform: translate3d(3vw, 106vh, 0);
  }

  to {
    -webkit-transform: translate3d(58vw, -126vh, 0);
            transform: translate3d(58vw, -126vh, 0);
  }
}

.circle-container2:nth-child(114) .circle {
  -webkit-animation-delay: 3600ms;
          animation-delay: 3600ms;
}

.circle-container2:nth-child(115) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-115;
          animation-name: move-frames-115;
  -webkit-animation-duration: 28611ms;
          animation-duration: 28611ms;
  -webkit-animation-delay: 21873ms;
          animation-delay: 21873ms;
}

@-webkit-keyframes move-frames-115 {
  from {
    -webkit-transform: translate3d(26vw, 107vh, 0);
            transform: translate3d(26vw, 107vh, 0);
  }

  to {
    -webkit-transform: translate3d(20vw, -123vh, 0);
            transform: translate3d(20vw, -123vh, 0);
  }
}

@keyframes move-frames-115 {
  from {
    -webkit-transform: translate3d(26vw, 107vh, 0);
            transform: translate3d(26vw, 107vh, 0);
  }

  to {
    -webkit-transform: translate3d(20vw, -123vh, 0);
            transform: translate3d(20vw, -123vh, 0);
  }
}

.circle-container2:nth-child(115) .circle {
  -webkit-animation-delay: 1609ms;
          animation-delay: 1609ms;
}

.circle-container2:nth-child(116) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-116;
          animation-name: move-frames-116;
  -webkit-animation-duration: 35599ms;
          animation-duration: 35599ms;
  -webkit-animation-delay: 734ms;
          animation-delay: 734ms;
}

@-webkit-keyframes move-frames-116 {
  from {
    -webkit-transform: translate3d(86vw, 107vh, 0);
            transform: translate3d(86vw, 107vh, 0);
  }

  to {
    -webkit-transform: translate3d(41vw, -133vh, 0);
            transform: translate3d(41vw, -133vh, 0);
  }
}

@keyframes move-frames-116 {
  from {
    -webkit-transform: translate3d(86vw, 107vh, 0);
            transform: translate3d(86vw, 107vh, 0);
  }

  to {
    -webkit-transform: translate3d(41vw, -133vh, 0);
            transform: translate3d(41vw, -133vh, 0);
  }
}

.circle-container2:nth-child(116) .circle {
  -webkit-animation-delay: 1710ms;
          animation-delay: 1710ms;
}

.circle-container2:nth-child(117) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-117;
          animation-name: move-frames-117;
  -webkit-animation-duration: 35085ms;
          animation-duration: 35085ms;
  -webkit-animation-delay: 32674ms;
          animation-delay: 32674ms;
}

@-webkit-keyframes move-frames-117 {
  from {
    -webkit-transform: translate3d(65vw, 107vh, 0);
            transform: translate3d(65vw, 107vh, 0);
  }

  to {
    -webkit-transform: translate3d(86vw, -111vh, 0);
            transform: translate3d(86vw, -111vh, 0);
  }
}

@keyframes move-frames-117 {
  from {
    -webkit-transform: translate3d(65vw, 107vh, 0);
            transform: translate3d(65vw, 107vh, 0);
  }

  to {
    -webkit-transform: translate3d(86vw, -111vh, 0);
            transform: translate3d(86vw, -111vh, 0);
  }
}

.circle-container2:nth-child(117) .circle {
  -webkit-animation-delay: 391ms;
          animation-delay: 391ms;
}

.circle-container2:nth-child(118) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-118;
          animation-name: move-frames-118;
  -webkit-animation-duration: 28426ms;
          animation-duration: 28426ms;
  -webkit-animation-delay: 13214ms;
          animation-delay: 13214ms;
}

@-webkit-keyframes move-frames-118 {
  from {
    -webkit-transform: translate3d(88vw, 109vh, 0);
            transform: translate3d(88vw, 109vh, 0);
  }

  to {
    -webkit-transform: translate3d(39vw, -131vh, 0);
            transform: translate3d(39vw, -131vh, 0);
  }
}

@keyframes move-frames-118 {
  from {
    -webkit-transform: translate3d(88vw, 109vh, 0);
            transform: translate3d(88vw, 109vh, 0);
  }

  to {
    -webkit-transform: translate3d(39vw, -131vh, 0);
            transform: translate3d(39vw, -131vh, 0);
  }
}

.circle-container2:nth-child(118) .circle {
  -webkit-animation-delay: 2889ms;
          animation-delay: 2889ms;
}

.circle-container2:nth-child(119) {
  width: 1px;
  height: 1px;
  -webkit-animation-name: move-frames-119;
          animation-name: move-frames-119;
  -webkit-animation-duration: 31179ms;
          animation-duration: 31179ms;
  -webkit-animation-delay: 1360ms;
          animation-delay: 1360ms;
}

@-webkit-keyframes move-frames-119 {
  from {
    -webkit-transform: translate3d(17vw, 109vh, 0);
            transform: translate3d(17vw, 109vh, 0);
  }

  to {
    -webkit-transform: translate3d(86vw, -118vh, 0);
            transform: translate3d(86vw, -118vh, 0);
  }
}

@keyframes move-frames-119 {
  from {
    -webkit-transform: translate3d(17vw, 109vh, 0);
            transform: translate3d(17vw, 109vh, 0);
  }

  to {
    -webkit-transform: translate3d(86vw, -118vh, 0);
            transform: translate3d(86vw, -118vh, 0);
  }
}

.circle-container2:nth-child(119) .circle {
  -webkit-animation-delay: 1297ms;
          animation-delay: 1297ms;
}

.circle-container2:nth-child(120) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-120;
          animation-name: move-frames-120;
  -webkit-animation-duration: 35514ms;
          animation-duration: 35514ms;
  -webkit-animation-delay: 23725ms;
          animation-delay: 23725ms;
}

@-webkit-keyframes move-frames-120 {
  from {
    -webkit-transform: translate3d(70vw, 106vh, 0);
            transform: translate3d(70vw, 106vh, 0);
  }

  to {
    -webkit-transform: translate3d(37vw, -122vh, 0);
            transform: translate3d(37vw, -122vh, 0);
  }
}

@keyframes move-frames-120 {
  from {
    -webkit-transform: translate3d(70vw, 106vh, 0);
            transform: translate3d(70vw, 106vh, 0);
  }

  to {
    -webkit-transform: translate3d(37vw, -122vh, 0);
            transform: translate3d(37vw, -122vh, 0);
  }
}

.circle-container2:nth-child(120) .circle {
  -webkit-animation-delay: 1030ms;
          animation-delay: 1030ms;
}

.circle-container2:nth-child(121) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-121;
          animation-name: move-frames-121;
  -webkit-animation-duration: 32154ms;
          animation-duration: 32154ms;
  -webkit-animation-delay: 30916ms;
          animation-delay: 30916ms;
}

@-webkit-keyframes move-frames-121 {
  from {
    -webkit-transform: translate3d(69vw, 109vh, 0);
            transform: translate3d(69vw, 109vh, 0);
  }

  to {
    -webkit-transform: translate3d(90vw, -117vh, 0);
            transform: translate3d(90vw, -117vh, 0);
  }
}

@keyframes move-frames-121 {
  from {
    -webkit-transform: translate3d(69vw, 109vh, 0);
            transform: translate3d(69vw, 109vh, 0);
  }

  to {
    -webkit-transform: translate3d(90vw, -117vh, 0);
            transform: translate3d(90vw, -117vh, 0);
  }
}

.circle-container2:nth-child(121) .circle {
  -webkit-animation-delay: 2214ms;
          animation-delay: 2214ms;
}

.circle-container2:nth-child(122) {
  width: 7px;
  height: 7px;
  -webkit-animation-name: move-frames-122;
          animation-name: move-frames-122;
  -webkit-animation-duration: 35331ms;
          animation-duration: 35331ms;
  -webkit-animation-delay: 32463ms;
          animation-delay: 32463ms;
}

@-webkit-keyframes move-frames-122 {
  from {
    -webkit-transform: translate3d(47vw, 108vh, 0);
            transform: translate3d(47vw, 108vh, 0);
  }

  to {
    -webkit-transform: translate3d(51vw, -125vh, 0);
            transform: translate3d(51vw, -125vh, 0);
  }
}

@keyframes move-frames-122 {
  from {
    -webkit-transform: translate3d(47vw, 108vh, 0);
            transform: translate3d(47vw, 108vh, 0);
  }

  to {
    -webkit-transform: translate3d(51vw, -125vh, 0);
            transform: translate3d(51vw, -125vh, 0);
  }
}

.circle-container2:nth-child(122) .circle {
  -webkit-animation-delay: 3707ms;
          animation-delay: 3707ms;
}

.circle-container2:nth-child(123) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-123;
          animation-name: move-frames-123;
  -webkit-animation-duration: 28684ms;
          animation-duration: 28684ms;
  -webkit-animation-delay: 36500ms;
          animation-delay: 36500ms;
}

@-webkit-keyframes move-frames-123 {
  from {
    -webkit-transform: translate3d(44vw, 109vh, 0);
            transform: translate3d(44vw, 109vh, 0);
  }

  to {
    -webkit-transform: translate3d(31vw, -122vh, 0);
            transform: translate3d(31vw, -122vh, 0);
  }
}

@keyframes move-frames-123 {
  from {
    -webkit-transform: translate3d(44vw, 109vh, 0);
            transform: translate3d(44vw, 109vh, 0);
  }

  to {
    -webkit-transform: translate3d(31vw, -122vh, 0);
            transform: translate3d(31vw, -122vh, 0);
  }
}

.circle-container2:nth-child(123) .circle {
  -webkit-animation-delay: 447ms;
          animation-delay: 447ms;
}

.circle-container2:nth-child(124) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-124;
          animation-name: move-frames-124;
  -webkit-animation-duration: 36090ms;
          animation-duration: 36090ms;
  -webkit-animation-delay: 7067ms;
          animation-delay: 7067ms;
}

@-webkit-keyframes move-frames-124 {
  from {
    -webkit-transform: translate3d(74vw, 107vh, 0);
            transform: translate3d(74vw, 107vh, 0);
  }

  to {
    -webkit-transform: translate3d(89vw, -111vh, 0);
            transform: translate3d(89vw, -111vh, 0);
  }
}

@keyframes move-frames-124 {
  from {
    -webkit-transform: translate3d(74vw, 107vh, 0);
            transform: translate3d(74vw, 107vh, 0);
  }

  to {
    -webkit-transform: translate3d(89vw, -111vh, 0);
            transform: translate3d(89vw, -111vh, 0);
  }
}

.circle-container2:nth-child(124) .circle {
  -webkit-animation-delay: 2240ms;
          animation-delay: 2240ms;
}

.circle-container2:nth-child(125) {
  width: 1px;
  height: 1px;
  -webkit-animation-name: move-frames-125;
          animation-name: move-frames-125;
  -webkit-animation-duration: 28814ms;
          animation-duration: 28814ms;
  -webkit-animation-delay: 9308ms;
          animation-delay: 9308ms;
}

@-webkit-keyframes move-frames-125 {
  from {
    -webkit-transform: translate3d(69vw, 108vh, 0);
            transform: translate3d(69vw, 108vh, 0);
  }

  to {
    -webkit-transform: translate3d(78vw, -122vh, 0);
            transform: translate3d(78vw, -122vh, 0);
  }
}

@keyframes move-frames-125 {
  from {
    -webkit-transform: translate3d(69vw, 108vh, 0);
            transform: translate3d(69vw, 108vh, 0);
  }

  to {
    -webkit-transform: translate3d(78vw, -122vh, 0);
            transform: translate3d(78vw, -122vh, 0);
  }
}

.circle-container2:nth-child(125) .circle {
  -webkit-animation-delay: 69ms;
          animation-delay: 69ms;
}

.circle-container2:nth-child(126) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-126;
          animation-name: move-frames-126;
  -webkit-animation-duration: 29080ms;
          animation-duration: 29080ms;
  -webkit-animation-delay: 28145ms;
          animation-delay: 28145ms;
}

@-webkit-keyframes move-frames-126 {
  from {
    -webkit-transform: translate3d(99vw, 105vh, 0);
            transform: translate3d(99vw, 105vh, 0);
  }

  to {
    -webkit-transform: translate3d(42vw, -112vh, 0);
            transform: translate3d(42vw, -112vh, 0);
  }
}

@keyframes move-frames-126 {
  from {
    -webkit-transform: translate3d(99vw, 105vh, 0);
            transform: translate3d(99vw, 105vh, 0);
  }

  to {
    -webkit-transform: translate3d(42vw, -112vh, 0);
            transform: translate3d(42vw, -112vh, 0);
  }
}

.circle-container2:nth-child(126) .circle {
  -webkit-animation-delay: 7ms;
          animation-delay: 7ms;
}

.circle-container2:nth-child(127) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-127;
          animation-name: move-frames-127;
  -webkit-animation-duration: 28660ms;
          animation-duration: 28660ms;
  -webkit-animation-delay: 33412ms;
          animation-delay: 33412ms;
}

@-webkit-keyframes move-frames-127 {
  from {
    -webkit-transform: translate3d(69vw, 102vh, 0);
            transform: translate3d(69vw, 102vh, 0);
  }

  to {
    -webkit-transform: translate3d(23vw, -111vh, 0);
            transform: translate3d(23vw, -111vh, 0);
  }
}

@keyframes move-frames-127 {
  from {
    -webkit-transform: translate3d(69vw, 102vh, 0);
            transform: translate3d(69vw, 102vh, 0);
  }

  to {
    -webkit-transform: translate3d(23vw, -111vh, 0);
            transform: translate3d(23vw, -111vh, 0);
  }
}

.circle-container2:nth-child(127) .circle {
  -webkit-animation-delay: 2882ms;
          animation-delay: 2882ms;
}

.circle-container2:nth-child(128) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-128;
          animation-name: move-frames-128;
  -webkit-animation-duration: 31174ms;
          animation-duration: 31174ms;
  -webkit-animation-delay: 24214ms;
          animation-delay: 24214ms;
}

@-webkit-keyframes move-frames-128 {
  from {
    -webkit-transform: translate3d(7vw, 106vh, 0);
            transform: translate3d(7vw, 106vh, 0);
  }

  to {
    -webkit-transform: translate3d(23vw, -115vh, 0);
            transform: translate3d(23vw, -115vh, 0);
  }
}

@keyframes move-frames-128 {
  from {
    -webkit-transform: translate3d(7vw, 106vh, 0);
            transform: translate3d(7vw, 106vh, 0);
  }

  to {
    -webkit-transform: translate3d(23vw, -115vh, 0);
            transform: translate3d(23vw, -115vh, 0);
  }
}

.circle-container2:nth-child(128) .circle {
  -webkit-animation-delay: 3082ms;
          animation-delay: 3082ms;
}

.circle-container2:nth-child(129) {
  width: 7px;
  height: 7px;
  -webkit-animation-name: move-frames-129;
          animation-name: move-frames-129;
  -webkit-animation-duration: 32171ms;
          animation-duration: 32171ms;
  -webkit-animation-delay: 34435ms;
          animation-delay: 34435ms;
}

@-webkit-keyframes move-frames-129 {
  from {
    -webkit-transform: translate3d(31vw, 101vh, 0);
            transform: translate3d(31vw, 101vh, 0);
  }

  to {
    -webkit-transform: translate3d(34vw, -129vh, 0);
            transform: translate3d(34vw, -129vh, 0);
  }
}

@keyframes move-frames-129 {
  from {
    -webkit-transform: translate3d(31vw, 101vh, 0);
            transform: translate3d(31vw, 101vh, 0);
  }

  to {
    -webkit-transform: translate3d(34vw, -129vh, 0);
            transform: translate3d(34vw, -129vh, 0);
  }
}

.circle-container2:nth-child(129) .circle {
  -webkit-animation-delay: 872ms;
          animation-delay: 872ms;
}

.circle-container2:nth-child(130) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-130;
          animation-name: move-frames-130;
  -webkit-animation-duration: 36636ms;
          animation-duration: 36636ms;
  -webkit-animation-delay: 5949ms;
          animation-delay: 5949ms;
}

@-webkit-keyframes move-frames-130 {
  from {
    -webkit-transform: translate3d(46vw, 105vh, 0);
            transform: translate3d(46vw, 105vh, 0);
  }

  to {
    -webkit-transform: translate3d(5vw, -130vh, 0);
            transform: translate3d(5vw, -130vh, 0);
  }
}

@keyframes move-frames-130 {
  from {
    -webkit-transform: translate3d(46vw, 105vh, 0);
            transform: translate3d(46vw, 105vh, 0);
  }

  to {
    -webkit-transform: translate3d(5vw, -130vh, 0);
            transform: translate3d(5vw, -130vh, 0);
  }
}

.circle-container2:nth-child(130) .circle {
  -webkit-animation-delay: 2219ms;
          animation-delay: 2219ms;
}

.circle-container2:nth-child(131) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-131;
          animation-name: move-frames-131;
  -webkit-animation-duration: 31452ms;
          animation-duration: 31452ms;
  -webkit-animation-delay: 9541ms;
          animation-delay: 9541ms;
}

@-webkit-keyframes move-frames-131 {
  from {
    -webkit-transform: translate3d(71vw, 107vh, 0);
            transform: translate3d(71vw, 107vh, 0);
  }

  to {
    -webkit-transform: translate3d(25vw, -126vh, 0);
            transform: translate3d(25vw, -126vh, 0);
  }
}

@keyframes move-frames-131 {
  from {
    -webkit-transform: translate3d(71vw, 107vh, 0);
            transform: translate3d(71vw, 107vh, 0);
  }

  to {
    -webkit-transform: translate3d(25vw, -126vh, 0);
            transform: translate3d(25vw, -126vh, 0);
  }
}

.circle-container2:nth-child(131) .circle {
  -webkit-animation-delay: 421ms;
          animation-delay: 421ms;
}

.circle-container2:nth-child(132) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-132;
          animation-name: move-frames-132;
  -webkit-animation-duration: 29409ms;
          animation-duration: 29409ms;
  -webkit-animation-delay: 5131ms;
          animation-delay: 5131ms;
}

@-webkit-keyframes move-frames-132 {
  from {
    -webkit-transform: translate3d(96vw, 110vh, 0);
            transform: translate3d(96vw, 110vh, 0);
  }

  to {
    -webkit-transform: translate3d(16vw, -119vh, 0);
            transform: translate3d(16vw, -119vh, 0);
  }
}

@keyframes move-frames-132 {
  from {
    -webkit-transform: translate3d(96vw, 110vh, 0);
            transform: translate3d(96vw, 110vh, 0);
  }

  to {
    -webkit-transform: translate3d(16vw, -119vh, 0);
            transform: translate3d(16vw, -119vh, 0);
  }
}

.circle-container2:nth-child(132) .circle {
  -webkit-animation-delay: 2650ms;
          animation-delay: 2650ms;
}

.circle-container2:nth-child(133) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-133;
          animation-name: move-frames-133;
  -webkit-animation-duration: 34135ms;
          animation-duration: 34135ms;
  -webkit-animation-delay: 22884ms;
          animation-delay: 22884ms;
}

@-webkit-keyframes move-frames-133 {
  from {
    -webkit-transform: translate3d(38vw, 103vh, 0);
            transform: translate3d(38vw, 103vh, 0);
  }

  to {
    -webkit-transform: translate3d(9vw, -122vh, 0);
            transform: translate3d(9vw, -122vh, 0);
  }
}

@keyframes move-frames-133 {
  from {
    -webkit-transform: translate3d(38vw, 103vh, 0);
            transform: translate3d(38vw, 103vh, 0);
  }

  to {
    -webkit-transform: translate3d(9vw, -122vh, 0);
            transform: translate3d(9vw, -122vh, 0);
  }
}

.circle-container2:nth-child(133) .circle {
  -webkit-animation-delay: 2856ms;
          animation-delay: 2856ms;
}

.circle-container2:nth-child(134) {
  width: 7px;
  height: 7px;
  -webkit-animation-name: move-frames-134;
          animation-name: move-frames-134;
  -webkit-animation-duration: 28153ms;
          animation-duration: 28153ms;
  -webkit-animation-delay: 13261ms;
          animation-delay: 13261ms;
}

@-webkit-keyframes move-frames-134 {
  from {
    -webkit-transform: translate3d(100vw, 105vh, 0);
            transform: translate3d(100vw, 105vh, 0);
  }

  to {
    -webkit-transform: translate3d(10vw, -133vh, 0);
            transform: translate3d(10vw, -133vh, 0);
  }
}

@keyframes move-frames-134 {
  from {
    -webkit-transform: translate3d(100vw, 105vh, 0);
            transform: translate3d(100vw, 105vh, 0);
  }

  to {
    -webkit-transform: translate3d(10vw, -133vh, 0);
            transform: translate3d(10vw, -133vh, 0);
  }
}

.circle-container2:nth-child(134) .circle {
  -webkit-animation-delay: 3709ms;
          animation-delay: 3709ms;
}

.circle-container2:nth-child(135) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-135;
          animation-name: move-frames-135;
  -webkit-animation-duration: 32127ms;
          animation-duration: 32127ms;
  -webkit-animation-delay: 34353ms;
          animation-delay: 34353ms;
}

@-webkit-keyframes move-frames-135 {
  from {
    -webkit-transform: translate3d(5vw, 110vh, 0);
            transform: translate3d(5vw, 110vh, 0);
  }

  to {
    -webkit-transform: translate3d(97vw, -121vh, 0);
            transform: translate3d(97vw, -121vh, 0);
  }
}

@keyframes move-frames-135 {
  from {
    -webkit-transform: translate3d(5vw, 110vh, 0);
            transform: translate3d(5vw, 110vh, 0);
  }

  to {
    -webkit-transform: translate3d(97vw, -121vh, 0);
            transform: translate3d(97vw, -121vh, 0);
  }
}

.circle-container2:nth-child(135) .circle {
  -webkit-animation-delay: 799ms;
          animation-delay: 799ms;
}

.circle-container2:nth-child(136) {
  width: 1px;
  height: 1px;
  -webkit-animation-name: move-frames-136;
          animation-name: move-frames-136;
  -webkit-animation-duration: 29737ms;
          animation-duration: 29737ms;
  -webkit-animation-delay: 25429ms;
          animation-delay: 25429ms;
}

@-webkit-keyframes move-frames-136 {
  from {
    -webkit-transform: translate3d(61vw, 108vh, 0);
            transform: translate3d(61vw, 108vh, 0);
  }

  to {
    -webkit-transform: translate3d(97vw, -131vh, 0);
            transform: translate3d(97vw, -131vh, 0);
  }
}

@keyframes move-frames-136 {
  from {
    -webkit-transform: translate3d(61vw, 108vh, 0);
            transform: translate3d(61vw, 108vh, 0);
  }

  to {
    -webkit-transform: translate3d(97vw, -131vh, 0);
            transform: translate3d(97vw, -131vh, 0);
  }
}

.circle-container2:nth-child(136) .circle {
  -webkit-animation-delay: 2671ms;
          animation-delay: 2671ms;
}

.circle-container2:nth-child(137) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-137;
          animation-name: move-frames-137;
  -webkit-animation-duration: 34817ms;
          animation-duration: 34817ms;
  -webkit-animation-delay: 16326ms;
          animation-delay: 16326ms;
}

@-webkit-keyframes move-frames-137 {
  from {
    -webkit-transform: translate3d(13vw, 109vh, 0);
            transform: translate3d(13vw, 109vh, 0);
  }

  to {
    -webkit-transform: translate3d(63vw, -132vh, 0);
            transform: translate3d(63vw, -132vh, 0);
  }
}

@keyframes move-frames-137 {
  from {
    -webkit-transform: translate3d(13vw, 109vh, 0);
            transform: translate3d(13vw, 109vh, 0);
  }

  to {
    -webkit-transform: translate3d(63vw, -132vh, 0);
            transform: translate3d(63vw, -132vh, 0);
  }
}

.circle-container2:nth-child(137) .circle {
  -webkit-animation-delay: 1927ms;
          animation-delay: 1927ms;
}

.circle-container2:nth-child(138) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-138;
          animation-name: move-frames-138;
  -webkit-animation-duration: 36324ms;
          animation-duration: 36324ms;
  -webkit-animation-delay: 3871ms;
          animation-delay: 3871ms;
}

@-webkit-keyframes move-frames-138 {
  from {
    -webkit-transform: translate3d(3vw, 109vh, 0);
            transform: translate3d(3vw, 109vh, 0);
  }

  to {
    -webkit-transform: translate3d(5vw, -119vh, 0);
            transform: translate3d(5vw, -119vh, 0);
  }
}

@keyframes move-frames-138 {
  from {
    -webkit-transform: translate3d(3vw, 109vh, 0);
            transform: translate3d(3vw, 109vh, 0);
  }

  to {
    -webkit-transform: translate3d(5vw, -119vh, 0);
            transform: translate3d(5vw, -119vh, 0);
  }
}

.circle-container2:nth-child(138) .circle {
  -webkit-animation-delay: 2589ms;
          animation-delay: 2589ms;
}

.circle-container2:nth-child(139) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-139;
          animation-name: move-frames-139;
  -webkit-animation-duration: 33537ms;
          animation-duration: 33537ms;
  -webkit-animation-delay: 11929ms;
          animation-delay: 11929ms;
}

@-webkit-keyframes move-frames-139 {
  from {
    -webkit-transform: translate3d(59vw, 109vh, 0);
            transform: translate3d(59vw, 109vh, 0);
  }

  to {
    -webkit-transform: translate3d(9vw, -133vh, 0);
            transform: translate3d(9vw, -133vh, 0);
  }
}

@keyframes move-frames-139 {
  from {
    -webkit-transform: translate3d(59vw, 109vh, 0);
            transform: translate3d(59vw, 109vh, 0);
  }

  to {
    -webkit-transform: translate3d(9vw, -133vh, 0);
            transform: translate3d(9vw, -133vh, 0);
  }
}

.circle-container2:nth-child(139) .circle {
  -webkit-animation-delay: 424ms;
          animation-delay: 424ms;
}

.circle-container2:nth-child(140) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-140;
          animation-name: move-frames-140;
  -webkit-animation-duration: 34662ms;
          animation-duration: 34662ms;
  -webkit-animation-delay: 21605ms;
          animation-delay: 21605ms;
}

@-webkit-keyframes move-frames-140 {
  from {
    -webkit-transform: translate3d(84vw, 106vh, 0);
            transform: translate3d(84vw, 106vh, 0);
  }

  to {
    -webkit-transform: translate3d(15vw, -136vh, 0);
            transform: translate3d(15vw, -136vh, 0);
  }
}

@keyframes move-frames-140 {
  from {
    -webkit-transform: translate3d(84vw, 106vh, 0);
            transform: translate3d(84vw, 106vh, 0);
  }

  to {
    -webkit-transform: translate3d(15vw, -136vh, 0);
            transform: translate3d(15vw, -136vh, 0);
  }
}

.circle-container2:nth-child(140) .circle {
  -webkit-animation-delay: 3430ms;
          animation-delay: 3430ms;
}

.circle-container2:nth-child(141) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-141;
          animation-name: move-frames-141;
  -webkit-animation-duration: 31241ms;
          animation-duration: 31241ms;
  -webkit-animation-delay: 15328ms;
          animation-delay: 15328ms;
}

@-webkit-keyframes move-frames-141 {
  from {
    -webkit-transform: translate3d(30vw, 101vh, 0);
            transform: translate3d(30vw, 101vh, 0);
  }

  to {
    -webkit-transform: translate3d(42vw, -107vh, 0);
            transform: translate3d(42vw, -107vh, 0);
  }
}

@keyframes move-frames-141 {
  from {
    -webkit-transform: translate3d(30vw, 101vh, 0);
            transform: translate3d(30vw, 101vh, 0);
  }

  to {
    -webkit-transform: translate3d(42vw, -107vh, 0);
            transform: translate3d(42vw, -107vh, 0);
  }
}

.circle-container2:nth-child(141) .circle {
  -webkit-animation-delay: 632ms;
          animation-delay: 632ms;
}

.circle-container2:nth-child(142) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-142;
          animation-name: move-frames-142;
  -webkit-animation-duration: 28931ms;
          animation-duration: 28931ms;
  -webkit-animation-delay: 10856ms;
          animation-delay: 10856ms;
}

@-webkit-keyframes move-frames-142 {
  from {
    -webkit-transform: translate3d(66vw, 105vh, 0);
            transform: translate3d(66vw, 105vh, 0);
  }

  to {
    -webkit-transform: translate3d(26vw, -128vh, 0);
            transform: translate3d(26vw, -128vh, 0);
  }
}

@keyframes move-frames-142 {
  from {
    -webkit-transform: translate3d(66vw, 105vh, 0);
            transform: translate3d(66vw, 105vh, 0);
  }

  to {
    -webkit-transform: translate3d(26vw, -128vh, 0);
            transform: translate3d(26vw, -128vh, 0);
  }
}

.circle-container2:nth-child(142) .circle {
  -webkit-animation-delay: 507ms;
          animation-delay: 507ms;
}

.circle-container2:nth-child(143) {
  width: 1px;
  height: 1px;
  -webkit-animation-name: move-frames-143;
          animation-name: move-frames-143;
  -webkit-animation-duration: 35135ms;
          animation-duration: 35135ms;
  -webkit-animation-delay: 5486ms;
          animation-delay: 5486ms;
}

@-webkit-keyframes move-frames-143 {
  from {
    -webkit-transform: translate3d(80vw, 105vh, 0);
            transform: translate3d(80vw, 105vh, 0);
  }

  to {
    -webkit-transform: translate3d(28vw, -128vh, 0);
            transform: translate3d(28vw, -128vh, 0);
  }
}

@keyframes move-frames-143 {
  from {
    -webkit-transform: translate3d(80vw, 105vh, 0);
            transform: translate3d(80vw, 105vh, 0);
  }

  to {
    -webkit-transform: translate3d(28vw, -128vh, 0);
            transform: translate3d(28vw, -128vh, 0);
  }
}

.circle-container2:nth-child(143) .circle {
  -webkit-animation-delay: 1790ms;
          animation-delay: 1790ms;
}

.circle-container2:nth-child(144) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-144;
          animation-name: move-frames-144;
  -webkit-animation-duration: 34630ms;
          animation-duration: 34630ms;
  -webkit-animation-delay: 19793ms;
          animation-delay: 19793ms;
}

@-webkit-keyframes move-frames-144 {
  from {
    -webkit-transform: translate3d(68vw, 108vh, 0);
            transform: translate3d(68vw, 108vh, 0);
  }

  to {
    -webkit-transform: translate3d(66vw, -130vh, 0);
            transform: translate3d(66vw, -130vh, 0);
  }
}

@keyframes move-frames-144 {
  from {
    -webkit-transform: translate3d(68vw, 108vh, 0);
            transform: translate3d(68vw, 108vh, 0);
  }

  to {
    -webkit-transform: translate3d(66vw, -130vh, 0);
            transform: translate3d(66vw, -130vh, 0);
  }
}

.circle-container2:nth-child(144) .circle {
  -webkit-animation-delay: 1756ms;
          animation-delay: 1756ms;
}

.circle-container2:nth-child(145) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-145;
          animation-name: move-frames-145;
  -webkit-animation-duration: 29462ms;
          animation-duration: 29462ms;
  -webkit-animation-delay: 25517ms;
          animation-delay: 25517ms;
}

@-webkit-keyframes move-frames-145 {
  from {
    -webkit-transform: translate3d(49vw, 104vh, 0);
            transform: translate3d(49vw, 104vh, 0);
  }

  to {
    -webkit-transform: translate3d(73vw, -124vh, 0);
            transform: translate3d(73vw, -124vh, 0);
  }
}

@keyframes move-frames-145 {
  from {
    -webkit-transform: translate3d(49vw, 104vh, 0);
            transform: translate3d(49vw, 104vh, 0);
  }

  to {
    -webkit-transform: translate3d(73vw, -124vh, 0);
            transform: translate3d(73vw, -124vh, 0);
  }
}

.circle-container2:nth-child(145) .circle {
  -webkit-animation-delay: 3803ms;
          animation-delay: 3803ms;
}

.circle-container2:nth-child(146) {
  width: 7px;
  height: 7px;
  -webkit-animation-name: move-frames-146;
          animation-name: move-frames-146;
  -webkit-animation-duration: 35256ms;
          animation-duration: 35256ms;
  -webkit-animation-delay: 9570ms;
          animation-delay: 9570ms;
}

@-webkit-keyframes move-frames-146 {
  from {
    -webkit-transform: translate3d(15vw, 106vh, 0);
            transform: translate3d(15vw, 106vh, 0);
  }

  to {
    -webkit-transform: translate3d(29vw, -117vh, 0);
            transform: translate3d(29vw, -117vh, 0);
  }
}

@keyframes move-frames-146 {
  from {
    -webkit-transform: translate3d(15vw, 106vh, 0);
            transform: translate3d(15vw, 106vh, 0);
  }

  to {
    -webkit-transform: translate3d(29vw, -117vh, 0);
            transform: translate3d(29vw, -117vh, 0);
  }
}

.circle-container2:nth-child(146) .circle {
  -webkit-animation-delay: 3970ms;
          animation-delay: 3970ms;
}

.circle-container2:nth-child(147) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-147;
          animation-name: move-frames-147;
  -webkit-animation-duration: 33627ms;
          animation-duration: 33627ms;
  -webkit-animation-delay: 36109ms;
          animation-delay: 36109ms;
}

@-webkit-keyframes move-frames-147 {
  from {
    -webkit-transform: translate3d(91vw, 110vh, 0);
            transform: translate3d(91vw, 110vh, 0);
  }

  to {
    -webkit-transform: translate3d(67vw, -123vh, 0);
            transform: translate3d(67vw, -123vh, 0);
  }
}

@keyframes move-frames-147 {
  from {
    -webkit-transform: translate3d(91vw, 110vh, 0);
            transform: translate3d(91vw, 110vh, 0);
  }

  to {
    -webkit-transform: translate3d(67vw, -123vh, 0);
            transform: translate3d(67vw, -123vh, 0);
  }
}

.circle-container2:nth-child(147) .circle {
  -webkit-animation-delay: 2714ms;
          animation-delay: 2714ms;
}

.circle-container2:nth-child(148) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-148;
          animation-name: move-frames-148;
  -webkit-animation-duration: 34801ms;
          animation-duration: 34801ms;
  -webkit-animation-delay: 743ms;
          animation-delay: 743ms;
}

@-webkit-keyframes move-frames-148 {
  from {
    -webkit-transform: translate3d(82vw, 102vh, 0);
            transform: translate3d(82vw, 102vh, 0);
  }

  to {
    -webkit-transform: translate3d(43vw, -115vh, 0);
            transform: translate3d(43vw, -115vh, 0);
  }
}

@keyframes move-frames-148 {
  from {
    -webkit-transform: translate3d(82vw, 102vh, 0);
            transform: translate3d(82vw, 102vh, 0);
  }

  to {
    -webkit-transform: translate3d(43vw, -115vh, 0);
            transform: translate3d(43vw, -115vh, 0);
  }
}

.circle-container2:nth-child(148) .circle {
  -webkit-animation-delay: 2064ms;
          animation-delay: 2064ms;
}

.circle-container2:nth-child(149) {
  width: 7px;
  height: 7px;
  -webkit-animation-name: move-frames-149;
          animation-name: move-frames-149;
  -webkit-animation-duration: 33669ms;
          animation-duration: 33669ms;
  -webkit-animation-delay: 32275ms;
          animation-delay: 32275ms;
}

@-webkit-keyframes move-frames-149 {
  from {
    -webkit-transform: translate3d(45vw, 101vh, 0);
            transform: translate3d(45vw, 101vh, 0);
  }

  to {
    -webkit-transform: translate3d(23vw, -103vh, 0);
            transform: translate3d(23vw, -103vh, 0);
  }
}

@keyframes move-frames-149 {
  from {
    -webkit-transform: translate3d(45vw, 101vh, 0);
            transform: translate3d(45vw, 101vh, 0);
  }

  to {
    -webkit-transform: translate3d(23vw, -103vh, 0);
            transform: translate3d(23vw, -103vh, 0);
  }
}

.circle-container2:nth-child(149) .circle {
  -webkit-animation-delay: 1628ms;
          animation-delay: 1628ms;
}

.circle-container2:nth-child(150) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-150;
          animation-name: move-frames-150;
  -webkit-animation-duration: 34795ms;
          animation-duration: 34795ms;
  -webkit-animation-delay: 4749ms;
          animation-delay: 4749ms;
}

@-webkit-keyframes move-frames-150 {
  from {
    -webkit-transform: translate3d(14vw, 110vh, 0);
            transform: translate3d(14vw, 110vh, 0);
  }

  to {
    -webkit-transform: translate3d(73vw, -123vh, 0);
            transform: translate3d(73vw, -123vh, 0);
  }
}

@keyframes move-frames-150 {
  from {
    -webkit-transform: translate3d(14vw, 110vh, 0);
            transform: translate3d(14vw, 110vh, 0);
  }

  to {
    -webkit-transform: translate3d(73vw, -123vh, 0);
            transform: translate3d(73vw, -123vh, 0);
  }
}

.circle-container2:nth-child(150) .circle {
  -webkit-animation-delay: 2055ms;
          animation-delay: 2055ms;
}

.circle-container2:nth-child(151) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-151;
          animation-name: move-frames-151;
  -webkit-animation-duration: 33885ms;
          animation-duration: 33885ms;
  -webkit-animation-delay: 29883ms;
          animation-delay: 29883ms;
}

@-webkit-keyframes move-frames-151 {
  from {
    -webkit-transform: translate3d(4vw, 103vh, 0);
            transform: translate3d(4vw, 103vh, 0);
  }

  to {
    -webkit-transform: translate3d(51vw, -110vh, 0);
            transform: translate3d(51vw, -110vh, 0);
  }
}

@keyframes move-frames-151 {
  from {
    -webkit-transform: translate3d(4vw, 103vh, 0);
            transform: translate3d(4vw, 103vh, 0);
  }

  to {
    -webkit-transform: translate3d(51vw, -110vh, 0);
            transform: translate3d(51vw, -110vh, 0);
  }
}

.circle-container2:nth-child(151) .circle {
  -webkit-animation-delay: 2272ms;
          animation-delay: 2272ms;
}

.circle-container2:nth-child(152) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-152;
          animation-name: move-frames-152;
  -webkit-animation-duration: 36908ms;
          animation-duration: 36908ms;
  -webkit-animation-delay: 36920ms;
          animation-delay: 36920ms;
}

@-webkit-keyframes move-frames-152 {
  from {
    -webkit-transform: translate3d(30vw, 102vh, 0);
            transform: translate3d(30vw, 102vh, 0);
  }

  to {
    -webkit-transform: translate3d(99vw, -124vh, 0);
            transform: translate3d(99vw, -124vh, 0);
  }
}

@keyframes move-frames-152 {
  from {
    -webkit-transform: translate3d(30vw, 102vh, 0);
            transform: translate3d(30vw, 102vh, 0);
  }

  to {
    -webkit-transform: translate3d(99vw, -124vh, 0);
            transform: translate3d(99vw, -124vh, 0);
  }
}

.circle-container2:nth-child(152) .circle {
  -webkit-animation-delay: 778ms;
          animation-delay: 778ms;
}

.circle-container2:nth-child(153) {
  width: 7px;
  height: 7px;
  -webkit-animation-name: move-frames-153;
          animation-name: move-frames-153;
  -webkit-animation-duration: 28352ms;
          animation-duration: 28352ms;
  -webkit-animation-delay: 7520ms;
          animation-delay: 7520ms;
}

@-webkit-keyframes move-frames-153 {
  from {
    -webkit-transform: translate3d(25vw, 107vh, 0);
            transform: translate3d(25vw, 107vh, 0);
  }

  to {
    -webkit-transform: translate3d(93vw, -111vh, 0);
            transform: translate3d(93vw, -111vh, 0);
  }
}

@keyframes move-frames-153 {
  from {
    -webkit-transform: translate3d(25vw, 107vh, 0);
            transform: translate3d(25vw, 107vh, 0);
  }

  to {
    -webkit-transform: translate3d(93vw, -111vh, 0);
            transform: translate3d(93vw, -111vh, 0);
  }
}

.circle-container2:nth-child(153) .circle {
  -webkit-animation-delay: 720ms;
          animation-delay: 720ms;
}

.circle-container2:nth-child(154) {
  width: 7px;
  height: 7px;
  -webkit-animation-name: move-frames-154;
          animation-name: move-frames-154;
  -webkit-animation-duration: 36188ms;
          animation-duration: 36188ms;
  -webkit-animation-delay: 2989ms;
          animation-delay: 2989ms;
}

@-webkit-keyframes move-frames-154 {
  from {
    -webkit-transform: translate3d(81vw, 102vh, 0);
            transform: translate3d(81vw, 102vh, 0);
  }

  to {
    -webkit-transform: translate3d(20vw, -126vh, 0);
            transform: translate3d(20vw, -126vh, 0);
  }
}

@keyframes move-frames-154 {
  from {
    -webkit-transform: translate3d(81vw, 102vh, 0);
            transform: translate3d(81vw, 102vh, 0);
  }

  to {
    -webkit-transform: translate3d(20vw, -126vh, 0);
            transform: translate3d(20vw, -126vh, 0);
  }
}

.circle-container2:nth-child(154) .circle {
  -webkit-animation-delay: 527ms;
          animation-delay: 527ms;
}

.circle-container2:nth-child(155) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-155;
          animation-name: move-frames-155;
  -webkit-animation-duration: 32707ms;
          animation-duration: 32707ms;
  -webkit-animation-delay: 19860ms;
          animation-delay: 19860ms;
}

@-webkit-keyframes move-frames-155 {
  from {
    -webkit-transform: translate3d(99vw, 108vh, 0);
            transform: translate3d(99vw, 108vh, 0);
  }

  to {
    -webkit-transform: translate3d(28vw, -133vh, 0);
            transform: translate3d(28vw, -133vh, 0);
  }
}

@keyframes move-frames-155 {
  from {
    -webkit-transform: translate3d(99vw, 108vh, 0);
            transform: translate3d(99vw, 108vh, 0);
  }

  to {
    -webkit-transform: translate3d(28vw, -133vh, 0);
            transform: translate3d(28vw, -133vh, 0);
  }
}

.circle-container2:nth-child(155) .circle {
  -webkit-animation-delay: 3559ms;
          animation-delay: 3559ms;
}

.circle-container2:nth-child(156) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-156;
          animation-name: move-frames-156;
  -webkit-animation-duration: 36634ms;
          animation-duration: 36634ms;
  -webkit-animation-delay: 5748ms;
          animation-delay: 5748ms;
}

@-webkit-keyframes move-frames-156 {
  from {
    -webkit-transform: translate3d(16vw, 109vh, 0);
            transform: translate3d(16vw, 109vh, 0);
  }

  to {
    -webkit-transform: translate3d(75vw, -138vh, 0);
            transform: translate3d(75vw, -138vh, 0);
  }
}

@keyframes move-frames-156 {
  from {
    -webkit-transform: translate3d(16vw, 109vh, 0);
            transform: translate3d(16vw, 109vh, 0);
  }

  to {
    -webkit-transform: translate3d(75vw, -138vh, 0);
            transform: translate3d(75vw, -138vh, 0);
  }
}

.circle-container2:nth-child(156) .circle {
  -webkit-animation-delay: 3847ms;
          animation-delay: 3847ms;
}

.circle-container2:nth-child(157) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-157;
          animation-name: move-frames-157;
  -webkit-animation-duration: 34146ms;
          animation-duration: 34146ms;
  -webkit-animation-delay: 1383ms;
          animation-delay: 1383ms;
}

@-webkit-keyframes move-frames-157 {
  from {
    -webkit-transform: translate3d(14vw, 107vh, 0);
            transform: translate3d(14vw, 107vh, 0);
  }

  to {
    -webkit-transform: translate3d(5vw, -109vh, 0);
            transform: translate3d(5vw, -109vh, 0);
  }
}

@keyframes move-frames-157 {
  from {
    -webkit-transform: translate3d(14vw, 107vh, 0);
            transform: translate3d(14vw, 107vh, 0);
  }

  to {
    -webkit-transform: translate3d(5vw, -109vh, 0);
            transform: translate3d(5vw, -109vh, 0);
  }
}

.circle-container2:nth-child(157) .circle {
  -webkit-animation-delay: 3120ms;
          animation-delay: 3120ms;
}

.circle-container2:nth-child(158) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-158;
          animation-name: move-frames-158;
  -webkit-animation-duration: 34661ms;
          animation-duration: 34661ms;
  -webkit-animation-delay: 24156ms;
          animation-delay: 24156ms;
}

@-webkit-keyframes move-frames-158 {
  from {
    -webkit-transform: translate3d(13vw, 106vh, 0);
            transform: translate3d(13vw, 106vh, 0);
  }

  to {
    -webkit-transform: translate3d(80vw, -108vh, 0);
            transform: translate3d(80vw, -108vh, 0);
  }
}

@keyframes move-frames-158 {
  from {
    -webkit-transform: translate3d(13vw, 106vh, 0);
            transform: translate3d(13vw, 106vh, 0);
  }

  to {
    -webkit-transform: translate3d(80vw, -108vh, 0);
            transform: translate3d(80vw, -108vh, 0);
  }
}

.circle-container2:nth-child(158) .circle {
  -webkit-animation-delay: 3187ms;
          animation-delay: 3187ms;
}

.circle-container2:nth-child(159) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-159;
          animation-name: move-frames-159;
  -webkit-animation-duration: 31555ms;
          animation-duration: 31555ms;
  -webkit-animation-delay: 35581ms;
          animation-delay: 35581ms;
}

@-webkit-keyframes move-frames-159 {
  from {
    -webkit-transform: translate3d(4vw, 108vh, 0);
            transform: translate3d(4vw, 108vh, 0);
  }

  to {
    -webkit-transform: translate3d(4vw, -124vh, 0);
            transform: translate3d(4vw, -124vh, 0);
  }
}

@keyframes move-frames-159 {
  from {
    -webkit-transform: translate3d(4vw, 108vh, 0);
            transform: translate3d(4vw, 108vh, 0);
  }

  to {
    -webkit-transform: translate3d(4vw, -124vh, 0);
            transform: translate3d(4vw, -124vh, 0);
  }
}

.circle-container2:nth-child(159) .circle {
  -webkit-animation-delay: 2664ms;
          animation-delay: 2664ms;
}

.circle-container2:nth-child(160) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-160;
          animation-name: move-frames-160;
  -webkit-animation-duration: 34063ms;
          animation-duration: 34063ms;
  -webkit-animation-delay: 36698ms;
          animation-delay: 36698ms;
}

@-webkit-keyframes move-frames-160 {
  from {
    -webkit-transform: translate3d(19vw, 104vh, 0);
            transform: translate3d(19vw, 104vh, 0);
  }

  to {
    -webkit-transform: translate3d(10vw, -125vh, 0);
            transform: translate3d(10vw, -125vh, 0);
  }
}

@keyframes move-frames-160 {
  from {
    -webkit-transform: translate3d(19vw, 104vh, 0);
            transform: translate3d(19vw, 104vh, 0);
  }

  to {
    -webkit-transform: translate3d(10vw, -125vh, 0);
            transform: translate3d(10vw, -125vh, 0);
  }
}

.circle-container2:nth-child(160) .circle {
  -webkit-animation-delay: 3848ms;
          animation-delay: 3848ms;
}

.circle-container2:nth-child(161) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-161;
          animation-name: move-frames-161;
  -webkit-animation-duration: 33296ms;
          animation-duration: 33296ms;
  -webkit-animation-delay: 31319ms;
          animation-delay: 31319ms;
}

@-webkit-keyframes move-frames-161 {
  from {
    -webkit-transform: translate3d(9vw, 107vh, 0);
            transform: translate3d(9vw, 107vh, 0);
  }

  to {
    -webkit-transform: translate3d(78vw, -120vh, 0);
            transform: translate3d(78vw, -120vh, 0);
  }
}

@keyframes move-frames-161 {
  from {
    -webkit-transform: translate3d(9vw, 107vh, 0);
            transform: translate3d(9vw, 107vh, 0);
  }

  to {
    -webkit-transform: translate3d(78vw, -120vh, 0);
            transform: translate3d(78vw, -120vh, 0);
  }
}

.circle-container2:nth-child(161) .circle {
  -webkit-animation-delay: 2432ms;
          animation-delay: 2432ms;
}

.circle-container2:nth-child(162) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-162;
          animation-name: move-frames-162;
  -webkit-animation-duration: 35812ms;
          animation-duration: 35812ms;
  -webkit-animation-delay: 14297ms;
          animation-delay: 14297ms;
}

@-webkit-keyframes move-frames-162 {
  from {
    -webkit-transform: translate3d(74vw, 105vh, 0);
            transform: translate3d(74vw, 105vh, 0);
  }

  to {
    -webkit-transform: translate3d(25vw, -121vh, 0);
            transform: translate3d(25vw, -121vh, 0);
  }
}

@keyframes move-frames-162 {
  from {
    -webkit-transform: translate3d(74vw, 105vh, 0);
            transform: translate3d(74vw, 105vh, 0);
  }

  to {
    -webkit-transform: translate3d(25vw, -121vh, 0);
            transform: translate3d(25vw, -121vh, 0);
  }
}

.circle-container2:nth-child(162) .circle {
  -webkit-animation-delay: 3380ms;
          animation-delay: 3380ms;
}

.circle-container2:nth-child(163) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-163;
          animation-name: move-frames-163;
  -webkit-animation-duration: 34820ms;
          animation-duration: 34820ms;
  -webkit-animation-delay: 26445ms;
          animation-delay: 26445ms;
}

@-webkit-keyframes move-frames-163 {
  from {
    -webkit-transform: translate3d(10vw, 101vh, 0);
            transform: translate3d(10vw, 101vh, 0);
  }

  to {
    -webkit-transform: translate3d(95vw, -118vh, 0);
            transform: translate3d(95vw, -118vh, 0);
  }
}

@keyframes move-frames-163 {
  from {
    -webkit-transform: translate3d(10vw, 101vh, 0);
            transform: translate3d(10vw, 101vh, 0);
  }

  to {
    -webkit-transform: translate3d(95vw, -118vh, 0);
            transform: translate3d(95vw, -118vh, 0);
  }
}

.circle-container2:nth-child(163) .circle {
  -webkit-animation-delay: 1034ms;
          animation-delay: 1034ms;
}

.circle-container2:nth-child(164) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-164;
          animation-name: move-frames-164;
  -webkit-animation-duration: 29794ms;
          animation-duration: 29794ms;
  -webkit-animation-delay: 16383ms;
          animation-delay: 16383ms;
}

@-webkit-keyframes move-frames-164 {
  from {
    -webkit-transform: translate3d(27vw, 101vh, 0);
            transform: translate3d(27vw, 101vh, 0);
  }

  to {
    -webkit-transform: translate3d(43vw, -116vh, 0);
            transform: translate3d(43vw, -116vh, 0);
  }
}

@keyframes move-frames-164 {
  from {
    -webkit-transform: translate3d(27vw, 101vh, 0);
            transform: translate3d(27vw, 101vh, 0);
  }

  to {
    -webkit-transform: translate3d(43vw, -116vh, 0);
            transform: translate3d(43vw, -116vh, 0);
  }
}

.circle-container2:nth-child(164) .circle {
  -webkit-animation-delay: 927ms;
          animation-delay: 927ms;
}

.circle-container2:nth-child(165) {
  width: 1px;
  height: 1px;
  -webkit-animation-name: move-frames-165;
          animation-name: move-frames-165;
  -webkit-animation-duration: 34792ms;
          animation-duration: 34792ms;
  -webkit-animation-delay: 30428ms;
          animation-delay: 30428ms;
}

@-webkit-keyframes move-frames-165 {
  from {
    -webkit-transform: translate3d(77vw, 107vh, 0);
            transform: translate3d(77vw, 107vh, 0);
  }

  to {
    -webkit-transform: translate3d(15vw, -121vh, 0);
            transform: translate3d(15vw, -121vh, 0);
  }
}

@keyframes move-frames-165 {
  from {
    -webkit-transform: translate3d(77vw, 107vh, 0);
            transform: translate3d(77vw, 107vh, 0);
  }

  to {
    -webkit-transform: translate3d(15vw, -121vh, 0);
            transform: translate3d(15vw, -121vh, 0);
  }
}

.circle-container2:nth-child(165) .circle {
  -webkit-animation-delay: 2331ms;
          animation-delay: 2331ms;
}

.circle-container2:nth-child(166) {
  width: 1px;
  height: 1px;
  -webkit-animation-name: move-frames-166;
          animation-name: move-frames-166;
  -webkit-animation-duration: 31779ms;
          animation-duration: 31779ms;
  -webkit-animation-delay: 8039ms;
          animation-delay: 8039ms;
}

@-webkit-keyframes move-frames-166 {
  from {
    -webkit-transform: translate3d(55vw, 109vh, 0);
            transform: translate3d(55vw, 109vh, 0);
  }

  to {
    -webkit-transform: translate3d(36vw, -133vh, 0);
            transform: translate3d(36vw, -133vh, 0);
  }
}

@keyframes move-frames-166 {
  from {
    -webkit-transform: translate3d(55vw, 109vh, 0);
            transform: translate3d(55vw, 109vh, 0);
  }

  to {
    -webkit-transform: translate3d(36vw, -133vh, 0);
            transform: translate3d(36vw, -133vh, 0);
  }
}

.circle-container2:nth-child(166) .circle {
  -webkit-animation-delay: 1501ms;
          animation-delay: 1501ms;
}

.circle-container2:nth-child(167) {
  width: 7px;
  height: 7px;
  -webkit-animation-name: move-frames-167;
          animation-name: move-frames-167;
  -webkit-animation-duration: 33558ms;
          animation-duration: 33558ms;
  -webkit-animation-delay: 18201ms;
          animation-delay: 18201ms;
}

@-webkit-keyframes move-frames-167 {
  from {
    -webkit-transform: translate3d(80vw, 110vh, 0);
            transform: translate3d(80vw, 110vh, 0);
  }

  to {
    -webkit-transform: translate3d(35vw, -113vh, 0);
            transform: translate3d(35vw, -113vh, 0);
  }
}

@keyframes move-frames-167 {
  from {
    -webkit-transform: translate3d(80vw, 110vh, 0);
            transform: translate3d(80vw, 110vh, 0);
  }

  to {
    -webkit-transform: translate3d(35vw, -113vh, 0);
            transform: translate3d(35vw, -113vh, 0);
  }
}

.circle-container2:nth-child(167) .circle {
  -webkit-animation-delay: 3387ms;
          animation-delay: 3387ms;
}

.circle-container2:nth-child(168) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-168;
          animation-name: move-frames-168;
  -webkit-animation-duration: 33625ms;
          animation-duration: 33625ms;
  -webkit-animation-delay: 21179ms;
          animation-delay: 21179ms;
}

@-webkit-keyframes move-frames-168 {
  from {
    -webkit-transform: translate3d(32vw, 108vh, 0);
            transform: translate3d(32vw, 108vh, 0);
  }

  to {
    -webkit-transform: translate3d(2vw, -130vh, 0);
            transform: translate3d(2vw, -130vh, 0);
  }
}

@keyframes move-frames-168 {
  from {
    -webkit-transform: translate3d(32vw, 108vh, 0);
            transform: translate3d(32vw, 108vh, 0);
  }

  to {
    -webkit-transform: translate3d(2vw, -130vh, 0);
            transform: translate3d(2vw, -130vh, 0);
  }
}

.circle-container2:nth-child(168) .circle {
  -webkit-animation-delay: 1929ms;
          animation-delay: 1929ms;
}

.circle-container2:nth-child(169) {
  width: 7px;
  height: 7px;
  -webkit-animation-name: move-frames-169;
          animation-name: move-frames-169;
  -webkit-animation-duration: 35307ms;
          animation-duration: 35307ms;
  -webkit-animation-delay: 29480ms;
          animation-delay: 29480ms;
}

@-webkit-keyframes move-frames-169 {
  from {
    -webkit-transform: translate3d(31vw, 102vh, 0);
            transform: translate3d(31vw, 102vh, 0);
  }

  to {
    -webkit-transform: translate3d(61vw, -130vh, 0);
            transform: translate3d(61vw, -130vh, 0);
  }
}

@keyframes move-frames-169 {
  from {
    -webkit-transform: translate3d(31vw, 102vh, 0);
            transform: translate3d(31vw, 102vh, 0);
  }

  to {
    -webkit-transform: translate3d(61vw, -130vh, 0);
            transform: translate3d(61vw, -130vh, 0);
  }
}

.circle-container2:nth-child(169) .circle {
  -webkit-animation-delay: 1027ms;
          animation-delay: 1027ms;
}

.circle-container2:nth-child(170) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-170;
          animation-name: move-frames-170;
  -webkit-animation-duration: 31199ms;
          animation-duration: 31199ms;
  -webkit-animation-delay: 28197ms;
          animation-delay: 28197ms;
}

@-webkit-keyframes move-frames-170 {
  from {
    -webkit-transform: translate3d(75vw, 105vh, 0);
            transform: translate3d(75vw, 105vh, 0);
  }

  to {
    -webkit-transform: translate3d(16vw, -127vh, 0);
            transform: translate3d(16vw, -127vh, 0);
  }
}

@keyframes move-frames-170 {
  from {
    -webkit-transform: translate3d(75vw, 105vh, 0);
            transform: translate3d(75vw, 105vh, 0);
  }

  to {
    -webkit-transform: translate3d(16vw, -127vh, 0);
            transform: translate3d(16vw, -127vh, 0);
  }
}

.circle-container2:nth-child(170) .circle {
  -webkit-animation-delay: 3401ms;
          animation-delay: 3401ms;
}

.circle-container2:nth-child(171) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-171;
          animation-name: move-frames-171;
  -webkit-animation-duration: 32710ms;
          animation-duration: 32710ms;
  -webkit-animation-delay: 25985ms;
          animation-delay: 25985ms;
}

@-webkit-keyframes move-frames-171 {
  from {
    -webkit-transform: translate3d(40vw, 108vh, 0);
            transform: translate3d(40vw, 108vh, 0);
  }

  to {
    -webkit-transform: translate3d(16vw, -129vh, 0);
            transform: translate3d(16vw, -129vh, 0);
  }
}

@keyframes move-frames-171 {
  from {
    -webkit-transform: translate3d(40vw, 108vh, 0);
            transform: translate3d(40vw, 108vh, 0);
  }

  to {
    -webkit-transform: translate3d(16vw, -129vh, 0);
            transform: translate3d(16vw, -129vh, 0);
  }
}

.circle-container2:nth-child(171) .circle {
  -webkit-animation-delay: 3100ms;
          animation-delay: 3100ms;
}

.circle-container2:nth-child(172) {
  width: 1px;
  height: 1px;
  -webkit-animation-name: move-frames-172;
          animation-name: move-frames-172;
  -webkit-animation-duration: 36094ms;
          animation-duration: 36094ms;
  -webkit-animation-delay: 22192ms;
          animation-delay: 22192ms;
}

@-webkit-keyframes move-frames-172 {
  from {
    -webkit-transform: translate3d(70vw, 107vh, 0);
            transform: translate3d(70vw, 107vh, 0);
  }

  to {
    -webkit-transform: translate3d(33vw, -133vh, 0);
            transform: translate3d(33vw, -133vh, 0);
  }
}

@keyframes move-frames-172 {
  from {
    -webkit-transform: translate3d(70vw, 107vh, 0);
            transform: translate3d(70vw, 107vh, 0);
  }

  to {
    -webkit-transform: translate3d(33vw, -133vh, 0);
            transform: translate3d(33vw, -133vh, 0);
  }
}

.circle-container2:nth-child(172) .circle {
  -webkit-animation-delay: 3260ms;
          animation-delay: 3260ms;
}

.circle-container2:nth-child(173) {
  width: 1px;
  height: 1px;
  -webkit-animation-name: move-frames-173;
          animation-name: move-frames-173;
  -webkit-animation-duration: 28325ms;
          animation-duration: 28325ms;
  -webkit-animation-delay: 14848ms;
          animation-delay: 14848ms;
}

@-webkit-keyframes move-frames-173 {
  from {
    -webkit-transform: translate3d(39vw, 107vh, 0);
            transform: translate3d(39vw, 107vh, 0);
  }

  to {
    -webkit-transform: translate3d(55vw, -137vh, 0);
            transform: translate3d(55vw, -137vh, 0);
  }
}

@keyframes move-frames-173 {
  from {
    -webkit-transform: translate3d(39vw, 107vh, 0);
            transform: translate3d(39vw, 107vh, 0);
  }

  to {
    -webkit-transform: translate3d(55vw, -137vh, 0);
            transform: translate3d(55vw, -137vh, 0);
  }
}

.circle-container2:nth-child(173) .circle {
  -webkit-animation-delay: 1544ms;
          animation-delay: 1544ms;
}

.circle-container2:nth-child(174) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-174;
          animation-name: move-frames-174;
  -webkit-animation-duration: 31498ms;
          animation-duration: 31498ms;
  -webkit-animation-delay: 34860ms;
          animation-delay: 34860ms;
}

@-webkit-keyframes move-frames-174 {
  from {
    -webkit-transform: translate3d(82vw, 105vh, 0);
            transform: translate3d(82vw, 105vh, 0);
  }

  to {
    -webkit-transform: translate3d(68vw, -110vh, 0);
            transform: translate3d(68vw, -110vh, 0);
  }
}

@keyframes move-frames-174 {
  from {
    -webkit-transform: translate3d(82vw, 105vh, 0);
            transform: translate3d(82vw, 105vh, 0);
  }

  to {
    -webkit-transform: translate3d(68vw, -110vh, 0);
            transform: translate3d(68vw, -110vh, 0);
  }
}

.circle-container2:nth-child(174) .circle {
  -webkit-animation-delay: 1419ms;
          animation-delay: 1419ms;
}

.circle-container2:nth-child(175) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-175;
          animation-name: move-frames-175;
  -webkit-animation-duration: 32296ms;
          animation-duration: 32296ms;
  -webkit-animation-delay: 12310ms;
          animation-delay: 12310ms;
}

@-webkit-keyframes move-frames-175 {
  from {
    -webkit-transform: translate3d(48vw, 105vh, 0);
            transform: translate3d(48vw, 105vh, 0);
  }

  to {
    -webkit-transform: translate3d(72vw, -123vh, 0);
            transform: translate3d(72vw, -123vh, 0);
  }
}

@keyframes move-frames-175 {
  from {
    -webkit-transform: translate3d(48vw, 105vh, 0);
            transform: translate3d(48vw, 105vh, 0);
  }

  to {
    -webkit-transform: translate3d(72vw, -123vh, 0);
            transform: translate3d(72vw, -123vh, 0);
  }
}

.circle-container2:nth-child(175) .circle {
  -webkit-animation-delay: 89ms;
          animation-delay: 89ms;
}

.circle-container2:nth-child(176) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-176;
          animation-name: move-frames-176;
  -webkit-animation-duration: 31695ms;
          animation-duration: 31695ms;
  -webkit-animation-delay: 24667ms;
          animation-delay: 24667ms;
}

@-webkit-keyframes move-frames-176 {
  from {
    -webkit-transform: translate3d(22vw, 109vh, 0);
            transform: translate3d(22vw, 109vh, 0);
  }

  to {
    -webkit-transform: translate3d(4vw, -126vh, 0);
            transform: translate3d(4vw, -126vh, 0);
  }
}

@keyframes move-frames-176 {
  from {
    -webkit-transform: translate3d(22vw, 109vh, 0);
            transform: translate3d(22vw, 109vh, 0);
  }

  to {
    -webkit-transform: translate3d(4vw, -126vh, 0);
            transform: translate3d(4vw, -126vh, 0);
  }
}

.circle-container2:nth-child(176) .circle {
  -webkit-animation-delay: 3187ms;
          animation-delay: 3187ms;
}

.circle-container2:nth-child(177) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-177;
          animation-name: move-frames-177;
  -webkit-animation-duration: 31664ms;
          animation-duration: 31664ms;
  -webkit-animation-delay: 18211ms;
          animation-delay: 18211ms;
}

@-webkit-keyframes move-frames-177 {
  from {
    -webkit-transform: translate3d(49vw, 101vh, 0);
            transform: translate3d(49vw, 101vh, 0);
  }

  to {
    -webkit-transform: translate3d(29vw, -125vh, 0);
            transform: translate3d(29vw, -125vh, 0);
  }
}

@keyframes move-frames-177 {
  from {
    -webkit-transform: translate3d(49vw, 101vh, 0);
            transform: translate3d(49vw, 101vh, 0);
  }

  to {
    -webkit-transform: translate3d(29vw, -125vh, 0);
            transform: translate3d(29vw, -125vh, 0);
  }
}

.circle-container2:nth-child(177) .circle {
  -webkit-animation-delay: 2819ms;
          animation-delay: 2819ms;
}

.circle-container2:nth-child(178) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-178;
          animation-name: move-frames-178;
  -webkit-animation-duration: 28392ms;
          animation-duration: 28392ms;
  -webkit-animation-delay: 34567ms;
          animation-delay: 34567ms;
}

@-webkit-keyframes move-frames-178 {
  from {
    -webkit-transform: translate3d(51vw, 110vh, 0);
            transform: translate3d(51vw, 110vh, 0);
  }

  to {
    -webkit-transform: translate3d(100vw, -131vh, 0);
            transform: translate3d(100vw, -131vh, 0);
  }
}

@keyframes move-frames-178 {
  from {
    -webkit-transform: translate3d(51vw, 110vh, 0);
            transform: translate3d(51vw, 110vh, 0);
  }

  to {
    -webkit-transform: translate3d(100vw, -131vh, 0);
            transform: translate3d(100vw, -131vh, 0);
  }
}

.circle-container2:nth-child(178) .circle {
  -webkit-animation-delay: 2722ms;
          animation-delay: 2722ms;
}

.circle-container2:nth-child(179) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-179;
          animation-name: move-frames-179;
  -webkit-animation-duration: 30963ms;
          animation-duration: 30963ms;
  -webkit-animation-delay: 24078ms;
          animation-delay: 24078ms;
}

@-webkit-keyframes move-frames-179 {
  from {
    -webkit-transform: translate3d(52vw, 108vh, 0);
            transform: translate3d(52vw, 108vh, 0);
  }

  to {
    -webkit-transform: translate3d(41vw, -136vh, 0);
            transform: translate3d(41vw, -136vh, 0);
  }
}

@keyframes move-frames-179 {
  from {
    -webkit-transform: translate3d(52vw, 108vh, 0);
            transform: translate3d(52vw, 108vh, 0);
  }

  to {
    -webkit-transform: translate3d(41vw, -136vh, 0);
            transform: translate3d(41vw, -136vh, 0);
  }
}

.circle-container2:nth-child(179) .circle {
  -webkit-animation-delay: 1139ms;
          animation-delay: 1139ms;
}

.circle-container2:nth-child(180) {
  width: 7px;
  height: 7px;
  -webkit-animation-name: move-frames-180;
          animation-name: move-frames-180;
  -webkit-animation-duration: 33541ms;
          animation-duration: 33541ms;
  -webkit-animation-delay: 17296ms;
          animation-delay: 17296ms;
}

@-webkit-keyframes move-frames-180 {
  from {
    -webkit-transform: translate3d(57vw, 102vh, 0);
            transform: translate3d(57vw, 102vh, 0);
  }

  to {
    -webkit-transform: translate3d(58vw, -132vh, 0);
            transform: translate3d(58vw, -132vh, 0);
  }
}

@keyframes move-frames-180 {
  from {
    -webkit-transform: translate3d(57vw, 102vh, 0);
            transform: translate3d(57vw, 102vh, 0);
  }

  to {
    -webkit-transform: translate3d(58vw, -132vh, 0);
            transform: translate3d(58vw, -132vh, 0);
  }
}

.circle-container2:nth-child(180) .circle {
  -webkit-animation-delay: 2123ms;
          animation-delay: 2123ms;
}

.circle-container2:nth-child(181) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-181;
          animation-name: move-frames-181;
  -webkit-animation-duration: 32098ms;
          animation-duration: 32098ms;
  -webkit-animation-delay: 24109ms;
          animation-delay: 24109ms;
}

@-webkit-keyframes move-frames-181 {
  from {
    -webkit-transform: translate3d(16vw, 107vh, 0);
            transform: translate3d(16vw, 107vh, 0);
  }

  to {
    -webkit-transform: translate3d(71vw, -116vh, 0);
            transform: translate3d(71vw, -116vh, 0);
  }
}

@keyframes move-frames-181 {
  from {
    -webkit-transform: translate3d(16vw, 107vh, 0);
            transform: translate3d(16vw, 107vh, 0);
  }

  to {
    -webkit-transform: translate3d(71vw, -116vh, 0);
            transform: translate3d(71vw, -116vh, 0);
  }
}

.circle-container2:nth-child(181) .circle {
  -webkit-animation-delay: 215ms;
          animation-delay: 215ms;
}

.circle-container2:nth-child(182) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-182;
          animation-name: move-frames-182;
  -webkit-animation-duration: 33521ms;
          animation-duration: 33521ms;
  -webkit-animation-delay: 20413ms;
          animation-delay: 20413ms;
}

@-webkit-keyframes move-frames-182 {
  from {
    -webkit-transform: translate3d(1vw, 106vh, 0);
            transform: translate3d(1vw, 106vh, 0);
  }

  to {
    -webkit-transform: translate3d(17vw, -136vh, 0);
            transform: translate3d(17vw, -136vh, 0);
  }
}

@keyframes move-frames-182 {
  from {
    -webkit-transform: translate3d(1vw, 106vh, 0);
            transform: translate3d(1vw, 106vh, 0);
  }

  to {
    -webkit-transform: translate3d(17vw, -136vh, 0);
            transform: translate3d(17vw, -136vh, 0);
  }
}

.circle-container2:nth-child(182) .circle {
  -webkit-animation-delay: 2956ms;
          animation-delay: 2956ms;
}

.circle-container2:nth-child(183) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-183;
          animation-name: move-frames-183;
  -webkit-animation-duration: 35438ms;
          animation-duration: 35438ms;
  -webkit-animation-delay: 29444ms;
          animation-delay: 29444ms;
}

@-webkit-keyframes move-frames-183 {
  from {
    -webkit-transform: translate3d(20vw, 102vh, 0);
            transform: translate3d(20vw, 102vh, 0);
  }

  to {
    -webkit-transform: translate3d(35vw, -126vh, 0);
            transform: translate3d(35vw, -126vh, 0);
  }
}

@keyframes move-frames-183 {
  from {
    -webkit-transform: translate3d(20vw, 102vh, 0);
            transform: translate3d(20vw, 102vh, 0);
  }

  to {
    -webkit-transform: translate3d(35vw, -126vh, 0);
            transform: translate3d(35vw, -126vh, 0);
  }
}

.circle-container2:nth-child(183) .circle {
  -webkit-animation-delay: 3321ms;
          animation-delay: 3321ms;
}

.circle-container2:nth-child(184) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-184;
          animation-name: move-frames-184;
  -webkit-animation-duration: 34660ms;
          animation-duration: 34660ms;
  -webkit-animation-delay: 22744ms;
          animation-delay: 22744ms;
}

@-webkit-keyframes move-frames-184 {
  from {
    -webkit-transform: translate3d(12vw, 103vh, 0);
            transform: translate3d(12vw, 103vh, 0);
  }

  to {
    -webkit-transform: translate3d(10vw, -121vh, 0);
            transform: translate3d(10vw, -121vh, 0);
  }
}

@keyframes move-frames-184 {
  from {
    -webkit-transform: translate3d(12vw, 103vh, 0);
            transform: translate3d(12vw, 103vh, 0);
  }

  to {
    -webkit-transform: translate3d(10vw, -121vh, 0);
            transform: translate3d(10vw, -121vh, 0);
  }
}

.circle-container2:nth-child(184) .circle {
  -webkit-animation-delay: 3626ms;
          animation-delay: 3626ms;
}

.circle-container2:nth-child(185) {
  width: 1px;
  height: 1px;
  -webkit-animation-name: move-frames-185;
          animation-name: move-frames-185;
  -webkit-animation-duration: 33549ms;
          animation-duration: 33549ms;
  -webkit-animation-delay: 17214ms;
          animation-delay: 17214ms;
}

@-webkit-keyframes move-frames-185 {
  from {
    -webkit-transform: translate3d(3vw, 109vh, 0);
            transform: translate3d(3vw, 109vh, 0);
  }

  to {
    -webkit-transform: translate3d(97vw, -111vh, 0);
            transform: translate3d(97vw, -111vh, 0);
  }
}

@keyframes move-frames-185 {
  from {
    -webkit-transform: translate3d(3vw, 109vh, 0);
            transform: translate3d(3vw, 109vh, 0);
  }

  to {
    -webkit-transform: translate3d(97vw, -111vh, 0);
            transform: translate3d(97vw, -111vh, 0);
  }
}

.circle-container2:nth-child(185) .circle {
  -webkit-animation-delay: 3664ms;
          animation-delay: 3664ms;
}

.circle-container2:nth-child(186) {
  width: 7px;
  height: 7px;
  -webkit-animation-name: move-frames-186;
          animation-name: move-frames-186;
  -webkit-animation-duration: 30535ms;
          animation-duration: 30535ms;
  -webkit-animation-delay: 32724ms;
          animation-delay: 32724ms;
}

@-webkit-keyframes move-frames-186 {
  from {
    -webkit-transform: translate3d(78vw, 103vh, 0);
            transform: translate3d(78vw, 103vh, 0);
  }

  to {
    -webkit-transform: translate3d(75vw, -115vh, 0);
            transform: translate3d(75vw, -115vh, 0);
  }
}

@keyframes move-frames-186 {
  from {
    -webkit-transform: translate3d(78vw, 103vh, 0);
            transform: translate3d(78vw, 103vh, 0);
  }

  to {
    -webkit-transform: translate3d(75vw, -115vh, 0);
            transform: translate3d(75vw, -115vh, 0);
  }
}

.circle-container2:nth-child(186) .circle {
  -webkit-animation-delay: 3761ms;
          animation-delay: 3761ms;
}

.circle-container2:nth-child(187) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-187;
          animation-name: move-frames-187;
  -webkit-animation-duration: 35438ms;
          animation-duration: 35438ms;
  -webkit-animation-delay: 18335ms;
          animation-delay: 18335ms;
}

@-webkit-keyframes move-frames-187 {
  from {
    -webkit-transform: translate3d(45vw, 104vh, 0);
            transform: translate3d(45vw, 104vh, 0);
  }

  to {
    -webkit-transform: translate3d(99vw, -120vh, 0);
            transform: translate3d(99vw, -120vh, 0);
  }
}

@keyframes move-frames-187 {
  from {
    -webkit-transform: translate3d(45vw, 104vh, 0);
            transform: translate3d(45vw, 104vh, 0);
  }

  to {
    -webkit-transform: translate3d(99vw, -120vh, 0);
            transform: translate3d(99vw, -120vh, 0);
  }
}

.circle-container2:nth-child(187) .circle {
  -webkit-animation-delay: 477ms;
          animation-delay: 477ms;
}

.circle-container2:nth-child(188) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-188;
          animation-name: move-frames-188;
  -webkit-animation-duration: 33244ms;
          animation-duration: 33244ms;
  -webkit-animation-delay: 6241ms;
          animation-delay: 6241ms;
}

@-webkit-keyframes move-frames-188 {
  from {
    -webkit-transform: translate3d(17vw, 107vh, 0);
            transform: translate3d(17vw, 107vh, 0);
  }

  to {
    -webkit-transform: translate3d(22vw, -126vh, 0);
            transform: translate3d(22vw, -126vh, 0);
  }
}

@keyframes move-frames-188 {
  from {
    -webkit-transform: translate3d(17vw, 107vh, 0);
            transform: translate3d(17vw, 107vh, 0);
  }

  to {
    -webkit-transform: translate3d(22vw, -126vh, 0);
            transform: translate3d(22vw, -126vh, 0);
  }
}

.circle-container2:nth-child(188) .circle {
  -webkit-animation-delay: 899ms;
          animation-delay: 899ms;
}

.circle-container2:nth-child(189) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-189;
          animation-name: move-frames-189;
  -webkit-animation-duration: 34146ms;
          animation-duration: 34146ms;
  -webkit-animation-delay: 22185ms;
          animation-delay: 22185ms;
}

@-webkit-keyframes move-frames-189 {
  from {
    -webkit-transform: translate3d(96vw, 105vh, 0);
            transform: translate3d(96vw, 105vh, 0);
  }

  to {
    -webkit-transform: translate3d(54vw, -129vh, 0);
            transform: translate3d(54vw, -129vh, 0);
  }
}

@keyframes move-frames-189 {
  from {
    -webkit-transform: translate3d(96vw, 105vh, 0);
            transform: translate3d(96vw, 105vh, 0);
  }

  to {
    -webkit-transform: translate3d(54vw, -129vh, 0);
            transform: translate3d(54vw, -129vh, 0);
  }
}

.circle-container2:nth-child(189) .circle {
  -webkit-animation-delay: 1636ms;
          animation-delay: 1636ms;
}

.circle-container2:nth-child(190) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-190;
          animation-name: move-frames-190;
  -webkit-animation-duration: 31554ms;
          animation-duration: 31554ms;
  -webkit-animation-delay: 3633ms;
          animation-delay: 3633ms;
}

@-webkit-keyframes move-frames-190 {
  from {
    -webkit-transform: translate3d(58vw, 105vh, 0);
            transform: translate3d(58vw, 105vh, 0);
  }

  to {
    -webkit-transform: translate3d(31vw, -133vh, 0);
            transform: translate3d(31vw, -133vh, 0);
  }
}

@keyframes move-frames-190 {
  from {
    -webkit-transform: translate3d(58vw, 105vh, 0);
            transform: translate3d(58vw, 105vh, 0);
  }

  to {
    -webkit-transform: translate3d(31vw, -133vh, 0);
            transform: translate3d(31vw, -133vh, 0);
  }
}

.circle-container2:nth-child(190) .circle {
  -webkit-animation-delay: 1078ms;
          animation-delay: 1078ms;
}

.circle-container2:nth-child(191) {
  width: 7px;
  height: 7px;
  -webkit-animation-name: move-frames-191;
          animation-name: move-frames-191;
  -webkit-animation-duration: 28930ms;
          animation-duration: 28930ms;
  -webkit-animation-delay: 26467ms;
          animation-delay: 26467ms;
}

@-webkit-keyframes move-frames-191 {
  from {
    -webkit-transform: translate3d(79vw, 101vh, 0);
            transform: translate3d(79vw, 101vh, 0);
  }

  to {
    -webkit-transform: translate3d(62vw, -108vh, 0);
            transform: translate3d(62vw, -108vh, 0);
  }
}

@keyframes move-frames-191 {
  from {
    -webkit-transform: translate3d(79vw, 101vh, 0);
            transform: translate3d(79vw, 101vh, 0);
  }

  to {
    -webkit-transform: translate3d(62vw, -108vh, 0);
            transform: translate3d(62vw, -108vh, 0);
  }
}

.circle-container2:nth-child(191) .circle {
  -webkit-animation-delay: 3548ms;
          animation-delay: 3548ms;
}

.circle-container2:nth-child(192) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-192;
          animation-name: move-frames-192;
  -webkit-animation-duration: 28483ms;
          animation-duration: 28483ms;
  -webkit-animation-delay: 34660ms;
          animation-delay: 34660ms;
}

@-webkit-keyframes move-frames-192 {
  from {
    -webkit-transform: translate3d(85vw, 103vh, 0);
            transform: translate3d(85vw, 103vh, 0);
  }

  to {
    -webkit-transform: translate3d(54vw, -121vh, 0);
            transform: translate3d(54vw, -121vh, 0);
  }
}

@keyframes move-frames-192 {
  from {
    -webkit-transform: translate3d(85vw, 103vh, 0);
            transform: translate3d(85vw, 103vh, 0);
  }

  to {
    -webkit-transform: translate3d(54vw, -121vh, 0);
            transform: translate3d(54vw, -121vh, 0);
  }
}

.circle-container2:nth-child(192) .circle {
  -webkit-animation-delay: 440ms;
          animation-delay: 440ms;
}

.circle-container2:nth-child(193) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-193;
          animation-name: move-frames-193;
  -webkit-animation-duration: 32543ms;
          animation-duration: 32543ms;
  -webkit-animation-delay: 10492ms;
          animation-delay: 10492ms;
}

@-webkit-keyframes move-frames-193 {
  from {
    -webkit-transform: translate3d(59vw, 104vh, 0);
            transform: translate3d(59vw, 104vh, 0);
  }

  to {
    -webkit-transform: translate3d(69vw, -131vh, 0);
            transform: translate3d(69vw, -131vh, 0);
  }
}

@keyframes move-frames-193 {
  from {
    -webkit-transform: translate3d(59vw, 104vh, 0);
            transform: translate3d(59vw, 104vh, 0);
  }

  to {
    -webkit-transform: translate3d(69vw, -131vh, 0);
            transform: translate3d(69vw, -131vh, 0);
  }
}

.circle-container2:nth-child(193) .circle {
  -webkit-animation-delay: 829ms;
          animation-delay: 829ms;
}

.circle-container2:nth-child(194) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-194;
          animation-name: move-frames-194;
  -webkit-animation-duration: 30645ms;
          animation-duration: 30645ms;
  -webkit-animation-delay: 12380ms;
          animation-delay: 12380ms;
}

@-webkit-keyframes move-frames-194 {
  from {
    -webkit-transform: translate3d(12vw, 110vh, 0);
            transform: translate3d(12vw, 110vh, 0);
  }

  to {
    -webkit-transform: translate3d(45vw, -127vh, 0);
            transform: translate3d(45vw, -127vh, 0);
  }
}

@keyframes move-frames-194 {
  from {
    -webkit-transform: translate3d(12vw, 110vh, 0);
            transform: translate3d(12vw, 110vh, 0);
  }

  to {
    -webkit-transform: translate3d(45vw, -127vh, 0);
            transform: translate3d(45vw, -127vh, 0);
  }
}

.circle-container2:nth-child(194) .circle {
  -webkit-animation-delay: 1113ms;
          animation-delay: 1113ms;
}

.circle-container2:nth-child(195) {
  width: 7px;
  height: 7px;
  -webkit-animation-name: move-frames-195;
          animation-name: move-frames-195;
  -webkit-animation-duration: 34270ms;
          animation-duration: 34270ms;
  -webkit-animation-delay: 15876ms;
          animation-delay: 15876ms;
}

@-webkit-keyframes move-frames-195 {
  from {
    -webkit-transform: translate3d(50vw, 101vh, 0);
            transform: translate3d(50vw, 101vh, 0);
  }

  to {
    -webkit-transform: translate3d(87vw, -118vh, 0);
            transform: translate3d(87vw, -118vh, 0);
  }
}

@keyframes move-frames-195 {
  from {
    -webkit-transform: translate3d(50vw, 101vh, 0);
            transform: translate3d(50vw, 101vh, 0);
  }

  to {
    -webkit-transform: translate3d(87vw, -118vh, 0);
            transform: translate3d(87vw, -118vh, 0);
  }
}

.circle-container2:nth-child(195) .circle {
  -webkit-animation-delay: 1624ms;
          animation-delay: 1624ms;
}

.circle-container2:nth-child(196) {
  width: 7px;
  height: 7px;
  -webkit-animation-name: move-frames-196;
          animation-name: move-frames-196;
  -webkit-animation-duration: 29392ms;
          animation-duration: 29392ms;
  -webkit-animation-delay: 25816ms;
          animation-delay: 25816ms;
}

@-webkit-keyframes move-frames-196 {
  from {
    -webkit-transform: translate3d(42vw, 102vh, 0);
            transform: translate3d(42vw, 102vh, 0);
  }

  to {
    -webkit-transform: translate3d(63vw, -107vh, 0);
            transform: translate3d(63vw, -107vh, 0);
  }
}

@keyframes move-frames-196 {
  from {
    -webkit-transform: translate3d(42vw, 102vh, 0);
            transform: translate3d(42vw, 102vh, 0);
  }

  to {
    -webkit-transform: translate3d(63vw, -107vh, 0);
            transform: translate3d(63vw, -107vh, 0);
  }
}

.circle-container2:nth-child(196) .circle {
  -webkit-animation-delay: 1477ms;
          animation-delay: 1477ms;
}

.circle-container2:nth-child(197) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-197;
          animation-name: move-frames-197;
  -webkit-animation-duration: 36800ms;
          animation-duration: 36800ms;
  -webkit-animation-delay: 5377ms;
          animation-delay: 5377ms;
}

@-webkit-keyframes move-frames-197 {
  from {
    -webkit-transform: translate3d(79vw, 101vh, 0);
            transform: translate3d(79vw, 101vh, 0);
  }

  to {
    -webkit-transform: translate3d(82vw, -130vh, 0);
            transform: translate3d(82vw, -130vh, 0);
  }
}

@keyframes move-frames-197 {
  from {
    -webkit-transform: translate3d(79vw, 101vh, 0);
            transform: translate3d(79vw, 101vh, 0);
  }

  to {
    -webkit-transform: translate3d(82vw, -130vh, 0);
            transform: translate3d(82vw, -130vh, 0);
  }
}

.circle-container2:nth-child(197) .circle {
  -webkit-animation-delay: 3276ms;
          animation-delay: 3276ms;
}

.circle-container2:nth-child(198) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-198;
          animation-name: move-frames-198;
  -webkit-animation-duration: 28760ms;
          animation-duration: 28760ms;
  -webkit-animation-delay: 21968ms;
          animation-delay: 21968ms;
}

@-webkit-keyframes move-frames-198 {
  from {
    -webkit-transform: translate3d(16vw, 106vh, 0);
            transform: translate3d(16vw, 106vh, 0);
  }

  to {
    -webkit-transform: translate3d(5vw, -126vh, 0);
            transform: translate3d(5vw, -126vh, 0);
  }
}

@keyframes move-frames-198 {
  from {
    -webkit-transform: translate3d(16vw, 106vh, 0);
            transform: translate3d(16vw, 106vh, 0);
  }

  to {
    -webkit-transform: translate3d(5vw, -126vh, 0);
            transform: translate3d(5vw, -126vh, 0);
  }
}

.circle-container2:nth-child(198) .circle {
  -webkit-animation-delay: 2084ms;
          animation-delay: 2084ms;
}

.circle-container2:nth-child(199) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-199;
          animation-name: move-frames-199;
  -webkit-animation-duration: 33994ms;
          animation-duration: 33994ms;
  -webkit-animation-delay: 7886ms;
          animation-delay: 7886ms;
}

@-webkit-keyframes move-frames-199 {
  from {
    -webkit-transform: translate3d(72vw, 106vh, 0);
            transform: translate3d(72vw, 106vh, 0);
  }

  to {
    -webkit-transform: translate3d(25vw, -123vh, 0);
            transform: translate3d(25vw, -123vh, 0);
  }
}

@keyframes move-frames-199 {
  from {
    -webkit-transform: translate3d(72vw, 106vh, 0);
            transform: translate3d(72vw, 106vh, 0);
  }

  to {
    -webkit-transform: translate3d(25vw, -123vh, 0);
            transform: translate3d(25vw, -123vh, 0);
  }
}

.circle-container2:nth-child(199) .circle {
  -webkit-animation-delay: 367ms;
          animation-delay: 367ms;
}

.circle-container2:nth-child(200) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-200;
          animation-name: move-frames-200;
  -webkit-animation-duration: 32701ms;
          animation-duration: 32701ms;
  -webkit-animation-delay: 18293ms;
          animation-delay: 18293ms;
}

@-webkit-keyframes move-frames-200 {
  from {
    -webkit-transform: translate3d(61vw, 103vh, 0);
            transform: translate3d(61vw, 103vh, 0);
  }

  to {
    -webkit-transform: translate3d(24vw, -110vh, 0);
            transform: translate3d(24vw, -110vh, 0);
  }
}

@keyframes move-frames-200 {
  from {
    -webkit-transform: translate3d(61vw, 103vh, 0);
            transform: translate3d(61vw, 103vh, 0);
  }

  to {
    -webkit-transform: translate3d(24vw, -110vh, 0);
            transform: translate3d(24vw, -110vh, 0);
  }
}

.circle-container2:nth-child(200) .circle {
  -webkit-animation-delay: 3411ms;
          animation-delay: 3411ms;
}

*:before, *:after {
	box-sizing: border-box;
	-webkit-transform-style: preserve-3d;
	        transform-style: preserve-3d;
	position: absolute;
	content: "";
}

@media screen and (min-width: 992px) and (max-width: 1199px) {

  @-webkit-keyframes gradient {
      0% {
          background-position: 0% 0%;
      }
      50% {
          background-position: 100% 100%;
      }
      100% {
          background-position: 0% 0%;
      }
  }

  @keyframes gradient {
      0% {
          background-position: 0% 0%;
      }
      50% {
          background-position: 100% 100%;
      }
      100% {
          background-position: 0% 0%;
      }
  }
  
  .particles {
    position: absolute;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
  }
  
  .particles:before, .particles:after {
    width: 200vw;
    height: 130vh;
    left: -50vw;
    top: -50vh;
    content: "";
    border-radius: 100%;
    opacity: 0;
    -webkit-transform: translateZ(-50vmin);
            transform: translateZ(-50vmin);
    background-image: repeating-conic-gradient(#FFF8 0%, transparent .0002%, transparent .075%, transparent .65%), 
      repeating-conic-gradient(#FFF 0%, transparent .0004%, transparent 0.05%, transparent .495%);
    -webkit-animation: particlesMovement 5s ease-in 0s infinite;
            animation: particlesMovement 5s ease-in 0s infinite;
    z-index: 1;
  }
  
  .particles:after {
    -webkit-animation-name: particlesMovement2;
            animation-name: particlesMovement2;
    -webkit-animation-delay: 1s;
            animation-delay: 1s;
  }
  
  .particles + .particles + .particles::before {
      -webkit-animation-delay: 2s;
              animation-delay: 2s;
  }
  
  .particles + .particles + .particles::after {
      -webkit-animation-delay: 3s;
              animation-delay: 3s;
  }
  
  @-webkit-keyframes particlesMovement {
    0% { 
      -webkit-transform: translateZ(-50vmin); 
              transform: translateZ(-50vmin); 
      opacity: 0; 
    }
    30%, 70%, 90% { 
      opacity: 1; 
    }
    100% { 
      -webkit-transform: translateZ(50vmin) rotate(15deg); 
              transform: translateZ(50vmin) rotate(15deg); 
      opacity: 0; 
    }
  }
  
  @keyframes particlesMovement {
    0% { 
      -webkit-transform: translateZ(-50vmin); 
              transform: translateZ(-50vmin); 
      opacity: 0; 
    }
    30%, 70%, 90% { 
      opacity: 1; 
    }
    100% { 
      -webkit-transform: translateZ(50vmin) rotate(15deg); 
              transform: translateZ(50vmin) rotate(15deg); 
      opacity: 0; 
    }
  }
  
  @-webkit-keyframes particlesMovement2 {
    0% { 
      -webkit-transform: translateZ(-50vmin) rotate(180deg); 
              transform: translateZ(-50vmin) rotate(180deg); 
      opacity: 0; 
    }
    30%, 70%, 90% { 
       opacity: 1; 
    }
    100% { 
      -webkit-transform: translateZ(50vmin) rotate(195deg); 
              transform: translateZ(50vmin) rotate(195deg); 
      opacity: 0; 
    }
  }
  
  @keyframes particlesMovement2 {
    0% { 
      -webkit-transform: translateZ(-50vmin) rotate(180deg); 
              transform: translateZ(-50vmin) rotate(180deg); 
      opacity: 0; 
    }
    30%, 70%, 90% { 
       opacity: 1; 
    }
    100% { 
      -webkit-transform: translateZ(50vmin) rotate(195deg); 
              transform: translateZ(50vmin) rotate(195deg); 
      opacity: 0; 
    }
  }
  }

@media screen and (min-width: 1200px) and (max-width: 1919px) {

@-webkit-keyframes gradient {
    0% {
        background-position: 0% 0%;
    }
    50% {
        background-position: 100% 100%;
    }
    100% {
        background-position: 0% 0%;
    }
}

@keyframes gradient {
    0% {
        background-position: 0% 0%;
    }
    50% {
        background-position: 100% 100%;
    }
    100% {
        background-position: 0% 0%;
    }
}

.particles {
	position: absolute;
	width: 100vw;
	height: 100vh;
  overflow: hidden;
}

.particles:before, .particles:after {
	width: 200vw;
  height: 200vh;
  left: -50vw;
  top: -50vh;
  content: "";
  border-radius: 100%;
  opacity: 0;
  -webkit-transform: translateZ(-50vmin);
          transform: translateZ(-50vmin);
  background-image: repeating-conic-gradient(#FFF8 0%, transparent .0002%, transparent .075%, transparent .65%), 
    repeating-conic-gradient(#FFF 0%, transparent .0004%, transparent 0.05%, transparent .495%);
  -webkit-animation: particlesMovement 5s ease-in 0s infinite;
          animation: particlesMovement 5s ease-in 0s infinite;
  z-index: 1;
}

.particles:after {
	-webkit-animation-name: particlesMovement2;
	        animation-name: particlesMovement2;
	-webkit-animation-delay: 1s;
	        animation-delay: 1s;
}

.particles + .particles + .particles::before {
    -webkit-animation-delay: 2s;
            animation-delay: 2s;
}

.particles + .particles + .particles::after {
    -webkit-animation-delay: 3s;
            animation-delay: 3s;
}

@-webkit-keyframes particlesMovement {
  0% { 
    -webkit-transform: translateZ(-50vmin); 
            transform: translateZ(-50vmin); 
    opacity: 0; 
  }
	30%, 70%, 90% { 
    opacity: 1; 
  }
	100% { 
    -webkit-transform: translateZ(50vmin) rotate(15deg); 
            transform: translateZ(50vmin) rotate(15deg); 
    opacity: 0; 
  }
}

@keyframes particlesMovement {
  0% { 
    -webkit-transform: translateZ(-50vmin); 
            transform: translateZ(-50vmin); 
    opacity: 0; 
  }
	30%, 70%, 90% { 
    opacity: 1; 
  }
	100% { 
    -webkit-transform: translateZ(50vmin) rotate(15deg); 
            transform: translateZ(50vmin) rotate(15deg); 
    opacity: 0; 
  }
}

@-webkit-keyframes particlesMovement2 {
  0% { 
    -webkit-transform: translateZ(-50vmin) rotate(180deg); 
            transform: translateZ(-50vmin) rotate(180deg); 
    opacity: 0; 
  }
	30%, 70%, 90% { 
     opacity: 1; 
  }
	100% { 
    -webkit-transform: translateZ(50vmin) rotate(195deg); 
            transform: translateZ(50vmin) rotate(195deg); 
    opacity: 0; 
  }
}

@keyframes particlesMovement2 {
  0% { 
    -webkit-transform: translateZ(-50vmin) rotate(180deg); 
            transform: translateZ(-50vmin) rotate(180deg); 
    opacity: 0; 
  }
	30%, 70%, 90% { 
     opacity: 1; 
  }
	100% { 
    -webkit-transform: translateZ(50vmin) rotate(195deg); 
            transform: translateZ(50vmin) rotate(195deg); 
    opacity: 0; 
  }
}
}

@media screen and (min-width: 1920px) {

  @-webkit-keyframes gradient {
      0% {
          background-position: 0% 0%;
      }
      50% {
          background-position: 100% 100%;
      }
      100% {
          background-position: 0% 0%;
      }
  }

  @keyframes gradient {
      0% {
          background-position: 0% 0%;
      }
      50% {
          background-position: 100% 100%;
      }
      100% {
          background-position: 0% 0%;
      }
  }
  
  .particles {
    position: absolute;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
  }
  
  .particles:before, .particles:after {
    width: 200vw;
    height: 130vh;
    left: -50vw;
    top: -50vh;
    content: "";
    border-radius: 100%;
    opacity: 0;
    -webkit-transform: translateZ(-50vmin);
            transform: translateZ(-50vmin);
    background-image: repeating-conic-gradient(#FFF8 0%, transparent .0002%, transparent .075%, transparent .65%), 
      repeating-conic-gradient(#FFF 0%, transparent .0004%, transparent 0.05%, transparent .495%);
    -webkit-animation: particlesMovement 5s ease-in 0s infinite;
            animation: particlesMovement 5s ease-in 0s infinite;
    z-index: 1;
  }
  
  .particles:after {
    -webkit-animation-name: particlesMovement2;
            animation-name: particlesMovement2;
    -webkit-animation-delay: 1s;
            animation-delay: 1s;
  }
  
  .particles + .particles + .particles::before {
      -webkit-animation-delay: 2s;
              animation-delay: 2s;
  }
  
  .particles + .particles + .particles::after {
      -webkit-animation-delay: 3s;
              animation-delay: 3s;
  }
  
  @-webkit-keyframes particlesMovement {
    0% { 
      -webkit-transform: translateZ(-50vmin); 
              transform: translateZ(-50vmin); 
      opacity: 0; 
    }
    30%, 70%, 90% { 
      opacity: 1; 
    }
    100% { 
      -webkit-transform: translateZ(50vmin) rotate(15deg); 
              transform: translateZ(50vmin) rotate(15deg); 
      opacity: 0; 
    }
  }
  
  @keyframes particlesMovement {
    0% { 
      -webkit-transform: translateZ(-50vmin); 
              transform: translateZ(-50vmin); 
      opacity: 0; 
    }
    30%, 70%, 90% { 
      opacity: 1; 
    }
    100% { 
      -webkit-transform: translateZ(50vmin) rotate(15deg); 
              transform: translateZ(50vmin) rotate(15deg); 
      opacity: 0; 
    }
  }
  
  @-webkit-keyframes particlesMovement2 {
    0% { 
      -webkit-transform: translateZ(-50vmin) rotate(180deg); 
              transform: translateZ(-50vmin) rotate(180deg); 
      opacity: 0; 
    }
    30%, 70%, 90% { 
       opacity: 1; 
    }
    100% { 
      -webkit-transform: translateZ(50vmin) rotate(195deg); 
              transform: translateZ(50vmin) rotate(195deg); 
      opacity: 0; 
    }
  }
  
  @keyframes particlesMovement2 {
    0% { 
      -webkit-transform: translateZ(-50vmin) rotate(180deg); 
              transform: translateZ(-50vmin) rotate(180deg); 
      opacity: 0; 
    }
    30%, 70%, 90% { 
       opacity: 1; 
    }
    100% { 
      -webkit-transform: translateZ(50vmin) rotate(195deg); 
              transform: translateZ(50vmin) rotate(195deg); 
      opacity: 0; 
    }
  }
}

@media screen and (min-width: 1921px) and (max-width: 3839px) {

  @-webkit-keyframes gradient {
      0% {
          background-position: 0% 0%;
      }
      50% {
          background-position: 100% 100%;
      }
      100% {
          background-position: 0% 0%;
      }
  }

  @keyframes gradient {
      0% {
          background-position: 0% 0%;
      }
      50% {
          background-position: 100% 100%;
      }
      100% {
          background-position: 0% 0%;
      }
  }
  
  .particles {
    position: absolute;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
  }
  
  .particles:before, .particles:after {
    width: 200vw;
    height: 130vh;
    left: -50vw;
    top: -50vh;
    content: "";
    border-radius: 100%;
    opacity: 0;
    -webkit-transform: translateZ(-50vmin);
            transform: translateZ(-50vmin);
    background-image: repeating-conic-gradient(#FFF8 0%, transparent .0002%, transparent .075%, transparent .65%), 
      repeating-conic-gradient(#FFF 0%, transparent .0004%, transparent 0.05%, transparent .495%);
    -webkit-animation: particlesMovement 5s ease-in 0s infinite;
            animation: particlesMovement 5s ease-in 0s infinite;
    z-index: 1;
  }
  
  .particles:after {
    -webkit-animation-name: particlesMovement2;
            animation-name: particlesMovement2;
    -webkit-animation-delay: 1s;
            animation-delay: 1s;
  }
  
  .particles + .particles + .particles::before {
      -webkit-animation-delay: 2s;
              animation-delay: 2s;
  }
  
  .particles + .particles + .particles::after {
      -webkit-animation-delay: 3s;
              animation-delay: 3s;
  }
  
  @-webkit-keyframes particlesMovement {
    0% { 
      -webkit-transform: translateZ(-50vmin); 
              transform: translateZ(-50vmin); 
      opacity: 0; 
    }
    30%, 70%, 90% { 
      opacity: 1; 
    }
    100% { 
      -webkit-transform: translateZ(50vmin) rotate(15deg); 
              transform: translateZ(50vmin) rotate(15deg); 
      opacity: 0; 
    }
  }
  
  @keyframes particlesMovement {
    0% { 
      -webkit-transform: translateZ(-50vmin); 
              transform: translateZ(-50vmin); 
      opacity: 0; 
    }
    30%, 70%, 90% { 
      opacity: 1; 
    }
    100% { 
      -webkit-transform: translateZ(50vmin) rotate(15deg); 
              transform: translateZ(50vmin) rotate(15deg); 
      opacity: 0; 
    }
  }
  
  @-webkit-keyframes particlesMovement2 {
    0% { 
      -webkit-transform: translateZ(-50vmin) rotate(180deg); 
              transform: translateZ(-50vmin) rotate(180deg); 
      opacity: 0; 
    }
    30%, 70%, 90% { 
       opacity: 1; 
    }
    100% { 
      -webkit-transform: translateZ(50vmin) rotate(195deg); 
              transform: translateZ(50vmin) rotate(195deg); 
      opacity: 0; 
    }
  }
  
  @keyframes particlesMovement2 {
    0% { 
      -webkit-transform: translateZ(-50vmin) rotate(180deg); 
              transform: translateZ(-50vmin) rotate(180deg); 
      opacity: 0; 
    }
    30%, 70%, 90% { 
       opacity: 1; 
    }
    100% { 
      -webkit-transform: translateZ(50vmin) rotate(195deg); 
              transform: translateZ(50vmin) rotate(195deg); 
      opacity: 0; 
    }
  }
}

@media screen and (min-width: 3840px) {

  @-webkit-keyframes gradient {
      0% {
          background-position: 0% 0%;
      }
      50% {
          background-position: 100% 100%;
      }
      100% {
          background-position: 0% 0%;
      }
  }

  @keyframes gradient {
      0% {
          background-position: 0% 0%;
      }
      50% {
          background-position: 100% 100%;
      }
      100% {
          background-position: 0% 0%;
      }
  }
  
  .particles {
    position: absolute;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
  }
  
  .particles:before, .particles:after {
    width: 200vw;
    height: 130vh;
    left: -50vw;
    top: -50vh;
    content: "";
    border-radius: 100%;
    opacity: 0;
    -webkit-transform: translateZ(-50vmin);
            transform: translateZ(-50vmin);
    background-image: repeating-conic-gradient(#FFF8 0%, transparent .0002%, transparent .075%, transparent .65%), 
      repeating-conic-gradient(#FFF 0%, transparent .0004%, transparent 0.05%, transparent .495%);
    -webkit-animation: particlesMovement 5s ease-in 0s infinite;
            animation: particlesMovement 5s ease-in 0s infinite;
    z-index: 1;
  }
  
  .particles:after {
    -webkit-animation-name: particlesMovement2;
            animation-name: particlesMovement2;
    -webkit-animation-delay: 1s;
            animation-delay: 1s;
  }
  
  .particles + .particles + .particles::before {
      -webkit-animation-delay: 2s;
              animation-delay: 2s;
  }
  
  .particles + .particles + .particles::after {
      -webkit-animation-delay: 3s;
              animation-delay: 3s;
  }
  
  @-webkit-keyframes particlesMovement {
    0% { 
      -webkit-transform: translateZ(-50vmin); 
              transform: translateZ(-50vmin); 
      opacity: 0; 
    }
    30%, 70%, 90% { 
      opacity: 1; 
    }
    100% { 
      -webkit-transform: translateZ(50vmin) rotate(15deg); 
              transform: translateZ(50vmin) rotate(15deg); 
      opacity: 0; 
    }
  }
  
  @keyframes particlesMovement {
    0% { 
      -webkit-transform: translateZ(-50vmin); 
              transform: translateZ(-50vmin); 
      opacity: 0; 
    }
    30%, 70%, 90% { 
      opacity: 1; 
    }
    100% { 
      -webkit-transform: translateZ(50vmin) rotate(15deg); 
              transform: translateZ(50vmin) rotate(15deg); 
      opacity: 0; 
    }
  }
  
  @-webkit-keyframes particlesMovement2 {
    0% { 
      -webkit-transform: translateZ(-50vmin) rotate(180deg); 
              transform: translateZ(-50vmin) rotate(180deg); 
      opacity: 0; 
    }
    30%, 70%, 90% { 
       opacity: 1; 
    }
    100% { 
      -webkit-transform: translateZ(50vmin) rotate(195deg); 
              transform: translateZ(50vmin) rotate(195deg); 
      opacity: 0; 
    }
  }
  
  @keyframes particlesMovement2 {
    0% { 
      -webkit-transform: translateZ(-50vmin) rotate(180deg); 
              transform: translateZ(-50vmin) rotate(180deg); 
      opacity: 0; 
    }
    30%, 70%, 90% { 
       opacity: 1; 
    }
    100% { 
      -webkit-transform: translateZ(50vmin) rotate(195deg); 
              transform: translateZ(50vmin) rotate(195deg); 
      opacity: 0; 
    }
  }
}
.boxWrapC {
  display: flex;
  flex-flow: column nowrap;
   margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: 100%;
  background-color: rgba(255, 255, 255, 0);
} 


@media screen and (max-width: 499px) {

  .carouselIMG {
    width: 240px;
    height: 240px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .carouselIMG2 {
    width: 300px;
    height: 300px;
    margin-left: 10px;
    margin-right: 10px;
  }

  

  @-webkit-keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(96, 37, 163);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(104, 27, 128, 0.233);
    }
  }

  

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(96, 37, 163);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(104, 27, 128, 0.233);
    }
  }

  @-webkit-keyframes scroll {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(calc(-250px * 8));
              transform: translateX(calc(-250px * 8));
    }
  }

  @keyframes scroll {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(calc(-250px * 8));
              transform: translateX(calc(-250px * 8));
    }
  }

  @-webkit-keyframes scroll2 {
    0% {
      -webkit-transform: translateX(-1500px);
              transform: translateX(-1500px);
    }

    100% {
      -webkit-transform: translateX(calc(0 * 16));
              transform: translateX(calc(0 * 16));
    }
  }

  @keyframes scroll2 {
    0% {
      -webkit-transform: translateX(-1500px);
              transform: translateX(-1500px);
    }

    100% {
      -webkit-transform: translateX(calc(0 * 16));
              transform: translateX(calc(0 * 16));
    }
  }

  @-webkit-keyframes scroll3 {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(calc(-90px * 7));
              transform: translateX(calc(-90px * 7));
    }
  }

  @keyframes scroll3 {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(calc(-90px * 7));
              transform: translateX(calc(-90px * 7));
    }
  }

  .slider {
    height: 230px;
    width: 100%;
    overflow: hidden;
    position: relative;
    margin-top: 50px;
   }

  .slider2 {
    height: 400px;
    width: 100%;
    overflow: hidden;
    position: relative;
    margin-top: -5%;
  }

  .slider::before,
  .slider::after {
    content: "";
    height: 500px;
    position: absolute;
    width: 150px;
    z-index: 2;
  }

  .slider::after {
    right: 0;
    top: 0;
    -webkit-transform: rotateZ(180deg);
            transform: rotateZ(180deg);
  }

  .slider::before {
    left: 0;
    top: 0;
  }

  .slider .slide-track2 {
    -webkit-animation: scroll2 40s linear infinite;
            animation: scroll2 40s linear infinite;
    display: flex;
    width: calc(150px * 14);
  }

  .slider2 .slide-track2 {
    -webkit-animation: scroll2 40s linear infinite;
            animation: scroll2 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }

  .slider .slide-track3 {
    -webkit-animation: scroll3 40s linear infinite;
            animation: scroll3 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }

  .slider2 .slide-track3 {
    -webkit-animation: scroll3 40s linear infinite;
            animation: scroll3 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }


  .slider .slide {
    height: 250px;
    width: 435px;
  }

  .slider2 .slide {
    height: 250px;
    width: 435px;
  }

  .welcome {
    font-size: 45px;
    font-family: 'Roboto', sans-serif;
    text-align: center;
    padding: 50px;
    color: white;
    background-color: rgb(22, 22, 22);
  }
}

@media screen and (min-width: 500px) and (max-width: 767px) {

  .carouselIMG {
    width: 270px;
    height: 270px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .carouselIMG2 {
    width: 300px;
    height: 300px;
    margin-left: 10px;
    margin-right: 10px;
  }

  

  @-webkit-keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(96, 37, 163);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(104, 27, 128, 0.233);
    }
  }

  

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(96, 37, 163);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(104, 27, 128, 0.233);
    }
  }

  @-webkit-keyframes scroll {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(calc(-250px * 8));
              transform: translateX(calc(-250px * 8));
    }
  }

  @keyframes scroll {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(calc(-250px * 8));
              transform: translateX(calc(-250px * 8));
    }
  }

  @-webkit-keyframes scroll2 {
    0% {
      -webkit-transform: translateX(-1500px);
              transform: translateX(-1500px);
    }

    100% {
      -webkit-transform: translateX(calc(0 * 16));
              transform: translateX(calc(0 * 16));
    }
  }

  @keyframes scroll2 {
    0% {
      -webkit-transform: translateX(-1500px);
              transform: translateX(-1500px);
    }

    100% {
      -webkit-transform: translateX(calc(0 * 16));
              transform: translateX(calc(0 * 16));
    }
  }

  @-webkit-keyframes scroll3 {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(calc(-90px * 7));
              transform: translateX(calc(-90px * 7));
    }
  }

  @keyframes scroll3 {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(calc(-90px * 7));
              transform: translateX(calc(-90px * 7));
    }
  }

  .slider {
    height: 270px;
    width: 100%;
    overflow: hidden;
    position: relative;
    margin-top: 50px;
   }

  .slider2 {
    height: 400px;
    width: 100%;
    overflow: hidden;
    position: relative;
    margin-top: -5%;
  }

  .slider::before,
  .slider::after {
    content: "";
    height: 500px;
    position: absolute;
    width: 150px;
    z-index: 2;
  }

  .slider::after {
    right: 0;
    top: 0;
    -webkit-transform: rotateZ(180deg);
            transform: rotateZ(180deg);
  }

  .slider::before {
    left: 0;
    top: 0;
  }

  .slider .slide-track2 {
    -webkit-animation: scroll2 40s linear infinite;
            animation: scroll2 40s linear infinite;
    display: flex;
    width: calc(150px * 14);
  }

  .slider2 .slide-track2 {
    -webkit-animation: scroll2 40s linear infinite;
            animation: scroll2 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }

  .slider .slide-track3 {
    -webkit-animation: scroll3 40s linear infinite;
            animation: scroll3 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }

  .slider2 .slide-track3 {
    -webkit-animation: scroll3 40s linear infinite;
            animation: scroll3 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }


  .slider .slide {
    height: 250px;
    width: 435px;
  }

  .slider2 .slide {
    height: 250px;
    width: 435px;
  }

  .welcome {
    font-size: 45px;
    font-family: 'Roboto', sans-serif;
    text-align: center;
    padding: 50px;
    color: white;
    background-color: rgb(22, 22, 22);
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {

  .carouselIMG {
    width: 300px;
    height: 300px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .carouselIMG2 {
    width: 300px;
    height: 300px;
    margin-left: 10px;
    margin-right: 10px;
  }

  

  @-webkit-keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(96, 37, 163);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(104, 27, 128, 0.233);
    }
  }

  

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(96, 37, 163);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(104, 27, 128, 0.233);
    }
  }

  @-webkit-keyframes scroll {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(calc(-250px * 8));
              transform: translateX(calc(-250px * 8));
    }
  }

  @keyframes scroll {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(calc(-250px * 8));
              transform: translateX(calc(-250px * 8));
    }
  }

  @-webkit-keyframes scroll2 {
    0% {
      -webkit-transform: translateX(-1500px);
              transform: translateX(-1500px);
    }

    100% {
      -webkit-transform: translateX(calc(0 * 16));
              transform: translateX(calc(0 * 16));
    }
  }

  @keyframes scroll2 {
    0% {
      -webkit-transform: translateX(-1500px);
              transform: translateX(-1500px);
    }

    100% {
      -webkit-transform: translateX(calc(0 * 16));
              transform: translateX(calc(0 * 16));
    }
  }

  @-webkit-keyframes scroll3 {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(calc(-90px * 7));
              transform: translateX(calc(-90px * 7));
    }
  }

  @keyframes scroll3 {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(calc(-90px * 7));
              transform: translateX(calc(-90px * 7));
    }
  }

  .slider {
    height: 300px;
    width: 100%;
    overflow: hidden;
    position: relative;
    margin-top: 50px;
   }

  .slider2 {
    height: 400px;
    width: 100%;
    overflow: hidden;
    position: relative;
    margin-top: -5%;
  }

  .slider::before,
  .slider::after {
    content: "";
    height: 500px;
    position: absolute;
    width: 200px;
    z-index: 2;
  }

  .slider::after {
    right: 0;
    top: 0;
    -webkit-transform: rotateZ(180deg);
            transform: rotateZ(180deg);
  }

  .slider::before {
    left: 0;
    top: 0;
  }

  .slider .slide-track2 {
    -webkit-animation: scroll2 40s linear infinite;
            animation: scroll2 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }

  .slider2 .slide-track2 {
    -webkit-animation: scroll2 40s linear infinite;
            animation: scroll2 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }

  .slider .slide-track3 {
    -webkit-animation: scroll3 40s linear infinite;
            animation: scroll3 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }

  .slider2 .slide-track3 {
    -webkit-animation: scroll3 40s linear infinite;
            animation: scroll3 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }


  .slider .slide {
    height: 250px;
    width: 435px;
  }

  .slider2 .slide {
    height: 250px;
    width: 435px;
  }

  .welcome {
    font-size: 45px;
    font-family: 'Roboto', sans-serif;
    text-align: center;
    padding: 50px;
    color: white;
    background-color: rgb(22, 22, 22);
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {

  .carouselIMG {
    width: 300px;
    height: 300px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .carouselIMG2 {
    width: 300px;
    height: 300px;
    margin-left: 10px;
    margin-right: 10px;
  }

  

  @-webkit-keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(96, 37, 163);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(104, 27, 128, 0.233);
    }
  }

  

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(96, 37, 163);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(104, 27, 128, 0.233);
    }
  }

  @-webkit-keyframes scroll {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(calc(-250px * 8));
              transform: translateX(calc(-250px * 8));
    }
  }

  @keyframes scroll {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(calc(-250px * 8));
              transform: translateX(calc(-250px * 8));
    }
  }

  @-webkit-keyframes scroll2 {
    0% {
      -webkit-transform: translateX(-1500px);
              transform: translateX(-1500px);
    }

    100% {
      -webkit-transform: translateX(calc(0 * 16));
              transform: translateX(calc(0 * 16));
    }
  }

  @keyframes scroll2 {
    0% {
      -webkit-transform: translateX(-1500px);
              transform: translateX(-1500px);
    }

    100% {
      -webkit-transform: translateX(calc(0 * 16));
              transform: translateX(calc(0 * 16));
    }
  }

  @-webkit-keyframes scroll3 {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(calc(-90px * 7));
              transform: translateX(calc(-90px * 7));
    }
  }

  @keyframes scroll3 {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(calc(-90px * 7));
              transform: translateX(calc(-90px * 7));
    }
  }

  .slider {
    height: 300px;
    width: 100%;
    overflow: hidden;
    position: relative;
    margin-top: 50px;
   }

  .slider2 {
    height: 400px;
    width: 100%;
    overflow: hidden;
    position: relative;
    margin-top: -5%;
  }

  .slider::before,
  .slider::after {
    content: "";
    height: 500px;
    position: absolute;
    width: 200px;
    z-index: 2;
  }

  .slider::after {
    right: 0;
    top: 0;
    -webkit-transform: rotateZ(180deg);
            transform: rotateZ(180deg);
  }

  .slider::before {
    left: 0;
    top: 0;
  }

  .slider .slide-track2 {
    -webkit-animation: scroll2 40s linear infinite;
            animation: scroll2 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }

  .slider2 .slide-track2 {
    -webkit-animation: scroll2 40s linear infinite;
            animation: scroll2 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }

  .slider .slide-track3 {
    -webkit-animation: scroll3 40s linear infinite;
            animation: scroll3 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }

  .slider2 .slide-track3 {
    -webkit-animation: scroll3 40s linear infinite;
            animation: scroll3 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }


  .slider .slide {
    height: 250px;
    width: 435px;
  }

  .slider2 .slide {
    height: 250px;
    width: 435px;
  }

  .welcome {
    font-size: 45px;
    font-family: 'Roboto', sans-serif;
    text-align: center;
    padding: 50px;
    color: white;
    background-color: rgb(22, 22, 22);
  }
}

@media screen and (min-width: 1200px) and (max-width: 1919px) {

  .carouselIMG {
    width: 450px;
    height: 450px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .carouselIMG2 {
    width: 300px;
    height: 300px;
    margin-left: 10px;
    margin-right: 10px;
  }

  

  @-webkit-keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(96, 37, 163);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(104, 27, 128, 0.233);
    }
  }

  

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(96, 37, 163);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(104, 27, 128, 0.233);
    }
  }

  @-webkit-keyframes scroll {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(calc(-250px * 8));
              transform: translateX(calc(-250px * 8));
    }
  }

  @keyframes scroll {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(calc(-250px * 8));
              transform: translateX(calc(-250px * 8));
    }
  }

  @-webkit-keyframes scroll2 {
    0% {
      -webkit-transform: translateX(-1500px);
              transform: translateX(-1500px);
    }

    100% {
      -webkit-transform: translateX(calc(0 * 16));
              transform: translateX(calc(0 * 16));
    }
  }

  @keyframes scroll2 {
    0% {
      -webkit-transform: translateX(-1500px);
              transform: translateX(-1500px);
    }

    100% {
      -webkit-transform: translateX(calc(0 * 16));
              transform: translateX(calc(0 * 16));
    }
  }

  @-webkit-keyframes scroll3 {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(calc(-90px * 7));
              transform: translateX(calc(-90px * 7));
    }
  }

  @keyframes scroll3 {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(calc(-90px * 7));
              transform: translateX(calc(-90px * 7));
    }
  }

  .slider {
    height: 450px;
    width: 100%;
    overflow: hidden;
    position: relative;
    margin-top: 50px;
   }

  .slider2 {
    height: 400px;
    width: 100%;
    overflow: hidden;
    position: relative;
    margin-top: -5%;
  }

  .slider::before,
  .slider::after {
    content: "";
    height: 500px;
    position: absolute;
    width: 200px;
    z-index: 2;
  }

  .slider::after {
    right: 0;
    top: 0;
    -webkit-transform: rotateZ(180deg);
            transform: rotateZ(180deg);
  }

  .slider::before {
    left: 0;
    top: 0;
  }

  .slider .slide-track2 {
    -webkit-animation: scroll2 40s linear infinite;
            animation: scroll2 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }

  .slider2 .slide-track2 {
    -webkit-animation: scroll2 40s linear infinite;
            animation: scroll2 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }

  .slider .slide-track3 {
    -webkit-animation: scroll3 40s linear infinite;
            animation: scroll3 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }

  .slider2 .slide-track3 {
    -webkit-animation: scroll3 40s linear infinite;
            animation: scroll3 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }


  .slider .slide {
    height: 250px;
    width: 435px;
  }

  .slider2 .slide {
    height: 250px;
    width: 435px;
  }

  .welcome {
    font-size: 45px;
    font-family: 'Roboto', sans-serif;
    text-align: center;
    padding: 50px;
    color: white;
    background-color: rgb(22, 22, 22);
  }
}

@media screen and (min-width: 1920px) {

  .carouselIMG {
    width: 550px;
    height: 550px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .carouselIMG2 {
    width: 300px;
    height: 300px;
    margin-left: 10px;
    margin-right: 10px;
  }

  

  @-webkit-keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(96, 37, 163);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(104, 27, 128, 0.233);
    }
  }

  

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(96, 37, 163);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(104, 27, 128, 0.233);
    }
  }

  @-webkit-keyframes scroll {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(calc(-250px * 8));
              transform: translateX(calc(-250px * 8));
    }
  }

  @keyframes scroll {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(calc(-250px * 8));
              transform: translateX(calc(-250px * 8));
    }
  }

  @-webkit-keyframes scroll2 {
    0% {
      -webkit-transform: translateX(-1500px);
              transform: translateX(-1500px);
    }

    100% {
      -webkit-transform: translateX(calc(0 * 16));
              transform: translateX(calc(0 * 16));
    }
  }

  @keyframes scroll2 {
    0% {
      -webkit-transform: translateX(-1500px);
              transform: translateX(-1500px);
    }

    100% {
      -webkit-transform: translateX(calc(0 * 16));
              transform: translateX(calc(0 * 16));
    }
  }

  @-webkit-keyframes scroll3 {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(calc(-90px * 7));
              transform: translateX(calc(-90px * 7));
    }
  }

  @keyframes scroll3 {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(calc(-90px * 7));
              transform: translateX(calc(-90px * 7));
    }
  }

  .slider {
    height: 540px;
    width: 100%;
    overflow: hidden;
    position: relative;
    margin-top: 50px;
   }

  .slider2 {
    height: 400px;
    width: 100%;
    overflow: hidden;
    position: relative;
    margin-top: -5%;
  }

  .slider::before,
  .slider::after {
    content: "";
    height: 500px;
    position: absolute;
    width: 300px;
    z-index: 2;
  }

  .slider::after {
    right: 0;
    top: 0;
    -webkit-transform: rotateZ(180deg);
            transform: rotateZ(180deg);
  }

  .slider::before {
    left: 0;
    top: 0;
  }

  .slider .slide-track2 {
    -webkit-animation: scroll2 40s linear infinite;
            animation: scroll2 40s linear infinite;
    display: flex;
    width: calc(350px * 14);
  }

  .slider2 .slide-track2 {
    -webkit-animation: scroll2 40s linear infinite;
            animation: scroll2 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }

  .slider .slide-track3 {
    -webkit-animation: scroll3 40s linear infinite;
            animation: scroll3 40s linear infinite;
    display: flex;
    width: calc(400px * 14);
  }

  .slider2 .slide-track3 {
    -webkit-animation: scroll3 40s linear infinite;
            animation: scroll3 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }


  .slider .slide {
    height: 250px;
    width: 700px;
  }

  .slider2 .slide {
    height: 250px;
    width: 435px;
  }

  .welcome {
    font-size: 45px;
    font-family: 'Roboto', sans-serif;
    text-align: center;
    padding: 50px;
    color: white;
    background-color: rgb(22, 22, 22);
  }
}

@media screen and (min-width: 1921px) and (max-width: 3839px) {

  .carouselIMG {
    width: 750px;
    height: 750px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .carouselIMG2 {
    width: 300px;
    height: 300px;
    margin-left: 10px;
    margin-right: 10px;
  }

  

  @-webkit-keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(96, 37, 163);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(104, 27, 128, 0.233);
    }
  }

  

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(96, 37, 163);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(104, 27, 128, 0.233);
    }
  }

  @-webkit-keyframes scroll {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(calc(-250px * 8));
              transform: translateX(calc(-250px * 8));
    }
  }

  @keyframes scroll {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(calc(-250px * 8));
              transform: translateX(calc(-250px * 8));
    }
  }

  @-webkit-keyframes scroll2 {
    0% {
      -webkit-transform: translateX(-1500px);
              transform: translateX(-1500px);
    }

    100% {
      -webkit-transform: translateX(calc(0 * 16));
              transform: translateX(calc(0 * 16));
    }
  }

  @keyframes scroll2 {
    0% {
      -webkit-transform: translateX(-1500px);
              transform: translateX(-1500px);
    }

    100% {
      -webkit-transform: translateX(calc(0 * 16));
              transform: translateX(calc(0 * 16));
    }
  }

  @-webkit-keyframes scroll3 {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(calc(-90px * 7));
              transform: translateX(calc(-90px * 7));
    }
  }

  @keyframes scroll3 {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(calc(-90px * 7));
              transform: translateX(calc(-90px * 7));
    }
  }

  .slider {
    height: 700px;
    width: 100%;
    overflow: hidden;
    position: relative;
    margin-top: 50px;
   }

  .slider2 {
    height: 400px;
    width: 100%;
    overflow: hidden;
    position: relative;
    margin-top: -5%;
  }

  .slider::before,
  .slider::after {
    content: "";
    height: 500px;
    position: absolute;
    width: 300px;
    z-index: 2;
  }

  .slider::after {
    right: 0;
    top: 0;
    -webkit-transform: rotateZ(180deg);
            transform: rotateZ(180deg);
  }

  .slider::before {
    left: 0;
    top: 0;
  }

  .slider .slide-track2 {
    -webkit-animation: scroll2 40s linear infinite;
            animation: scroll2 40s linear infinite;
    display: flex;
    width: calc(480px * 14);
  }

  .slider2 .slide-track2 {
    -webkit-animation: scroll2 40s linear infinite;
            animation: scroll2 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }

  .slider .slide-track3 {
    -webkit-animation: scroll3 40s linear infinite;
            animation: scroll3 40s linear infinite;
    display: flex;
    width: calc(400px * 14);
  }

  .slider2 .slide-track3 {
    -webkit-animation: scroll3 40s linear infinite;
            animation: scroll3 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }


  .slider .slide {
    height: 250px;
    width: 800px;
  }

  .slider2 .slide {
    height: 250px;
    width: 435px;
  }

  .welcome {
    font-size: 45px;
    font-family: 'Roboto', sans-serif;
    text-align: center;
    padding: 50px;
    color: white;
    background-color: rgb(22, 22, 22);
  }
}

@media screen and (min-width: 3840px) {

  .carouselIMG {
    width: 850px;
    height: 850px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .carouselIMG2 {
    width: 300px;
    height: 300px;
    margin-left: 10px;
    margin-right: 10px;
  }

  

  @-webkit-keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(96, 37, 163);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(104, 27, 128, 0.233);
    }
  }

  

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(96, 37, 163);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(104, 27, 128, 0.233);
    }
  }

  @-webkit-keyframes scroll {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(calc(-250px * 8));
              transform: translateX(calc(-250px * 8));
    }
  }

  @keyframes scroll {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(calc(-250px * 8));
              transform: translateX(calc(-250px * 8));
    }
  }

  @-webkit-keyframes scroll2 {
    0% {
      -webkit-transform: translateX(-1500px);
              transform: translateX(-1500px);
    }

    100% {
      -webkit-transform: translateX(calc(0 * 16));
              transform: translateX(calc(0 * 16));
    }
  }

  @keyframes scroll2 {
    0% {
      -webkit-transform: translateX(-1500px);
              transform: translateX(-1500px);
    }

    100% {
      -webkit-transform: translateX(calc(0 * 16));
              transform: translateX(calc(0 * 16));
    }
  }

  @-webkit-keyframes scroll3 {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(calc(-90px * 7));
              transform: translateX(calc(-90px * 7));
    }
  }

  @keyframes scroll3 {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(calc(-90px * 7));
              transform: translateX(calc(-90px * 7));
    }
  }

  .slider {
    height: 840px;
    width: 100%;
    overflow: hidden;
    position: relative;
    margin-top: 50px;
   }

  .slider2 {
    height: 400px;
    width: 100%;
    overflow: hidden;
    position: relative;
    margin-top: -5%;
  }

  .slider::before,
  .slider::after {
    content: "";
    height: 500px;
    position: absolute;
    width: 300px;
    z-index: 2;
  }

  .slider::after {
    right: 0;
    top: 0;
    -webkit-transform: rotateZ(180deg);
            transform: rotateZ(180deg);
  }

  .slider::before {
    left: 0;
    top: 0;
  }

  .slider .slide-track2 {
    -webkit-animation: scroll2 40s linear infinite;
            animation: scroll2 40s linear infinite;
    display: flex;
    width: calc(480px * 14);
  }

  .slider2 .slide-track2 {
    -webkit-animation: scroll2 40s linear infinite;
            animation: scroll2 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }

  .slider .slide-track3 {
    -webkit-animation: scroll3 40s linear infinite;
            animation: scroll3 40s linear infinite;
    display: flex;
    width: calc(400px * 14);
  }

  .slider2 .slide-track3 {
    -webkit-animation: scroll3 40s linear infinite;
            animation: scroll3 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }


  .slider .slide {
    height: 250px;
    width: 800px;
  }

  .slider2 .slide {
    height: 250px;
    width: 435px;
  }

  .welcome {
    font-size: 45px;
    font-family: 'Roboto', sans-serif;
    text-align: center;
    padding: 50px;
    color: white;
    background-color: rgb(22, 22, 22);
  }
}
