.boxWrapC {
  display: flex;
  flex-flow: column nowrap;
   margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: 100%;
  background-color: rgba(255, 255, 255, 0);
} 


@media screen and (max-width: 499px) {

  .carouselIMG {
    width: 240px;
    height: 240px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .carouselIMG2 {
    width: 300px;
    height: 300px;
    margin-left: 10px;
    margin-right: 10px;
  }

  

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(96, 37, 163);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(104, 27, 128, 0.233);
    }
  }

  @keyframes scroll {
    0% {
      transform: translateX(0);
    }

    100% {
      transform: translateX(calc(-250px * 8));
    }
  }

  @keyframes scroll2 {
    0% {
      transform: translateX(-1500px);
    }

    100% {
      transform: translateX(calc(0 * 16));
    }
  }

  @keyframes scroll3 {
    0% {
      transform: translateX(0);
    }

    100% {
      transform: translateX(calc(-90px * 7));
    }
  }

  .slider {
    height: 230px;
    width: 100%;
    overflow: hidden;
    position: relative;
    margin-top: 50px;
   }

  .slider2 {
    height: 400px;
    width: 100%;
    overflow: hidden;
    position: relative;
    margin-top: -5%;
  }

  .slider::before,
  .slider::after {
    content: "";
    height: 500px;
    position: absolute;
    width: 150px;
    z-index: 2;
  }

  .slider::after {
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
  }

  .slider::before {
    left: 0;
    top: 0;
  }

  .slider .slide-track2 {
    animation: scroll2 40s linear infinite;
    display: flex;
    width: calc(150px * 14);
  }

  .slider2 .slide-track2 {
    animation: scroll2 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }

  .slider .slide-track3 {
    animation: scroll3 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }

  .slider2 .slide-track3 {
    animation: scroll3 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }


  .slider .slide {
    height: 250px;
    width: 435px;
  }

  .slider2 .slide {
    height: 250px;
    width: 435px;
  }

  .welcome {
    font-size: 45px;
    font-family: 'Roboto', sans-serif;
    text-align: center;
    padding: 50px;
    color: white;
    background-color: rgb(22, 22, 22);
  }
}

@media screen and (min-width: 500px) and (max-width: 767px) {

  .carouselIMG {
    width: 270px;
    height: 270px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .carouselIMG2 {
    width: 300px;
    height: 300px;
    margin-left: 10px;
    margin-right: 10px;
  }

  

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(96, 37, 163);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(104, 27, 128, 0.233);
    }
  }

  @keyframes scroll {
    0% {
      transform: translateX(0);
    }

    100% {
      transform: translateX(calc(-250px * 8));
    }
  }

  @keyframes scroll2 {
    0% {
      transform: translateX(-1500px);
    }

    100% {
      transform: translateX(calc(0 * 16));
    }
  }

  @keyframes scroll3 {
    0% {
      transform: translateX(0);
    }

    100% {
      transform: translateX(calc(-90px * 7));
    }
  }

  .slider {
    height: 270px;
    width: 100%;
    overflow: hidden;
    position: relative;
    margin-top: 50px;
   }

  .slider2 {
    height: 400px;
    width: 100%;
    overflow: hidden;
    position: relative;
    margin-top: -5%;
  }

  .slider::before,
  .slider::after {
    content: "";
    height: 500px;
    position: absolute;
    width: 150px;
    z-index: 2;
  }

  .slider::after {
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
  }

  .slider::before {
    left: 0;
    top: 0;
  }

  .slider .slide-track2 {
    animation: scroll2 40s linear infinite;
    display: flex;
    width: calc(150px * 14);
  }

  .slider2 .slide-track2 {
    animation: scroll2 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }

  .slider .slide-track3 {
    animation: scroll3 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }

  .slider2 .slide-track3 {
    animation: scroll3 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }


  .slider .slide {
    height: 250px;
    width: 435px;
  }

  .slider2 .slide {
    height: 250px;
    width: 435px;
  }

  .welcome {
    font-size: 45px;
    font-family: 'Roboto', sans-serif;
    text-align: center;
    padding: 50px;
    color: white;
    background-color: rgb(22, 22, 22);
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {

  .carouselIMG {
    width: 300px;
    height: 300px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .carouselIMG2 {
    width: 300px;
    height: 300px;
    margin-left: 10px;
    margin-right: 10px;
  }

  

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(96, 37, 163);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(104, 27, 128, 0.233);
    }
  }

  @keyframes scroll {
    0% {
      transform: translateX(0);
    }

    100% {
      transform: translateX(calc(-250px * 8));
    }
  }

  @keyframes scroll2 {
    0% {
      transform: translateX(-1500px);
    }

    100% {
      transform: translateX(calc(0 * 16));
    }
  }

  @keyframes scroll3 {
    0% {
      transform: translateX(0);
    }

    100% {
      transform: translateX(calc(-90px * 7));
    }
  }

  .slider {
    height: 300px;
    width: 100%;
    overflow: hidden;
    position: relative;
    margin-top: 50px;
   }

  .slider2 {
    height: 400px;
    width: 100%;
    overflow: hidden;
    position: relative;
    margin-top: -5%;
  }

  .slider::before,
  .slider::after {
    content: "";
    height: 500px;
    position: absolute;
    width: 200px;
    z-index: 2;
  }

  .slider::after {
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
  }

  .slider::before {
    left: 0;
    top: 0;
  }

  .slider .slide-track2 {
    animation: scroll2 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }

  .slider2 .slide-track2 {
    animation: scroll2 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }

  .slider .slide-track3 {
    animation: scroll3 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }

  .slider2 .slide-track3 {
    animation: scroll3 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }


  .slider .slide {
    height: 250px;
    width: 435px;
  }

  .slider2 .slide {
    height: 250px;
    width: 435px;
  }

  .welcome {
    font-size: 45px;
    font-family: 'Roboto', sans-serif;
    text-align: center;
    padding: 50px;
    color: white;
    background-color: rgb(22, 22, 22);
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {

  .carouselIMG {
    width: 300px;
    height: 300px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .carouselIMG2 {
    width: 300px;
    height: 300px;
    margin-left: 10px;
    margin-right: 10px;
  }

  

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(96, 37, 163);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(104, 27, 128, 0.233);
    }
  }

  @keyframes scroll {
    0% {
      transform: translateX(0);
    }

    100% {
      transform: translateX(calc(-250px * 8));
    }
  }

  @keyframes scroll2 {
    0% {
      transform: translateX(-1500px);
    }

    100% {
      transform: translateX(calc(0 * 16));
    }
  }

  @keyframes scroll3 {
    0% {
      transform: translateX(0);
    }

    100% {
      transform: translateX(calc(-90px * 7));
    }
  }

  .slider {
    height: 300px;
    width: 100%;
    overflow: hidden;
    position: relative;
    margin-top: 50px;
   }

  .slider2 {
    height: 400px;
    width: 100%;
    overflow: hidden;
    position: relative;
    margin-top: -5%;
  }

  .slider::before,
  .slider::after {
    content: "";
    height: 500px;
    position: absolute;
    width: 200px;
    z-index: 2;
  }

  .slider::after {
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
  }

  .slider::before {
    left: 0;
    top: 0;
  }

  .slider .slide-track2 {
    animation: scroll2 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }

  .slider2 .slide-track2 {
    animation: scroll2 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }

  .slider .slide-track3 {
    animation: scroll3 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }

  .slider2 .slide-track3 {
    animation: scroll3 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }


  .slider .slide {
    height: 250px;
    width: 435px;
  }

  .slider2 .slide {
    height: 250px;
    width: 435px;
  }

  .welcome {
    font-size: 45px;
    font-family: 'Roboto', sans-serif;
    text-align: center;
    padding: 50px;
    color: white;
    background-color: rgb(22, 22, 22);
  }
}

@media screen and (min-width: 1200px) and (max-width: 1919px) {

  .carouselIMG {
    width: 450px;
    height: 450px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .carouselIMG2 {
    width: 300px;
    height: 300px;
    margin-left: 10px;
    margin-right: 10px;
  }

  

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(96, 37, 163);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(104, 27, 128, 0.233);
    }
  }

  @keyframes scroll {
    0% {
      transform: translateX(0);
    }

    100% {
      transform: translateX(calc(-250px * 8));
    }
  }

  @keyframes scroll2 {
    0% {
      transform: translateX(-1500px);
    }

    100% {
      transform: translateX(calc(0 * 16));
    }
  }

  @keyframes scroll3 {
    0% {
      transform: translateX(0);
    }

    100% {
      transform: translateX(calc(-90px * 7));
    }
  }

  .slider {
    height: 450px;
    width: 100%;
    overflow: hidden;
    position: relative;
    margin-top: 50px;
   }

  .slider2 {
    height: 400px;
    width: 100%;
    overflow: hidden;
    position: relative;
    margin-top: -5%;
  }

  .slider::before,
  .slider::after {
    content: "";
    height: 500px;
    position: absolute;
    width: 200px;
    z-index: 2;
  }

  .slider::after {
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
  }

  .slider::before {
    left: 0;
    top: 0;
  }

  .slider .slide-track2 {
    animation: scroll2 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }

  .slider2 .slide-track2 {
    animation: scroll2 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }

  .slider .slide-track3 {
    animation: scroll3 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }

  .slider2 .slide-track3 {
    animation: scroll3 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }


  .slider .slide {
    height: 250px;
    width: 435px;
  }

  .slider2 .slide {
    height: 250px;
    width: 435px;
  }

  .welcome {
    font-size: 45px;
    font-family: 'Roboto', sans-serif;
    text-align: center;
    padding: 50px;
    color: white;
    background-color: rgb(22, 22, 22);
  }
}

@media screen and (min-width: 1920px) {

  .carouselIMG {
    width: 550px;
    height: 550px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .carouselIMG2 {
    width: 300px;
    height: 300px;
    margin-left: 10px;
    margin-right: 10px;
  }

  

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(96, 37, 163);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(104, 27, 128, 0.233);
    }
  }

  @keyframes scroll {
    0% {
      transform: translateX(0);
    }

    100% {
      transform: translateX(calc(-250px * 8));
    }
  }

  @keyframes scroll2 {
    0% {
      transform: translateX(-1500px);
    }

    100% {
      transform: translateX(calc(0 * 16));
    }
  }

  @keyframes scroll3 {
    0% {
      transform: translateX(0);
    }

    100% {
      transform: translateX(calc(-90px * 7));
    }
  }

  .slider {
    height: 540px;
    width: 100%;
    overflow: hidden;
    position: relative;
    margin-top: 50px;
   }

  .slider2 {
    height: 400px;
    width: 100%;
    overflow: hidden;
    position: relative;
    margin-top: -5%;
  }

  .slider::before,
  .slider::after {
    content: "";
    height: 500px;
    position: absolute;
    width: 300px;
    z-index: 2;
  }

  .slider::after {
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
  }

  .slider::before {
    left: 0;
    top: 0;
  }

  .slider .slide-track2 {
    animation: scroll2 40s linear infinite;
    display: flex;
    width: calc(350px * 14);
  }

  .slider2 .slide-track2 {
    animation: scroll2 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }

  .slider .slide-track3 {
    animation: scroll3 40s linear infinite;
    display: flex;
    width: calc(400px * 14);
  }

  .slider2 .slide-track3 {
    animation: scroll3 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }


  .slider .slide {
    height: 250px;
    width: 700px;
  }

  .slider2 .slide {
    height: 250px;
    width: 435px;
  }

  .welcome {
    font-size: 45px;
    font-family: 'Roboto', sans-serif;
    text-align: center;
    padding: 50px;
    color: white;
    background-color: rgb(22, 22, 22);
  }
}

@media screen and (min-width: 1921px) and (max-width: 3839px) {

  .carouselIMG {
    width: 750px;
    height: 750px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .carouselIMG2 {
    width: 300px;
    height: 300px;
    margin-left: 10px;
    margin-right: 10px;
  }

  

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(96, 37, 163);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(104, 27, 128, 0.233);
    }
  }

  @keyframes scroll {
    0% {
      transform: translateX(0);
    }

    100% {
      transform: translateX(calc(-250px * 8));
    }
  }

  @keyframes scroll2 {
    0% {
      transform: translateX(-1500px);
    }

    100% {
      transform: translateX(calc(0 * 16));
    }
  }

  @keyframes scroll3 {
    0% {
      transform: translateX(0);
    }

    100% {
      transform: translateX(calc(-90px * 7));
    }
  }

  .slider {
    height: 700px;
    width: 100%;
    overflow: hidden;
    position: relative;
    margin-top: 50px;
   }

  .slider2 {
    height: 400px;
    width: 100%;
    overflow: hidden;
    position: relative;
    margin-top: -5%;
  }

  .slider::before,
  .slider::after {
    content: "";
    height: 500px;
    position: absolute;
    width: 300px;
    z-index: 2;
  }

  .slider::after {
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
  }

  .slider::before {
    left: 0;
    top: 0;
  }

  .slider .slide-track2 {
    animation: scroll2 40s linear infinite;
    display: flex;
    width: calc(480px * 14);
  }

  .slider2 .slide-track2 {
    animation: scroll2 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }

  .slider .slide-track3 {
    animation: scroll3 40s linear infinite;
    display: flex;
    width: calc(400px * 14);
  }

  .slider2 .slide-track3 {
    animation: scroll3 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }


  .slider .slide {
    height: 250px;
    width: 800px;
  }

  .slider2 .slide {
    height: 250px;
    width: 435px;
  }

  .welcome {
    font-size: 45px;
    font-family: 'Roboto', sans-serif;
    text-align: center;
    padding: 50px;
    color: white;
    background-color: rgb(22, 22, 22);
  }
}

@media screen and (min-width: 3840px) {

  .carouselIMG {
    width: 850px;
    height: 850px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .carouselIMG2 {
    width: 300px;
    height: 300px;
    margin-left: 10px;
    margin-right: 10px;
  }

  

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(96, 37, 163);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(104, 27, 128, 0.233);
    }
  }

  @keyframes scroll {
    0% {
      transform: translateX(0);
    }

    100% {
      transform: translateX(calc(-250px * 8));
    }
  }

  @keyframes scroll2 {
    0% {
      transform: translateX(-1500px);
    }

    100% {
      transform: translateX(calc(0 * 16));
    }
  }

  @keyframes scroll3 {
    0% {
      transform: translateX(0);
    }

    100% {
      transform: translateX(calc(-90px * 7));
    }
  }

  .slider {
    height: 840px;
    width: 100%;
    overflow: hidden;
    position: relative;
    margin-top: 50px;
   }

  .slider2 {
    height: 400px;
    width: 100%;
    overflow: hidden;
    position: relative;
    margin-top: -5%;
  }

  .slider::before,
  .slider::after {
    content: "";
    height: 500px;
    position: absolute;
    width: 300px;
    z-index: 2;
  }

  .slider::after {
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
  }

  .slider::before {
    left: 0;
    top: 0;
  }

  .slider .slide-track2 {
    animation: scroll2 40s linear infinite;
    display: flex;
    width: calc(480px * 14);
  }

  .slider2 .slide-track2 {
    animation: scroll2 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }

  .slider .slide-track3 {
    animation: scroll3 40s linear infinite;
    display: flex;
    width: calc(400px * 14);
  }

  .slider2 .slide-track3 {
    animation: scroll3 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }


  .slider .slide {
    height: 250px;
    width: 800px;
  }

  .slider2 .slide {
    height: 250px;
    width: 435px;
  }

  .welcome {
    font-size: 45px;
    font-family: 'Roboto', sans-serif;
    text-align: center;
    padding: 50px;
    color: white;
    background-color: rgb(22, 22, 22);
  }
}